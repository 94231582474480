<template>
    <v-select>
        <template v-slot:selected-option="option">
            <slot name="selected-option" v-bind="option"></slot>
        </template>
        <template v-slot:option="option">
            <slot name="option" v-bind="option"></slot>
        </template>
    </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    }
}
</script>
