<template>
    <section class="fixed inset-y-0 right-4 max-w-full flex z-40" aria-labelledby="slide-over-heading">
        <transition
            enter-active-class="transition ease-in-out duration-200"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in-out duration-200"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
            <div class="w-screen max-w-md pt-4 pb-20" v-show="isShown">
                <div class="h-full flex flex-col bg-white border border-gray-100 shadow-lg rounded">
                    <div class="flex items-center justify-between px-3 py-3">
                        <div class="flex items-center">
                            <a href="#" class="text-gray-800 hover:text-gray-900" v-if="thread || isThreadLoading || isCreatingNewThread" @click.prevent="resetThread">
                                <ui-icon name="back" class="text-xl mr-1.5"></ui-icon>
                            </a>
                            <h2 id="slide-over-heading" class="text-xl font-semibold">
                                Support
                            </h2>
                        </div>
                        <div>
                            <ui-button v-if="! thread && ! isThreadLoading && ! isCreatingNewThread" :action="newThread" color="green">New Issue</ui-button>
                        </div>
                    </div>

                    <div class="relative flex-1 flex flex-col min-h-0">
                        <form v-if="isCreatingNewThread" @submit.prevent="saveNewThread" class="flex-1 flex flex-col items-center justify-center">
                            <div class="w-full px-3">
                                <label for="name" class="block text-sm font-medium text-gray-700">
                                    Title
                                </label>
                                <div class="mt-1">
                                    <ui-select :options="curatedNames" v-model="newThreadCuratedName"></ui-select>
                                    <div class="text-xs text-red-600 mt-0.5" v-if="errors.name">
                                        <div :key="index" v-for="error, index in errors.name">{{ error }}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="newThreadCuratedName === 'Other issues'" class="w-full px-3 mt-3">
                                <div class="mt-1">
                                    <ui-input required type="text" name="name" id="name" placeholder="Short description for your issue..." v-model="newThreadName"></ui-input>
                                </div>
                            </div>

                            <div class="w-full px-3 mt-3">
                                <label for="name" class="block text-sm font-medium text-gray-700">
                                    Message
                                </label>
                                <div class="mt-1">
                                    <ui-input
                                        type="textarea"
                                        rows="10"
                                        v-model="message"
                                        @keydown.enter.exact.prevent="saveNewThread"
                                        @keydown.enter.shift.exact.prevent="addNewlineToMessage"
                                        @keydown.enter.alt.exact.prevent="addNewlineToMessage"
                                        @focus="resizeMessageInput"
                                        @input="resizeMessageInput"
                                        ref="messageInput"
                                        placeholder="Describe your issue..."
                                        :errors="errors.message"
                                    ></ui-input>
                                </div>
                            </div>

                            <div class="w-full px-3 mt-3 flex justify-between items-center">
                                <p class="text-gray-700 text-xs">
                                    Tip: Use <strong>Shift + Enter</strong> to add a new line
                                </p>
                                <ui-button type="submit" color="blue">Send (⏎)</ui-button>
                            </div>
                        </form>

                        <div v-else-if="! thread && ! isThreadLoading" class="overflow-auto px-3 mb-8">
                            <ui-async class="flex-1 space-y-8"  :guards="threads">
                                <div v-if="threads.filter(t => ! t.resolvedAt).length">
                                    <h2 class="font-medium text-gray-700">Open issues</h2>
                                    <div class="space-y-1.5">
                                        <a href="#" @click.prevent="loadThread(thread.id)" :key="thread.id" v-for="thread in threads.filter(t => ! t.resolvedAt)" class="mt-1.5 flex items-center justify-between pl-4 pr-3 py-2.5 rounded bg-gray-100 shadow cursor-pointer flex hover:shadow-inner">
                                            <div class="flex items-center leading-none pr-3">
                                                <div class="pr-3">
                                                    <ui-icon class="text-3xl" name="chevron-right"></ui-icon>
                                                </div>
                                                <div>
                                                    <p class="text-lg font-medium leading-4">{{ thread.name }}</p>
                                                    <p class="mt-1 text-xs text-gray-700">{{ $dateTime(thread.createdAt) }}</p>
                                                </div>
                                            </div>
                                            <p class="font-medium text-blue-700 shrink-0" v-if="thread.unseenAgentMessagesCount">{{ thread.unseenAgentMessagesCount }} unread</p>
                                        </a>
                                    </div>
                                </div>

                                <div v-if="threads.filter(t => t.resolvedAt).length">
                                    <h2 class="font-medium text-gray-700">Resolved issues</h2>
                                    <a href="#" @click.prevent="loadThread(thread.id)" :key="thread.id" v-for="thread in threads.filter(t => t.resolvedAt)" class="mt-1.5 flex items-center justify-between pl-4 pr-3 rounded bg-gray-100 shadow cursor-pointer flex h-12 hover:shadow-inner">
                                        <div class="flex items-center leading-none">
                                            <div class="pr-3">
                                                <ui-icon class="text-3xl" name="chevron-right"></ui-icon>
                                            </div>
                                            <p class="text-lg font-medium">{{ thread.name }}</p>
                                        </div>
                                        <p class="font-medium" v-if="thread.unseenAgentMessagesCount">{{ thread.unseenAgentMessagesCount }} unread</p>
                                    </a>
                                </div>
                            </ui-async>
                        </div>

                        <div v-else-if="isThreadLoading" class="flex-1 flex flex-col justify-center">
                            <ui-spinner type="clip"></ui-spinner>
                        </div>

                        <div v-else class="overflow-auto px-3 flex flex-col justify-between grow">
                            <div class="flex flex-col overflow-y-auto space-y-2" ref="messages">
                                <div class="break-all" v-for="message in thread.messages" :key="message.id">
                                    <message :message="message"></message>
                                </div>
                            </div>

                            <div class="p-3">
                                <template v-if="! thread.resolvedAt">
                                    <div class="text-gray-700 text-center text-sm mb-3">
                                        You can continue using the app after submitting your question.<br>You will receive a
                                        notification when we have an answer for you.
                                    </div>
                                    <form @submit.prevent="_sendMessage">
                                        <ui-input
                                            type="textarea"
                                            rows="3"
                                            v-model="message"
                                            @keydown.enter.exact.prevent="_sendMessage"
                                            @keydown.enter.shift.exact.prevent="addNewlineToMessage"
                                            @keydown.enter.alt.exact.prevent="addNewlineToMessage"
                                            @focus="resizeMessageInput"
                                            @input="resizeMessageInput"
                                            ref="messageInput"
                                            placeholder="Message..."
                                            :errors="errors.message"
                                        ></ui-input>
                                        <div class="flex justify-between items-center mt-3">
                                            <p class="text-gray-700 text-xs">
                                                Tip: Use <strong>Shift + Enter</strong> to add a new line
                                            </p>
                                            <ui-button type="submit" color="blue">Send (⏎)</ui-button>
                                        </div>
                                    </form>
                                </template>

                                <template v-else>
                                    <div class="text-gray-700 text-center text-sm mb-3">
                                        This issue has been marked as resolved. If you have any further questions, please open a new issue.
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
import Message from '@/components/support/chat/message.vue'

import useSupportChatStore from '@/stores/support/chat'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: { Message },

    data: () => ({
        curatedNames: [
            "I'm getting an unexpected error when using the app",
            "There's a content missing for some of my targets",
            "I'm unable to add new targets to monitoring",
            "I'm having issues with analyses",
            "I'm having issues with Connections Explorer",
            "I'm having issues with my account",
            "I have a suggestion for a new feature",
            "Other issues"
        ]
    }),

    computed: {
        ...mapState(useSupportChatStore, [
            'isShown',
            'isThreadLoading',
            'isCreatingNewThread',
            'threads',
            'thread',
            'errors'
        ]),

        ...mapWritableState(useSupportChatStore, [
            'newThreadCuratedName',
            'newThreadName',
            'message'
        ])
    },

    methods: {
        ...mapActions(useSupportChatStore, [
            'sendMessage',
            'newThread',
            'loadThread',
            'resetThread',
            'saveNewThread'
        ]),

        _sendMessage() {
            this.sendMessage()

            this.$nextTick(() => {
                this.resizeMessageInput()
                this.scrollToBottom()
            })
        },

        scrollToBottom() {
            this.$nextTick(() => {
                if (this.$refs.messages) this.$refs.messages.scroll(0, 999999)
            })
        },

        resizeMessageInput() {
            if (! this.$refs.messageInput || ! this.$refs.messageInput.$refs.input) return

            this.$refs.messageInput.$refs.input.style.height = '2.25rem'

            if (this.$refs.messageInput.$refs.input.scrollHeight > 0 && this.$refs.messageInput.$refs.input.scrollHeight < 150) {
                this.$refs.messageInput.$refs.input.style.height = `${this.$refs.messageInput.$refs.input.scrollHeight}px`
            }

            if (this.$refs.messageInput.$refs.input.scrollHeight >= 150) {
                this.$refs.messageInput.$refs.input.style.height = `150px`
            }

            this.$refs.messageInput.$refs.input.scroll(0, 999999)
        },

        addNewlineToMessage() {
            this.message += '\n'

            this.$nextTick(() => {
                this.resizeMessageInput()
            })
        },
    },

    watch: {
        thread() {
            this.scrollToBottom()
        }
    }
}
</script>
