<template>
    <ui-modal name="report-export" :title="'Export a Report'" :max-width="520" :max-height="480">
        <p>
            This may take a minute. You can safely close this tab and we'll send you an e-mail with the PDF.
        </p>

        <div class="mt-3">
            The e-mail address receiving this export will be <span class="font-medium">{{ user.email }}</span>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" :color="'blue'" :action="() => exportReport()" :disabled="isBeingExported">
                Export
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>


import {mapActions, mapState} from 'pinia'
import useMyStore from '@/stores/me/my'
import useReportStore from '@/stores/dashboards/report'

export default {
    computed: {
        ...mapState(useMyStore, [ 'user' ]),
        ...mapState(useReportStore, [ 'isBeingExported' ])
    },

    methods: {
        ...mapActions(useReportStore, [ 'exportReport' ])
    }
}
</script>
