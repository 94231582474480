import defineCards from '@/stores/reusable/cards'

import useSupportThreadsIndexPage from '@/admin/pages/support-threads-index'
import useThreadsStore from '@/admin/stores/support/threads'

import { textSearch } from '@/helpers'
import { markRaw } from 'vue'

export const useThreadsCardsStore = defineCards({
    id: 'threadsCards',

    source: useThreadsStore(),
    filter: items => textSearch(useSupportThreadsIndexPage().search, items, i => i.customer.name),

    layoutOptions: [
        {
            id: 'table',
            name: 'Table',
            icon: 'menu',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/table').default),
            settings: { showCharts: false }
        }
    ]
})

export default useThreadsCardsStore
