<template>
    <ui-modal name="workspace-member-create" title="Create a new Workspace Member" :max-width="520" @opened="opened">
        <ui-form :store="memberSettingsFormStore" v-slot="{ inputs, submitting, errors }">
            <label for="name" class="block text-sm font-medium text-gray-700 mt-4">
                Name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
            </div>

            <label for="email" class="block text-sm font-medium text-gray-700 mt-4">
                E-mail
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.email" :errors="errors.email" ref="email"></ui-input>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="green" :disabled="submitting">
                    Create
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useMemberSettingsFormStore)
    },

    methods: {
        opened() { this.$refs.name.focus() }
    }
}
</script>
