import { defineTargetsStreamStore } from '@/stores/reusable/stream'

export const useTargetsListStreamStore = defineTargetsStreamStore({
    id: 'targetsListStream',

    exportable: true,
    importable: true,
    resultsToolbar: { inspect: false, tags: false }
})

export default useTargetsListStreamStore
