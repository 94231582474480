import useMemberStore from '@/admin/stores/accounts/member'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationMemberPage = defineStore({
    id: 'accountsOrganizationsOrganizationMemberPage',

    actions: {
        beforeEnter(to) {
            if (to.params.memberId != useMemberStore().member?.id) {
                useMemberStore().initialize(to.params.organizationId, to.params.memberId, true)
            }
        }
    }
})

export default useAccountsOrganizationsOrganizationMemberPage
