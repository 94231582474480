<template>
    <ui-async :guards="$page.analysis || $page.isNew">
        <ui-header :title="$page.store ? $page.store.name : 'Untitled Analysis'" :subtitle="$page.type.name" avatar-type="analysis">
            <template v-slot:actions>
                <div v-if="$page.store.isTakingTooLong">
                    <span class="font-medium">Tip</span>: Save this analysis while it's building and we'll notify you when it's processed!
                </div>

                <ui-button icon="check-square" color="blue" :action="() => { save($page.store.isTakingTooLong) }" :disabled="$page.store.isSaving" v-if="$page.store.isDirty || $page.store.isTakingTooLong">
                    Save
                </ui-button>

                <a href="#" class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full border border-blue-400 hover:border-blue-800 focus:border-blue-800 transition w-8 h-8 text-xl" @click.prevent="openInfoModal($page.store.analysis, $page.store)" v-if="$page.store.id">
                    <ui-icon name="settings"></ui-icon>
                </a>
            </template>

            <template v-slot:subsection>
                <div id="header-bottom"></div>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <content-performance-analysis :store="$page.store" v-if="$page.type.id == 'content-performance'"/>
            <content-breakdown-by-source-analysis :store="$page.store" v-if="$page.type.id == 'content-breakdown-by-source'"/>
            <content-breakdown-by-group-analysis :store="$page.store" v-if="$page.type.id == 'content-breakdown-by-group'"/>
            <content-breakdown-by-topic-analysis :store="$page.store" v-if="$page.type.id == 'content-breakdown-by-topic'"/>
            <content-publish-times-analysis :store="$page.store" v-if="$page.type.id == 'content-publish-times'"/>
            <content-pull-push-analysis :store="$page.store" v-if="$page.type.id == 'content-pull-push'"/>
            <content-sentiment-analysis :store="$page.store" v-if="$page.type.id == 'content-sentiment'"/>
            <content-top-platforms-analysis :store="$page.store" v-if="$page.type.id == 'content-top-platforms'"/>
            <content-keywords-analysis :store="$page.store" v-if="$page.type.id == 'content-keywords'"/>
            <target-publish-times-analysis :store="$page.store" v-if="$page.type.id == 'target-publish-times'"/>
            <target-audience-analysis :store="$page.store" v-if="$page.type.id == 'target-audience'"/>
        </div>
    </ui-async>
</template>

<script>
import ContentPerformanceAnalysis from './analyses/content-performance'
import ContentBreakdownBySourceAnalysis from './analyses/content-breakdown-by-source'
import ContentBreakdownByGroupAnalysis from './analyses/content-breakdown-by-group'
import ContentBreakdownByTopicAnalysis from './analyses/content-breakdown-by-topic'
import ContentPublishTimesAnalysis from './analyses/content-publish-times'
import ContentPullPushAnalysis from './analyses/content-pull-push'
import ContentSentimentAnalysis from './analyses/content-sentiment'
import ContentTopPlatformsAnalysis from './analyses/content-top-platforms'
import ContentKeywordsAnalysis from './analyses/content-keywords'
import TargetPublishTimesAnalysis from './analyses/target-publish-times'
import TargetAudienceAnalysis from './analyses/target-audience'

import useAnalysisModalsAnalysisInfoStore from '@/stores/analysis/modals/analysis-info'

import useRouter from '@/router'

import { mapActions } from 'pinia'

export default {
    components: {
        ContentPerformanceAnalysis,
        ContentBreakdownBySourceAnalysis,
        ContentBreakdownByGroupAnalysis,
        ContentBreakdownByTopicAnalysis,
        ContentPublishTimesAnalysis,
        ContentPullPushAnalysis,
        ContentSentimentAnalysis,
        ContentTopPlatformsAnalysis,
        ContentKeywordsAnalysis,
        TargetPublishTimesAnalysis,
        TargetAudienceAnalysis
    },

    methods: {
        ...mapActions(useAnalysisModalsAnalysisInfoStore, { openInfoModal: 'open' }),

        async save(notify = false) {
            if (this.$page.store.id) {
                await this.$page.store.save(notify)

                if (notify) {
                    useRouter().push({
                        name: 'analysis'
                    })
                }
            } else {
                this.openInfoModal(this.$page.store.analysis, this.$page.store, notify)
            }
        }
    }
}
</script>
