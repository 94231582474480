<template>
    <content-card
        v-bind="$props"
        :avatar-badge="content.source.type == 'telegram-user' ? 'badges.target-telegram-user' : 'badges.target-telegram-group'"
        :avatar-badge-tooltip="content.source.type == 'telegram-user' ? 'Telegram User' : 'Telegram Group'"
        :parent-component="{ 'telegram-channel-post': TelegramChannelPostCard, 'telegram-group-message': _.type }"
    >
        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                <div class="flex justify-center items-center" v-if="content.metrics.interactions">
                    <ui-icon name="interactions" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions
                    </div>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                    <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'
import TelegramChannelPostCard from './telegram-channel-post'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    data: () => ({
        TelegramChannelPostCard: markRaw(TelegramChannelPostCard)
    }),

    computed: {
        isShowingMetrics() {
            return this.content.metrics?.interactions
                || this.content.metrics?.views
        }
    }
}
</script>
