import api from '@/api'

export const asyncResource = settings => ({
    data: null,
    res: null,

    abortController: null,
    pending: false,

    appending: false,
    prepending: false,

    collection: true,

    request(api, state) {
    },

    transform(data) {
        return data
    },

    fetch(state) {
        this.abort()
        this.pending = true
        this.error = null

        return this.request(api, state)
            .signal(this.abortController = new AbortController())
            .get()
            .badRequest(err => this.setError('bad-request'))
            .unauthorized(err => this.setError('unauthorized'))
            .forbidden(err => this.setError('forbidden'))
            .notFound(err => this.setError('not-found'))
            .timeout(err => this.setError('timeout'))
            .internalError(err => this.setError('internal-error'))
            .json(res => {
                this.res = res

                if (this.collection && this.appending) {
                    this.data = this.data || []
                    this.data.push(...this.transform(res.data, state))
                } else if (this.collection && this.prepending) {
                    this.data = this.data || []
                    this.data.unshift(...this.transform(res.data, state))
                } else {
                    this.data = this.transform(res.data, state)
                }

                this.abortController = null
                this.pending = false

                return this.data
            })
    },

    abort() {
        if (this.abortController) {
            this.abortController.abort()
            this.pending = false
        }
    },

    reset() {
        this.abort()
        this.pending = false
        this.data = null
    },

    setError(error) {
        this.error = error
        this.pending = false
        this.data = null
    },

    ...settings
})

export default asyncResource
