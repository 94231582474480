<template>
    <router-link :to="{ name: 'dashboard.boards.board', params: { board: board.id } }" v-if="mode == 'card'">
        <div class="rounded bg-white shadow hover:shadow-lg cursor-pointer">
            <div class="flex items-center relative p-4 pb-3">
                <img :src="board.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{board.name}}
                    </h1>
                    <div class="text-gray-800 text-xs leading-tight">
                        Dashboard
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :board="board"></contextual-menu>
                </div>
            </div>

            <div class="text-sm text-gray-700 line-clamp-2 px-4 pb-3" v-if="board.description">
                {{board.description}}
            </div>

            <div class="flex items-center relative px-4 py-2 bg-gray-50 border-t border-gray-100 rounded-b">
                <div class="text-sm text-gray-700">
                    {{ $dateTime(board.createdAt) }}
                </div>

                <div class="ml-auto flex space-x-1 text-gray-500">
                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Recently used'" v-if="board.recent">
                        <ui-icon name="time"></ui-icon>
                    </span>

                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Pinned'" v-if="board.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>
                </div>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore.choose ? 'div' : 'router-link'" :to="{ name: 'dashboard.boards.board', params: { board: board.id } }" class="block" v-else-if="mode == 'row'">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <img :src="board.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{board.name}}
                    </h1>
                    <div class="text-gray-800 text-xs leading-tight">
                        Dashboard
                    </div>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <contextual-menu :board="board"></contextual-menu>
                </slot>
            </div>

            <div class="flex items-center w-64 mr-6 relative">
                <div class="text-sm text-gray-700 line-clamp-2">
                    {{board.description}}
                </div>
            </div>

            <div class="flex items-center space-x-5 px-4 w-72 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner" @click="action ? action() : navigate($event)">
                <div class="text-sm text-gray-700 line-clamp-2 px-4" v-if="board.createdAt">
                    {{ $dateTime(board.createdAt) }}
                </div>

                <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Recently used'" v-if="board.recent">
                        <ui-icon name="time"></ui-icon>
                    </span>

                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Pinned'" v-if="board.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(board)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import ContextualMenu from '@/components/dashboards/contextual-menus/board'

import useMyDashboards from '@/stores/me/dashboards'

import { mapActions } from 'pinia'

export default {
    props: { board: {}, cardsStore: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    methods: {
        ...mapActions(useMyDashboards, {
            updateBoard: 'update'
        }),

        widgetsCount(board, type) {
            return board.contents.filter(w => w.type == type).length
        }
    }
}
</script>
