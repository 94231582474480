import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentYoutubeVideoCommentsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentYoutubeVideoCommentsAnalysis',
    name: 'content.youtube-video-comments',
    title: 'Comments',
    color: 'indigo-600'
})

export default useTargetsTargetContentYoutubeVideoCommentsAnalysisStore
