import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentFacebookPostViewsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentFacebookPostViewsAnalysis',
    name: 'content.facebook-post-views',
    title: 'Views',
    color: 'pink-600'
})

export default useTargetsTargetContentFacebookPostViewsAnalysisStore
