<template>
    <div class="px-8 max-w-4xl mx-auto my-8">
        <history-card></history-card>
    </div>
</template>

<script>
import HistoryCard from './history/history-card'

export default {
    components: { HistoryCard }
}
</script>
