<template>
    <router-link :to="{ name: 'accounts.organizations.organization.license', params: { organizationId: organization.id, licenseId: license.id } }" class="block">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <div v-tooltip="isValid ? 'Valid' : 'Invalid'" class="h-4 w-4 rounded-full mr-3" :class="isValid ? 'bg-green-300' : 'bg-red-500'"></div>

                <img :src="license.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{ license.name }}
                    </h1>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <license-contextual-menu :organization="organization" :license="license" plain></license-contextual-menu>
                </slot>
            </div>

            <div class="flex items-center space-x-5 px-4 w-1/4 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                <div class="w-36">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Created on
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $dateTime(license.createdAt) }}
                    </div>
                </div>

                <div class="w-24">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Valid from
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $date(license.validFrom) }}
                    </div>
                </div>

                <div class="w-24">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Valid to
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $date(license.validTo) }}
                    </div>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(license)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import LicenseContextualMenu from '@/admin/components/accounts/contextual-menus/license'

export default {
    props: { organization: {}, license: {}, cardsStore: {} },

    components: {
        LicenseContextualMenu
    },

    computed: {
        isValid() {
            return new Date(this.license.validFrom) <= new Date() && new Date(this.license.validTo) >= new Date()
        }
    }
}
</script>
