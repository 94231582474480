<template>
    <div>
        <stream-toolbar-results :store="store" v-if="store.resultsToolbar">
            <template v-slot:results-actions>
                <slot name="results-actions"></slot>
            </template>
        </stream-toolbar-results>

        <slot name="above-results"></slot>

        <component :is="layoutComponent" :store="store" :stream-width="streamWidth" v-if="store.items.data && store.items.data.length || store.items.pending">
            <template v-slot:item="{ item, layout }">
                <slot name="item" :item="item" :layout="layout">
                    <content-facebook-group-post-card :content="item" :stream-store="store" :key="item.id" v-if="item.type == 'facebook-group-post'"></content-facebook-group-post-card>
                    <content-facebook-post-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'facebook-post'"></content-facebook-post-card>
                    <content-facebook-post-comment-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'facebook-post-comment'"></content-facebook-post-comment-card>
                    <content-facebook-user-post-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'facebook-user-post'"></content-facebook-user-post-card>
                    <content-instagram-post-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'instagram-post'"></content-instagram-post-card>
                    <content-telegram-channel-post-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'telegram-channel-post'"></content-telegram-channel-post-card>
                    <content-telegram-group-message-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'telegram-group-message'"></content-telegram-group-message-card>
                    <content-tiktok-video-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'tiktok-video'"></content-tiktok-video-card>
                    <content-twitter-tweet-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'twitter-tweet'"></content-twitter-tweet-card>
                    <content-vkontakte-post-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'vkontakte-post'"></content-vkontakte-post-card>
                    <content-web-article-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'web-article'"></content-web-article-card>
                    <content-web-resource-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'web-resource'"></content-web-resource-card>
                    <content-youtube-video-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'youtube-video'"></content-youtube-video-card>
                    <content-youtube-video-comment-card :content="item" :stream-store="store" :key="item.id" v-else-if="item.type == 'youtube-video-comment'"></content-youtube-video-comment-card>
                </slot>
            </template>

            <template v-slot:placeholder="{ item, layout }">
                <slot name="placeholder" :item="item" :layout="layout"></slot>
            </template>
        </component>

        <div v-else-if="store.items.error">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="frown" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Ooops, something went wrong!</div>
                <div class="text-center text-sm">
                    <p>Looks like we are experiencing some technical issues (sorry about that).</p>
                    <p>Please try again later, if the problem persists, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.</p>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="search" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">
                    <p>Check your spelling and try again, or try a different set of filters.</p>
                </div>
            </div>
        </div>

        <ui-infinite-loading class="mt-12 mb-8" :identifier="store.lastReloadTime" @infinite="store.loadAdditional($event)" v-if="store.items.data && store.items.data.length"></ui-infinite-loading>

        <Teleport to="#root">
            <stream-lightbox :store="store.mediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ContentFacebookGroupPostCard from '@/components/content/stream-cards/facebook-group-post'
import ContentFacebookPostCard from '@/components/content/stream-cards/facebook-post'
import ContentFacebookPostCommentCard from '@/components/content/stream-cards/facebook-post-comment'
import ContentFacebookUserPostCard from '@/components/content/stream-cards/facebook-user-post'
import ContentInstagramPostCard from '@/components/content/stream-cards/instagram-post'
import ContentTelegramChannelPostCard from '@/components/content/stream-cards/telegram-channel-post'
import ContentTelegramGroupMessageCard from '@/components/content/stream-cards/telegram-group-message'
import ContentTiktokVideoCard from '@/components/content/stream-cards/tiktok-video'
import ContentTwitterTweetCard from '@/components/content/stream-cards/twitter-tweet'
import ContentVkontaktePostCard from '@/components/content/stream-cards/vkontakte-post'
import ContentWebArticleCard from '@/components/content/stream-cards/web-article'
import ContentWebResourceCard from '@/components/content/stream-cards/web-resource'
import ContentYoutubeVideoCard from '@/components/content/stream-cards/youtube-video'
import ContentYoutubeVideoCommentCard from '@/components/content/stream-cards/youtube-video-comment'

import StreamLayoutCards from './layouts/cards'
import StreamLayoutGrid from './layouts/grid'
import StreamLayoutMasonry from './layouts/masonry'
import StreamLayoutImages from './layouts/images'
import StreamLayoutVideos from './layouts/videos'
import StreamLayoutTable from './layouts/table'
import StreamLightbox from './lightbox/lightbox'
import StreamToolbarResults from './toolbar/results'

import debounce from 'just-debounce-it'

export default {
    components: {
        ContentFacebookGroupPostCard, ContentFacebookPostCard, ContentFacebookPostCommentCard,
        ContentFacebookUserPostCard, ContentInstagramPostCard, ContentTelegramChannelPostCard,
        ContentTelegramGroupMessageCard, ContentTwitterTweetCard, ContentTiktokVideoCard, ContentVkontaktePostCard,
        ContentWebArticleCard, ContentWebResourceCard, ContentYoutubeVideoCard, ContentYoutubeVideoCommentCard,
        StreamLightbox, StreamToolbarResults
    },

    data: () => ({
        streamWidth: null
    }),

    computed: {
        layoutComponent() {
            if (this.store.layout.component) {
                return this.store.layout.component
            }

            return {
                cards: StreamLayoutCards,
                grid: StreamLayoutGrid,
                masonry: StreamLayoutMasonry,
                images: StreamLayoutImages,
                videos: StreamLayoutVideos,
                table: StreamLayoutTable
            }[this.store.layout.id]
        }
    },

    watch: {
        'store.layoutOptions'() {
            this.store.updateAvailableLayouts(this.streamWidth)
        },

        '$my.showSidebar'() {
            setTimeout(() => this.refreshStreamWidth(), 200)
        }
    },

    mounted() {
        window.addEventListener('resize', debounce(() => this.refreshStreamWidth(), 50))

        this.refreshStreamWidth()

        setTimeout(() => this.refreshStreamWidth(), 500)
    },

    methods: {
        refreshStreamWidth() {
            this.store.updateAvailableLayouts(this.streamWidth = this.$el.getBoundingClientRect().width)
        }
    }
}
</script>
