import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetStreamStore from '@/stores/targets/target/stream'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetStreamPage = defineStore({
    id: 'targetsTargetStreamPage',

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            let stream = useTargetsTargetStreamStore()

            let defaultFilters = (['telegram-user', 'twitter-user', 'facebook-user', 'vkontakte-user'].includes(this.target.type)) ? { 'replies': true } : {}

            stream.initialize()
            stream.navigatedTo(to, { 'targets': [ { type: this.type, id: this.target.id, name: this.target.title } ] }, defaultFilters)

            stream.searchContext = this.target.title
        }
    }
})

export default useTargetsTargetStreamPage
