import defineConnectionsGraph from '@/stores/connections/connections-graph'
import useMyStore from '@/stores/me/my'
import useQuickSearchStore from '@/stores/me/quick-search'

import { targets } from '@/api'

import { defineStore } from 'pinia'

export const useConnectionsPage = defineStore({
    id: 'connectionsPage',

    state: () => ({
        graph: defineConnectionsGraph({ id: 'connectionsGraph' })(),
        showSidebarPrevious: false
    }),

    getters: {
        title: () => 'Connections Explorer'
    },

    actions: {
        afterEnter(to) {
            this.showSidebarPrevious = useMyStore().showSidebar
            useMyStore().showSidebar = false

            this.graph.reset()

            if (to.params.nodeId) {
                setTimeout(() => this.initializeGraph(to.params.nodeId), 500)
            }
        },

        async beforeLeave() {
            useMyStore().showSidebar = this.showSidebarPrevious
        },

        chooseTarget() {
            useQuickSearchStore().show({
                families: [ 'targets' ],
                limit: 50,
                onSelect: result => {
                    targets().family(result.id[0] == 'G' ? 'groups' : 'sources').find(result.id.substring(1), target => {
                        this.initializeGraph(target.nodeId)
                    })

                    useQuickSearchStore().hide()
                }
            })
        },

        initializeGraph(nodeId) {
            this.graph.initialize()
            this.graph.inspectNode(nodeId)
        }
    }
})

export default useConnectionsPage
