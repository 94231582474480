import languageFilterOptions from './language-filter-options'

export default (settings) => ({
    table: 'targets',

    compactToolbar: true,
    resultsToolbar: { tags: false },

    supportsPerspectives: false,
    supportsSearchTopics: false,

    searchContext: 'All Targets',

    layoutOptions: [
        { name: 'Grid', id: 'grid', icon: 'view-grid', available: true },
        { name: 'Table', id: 'table', icon: 'menu', available: true }
    ],

    sortingOptions: [
        { name: 'Name', id: 'name', icon: 'text-case', value: i => ({ id: i.id, family: i.family }), default: true },
        {},
        { name: 'Followers', id: 'followers', icon: 'users', value: i => ({ id: i.id, family: i.family }), direction: 'desc' },
        { name: 'Content/week', id: 'publishedPerWeek', icon: 'stream', value: i => ({ id: i.id, family: i.family }), direction: 'desc' }
    ],

    filters: [
        { name: 'Name', id: 'title', type: 'search-query', hidden: true, search: true },
        {
            name: 'Type', id: 'type', type: 'options', icon: 'stream', multiple: true,
            options: [
                { name: 'Facebook Page', id: 'facebook-page', icon: 'badges.target-facebook-page' },
                { name: 'Facebook Group', id: 'facebook-group', icon: 'badge-target-facebook-group' },
                { name: 'Facebook User', id: 'facebook-user', icon: 'badges.target-facebook-user' },
                { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.target-instagram-profile' },
                { name: 'Telegram Channel', id: 'telegram-channel', icon: 'badges.target-telegram-channel' },
                { name: 'Telegram Group', id: 'telegram-group', icon: 'badges.target-telegram-group' },
                { name: 'Telegram User', id: 'telegram-user', icon: 'badges.target-telegram-user' },
                { name: 'TikTok User', id: 'tiktok-user', icon: 'badges.target-tiktok-user' },
                { name: 'Twitter User', id: 'twitter-user', icon: 'badges.target-twitter-user' },
                { name: 'VKontakte Community', id: 'vkontakte-community', icon: 'badges.target-vkontakte-community' },
                { name: 'VKontakte User', id: 'vkontakte-user', icon: 'badges.target-vkontakte-user' },
                { name: 'Web Feed', id: 'web-feed', icon: 'badges.target-web-feed' },
                { name: 'Web Site', id: 'web-site', icon: 'badges.target-web-site' },
                { name: 'Youtube Channel', id: 'youtube-channel', icon: 'badges.target-youtube-channel'}
            ]
        },
        { name: 'List', id: 'list', type: 'target-list', icon: 'lists', hidden: true },
        { name: 'Group', id: 'group', type: 'raw', hidden: true },
        {
            name: 'Language', id: 'language', type: 'options', icon: 'translate', alignment: 'right', multiple: true, searchable: true,
            options: languageFilterOptions
        },
        {
            name: 'Monitoring', id: 'activity', type: 'options', icon: 'eye', alignment: 'right',
            options: [
                { name: 'Monitored', id: 'monitored', icon: 'eye' },
                { name: 'Not Monitored', id: 'not-monitored', icon: 'eye-off' }
            ]
        }
    ],

    apiInclude: ['source.metrics', 'group.metrics'],
    apiQueryExtraParameters: settings.family ? { family: settings.family } : {}
})
