import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentVKontaktePostRepostsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentVKontaktePostRepostsAnalysis',
    name: 'content.vkontakte-post-reposts',
    title: 'Reposts',
    color: 'purple-600'
})

export default useTargetsTargetContentVKontaktePostRepostsAnalysisStore
