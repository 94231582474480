<template>
    <ui-modal name="board-edit" :title="board.id ? 'Edit a Board' : 'Create a new Board'" :max-width="520" :max-height="480" @opened="opened">
        <ui-form :store="modalsBoardEditStore" v-slot="{ inputs, submitting, errors }">
            <label for="name" class="block text-sm font-medium text-gray-700">
                Name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
            </div>

            <label for="description" class="block text-sm font-medium text-gray-700 mt-4">
                Description
            </label>
            <div class="mt-2">
                <ui-input type="textarea" id="description" rows="2" v-model="inputs.description" :errors="errors.description"></ui-input>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteBoard" v-if="inputs.board.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="inputs.board.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.board.id ? 'Update' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useModalsBoardEditStore),

        board() { return this.modalsBoardEditStore.inputs?.board }
    },

    methods: {
        ...mapActions(useModalsBoardEditStore, {
            deleteBoard: 'delete'
        }),

        opened() { this.$refs.name.focus() }
    }
}
</script>
