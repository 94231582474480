<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Features
    </h1>

    <navigation-section title="Recently Used"
        :items="recentFeatures" :wait-until="areRecentFeaturesInitialized" :max-shown="5"
        :active="item => false"
        placeholder="Nothing recently visited yet..."
    >
    </navigation-section>

    <navigation-section title="Library"
        :items="libraryFeatures"
        :to="item => ({ name: 'features', params: { type: item.type } })">
    </navigation-section>
</template>

<script>
import useFeaturesLibraryTypesStore from '@/stores/features/library-types'
import useMyRecentFeaturesStore from '@/stores/me/recent-features'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useFeaturesLibraryTypesStore, {
            libraryFeatures: 'features'
        }),

        ...mapState(useMyRecentFeaturesStore, {
            areRecentFeaturesInitialized: 'isInitialized',
            recentFeatures: 'items'
        })
    }
}
</script>
