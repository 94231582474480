<template>
    <div class="rounded bg-white shadow hover:shadow-lg">
        <div class="flex items-center relative p-4 pl-5 pb-3">
            <div class="shrink-0 relative">
                <img class="rounded-full w-8 h-8 shadow" :src="user.avatarUrl">
            </div>

            <div class="flex-1 min-w-0 px-4">
                <h1 class="text-lg font-semibold truncate leading-tight">
                    <span v-tooltip="user.name">{{user.name}}</span>
                </h1>
                <div class="text-gray-800 text-xs leading-tight flex items-center">
                    {{user.email}}
                </div>
            </div>

            <div class="flex items-center space-x-0.5 shrink-0">
                <span class="bg-blue-500 text-white rounded py-0.5 px-1 text-2xs uppercase" v-if="user.role == 'owner'">
                    Owner
                </span>

                <button class="inline-flex items-center justify-center text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900 rounded-full hover:bg-gray-75 transition w-7 h-7 text-sm" v-tooltip="'Remove'" @click="removeUser(workspace, user)" v-if="user.role != 'owner' && workspace.owned">
                    <ui-icon name="x"></ui-icon>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'user' ],

    computed: {
        ...mapState(useWorkspacesModalsManage, [ 'workspace' ])
    },

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'removeUser' ])
    }
}
</script>
