import { defineStore } from 'pinia'

export const defineHeadingWidget = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,
            type: settings.type,
            undeletable: settings.undeletable,
            uneditable: settings.uneditable,

            text: settings.text ?? 'Sample text',
            level: settings.level ?? 1,

            editForm: {
                text: '',
                level: 1
            },

            isNew: settings.isNew || false,

            refreshInterval: 30,
            lastRefresh: null
        }),

        getters: {
            isConfigured() {
                return !! this.text
            }
        },

        actions: {
            initialize() {
                this.refresh()
                return this
            },

            load() {
                if (! this.isConfigured) return
            },

            edit() {
                this.editForm.text = this.text
                this.editForm.level = this.level
            },

            save() {
                this.text = this.editForm.text
                this.level = this.editForm.level
                this.load()
            },

            destroy() {
                this.$dispose()
            },

            refresh() {
                this.load()
                this.lastRefresh = + new Date
            },

            layoutUpdated() {
            },

            serialize() {
                return {
                    text: this.text,
                    level: this.level
                }
            },

            dependencies() {
                return {}
            }
        }
    })
}

export default defineHeadingWidget
