<template>
    <ui-async :guards="$page.feature">
        <ui-header :title="$page.feature.title" :avatar="$page.feature.avatarUrl"
            :badge="$page.type.icon">
            <template v-slot:subtitle>
                <div>{{$page.type.name}}</div>
            </template>

            <template v-slot:actions>
                <contextual-menu :feature="$page.feature"></contextual-menu>
            </template>

            <template v-slot:navigation>
                <router-link :to="{ name: 'features.feature.information', params: { type: $page.feature.type, id: $page.feature.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500" v-if="showTab('information')">
                    <ui-icon name="info" class="mr-2"></ui-icon> Information
                </router-link>
                <router-link :to="{ name: 'features.feature.stream', params: { type: $page.feature.type, id: $page.feature.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="showTab('stream')">
                    <ui-icon name="stream" class="mr-2"></ui-icon> Stream
                </router-link>
                <router-link :to="{ name: 'features.feature.connections', params: { type: $page.feature.type, id: $page.feature.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="showTab('connections')">
                    <ui-icon name="share" class="mr-2"></ui-icon> Connections
                </router-link>
            </template>

            <template v-slot:subsection>
                <div id="header-bottom"></div>
            </template>
        </ui-header>

        <router-view></router-view>
    </ui-async>
</template>

<script>
import ContextualMenu from '@/components/features/contextual-menus/feature'

export default {
    components: {
        ContextualMenu
    },

    methods: {
        showTab(tab) {
            return this.$page.type ? this.$page.type.tabs.includes(tab) : false
        }
    }
}
</script>
