import useAuthSocialLogin from '@/stores/auth/social-login'

import { defineStore } from 'pinia'

export const useAuthSocialPage = defineStore({
    id: 'authSocialPage',

    actions: {
        beforeEnter(to) {
            useAuthSocialLogin().finish(to)
        }
    }
})

export default useAuthSocialPage
