<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset">
        <template v-slot:applied>
            <ui-icon :name="filter.icon" class="mr-1"></ui-icon>

            <div class="inline-flex space-x-1">
                <div :class="`text-xs font-medium tracking-tight rounded-sm py-0.5 px-2 border border-blue-200 bg-${label.color.light} text-${label.color.dark}`" :key="label.id" v-for="label in value">
                    {{label.title}}
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-52">
                <ui-dropdown-link :checkbox="filter.multiple" :selected="localItems.includes(label)" @click="toggleLocalItem(label)" :icon="true" :key="label.id" v-for="label in labels">
                    <template v-slot:icon>
                        <div class="w-4 flex justify-center items-center text-center">
                            <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${label.color.dark}`"></span>
                        </div>
                    </template>
                    <template v-slot:default>
                        {{label.title}}
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyLabels from '@/stores/me/labels'

import { mapState } from 'pinia'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        localItems: []
    }),

    computed: {
        ...mapState(useMyLabels, [ 'labels' ]),

        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    },

    methods: {
        toggleLocalItem(item) {
            this.localItems.some(v => v.id === item.id) ? this.localItems.splice(this.localItems.findIndex(v => v.id === item.id), 1) : this.localItems.push(item)
        },

        reset() {
            this.localItems = this.store.getFilterValue(this.filter.id) ? [...this.store.getFilterValue(this.filter.id)] : []
        },

        apply() {
            this.localItems.length ? this.store.addFilter(this.filter.id, this.localItems) : this.store.removeFilter(this.filter.id)
        }
    }
}
</script>
