<template>
    <div class="bg-gray-50 min-h-screen relative">
        <div class="mx-auto max-w-7xl w-full pt-4 pb-4 text-center lg:text-left">
            <div class="px-4 sm:px-8">
                <img src="@/assets/gerulata.png" alt="Gerulata" class="w-32 h-auto mb-4">
            </div>
            <div class="px-4 lg:w-1/2 sm:px-8 lg:py-32 xl:pr-32">
                <h1 class="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
                    <span class="inline">Sign in</span><br>
                    <span class="inline">to your account</span>
                </h1>

                <div class="mt-3 max-w-md mx-auto text-gray-700 text-lg md:mt-5 md:max-w-3xl">
                    <div class="border border-red-300 bg-red-50 text-red-900 w-full px-4 py-2 text-sm mb-3 rounded" v-if="socialLoginError">
                        <p>{{ socialLoginError }}</p>
                    </div>

                    <p class="mb-3">
                        Quick and secure sign-in via Facebook
                    </p>

                    <div class="mt-1 grid grid-cols-1 gap-3">
                        <div>
                            <a href="#" class="w-full inline-flex items-center justify-center py-3 px-4 rounded-md shadow-sm bg-gerulata-blue-600 font-medium text-gerulata-blue-50 hover:bg-gerulata-blue-700 shadow-md" @click.prevent="initiateSocialLogin()">
                                <ui-icon name="g" class="mr-2 text-2xl"></ui-icon>
                                <span>Sign in with Gerulata Connect</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="mt-6" v-if="! isShowingEmailLogin">
                    <a href="#" class="text-gerulata-blue-600 hover:text-gerulata-blue-500" @click.prevent="showEmailLogin()">
                        I want to login with email and password.
                    </a>
                </div>

                <template v-if="isShowingEmailLogin">
                    <div class="mt-6 relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300"></div>
                        </div>
                        <div class="relative flex justify-center">
                            <span class="px-2 bg-gray-50 text-gray-700">
                                Or continue with
                            </span>
                        </div>
                    </div>
                    <div class="mt-3 max-w-md mx-auto text-md text-gray-700 md:mt-5 md:max-w-3xl">
                        <ui-form :store="authLoginFormStore" v-slot="{ inputs, submitting, submitted, hasErrors, errors }" class="space-y-6">
                            <div class="border border-red-300 bg-red-50 text-red-900 w-full px-4 py-2 text-sm mt-3 md:mt-5 rounded" v-if="errors && errors.other">
                                {{ errors.other }}
                            </div>

                            <div>
                                <label for="email" class="block text-gray-700">
                                    Email address
                                </label>

                                <div class="mt-1">
                                    <ui-input id="email" type="email" autocomplete="email" required v-model="inputs.email" :errors="errors.email"></ui-input>
                                </div>
                            </div>

                            <div>
                                <label for="password" class="block text-gray-700">
                                    Password
                                </label>

                                <div class="mt-1">
                                    <ui-input id="password" type="password" autocomplete="current-password" required v-model="inputs.password" :errors="errors.password"></ui-input>
                                </div>
                            </div>

                            <div v-if="isTwoFactorAuthCodeRequired">
                                <label for="twoFactorAuthCode" class="block text-gray-700">
                                    Two Factor Auth Code
                                </label>

                                <div class="mt-1">
                                    <ui-input id="twoFactorAuthCode" required v-model="inputs.twoFactorAuthCode" :errors="errors.twoFactorAuthCode" ref="twoFactorAuthCode"></ui-input>
                                </div>
                            </div>

                            <div class="flex flex-col space-y-3">
                                <router-link :to="{ name: 'password-recovery.request' }" class="text-gerulata-blue-600 hover:text-gerulata-blue-500">
                                    Forgot your password?
                                </router-link>

                                <a href="#" v-if="isTwoFactorAuthCodeRequired" @click.prevent="toggleIsShowingTwoFactorResetHelp" class="text-gerulata-blue-600 hover:text-gerulata-blue-500">
                                    Lost your two-factor auth?
                                </a>

                                <div v-if="isShowingTwoFactorResetHelp">
                                    <p>You can use your recovery phrase from when you were setting up your two-factor authentication. Simply enter the entire 40 character phrase (including dashes) into the regular auth code field.</p>
                                    <p class="mt-3">If you're still unable to recover your account, please contact your organisation owner or <a class="underline" href="mailto:support@gerulata.com">Gerulata support</a>.</p>
                                </div>
                            </div>

                            <div>
                                <button type="submit" class="inline-flex items-center justify-center py-3 px-20 rounded-md shadow-sm bg-gerulata-blue-600 font-medium text-gerulata-blue-50 hover:bg-gerulata-blue-700 shadow-md text-lg" :disabled="submitting">
                                    <template v-if="submitting || (submitted && ! hasErrors)">
                                        <ui-spinner type="clip" color="#fff" class="mr-2"></ui-spinner>
                                        Signing in...
                                    </template>
                                    <template v-else>
                                        Sign in
                                    </template>
                                </button>
                            </div>
                        </ui-form>
                    </div>
                </template>
            </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <img class="absolute inset-0 w-full h-full object-cover" src="@/assets/auth-cover.jpg" alt="">
        </div>
    </div>
</template>

<script>
import useAuthLoginFormStore from '@/stores/auth/login-form'
import useAuthSocialLoginStore from '@/stores/auth/social-login'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    data: () => ({
        isShowingEmailLogin: false
    }),

    computed: {
        ...mapStores(useAuthLoginFormStore),
        ...mapState(useAuthLoginFormStore, [ 'isShowingTwoFactorResetHelp', 'isTwoFactorAuthCodeRequired' ]),
        ...mapState(useAuthSocialLoginStore, { socialLoginError: 'error' })
    },

    methods: {
        ...mapActions(useAuthLoginFormStore, [ 'toggleIsShowingTwoFactorResetHelp', 'stopRequiringTwoFactorAuthCode' ]),
        ...mapActions(useAuthSocialLoginStore, { initiateSocialLogin: 'initiate' }),

        showEmailLogin() {
            this.isShowingEmailLogin = true
        }
    },

    watch: {
        'authLoginFormStore.inputs.email'() {
            this.stopRequiringTwoFactorAuthCode()
        },

        isTwoFactorAuthCodeRequired(newVal) {
            if (newVal === true) { this.$nextTick(() => { this.$refs.twoFactorAuthCode.focus() }) }
        }
    }
}
</script>

