import useContentModalsPerspectiveNewStreamStore from './perspective-new-stream'
import useMyStore from '@/stores/me/my'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTaggingStore from '@/stores/tagging/tagging'

import languageFilterOptions from '@/stores/reusable/stream/language-filter-options'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useContentModalsPerspectiveNewStore = defineStore({
    id: 'contentModalsPerspectiveNew',

    state: () => ({
        promise: null,
        fulfillPromise: null,
        rejectPromise: null
    }),

    actions: {
        open() {
            useMyBookmarksStore().initialize()

            useMyPerspectivesStore().initialize()
            useMySearchTopicsStore().initialize()
            useTaggingStore().initialize(),
            useMyTargetListsStore().initialize()

            useModal().show('content-perspective-new')

            useContentModalsPerspectiveNewStreamStore().triggerLayoutUpdate()

            useContentModalsPerspectiveNewStreamStore().removeAllFilters()
            useContentModalsPerspectiveNewStreamStore().filter('date').set({ type: 'past', date: { past: 1, unit: 'weeks' } })
            useContentModalsPerspectiveNewStreamStore().filter('language').set([ languageFilterOptions.find(o => o.id == useMyStore().preferredLanguage) ])

            return this.makePromise()
        },

        cancel() {
            useModal().hide('content-perspective-new')

            this.fulfillPromise()
        },

        async save() {
            useContentModalsPerspectiveNewStreamStore().appliedPerspective = {
                name: 'Untitled Perspective', virtual: true
            }

            await useContentModalsPerspectiveNewStreamStore().savePerspective()

            this.fulfillPromise({
                resultType: 'perspective',
                id: useContentModalsPerspectiveNewStreamStore().appliedPerspective.id,
                title: useContentModalsPerspectiveNewStreamStore().appliedPerspective.title
            })

            useModal().hide('content-perspective-new')
        },

        makePromise() {
            return this.promise = new Promise((accept, reject) => {
                this.fulfillPromise = accept
                this.rejectsPromise = reject
            })
        }
    }
})

export default useContentModalsPerspectiveNewStore
