<template>
    <router-link :to="{ name: 'accounts.organizations.organization.member', params: { organizationId: organization.id, memberId: member.id } }" class="block">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center min-w-0" :class="{ 'flex-1': workspace, 'w-80': ! workspace }">
                <img :src="member.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{ member.name }}
                    </h1>
                    <div class="text-gray-800 text-xs leading-tight">
                        {{ member.email }}
                    </div>
                </div>
            </div>

            <div v-if="! workspace" class="pl-6 flex items-center space-x-2 flex-1">
                <div class="inline-flex items-center px-1.5 py-0.5 rounded-md text-sm text-gray-700 bg-gray-100" v-for="workspace in member.workspaces" :key="workspace.id">
                    {{ workspace.name }}
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <workspace-member-contextual-menu v-if="workspace" :workspace="workspace" :member="member" plain></workspace-member-contextual-menu>
                    <member-contextual-menu v-else :organization="organization" :member="member" plain></member-contextual-menu>
                </slot>
            </div>

            <div class="flex items-center space-x-5 px-4 w-96 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                <div class="w-36">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Created on
                    </div>
                    <div class="font-semibold text-sm">
                        <template v-if="workspace && ! member.memberSince">
                            {{ $dateTime(workspace.createdAt) }}
                        </template>
                        <template v-else-if="member.memberSince">
                            {{ $dateTime(member.memberSince) }}
                        </template>
                        <template v-else>
                            {{ $dateTime(member.createdAt) }}
                        </template>
                    </div>
                </div>

                <div class="flex items-center text-gray-700" v-tooltip="'Organization Admin'" v-if="member.organizationRole === 'organization-admin'">
                    <span>&bull;&bull;&bull;&bull;</span>
                    <span class="ml-1.5 text-2xs leading-tight">Organization Admin</span>
                </div>

                <div class="flex items-center text-gray-700" v-tooltip="'Workspace Owner'" v-if="workspace && member.role === 'owner'">
                    <span>&bull;&bull;&bull;</span>
                    <span class="ml-1.5 text-2xs leading-tight">Workspace Owner</span>
                </div>

                <div class="flex items-center text-gray-700" v-tooltip="'Workspace Admin'" v-if="workspace && member.role === 'team-admin'">
                    <span>&bull;&bull;</span>
                    <span class="ml-1.5 text-2xs leading-tight">Workspace Admin</span>
                </div>

                <div class="flex items-center text-gray-700" v-tooltip="'Workspace Member'" v-if="workspace && member.role === 'team-member'">
                    <span>&bull;</span>
                    <span class="ml-1.5 text-2xs leading-tight">Workspace Member</span>
                </div>

                <div class="flex items-center text-gray-700" v-tooltip="'Two Factor Auth'" v-if="member.hasTwoFactorAuth">
                    <ui-icon name="key"></ui-icon>
                    <span class="ml-1.5 text-2xs leading-tight">Has Two Factor Auth</span>
                </div>

                <div class="flex items-center text-red-700" v-tooltip="'Two Factor Auth'" v-if="member.disabledAt">
                    <ui-icon name="eye-off"></ui-icon>
                    <span class="ml-1.5 text-2xs leading-tight">Disabled</span>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(member)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import WorkspaceMemberContextualMenu from '@/admin/components/accounts/contextual-menus/workspace-member'
import MemberContextualMenu from '@/admin/components/accounts/contextual-menus/member'

export default {
    props: { organization: {}, workspace: {}, member: {}, cardsStore: {} },

    components: {
        WorkspaceMemberContextualMenu,
        MemberContextualMenu
    }
}
</script>
