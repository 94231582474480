import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import api from '@/api'

export const useContentModalsVideoDownloadStore = defineStore({
    id: 'contentModalsVideoDownload',

    state: () => ({
        content: null,
        video: null
    }),

    actions: {
        open(content, video) {
            this.$reset()

            this.content = content
            this.video = video

            useModal().show('content-video-download')
        },

        cancel() {
            useModal().hide('content-video-download')
        },

        confirm() {
            api.route('monitor content download-video', { id: this.content.id, videoId: this.video.id }).post()

            useModal().hide('content-video-download')
        }
    }
})

export default useContentModalsVideoDownloadStore
