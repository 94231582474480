<template>
    <div class="flex items-center justify-between px-4 pt-3">
        <h1 class="text-xl font-semibold">Home</h1>
    </div>

    <navigation-section
        :items="topNavigation">
    </navigation-section>
</template>

<script>
export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.topics', to: { name: 'home' } }
        ]
    })
}
</script>
