<template>
    <ui-modal name="workspace-create" title="Create a new Workspace" :max-width="520" @opened="opened">
        <ui-form :store="workspaceSettingsFormStore" v-slot="{ inputs, submitting, errors }">
            <label for="name" class="block text-sm font-medium text-gray-700">
                Name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
            </div>

            <label for="owner_name" class="block text-sm font-medium text-gray-700 mt-4">
                Owner name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.ownerName" :errors="errors.owner_name" ref="owner_name"></ui-input>
            </div>

            <label for="owner_email" class="block text-sm font-medium text-gray-700 mt-4">
                Owner e-mail
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.ownerEmail" :errors="errors.owner_email" ref="owner_email"></ui-input>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="green" :disabled="submitting">
                    Create
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useWorkspaceSettingsFormStore from '@/admin/stores/accounts/workspace-form'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useWorkspaceSettingsFormStore)
    },

    methods: {
        opened() { this.$refs.name.focus() }
    }
}
</script>
