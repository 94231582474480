<template>
    <div class="py-1 text-sm" v-if="filteredMentions.length">
        <div class="flex flex-wrap px-3">
            <card-mention :feature="feature" :key="feature.id" v-for="feature in shownMentions"></card-mention>

            <a href="#"
               class="flex shrink-0 items-center px-2 py-1 text-blue-600 max-w-2xl overflow-hidden rounded-sm hover:bg-gray-100 transition-all cursor-pointer group text-sm"
               @click.exact.prevent="showMore" @click.alt.exact.prevent="showAll"
               v-if="hiddenMentionsCount">
                <ui-icon name="plus" class="text-lg text-blue-500 shrink-0"></ui-icon>
                <span class="ml-1"><span class="font-semibold">{{hiddenMentionsCount}}</span> Mentions</span>
            </a>
        </div>
    </div>
</template>

<script>
import CardMention from './mention'

export default {
    components: { CardMention },

    props: [ 'mentions', 'streamStore' ],

    data: () => ({
        shownLimit: 5
    }),

    computed: {
        filteredMentions() {
            return (this.mentions || []).filter(m => [ 'email', 'iban', 'location', 'name', 'phone-number' ].includes(m.type))
        },

        shownMentions() {
            return this.filteredMentions.slice(0, this.shownLimit)
        },

        hiddenMentionsCount() {
            return Math.max(this.filteredMentions.length - this.shownLimit, 0)
        }
    },

    methods: {
        showMore() {
            this.shownLimit += 10
            this.streamStore.triggerLayoutUpdate()
        },

        showAll() {
            this.shownLimit = this.filteredMentions.length
            this.streamStore.triggerLayoutUpdate()
        }
    }
}
</script>
