import api from '@/admin/api'

import { defineStore } from 'pinia'

export const useAgentsStore = defineStore({
    id: 'agents',

    state: () => ({
        agents: null,
        agentsLoaded: false,
        agentsPromise: null
    }),

    actions: {
        async initialize(force = false) {
            await this.load(force)
        },

        async load(force = false) {
            if (this.agentsLoaded && ! force) return Promise.resolve()
            if (this.agentsPromise) return this.agentsPromise

            return this.agentsPromise = api.route('admin support agents').get().json(res => {
                this.agents = res.data
                this.agentsLoaded = true
                this.agentsPromise = null
            })
        }
    }
})

export default useAgentsStore
