<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="trash" @click="deleteLicense(organization.id, license)">
            Delete
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useLicensesStore from '@/admin/stores/accounts/licenses'

import { mapActions } from 'pinia'

export default {
    props: [ 'organization', 'license' ],

    methods: {
        ...mapActions(useLicensesStore, {
            'deleteLicense': 'delete'
        })
    }
}
</script>
