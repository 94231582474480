<template>
    <div :class="{ 'bg-white w-full overflow-hidden shadow rounded-sm': ! inline }">
        <div class="w-full h-32" v-if="latestCoverPicture">
            <img :src="latestCoverPicture.src" class="w-full h-full object-cover rounded-t-sm">
        </div>

        <div class="px-5">
            <div class="flex items-end space-x-3 mb-3" v-if="latestCoverPicture">
                <img :src="target.avatarUrl" class="w-16 h-16 rounded-full shadow ring-2 ring-white -mt-6">
                <h1 class="flex-1 text-xl font-bold leading-none line-clamp-2 pb-1">{{target.title}}</h1>
                <ui-icon :name="`flag-${target.language}`" class="text-xl mb-1" v-tooltip="language" v-if="languageHasFlag"></ui-icon>
            </div>
            <div class="flex items-center space-x-3 mt-4 mb-2" v-else>
                <img :src="target.avatarUrl" class="w-12 h-12 rounded-full shadow ring-2 ring-white">
                <h1 class="flex-1 text-xl font-bold leading-none line-clamp-2">{{target.title}}</h1>
                <ui-icon :name="`flag-${target.language}`" class="text-xl" v-tooltip="language" v-if="languageHasFlag"></ui-icon>
            </div>

            <table class="w-full table-fixed divide-y divide-gray-200 text-sm text-gray-900 mb-4" v-if="details">
                <tr :key="label" v-for="value, label in details">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium w-1/4">{{label}}</td>
                    <td class="pr-1 pl-3 py-3 whitespace-pre-line break-words">
                        {{ label !== 'Description' ? value : description }}
                        <a href="#" v-if="label === 'Description' && ! isShowingFullDescription" class="ml-1 text-blue-600" @click.prevent="readMoreDescription">Read more</a>
                    </td>
                </tr>

                <tr>
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium">Language</td>
                    <td class="pr-1 pl-3 py-3 whitespace-pre-line break-words">{{language}}</td>
                </tr>
            </table>

            <div class="space-y-1 text-sm mb-4">
                <a href="#" class="block text-blue-500 hover:text-blue-700 hover:underline" @click.prevent="inspectMedia(profilePictures, 0)" v-if="profilePictures.length">
                    <ui-icon name="search"></ui-icon> Inspect profile pictures
                </a>
                <a href="#" class="block text-blue-500 hover:text-blue-700 hover:underline" @click.prevent="inspectMedia(coverPictures, 0)" v-if="coverPictures.length">
                    <ui-icon name="search"></ui-icon> Inspect cover pictures
                </a>
                <div class="bg-gray-200 inline-block pl-1 pr-2 py-0.5 -ml-1 rounded-full cursor-default" v-tooltip="historyTooltip" v-if="target.history">
                    <template v-if="target.history.status == 'downloaded'">
                        <ui-icon name="check"></ui-icon> Historic content downloaded
                    </template>
                    <template v-if="target.history.status == 'downloading'">
                        <ui-icon name="check"></ui-icon> Historic content downloading...
                    </template>
                    <template v-if="target.history.status == 'not-downloaded'">
                        <ui-icon name="x"></ui-icon> Historic content not available
                    </template>
                </div>
            </div>
        </div>

        <div class="bg-gray-50 py-2 px-5 flex space-x-2 last:rounded-b-sm text-gray-900" v-if="target.lists && target.lists.length">
            <ui-avatar class="w-6 h-6" :name="list.name" type="target-list" :key="list.id" v-tooltip="list.name" v-for="list in target.lists"></ui-avatar>
        </div>
    </div>
</template>

<script>
import useTargetsTargetInformationLightboxStore from '@/stores/targets/target/information-lightbox'

import { languageName, sortByDate } from '@/helpers'

import filter from 'just-filter'
import { mapActions } from 'pinia'

export default {
    props: [ 'target', 'inline' ],

    data: () => ({
        showFullDescription: false
    }),

    computed: {
        details() {
            let details = {}

            if (this.target.type == 'facebook-page') {
                details = {
                    'About': this.target.description,
                    'Website': this.target.details?.website
                }
            } else if (this.target.type == 'instagram-profile') {
                details = {
                    'Description':  this.target.description
                }
            } else if (this.target.type == 'vkontakte-community' || this.target.type == 'vkontakte-user') {
                details = {
                    'Description': this.target.description
                }
            } else if (this.target.type == 'web-feed') {
                details = {
                    'Description': this.target.description,
                    'URL': this.target.link
                }
            } else if (this.target.type == 'web-site') {
                details = {
                    'Regex': this.target.details?.regex,
                    'URL': this.target.link
                }
            } else if (this.target.type == 'youtube-channel') {
                details = {
                    'Description': this.target.description,
                    'URL': this.target.link
                }
            }

            return filter({
                ...details,
                'Username': this.target.username,
                'Created At': this.target.createdAt ? this.$dateTime(this.target.createdAt) : null,
                'First Seen At': this.target.firstSeenAt ? this.$dateTime(this.target.firstSeenAt) : null
            }, (k, v) => v)
        },

        isShowingFullDescription() {
            return this.showFullDescription || (this.target.description || '').length <= 100
        },

        description() {
            return this.isShowingFullDescription
                ? this.target.description
                : this.target.description.replace(/\n\n/, "\n").substr(0, 100) + '...'
        },

        language() {
            if (this.target.language == '00') return 'Unknown'

            return languageName(this.target.language) || `Other (${this.target.language})`
        },

        languageHasFlag() {
            let availableFlags = [
                'bg', 'by', 'cn', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gr', 'hu', 'ie', 'il', 'in', 'it', 'jp',
                'kr', 'lt', 'lv', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'ru', 'sa', 'se', 'si', 'sk', 'tr', 'ua', 'uk',
                'us'
            ]

            return availableFlags.includes(this.target.language)
        },

        profilePictures() {
            return sortByDate(this.target?.images?.filter(i => i.flag == 'profile_picture') || [], 'firstSeenAt').reverse()
        },

        latestProfilePicture() {
            return this.profilePictures[0]
        },

        coverPictures() {
            return sortByDate(this.target?.images?.filter(i => i.flag == 'cover_picture') || [], 'firstSeenAt').reverse()
        },

        latestCoverPicture() {
            return this.coverPictures[0]
        },

        historyTooltip() {
            if (this.target?.history) {
                if (this.target?.history.status == 'downloading') {
                    return `Available until ${this.$dateTime(this.target?.history.until)} and downloading.`
                } else if (this.target?.history.status == 'not-downloaded') {
                    return 'Start monitoring this Target to trigger historic content download.'
                }
            }

            return ''
        }
    },

    methods: {
        ...mapActions(useTargetsTargetInformationLightboxStore, {
            inspectMedia: 'open'
        }),

        readMoreDescription() {
            this.showFullDescription = true
        },
    }
}
</script>
