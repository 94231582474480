<template>
    <div class="mb-2">
        <ui-switch @click="store.toggleRecipient(channel, '*')" :value="inputs.recipients[channel]['*']" size="sm" class="mr-3"></ui-switch>
        <span>Notify all members of the workspace</span>
    </div>

    <div class="space-y-2" v-if="! inputs.recipients[channel]['*']">
        <div class="flex items-center " :key="user.id" v-for="user in currentWorkspace.users">
            <div class="shrink-0 pr-3">
                <ui-switch @click="store.toggleRecipient(channel, user.id)" :value="inputs.recipients[channel][user.id]" size="sm"></ui-switch>
            </div>

            <div class="shrink-0">
                <img class="rounded-full w-4 h-4 shadow" :src="user.avatarUrl">
            </div>

            <div class="flex-1 min-w-0 px-2 space-x-1">
                <span v-tooltip="user.name">{{user.name}}</span>
                <span class="text-gray-700 text-xs">{{user.email}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import useMyStore from '@/stores/me/my'

import { mapState } from 'pinia'

export default {
    props: [ 'channel', 'store' ],

    computed: {
        ...mapState(useMyStore, [ 'currentWorkspace' ]),

        inputs() { return this.store.inputs }
    }
}
</script>
