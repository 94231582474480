import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsCuratedListsCategoryPage = defineStore({
    id: 'targetsCuratedListsCategoryPage',

    state: () => ({
        search: '',

        category: null
    }),

    getters: {
        title: () => 'Curated Lists | Targets'
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize().then(() => this.initializeCategories(to))
            useMyTargetListsStore().initialize()
            useMyRecentTargetsStore().initialize()
        },

        initializeCategories(to) {
            this.category = useMyCuratedListsStore().find(to.params.categoryId)
        }
    }
})

export default useTargetsCuratedListsCategoryPage
