<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-slot:widget>
            <div class="h-full flex items-center justify-center">
                <p :style="effectiveStyle">{{ this.store.text }}</p>
            </div>
        </template>

        <template v-slot:edit>
            <div class="flex flex-col items-center justify-center h-full w-full p-4">
                <ui-input class="w-96" type="text" v-model="store.editForm.text"></ui-input>

                <div class="flex items-center justify-between space-x-2 mt-4">
                    <div class="flex items-center">
                        <input class="mr-1" type="radio" id="1" name="level" value="1" v-model="store.editForm.level" checked>
                        <label for="1">Level 1</label>
                    </div>

                    <div class="flex items-center">
                        <input class="mr-1" type="radio" id="2" name="level" value="2" v-model="store.editForm.level">
                        <label for="2">Level 2</label>
                    </div>

                    <div class="flex items-center">
                        <input class="mr-1" type="radio" id="3" name="level" value="3" v-model="store.editForm.level">
                        <label for="3">Level 3</label>
                    </div>
                </div>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>

import DashboardWidget from './widget'

export default {
    components: { DashboardWidget },

    computed: {
        effectiveStyle() {
            return {
                1: 'font-size: 24px; line-height: 30px',
                2: 'font-size: 20px; line-height: 26px',
                3: 'font-size: 16px; line-height: 22px'
            }[this.store.level ?? 1]
        }
    }
}
</script>
