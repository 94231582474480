import api from '@/api'

import { defineStore } from 'pinia'

export const useTargetsTargetConnectionsStore = defineStore({
    id: 'targetsTargetConnections',

    state: () => ({
        target: null,

        sourcesUpstream: { data: null, error: null },
        sourcesDownstream: { data: null, error: null },
        sourcesSharingProperties: { data: null, error: null },
        sourcesConnectedFeatures: { data: null, error: null }
    }),

    actions: {
        load(target, force = false) {
            if (force) this.$reset()
            if (this.target?.family != target.family && this.target?.id != target.id) this.$reset()

            this.target = target

            if (this.target.family == 'sources') {
                this.fetchData('sources', 'upstream', 'sourcesUpstream')
                this.fetchData('sources', 'downstream', 'sourcesDownstream')
                this.fetchData('sources', 'sharing-properties', 'sourcesSharingProperties')
                this.fetchData('sources', 'connected-features', 'sourcesConnectedFeatures')
            }
        },

        reload() {
            this.load(this.target, true)
        },

        fetchData(family, connectionType, resultKey) {
            return api.route(`monitor targets connections`, { family, id: this.target.id, type: connectionType }).get()
                .error(500, err => this[resultKey] = { data: null, error: err?.json?.message || 'unknown' })
                .json(res => this[resultKey] = { data: res.data, error: null })
        }
    }
})

export default useTargetsTargetConnectionsStore
