<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="check" @click="markThreadAsResolvedWrapper(thread.id)">
            Resolve all messages
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useThreadsStore from '@/admin/stores/support/threads'

import { mapActions } from 'pinia'

export default {
    props: [ 'thread' ],

    methods: {
        async markThreadAsResolvedWrapper(id) {
            this.markThreadAsResolved(id).then(() => {
                this.load(true)
            })
        },

        ...mapActions(useThreadsStore, [
            'load',

            'markThreadAsResolved'
        ])
    }
}
</script>
