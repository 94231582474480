<template>
    <div class="px-8">
        <Teleport to="#root">
            <stream-lightbox :store="contentDetailMediaLightbox"></stream-lightbox>
        </Teleport>

        <Teleport to="#header-bottom">
            <stream-toolbar :store="targetsTargetStreamStore"></stream-toolbar>
        </Teleport>

        <ui-stream :store="targetsTargetStreamStore"></ui-stream>
    </div>
</template>

<script>
import StreamLightbox from '@/components/ui/stream/lightbox/lightbox'
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import UiStream from '@/components/ui/stream/stream'

import useTargetsTargetStreamStore from '@/stores/targets/target/stream'
import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

import { mapState, mapStores } from 'pinia'

export default {
    components: { StreamLightbox, StreamToolbar, UiStream },

    computed: {
        ...mapStores(useTargetsTargetStreamStore),
        ...mapState(useContentModalsContentDetailStore, { contentDetailMediaLightbox: 'mediaLightbox' })
    }
}
</script>
