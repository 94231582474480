import { defineContentStreamStore } from '@/stores/reusable/stream'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { defineStore } from 'pinia'

export const defineStreamWidget = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,
            type: settings.type,
            undeletable: settings.undeletable ?? false,
            uneditable: settings.uneditable ?? false,

            perspectiveId: settings.perspectiveId,

            sortingOptionId: settings.sortingOptionId || 'latest',
            sortingOptionDirection: settings.sortingOptionDirection || 'desc',
            showTitle: true,

            widgetStream: defineContentStreamStore({
                id: `${settings.id}WidgetStream`,
                resultsToolbar: false,
                updatesQuery: false,
                statistics: {},
                itemsLimit: 100
            }),

            expandedStream: defineContentStreamStore({
                id: `${settings.id}ExpandedStream`,
                updatesQuery: false,
                statistics: {}
            }),

            editForm: {
                sortingOptionId: null,
                sortingOptionDirection: null,
                showTitle: null,
                search: '',
                selectedPerspective: null
            },

            isNew: settings.isNew || false,

            refreshInterval: 5,
            lastRefresh: null
        }),

        getters: {
            perspective(store) {
                let myPerspectives = useMyPerspectivesStore()

                return myPerspectives.find(store.perspectiveId)
            },

            sortingOption(store) {
                return store.widgetStream().sortingOptions.find(o => o.id == store.sortingOptionId)
            },

            editFormPerspectives(store) {
                let myPerspectives = useMyPerspectivesStore()

                return store.editForm.search
                    ? myPerspectives.items.filter(ch => ch.name.toLowerCase().includes(store.editForm.search.toLowerCase()))
                    : myPerspectives.items
            },

            isConfigured() {
                return this.perspective
            }
        },

        actions: {
            initialize() {
                this.load()
                this.lastRefresh = + new Date

                return this
            },

            load() {
                if (! this.isConfigured) return

                let widgetStreamState = this.widgetStream()
                let expandedStreamState = this.expandedStream()

                widgetStreamState.applyPerspective(this.perspective)
                expandedStreamState.applyPerspective(this.perspective)

                widgetStreamState.setSorting(this.sortingOption.id, this.sortingOptionDirection)
                expandedStreamState.setSorting(this.sortingOption.id, this.sortingOptionDirection)

                widgetStreamState.load()
                expandedStreamState.load()
            },

            edit() {
                this.editForm.sortingOptionId = this.sortingOptionId
                this.editForm.sortingOptionDirection = this.sortingOptionDirection
                this.editForm.showTitle = this.showTitle
                this.editForm.search = ''
                this.editForm.selectedPerspective = this.perspective
            },

            save() {
                this.perspectiveId = this.editForm.selectedPerspective.id
                this.sortingOptionId = this.editForm.sortingOptionId
                this.sortingOptionDirection = this.editForm.sortingOptionDirection
                this.showTitle = this.editForm.showTitle

                this.load()
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                this.widgetStream().$dispose()
                this.expandedStream().$dispose()

                this.$dispose()
            },

            refresh() {
                this.load()

                this.lastRefresh = + new Date
            },

            layoutUpdated() {
            },

            serialize() {
                return {
                    perspectiveId: this.perspective?.id,
                    sortingOptionId: this.sortingOptionId,
                    sortingOptionDirection: this.sortingOptionDirection,
                    showTitle: this.showTitle
                }
            },

            dependencies() {
                return {
                    'perspectives': [ this.perspective?.id ]
                }
            }
        }
    })
}

export default defineStreamWidget
