import useFeaturesFeaturePage from '@/pages/features-feature'
import useFeaturesFeatureConnectionsStore from '@/stores/features/feature/connections'

import { defineStore, mapState } from 'pinia'

export const useFeaturesFeatureConnectionsPage = defineStore({
    id: 'featuresFeatureConnectionsPage',

    state: () => ({
        connectionsType: null
    }),

    getters: {
        ...mapState(useFeaturesFeaturePage, [ 'type', 'feature', 'model' ])
    },

    actions: {
        afterEnter(to) {
            useFeaturesFeatureConnectionsStore().load(this.feature)

            this.connectionsType = to.params.connectionsType || 'connected-sources'
        }
    }
})

export default useFeaturesFeatureConnectionsPage
