<template>
    <div class="flex flex-col items-center justify-center h-screen" v-if="! isInitialized">
        <img src="@/assets/gerulata.png" class="w-32">
    </div>

    <router-view v-else></router-view>

    <div id="root"></div>
</template>

<script>
import useRouter from './router'

export default {
    data: () => ({
        isInitialized: false
    }),

    mounted() {
        useRouter().isReady().then(() => this.isInitialized = true)
    }
}
</script>
