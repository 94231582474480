import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentFacebookPostReactionsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentFacebookPostReactionsAnalysis',
    name: 'content.facebook-post-reactions',
    title: 'Reactions',
    color: 'blue-600'
})

export default useTargetsTargetContentFacebookPostReactionsAnalysisStore
