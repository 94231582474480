import defineAnalysis from '@/stores/reusable/analysis'

export const useTargetsTargetContentYoutubeVideoActivityAnalysisStore = defineAnalysis({
    id: 'targetsTargetContentYoutubeVideoActivityAnalysis',

    type: 'content.youtube-video-activity',

    granularity: 'hour',

    series: [
        {
            color: '#2170f0',
            label: 'Reactions',
            dataset: 'reactions'
        },
        {
            color: '#4e46e5',
            label: 'Comments',
            dataset: 'comments'
        },
        {
            color: '#db2777',
            label: 'Views',
            dataset: 'views',
            yAxis: 'views'
        }
    ],

    chainAnalysisOptions: (store, options) => {
        options.plotOptions.series.marker.enabled = true
        options.xAxis.labels.rotation = 0

        return options
    },

    chainConfiguration: (store, configuration) => {
        configuration.modelId = store.content?.modelId

        return configuration
    },

    state: () => ({
        content: null
    }),

    actions: {
        setContent(content) {
            this.content = content
        }
    }
})

export default useTargetsTargetContentYoutubeVideoActivityAnalysisStore
