import api from '@/api'

import { defineStore } from 'pinia'

export const useTargetsTargetInformationTopGroupsStore = defineStore({
    id: 'targetsTargetInformationTopGroups',

    state: () => ({
        target: null,
        groups: null
    }),

    actions: {
        load(target) {
            if (this.target?.family != target.family && this.target?.id != target.id) this.$reset()

            this.target = target

            return api.route(`monitor targets top-groups`, { id: this.target.id })
                .get().json(res => this.groups = res.data)
        }
    }
})

export default useTargetsTargetInformationTopGroupsStore
