<template>
    <ui-contextual-menu v-bind="$attrs" z-index="50">
        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'features.feature.information', params: { type: feature.type, id: feature.id } }).href">
            Show details
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(feature)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
export default {
    props: [ 'feature' ]
}
</script>
