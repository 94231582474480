<template>
    <ui-modal name="targets-list-detach-confirmation" :title="`Are you sure you want to detach this Target List?`">
        <p class="text-sm text-gray-700 mt-2">
            You can't modify a curated list. However, we can create a copy for you. This copy, however, won't receive any further updates from our team of curators.
        </p>

        <p class="text-sm text-gray-700 mt-2">
            You can subscribe to the original curated list again, which won't affect this new copy.
        </p>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="button" :action="cancel" :disabled="submitting">
                Cancel
            </ui-button>

            <ui-button type="button" :action="confirm" color="red" :disabled="submitting">
                Create a copy
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useTargetsModalsListDetachConfirmationStore from '@/stores/targets/modals/list-detach-confirmation'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useTargetsModalsListDetachConfirmationStore),
        ...mapState(useTargetsModalsListDetachConfirmationStore, [ 'submitting' ])
    },

    methods: {
        ...mapActions(useTargetsModalsListDetachConfirmationStore, [ 'cancel', 'confirm' ])
    }
}
</script>
