import { defineStore } from 'pinia'
import useMembersStore from '@/admin/stores/accounts/members'

export const useAccountsOrganizationsOrganizationMembersPage = defineStore({
    id: 'accountsOrganizationsOrganizationMembersPage',

    actions: {
        beforeEnter(to) {
            useMembersStore().initialize(to.params.organizationId, true)
        }
    }
})

export default useAccountsOrganizationsOrganizationMembersPage
