<template>
    <ui-modal name="targets-my-targets-options-resource-unavailable" title="Resource Unavailable" width="320px">
        <div class="text-center">
            You have reached the maximum limit of Targets with this option enabled.
        </div>

        <div class="text-center mt-8">
            Please disable the feature on some of your targets or contact support for more options.
        </div>

        <div class="flex justify-center mt-8">
            <ui-button @click="$modal.hide('targets-my-targets-options-resource-unavailable')">
                Close
            </ui-button>
        </div>
    </ui-modal>
</template>
