import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyDashboards from '@/stores/me/dashboards'

export const useModalsBoardDuplicateStore = defineForm({
    id: 'modalsBoardDuplicate',

    inputs: () => ({
        board: {},
        name: null,
        description: null
    }),

    submitRequest() {
        trackEvent('dashboards', 'duplicated-dashboard-saved', 'board')

        return api.route('me dashboards duplicate', { id: this.inputs.board.id })
            .formData({
                _method: 'post',
                type: 'board',
                name: this.inputs.name,
                description: this.inputs.description
            })
            .post()
    },

    onResponse(res) {
        useMyDashboards().reload()

        useModal().hide('board-duplicate')

        useRouter().push({ name: 'dashboard.boards.board', params: { board: res.data.id } })
    },

    actions: {
        open(board) {
            this.reset()

            this.inputs.board = board
            this.inputs.name = board.name
            this.inputs.description = board.description

            useModal().show('board-duplicate')
        },

        cancel() {
            useModal().hide('board-duplicate')
        }
    }
})

export default useModalsBoardDuplicateStore
