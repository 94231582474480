<template>
    <div class="flex-1 min-h-0 px-8 py-6 overflow-y-auto">
        <ui-form :store="workspacesFormsDeleteStore" v-slot="{ inputs, submitting, errors }">
            <div class="space-y-3">
                <p>Are you sure you want to delete this workspace?</p>
                <p>The workspace will be irreversibly deleted along with all it's associated content like perspectives, target lists, analyses, etc.</p>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button :action="cancel">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="red" :disabled="submitting">
                    Delete
                </ui-button>
            </div>
        </ui-form>
    </div>
</template>

<script>
import useWorkspacesFormsDeleteStore from '@/stores/workspaces/forms/delete'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useWorkspacesFormsDeleteStore)
    },

    methods: {
        ...mapActions(useWorkspacesFormsDeleteStore, [ 'cancel' ])
    }
}
</script>
