<template>
    <div class="px-8 max-w-7xl mx-auto my-8 pb-6">
        <ui-async :guards="content" v-slot:default="{ isLoaded }">
            <template v-if="isLoaded">
                <div class="max-w-3xl mx-auto">
                    <content-facebook-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'facebook-post'"></content-facebook-post-card>
                    <content-facebook-group-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'facebook-group-post'"></content-facebook-group-post-card>
                    <content-facebook-user-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'facebook-user-post'"></content-facebook-user-post-card>
                    <content-instagram-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'instagram-post'"></content-instagram-post-card>
                    <content-telegram-channel-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'telegram-channel-post'"></content-telegram-channel-post-card>
                    <content-telegram-group-message-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'telegram-group-message'"></content-telegram-group-message-card>
                    <content-tiktok-video-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'tiktok-video'"></content-tiktok-video-card>
                    <content-twitter-tweet-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-if="content.type == 'twitter-tweet'"></content-twitter-tweet-card>
                    <content-vkontakte-post-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-else-if="content.type == 'vkontakte-post'"></content-vkontakte-post-card>
                    <content-web-article-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-else-if="content.type == 'web-article'"></content-web-article-card>
                    <content-web-resource-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-else-if="content.type == 'web-resource'"></content-web-resource-card>
                    <content-youtube-video-card :content="content" :stream-store="targetsTargetContentStreamStore" mode="details" v-else-if="content.type == 'youtube-video'"></content-youtube-video-card>
                </div>

                <div v-if="content.type == 'facebook-post'">
                    <div class="max-w-3xl mx-auto bg-white w-full overflow-hidden shadow rounded-sm flex flex-col my-6">
                        <div class="px-6 py-4 flex items-center justify-between">
                            <h3 class="font-semibold text-gray-900">
                                Statistics
                            </h3>
                        </div>
                        <div class="px-6 pb-6 pt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            <target-metric title="Reactions" :value="content.metrics.reactions" :analysis-store="targetsTargetContentFacebookPostReactionsAnalysisStore"></target-metric>
                            <target-metric title="Comments" :value="content.metrics.comments" :analysis-store="targetsTargetContentFacebookPostCommentsAnalysisStore"></target-metric>
                            <target-metric title="Shares" :value="content.metrics.shares" :analysis-store="targetsTargetContentFacebookPostSharesAnalysisStore"></target-metric>
                            <target-metric title="Views" :value="content.metrics.views" :analysis-store="targetsTargetContentFacebookPostViewsAnalysisStore" v-if="content.metrics.views"></target-metric>
                        </div>
                    </div>

                    <p class="text-center text-sm text-gray-700">
                        The analyses show <span class="font-medium">72 hours</span> interval after publishing.
                    </p>
                </div>

                <div v-else-if="content.type == 'vkontakte-post'">
                    <div class="max-w-3xl mx-auto bg-white w-full overflow-hidden shadow rounded-sm flex flex-col my-6">
                        <div class="px-6 py-4 flex items-center justify-between">
                            <h3 class="font-semibold text-gray-900">
                                Statistics
                            </h3>
                        </div>
                        <div class="px-6 pb-6 pt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            <target-metric title="Likes" :value="content.metrics.reactions" :analysis-store="targetsTargetContentVKontaktePostLikesAnalysisStore"></target-metric>
                            <target-metric title="Comments" :value="content.metrics.comments" :analysis-store="targetsTargetContentVKontaktePostCommentsAnalysisStore"></target-metric>
                            <target-metric title="Reposts" :value="content.metrics.shares" :analysis-store="targetsTargetContentVKontaktePostRepostsAnalysisStore"></target-metric>
                            <target-metric title="Views" :value="content.metrics.views" :analysis-store="targetsTargetContentVKontaktePostViewsAnalysisStore" v-if="content.metrics.views"></target-metric>
                        </div>
                    </div>

                    <p class="text-center text-sm text-gray-700">
                        The analyses show <span class="font-medium">72 hours</span> interval after publishing.
                    </p>
                </div>

                <div v-else-if="content.type == 'youtube-video'">
                    <div class="max-w-3xl mx-auto bg-white w-full overflow-hidden shadow rounded-sm flex flex-col my-6">
                        <div class="px-6 py-4 flex items-center justify-between">
                            <h3 class="font-semibold text-gray-900">
                                Statistics
                            </h3>
                        </div>
                        <div class="px-6 pb-6 pt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            <target-metric title="Views" :value="content.metrics.views" :analysis-store="targetsTargetContentYoutubeVideoViewsAnalysisStore"></target-metric>
                            <target-metric title="Reactions" :value="content.metrics.reactions" :analysis-store="targetsTargetContentYoutubeVideoReactionsAnalysisStore"></target-metric>
                            <target-metric title="Comments" :value="content.metrics.comments" :analysis-store="targetsTargetContentYoutubeVideoCommentsAnalysisStore"></target-metric>
                        </div>
                    </div>

                    <p class="text-center text-sm text-gray-700">
                        The analyses show <span class="font-medium">21 days</span> interval after publishing.
                    </p>
                </div>

                <template v-if="content.type == 'facebook-post'">
                    <div class="flex justify-center sticky inset-x-0 -top-6 z-30">
                        <nav class="flex mx-auto rounded-sm bg-white mt-6 px-8 pt-3 text-gray-900 space-x-8 shadow" aria-label="Tabs">
                            <a href="#" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" :class="detailsView == 'facebook-post-activity' ? 'text-blue-600 border-b-blue-500' : ''" @click.prevent="detailsView = 'facebook-post-activity'">
                                <ui-icon name="analysis" class="mr-2"></ui-icon>
                                Activity
                            </a>
                            <a href="#" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" :class="detailsView == 'facebook-post-shares' ? 'text-blue-600 border-b-blue-500' : ''" @click.prevent="detailsView = 'facebook-post-shares'">
                                <ui-icon name="share" class="mr-2"></ui-icon>
                                Shares
                            </a>
                            <a href="#" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" @click.prevent="jumpToTop">
                                <ui-icon name="arrow-up" class="mr-2"></ui-icon>
                                Top
                            </a>
                        </nav>
                    </div>

                    <div class="mt-6">
                        <facebook-post-activity-analysis ref="analysis" v-if="detailsView == 'facebook-post-activity'"></facebook-post-activity-analysis>
                        <facebook-post-shares v-else-if="detailsView == 'facebook-post-shares'"></facebook-post-shares>
                    </div>
                </template>

                <template v-else-if="content.type == 'vkontakte-post'">
                    <div class="flex justify-center sticky inset-x-0 -top-6 z-30">
                        <nav class="flex mx-auto rounded-sm bg-white mt-6 px-8 pt-3 text-gray-900 space-x-8 shadow" aria-label="Tabs">
                            <a href="#" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-3" :class="detailsView == 'vkontakte-post-activity' ? 'text-blue-600 border-b-blue-500' : ''" @click.prevent="detailsView = 'vkontakte-post-activity'">
                                <ui-icon name="analysis" class="mr-2"></ui-icon>
                                Activity
                            </a>
                            <a href="#" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-3" @click.prevent="jumpToTop">
                                <ui-icon name="arrow-up" class="mr-2"></ui-icon>
                                Top
                            </a>
                        </nav>
                    </div>

                    <div class="mt-6">
                        <vkontakte-post-activity-analysis ref="analysis" v-if="detailsView == 'vkontakte-post-activity'"></vkontakte-post-activity-analysis>
                    </div>
                </template>

                <template v-else-if="content.type == 'youtube-video'">
                    <div class="flex justify-center sticky inset-x-0 -top-6 z-30">
                        <nav class="flex mx-auto rounded-sm bg-white mt-6 p-1 space-x-1 shadow" aria-label="Tabs">
                            <a href="#" class="h-9 px-6 flex items-center justify-center font-medium text-sm rounded-sm transition ease-in-out duration-150 text-gray-600 hover:bg-blue-50" :class="detailsView == 'youtube-video-activity' ? 'bg-blue-50 text-blue-700' : ''" @click="detailsView = 'youtube-video-activity'">
                                <ui-icon name="analysis" class="text-xl mr-2"></ui-icon>
                                Activity
                            </a>
                            <a href="#" class="h-9 px-6 flex items-center justify-center font-medium text-sm rounded-sm transition ease-in-out duration-150 text-gray-600 hover:bg-blue-50" @click="jumpToTop">
                                <ui-icon name="arrow-up" class="text-xl mr-2"></ui-icon>
                                Top
                            </a>
                        </nav>
                    </div>

                    <div class="mt-6">
                        <youtube-video-activity-analysis ref="analysis" v-if="detailsView == 'youtube-video-activity'"></youtube-video-activity-analysis>
                    </div>
                </template>
            </template>
        </ui-async>

        <Teleport to="#root">
            <stream-lightbox :store="targetsTargetContentStreamStore.mediaLightbox"></stream-lightbox>
            <stream-lightbox :store="contentDetailMediaLightbox"></stream-lightbox>
            <sentiment-edit-modal></sentiment-edit-modal>
        </Teleport>
    </div>
</template>

<script>
import ContentFacebookPostCard from '@/components/content/stream-cards/facebook-post'
import ContentFacebookGroupPostCard from '@/components/content/stream-cards/facebook-group-post'
import ContentFacebookUserPostCard from '@/components/content/stream-cards/facebook-user-post'
import ContentInstagramPostCard from '@/components/content/stream-cards/instagram-post'
import ContentTelegramChannelPostCard from '@/components/content/stream-cards/telegram-channel-post'
import ContentTelegramGroupMessageCard from '@/components/content/stream-cards/telegram-group-message'
import ContentTiktokVideoCard from '@/components/content/stream-cards/tiktok-video'
import ContentTwitterTweetCard from '@/components/content/stream-cards/twitter-tweet'
import ContentVkontaktePostCard from '@/components/content/stream-cards/vkontakte-post'
import ContentWebArticleCard from '@/components/content/stream-cards/web-article'
import ContentWebResourceCard from '@/components/content/stream-cards/web-resource'
import ContentYoutubeVideoCard from '@/components/content/stream-cards/youtube-video'
import FacebookPostActivityAnalysis from '@/components/targets/target/content/facebook-post-activity-analysis'
import FacebookPostShares from '@/components/targets/target/content/facebook-post-shares'
import StreamLightbox from '@/components/ui/stream/lightbox/lightbox'
import TargetMetric from './information/target-metric'
import VkontaktePostActivityAnalysis from '@/components/targets/target/content/vkontakte-post-activity-analysis'
import YoutubeVideoActivityAnalysis from '@/components/targets/target/content/youtube-video-activity-analysis'
import SentimentEditModal from '@/components/content/modals/sentiment-edit.vue'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'
import useTargetsTargetContentFacebookPostCommentsAnalysisStore from '@/stores/targets/target/content-facebook-post-comments-analysis'
import useTargetsTargetContentFacebookPostReactionsAnalysisStore from '@/stores/targets/target/content-facebook-post-reactions-analysis'
import useTargetsTargetContentFacebookPostSharesAnalysisStore from '@/stores/targets/target/content-facebook-post-shares-analysis'
import useTargetsTargetContentFacebookPostViewsAnalysisStore from '@/stores/targets/target/content-facebook-post-views-analysis'
import useTargetsTargetContentStore from '@/stores/targets/target/content'
import useTargetsTargetContentStreamStore from '@/stores/targets/target/content-stream'
import useTargetsTargetContentVKontaktePostCommentsAnalysisStore from '@/stores/targets/target/content-vkontakte-post-comments-analysis'
import useTargetsTargetContentVKontaktePostLikesAnalysisStore from '@/stores/targets/target/content-vkontakte-post-likes-analysis'
import useTargetsTargetContentVKontaktePostRepostsAnalysisStore from '@/stores/targets/target/content-vkontakte-post-reposts-analysis'
import useTargetsTargetContentVKontaktePostViewsAnalysisStore from '@/stores/targets/target/content-vkontakte-post-views-analysis'
import useTargetsTargetContentYoutubeVideoCommentsAnalysisStore from '@/stores/targets/target/content-youtube-video-comments-analysis'
import useTargetsTargetContentYoutubeVideoReactionsAnalysisStore from '@/stores/targets/target/content-youtube-video-reactions-analysis'
import useTargetsTargetContentYoutubeVideoViewsAnalysisStore from '@/stores/targets/target/content-youtube-video-views-analysis'

import { mapState, mapStores } from 'pinia'

export default {
    components: {
        ContentFacebookPostCard, ContentFacebookGroupPostCard, ContentFacebookUserPostCard,
        ContentInstagramPostCard,
        ContentTelegramChannelPostCard, ContentTelegramGroupMessageCard,
        ContentTiktokVideoCard,
        ContentTwitterTweetCard,
        ContentVkontaktePostCard,
        ContentWebArticleCard, ContentWebResourceCard,
        ContentYoutubeVideoCard,
        FacebookPostActivityAnalysis, FacebookPostShares,
        VkontaktePostActivityAnalysis,
        StreamLightbox, TargetMetric, YoutubeVideoActivityAnalysis,
        SentimentEditModal
    },

    computed: {
        ...mapState(useTargetsTargetContentStore, [ 'content' ]),
        ...mapStores(
            useTargetsTargetContentFacebookPostCommentsAnalysisStore,
            useTargetsTargetContentFacebookPostReactionsAnalysisStore,
            useTargetsTargetContentFacebookPostSharesAnalysisStore,
            useTargetsTargetContentFacebookPostViewsAnalysisStore,
            useTargetsTargetContentStreamStore,
            useTargetsTargetContentVKontaktePostCommentsAnalysisStore,
            useTargetsTargetContentVKontaktePostLikesAnalysisStore,
            useTargetsTargetContentVKontaktePostRepostsAnalysisStore,
            useTargetsTargetContentVKontaktePostViewsAnalysisStore,
            useTargetsTargetContentYoutubeVideoCommentsAnalysisStore,
            useTargetsTargetContentYoutubeVideoReactionsAnalysisStore,
            useTargetsTargetContentYoutubeVideoViewsAnalysisStore
        ),
        ...mapState(useContentModalsContentDetailStore, { contentDetailMediaLightbox: 'mediaLightbox' })
    },

    data: () => ({
        detailsView: null
    }),

    methods: {
        jumpToTop() {
            document.querySelector('#app-content').scrollTo(0, 0)
        }
    },

    watch: {
        content(val) {
            if (! this.detailsView) {
                if (val.type == 'facebook-post') {
                    this.detailsView = 'facebook-post-activity'
                } else if (val.type == 'vkontakte-post') {
                    this.detailsView = 'vkontakte-post-activity'
                } else if (val.type == 'youtube-video') {
                    this.detailsView = 'youtube-video-activity'
                }
            }
        }
    }
}
</script>
