import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentVKontaktePostLikesAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentVKontaktePostLikesAnalysis',
    name: 'content.vkontakte-post-likes',
    title: 'Likes',
    color: 'blue-600'
})

export default useTargetsTargetContentVKontaktePostLikesAnalysisStore
