<template>
    <ui-dropdown align="top" position="right" z-index="50" width="w-64">
        <template v-slot:trigger>
            <button class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out h-9 w-9 mx-auto mb-2">
                <img class="h-9 w-9 rounded-sm bg-gray-100 shadow object-cover" :src="currentWorkspace.avatarUrl" v-if="currentWorkspace">
            </button>
        </template>

        <template v-slot:content>
            <ui-dropdown-link :selected="workspace.id == currentWorkspace.id" variant="large" @click="switchWorkspace(workspace)" :key="workspace.id" v-for="workspace in workspaces">
                <div class="inline-flex items-center pt-1">
                    <img class="h-6 w-6 rounded-sm shadow-sm object-cover" :src="workspace.avatarUrl">
                    <div class="ml-2">
                        {{workspace.name}}
                    </div>
                </div>
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link :selected="false" :href="$router.resolve({ name: 'settings.account' }).href">
                Account settings...
            </ui-dropdown-link>

            <ui-dropdown-link :selected="false" @click="manageWorkspaces">
                Manage workspaces...
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link :selected="false" @click="logOut()">
                Logout
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import useMyStore from '@/stores/me/my'
import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesModalsManageStore from '@/stores/workspaces/modals/manage'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useMyStore, [ 'currentWorkspace' ]),
        ...mapState(useMyWorkspacesStore, {
            workspaces(store) {
                if (! store.items.length) return []

                let personal = store.items.find(item => item.personal)
                let other = [ ...store.items.filter(item => ! item.personal) ]
                    .sort((a, b) => a.name.localeCompare(b.name))

                return [ personal, ...other ]
            }
        })
    },

    methods: {
        ...mapActions(useMyStore, [ 'logOut', 'switchWorkspace' ]),
        ...mapActions(useWorkspacesModalsManageStore, { manageWorkspaces: 'open' })
    }
}
</script>