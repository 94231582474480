import useMyAnalysesStore from '@/stores/me/analyses'
import useAnalysesChannel from '@/stores/analysis/analyses-channel'

import hasTargetConcern from './concerns/has-target-concern'

import api from '@/api'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'

export const defineTargetPublishTimesAnalysisStore = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            analysis: null,

            id: null,
            name: 'Untitled analysis',
            type: 'target-publish-times',
            fingerprint: null,

            series: [],
            styles: {},

            date: { type: 'past', date: { past: 1, unit: 'years' }, },
            granularity: false,

            isTakingTooLong: false,

            isLoading: false,
            isLoadingFresh: false,
            loadingPromise: null,
            abortLoading: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: [],
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 10,
                hasColors: false,
                hasLabels: false
            },

            styleOptions: [],
            styleDefaults: {},

            exportable: false,
            printable: false,

            passive: settings.passive || false,

            analysisRef: null,

            heatmapTypes: [
                { id: 'daily', name: 'Daily' },
                { id: 'hourly', name: 'Hourly' }
            ],

            ...hasTargetConcern.state
        }),

        getters: {
            heatmapOptions: store => ({
                styles: store.styles,
                isLoading: store.isLoading,
                series: store.analysisSeries,
                seriesGeneratedAt: store.seriesGeneratedAt
            }),

            effectiveStyles: store => ({
                ...store.styleDefaults,
                ...store.styles
            }),

            analysisConfiguration: store => ({
                series: store.series,
                styles: store.effectiveStyles,
                date: store.date,
                granularity: store.series[0].meta.type
            }),

            selectedHeatmapType: store => {
                return store.heatmapTypes.find(m => m && m.id == (store.series[0].meta.type || 'daily'))
            }
        },

        actions: {
            initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.date = analysis.configuration.date || this.date

                this.series = analysis.configuration.series || this.series
                this.styles = analysis.configuration.styles || this.styles

                this.loadSeries()
                this.loadSelectedTargets()
            },

            async loadSeries(fresh = false) {
                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true
                this.isLoadingFresh = fresh

                setTimeout(() => {
                    if (this.isLoading) {
                        this.isTakingTooLong = true
                    }
                }, 5000)

                return this.loadingPromise = api.route('me analyses series')
                    .json({
                        type: this.type,
                        configuration: this.analysisConfiguration,
                        background: true,
                        fresh: fresh
                    })
                    .signal(this.abortLoading = new AbortController())
                    .post()
                    .error(422, () => {
                        this.isLoading = false
                        this.fingerprint = null
                    })
                    .json(response => {
                        if (! response.fingerprint) {
                            this.fingerprint = null

                            this.analysisSeries = response.series
                            this.seriesGeneratedAt = new Date(response.cachedAt)

                            this.isLoading = this.isLoadingFresh = this.isTakingTooLong = false

                            if (! fresh && Math.abs(this.seriesGeneratedAt - new Date()) > 15 * 60 * 1000) {
                                this.loadSeries(true)
                            }
                        } else {
                            this.fingerprint = response.fingerprint

                            useAnalysesChannel().channel.listen('SeriesFinishedBuilding', (payload) => {
                                if (payload.fingerprint === this.fingerprint) {
                                    this.analysisSeries = payload.data

                                    this.isLoading = this.isLoadingFresh = this.isTakingTooLong = false
                                }
                            });
                        }
                    })
            },

            loadSeriesDebounced: debounce(function () {
                this.loadSeries()
            }, 500),

            addSeries(data) {
                this.series.push({
                    ...data,
                    meta: data.meta || { type: 'daily' }
                })

                this.isDirty = true
                this.loadSeries()
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    ...data,
                    meta: data.meta || series.meta
                })

                this.isDirty = true
                this.loadSeries()
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.series.splice(index, 1)

                    this.isDirty = true
                    this.loadSeries()
                }
            },

            async unserializeUriSeries(to) {
            },

            setDate(date) {
                this.date = date
                this.isDirty = true

                this.loadSeries()
            },

            setStyle(name, value) {
                this.styles[name] = value
                this.isDirty = true
            },

            setAnalysisRef(analysis) {
                this.analysisRef = analysis
            },

            setHeatmapType(type) {
                this.series[0].meta = { type }
                this.isDirty = true

                this.analysisSeries = []
                this.loadSeries()
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'target-lists': this.series.filter(s => s.datasetType == 'target-list').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'search-topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            ...hasTargetConcern.actions
        }
    })
}

const useAnalysisAnalysesTargetPublishTimesStore = defineTargetPublishTimesAnalysisStore({ id: 'analysisAnalysesTargetPublishTimes' })

export default useAnalysisAnalysesTargetPublishTimesStore
