<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset">
        <template v-slot:applied>
            <div class="inline-flex space-x-1">
                <div v-if="value.length > 1" class="inline-flex items-center leading-none space-x-1">
                    <ui-icon name="info"></ui-icon>
                    <span class="font-medium">{{ store.getFilterValue(filter.id).length }} Mentions</span>
                </div>

                <div v-else class="inline-flex items-center leading-none space-x-1">
                    <ui-icon :name="store.getFilterValue(filter.id)[0].type.icon"></ui-icon>
                    <span class="font-medium">{{store.getFilterValue(filter.id)[0].name}}</span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64" v-if="selectedType">
                <label class="block mb-2 px-4 font-medium text-gray-700 text-xs uppercase">
                    {{selectedType.name}}
                </label>

                <div class="relative mb-2 w-full text-gray-900 px-4">
                    <div class="absolute inset-y-0 left-6 flex items-center pointer-events-none">
                        <ui-icon name="search" class="text-sm"></ui-icon>
                    </div>
                    <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-8 py-2 rounded-sm bg-gray-100 placeholder-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm shadow-sm" v-model="search">
                </div>

                <div class="max-h-80 overflow-y-auto" v-if="mentionsResults.length">
                    <div class="mb-3 px-4 font-medium text-gray-700 text-xs uppercase">
                        Search Results
                    </div>

                    <ui-dropdown-link @click="store.addFilter(filter.id, [{ type: selectedType, id: result.id, name: result.title }])" :key="result.id" v-for="result in mentionsResults">
                        <div class="truncate">
                            {{result.title}}
                        </div>
                    </ui-dropdown-link>
                </div>

                <div class="h-full flex flex-col justify-center items-center text-center text-gray-600 h-full text-sm p-4" v-else>
                    <ui-icon name="search" class="text-2xl text-gray-500 mb-1"></ui-icon>
                    <p class="mb-1">No results found.</p>
                    <p class="text-xs">Please try a different search query.</p>
                </div>
            </div>

            <div class="w-48" v-else>
                <ui-dropdown-link :checkbox="filter.multiple" :selected="value && value.some(v => v.type.id === type.id)" @click="selectedType = type" :icon="type.icon" :key="type.id" v-for="type in store.mentionFilterTypes.filter(t => ! t.hidden)">
                    {{type.name}}
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import { features } from '@/api'

import debounce from 'just-debounce-it'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        search: '',
        selectedType: null,
        mentionsResults: []
    }),

    computed: {
        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    },

    methods: {
        reset() {
            this.search = ''
            this.selectedType = null
            this.mentionsResults = []
        }
    },

    watch: {
        search: debounce(function () {
            if (this.search.length < 3) return

            features()
                .types(this.selectedType.types)
                .filters('name', { query: this.search })
                .limit(50)
                .get(results => this.mentionsResults = results)
        }, 500)
    }
}
</script>
