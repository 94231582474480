import useMyAnalysesStore from '@/stores/me/analyses'

import defineCards from '@/stores/reusable/cards'

import { sortNaturally } from '@/helpers'

export const useAnalysisIndexCardsStore = defineCards({
    id: 'analysisIndexCards',

    source: useMyAnalysesStore(),
    filter: items => items.filter(item => item.pinned || item.recent),

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: 'asc',
            apply: items => sortNaturally(items)
        }
    ]
})

export default useAnalysisIndexCardsStore
