<template>
    <div class="px-2 py-1">
        <div class="relative w-full h-7">
            <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                <ui-icon name="search" class="text-sm"></ui-icon>
            </div>
            <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
        </div>
    </div>

    <ui-dropdown-separator></ui-dropdown-separator>

    <div class="max-h-80 my-2 overflow-y-auto">
        <div class="text-xs font-medium text-gray-700 px-4 mb-2 cursor-pointer" @click="isSelectingGroup = ! isSelectingGroup">
            {{ selectedGroup ? selectedGroup.name : 'All Tags' }}
            <ui-icon :name="isSelectingGroup ? 'chevron-up' : 'chevron-down'"></ui-icon>
        </div>

        <template v-if="isSelectingGroup">
            <ui-dropdown-link @click="selectGroup(null)" keep-open :selected="! selectedGroup">
                All Tags
            </ui-dropdown-link>
            <ui-dropdown-link @click="selectGroup(group)" keep-open :selected="!! (selectedGroup && selectedGroup.id == group.id)" :key="group.id" v-for="group in tagGroups">
                {{group.name}}
            </ui-dropdown-link>
        </template>

        <template v-else>
            <ui-dropdown-link @click="stream ? toggleTagOnStream(stream, tag) : toggleTagOn(item, tag)" :icon="true" :selected="stream ? isAppliedToStream(stream, tag) : isAppliedTo(item, tag)" :checkbox="true" keep-open :key="tag.id" v-for="tag in tags">
                <template v-slot:icon>
                    <div class="w-4 flex justify-center items-center text-center">
                        <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color}`"></span>
                    </div>
                </template>
                <template v-slot:default>
                    {{tag.name}}
                </template>
            </ui-dropdown-link>

            <div class="text-xs text-gray-700 text-center py-1" v-if="! tags.length">
                No tags have been found.
            </div>
        </template>
    </div>

    <ui-dropdown-separator></ui-dropdown-separator>

    <ui-dropdown-link icon="clear" keep-open @click="stream ? clearTagsOnStream(stream) : clearTagsOn(item)">
        Clear Tags
    </ui-dropdown-link>

    <ui-dropdown-link icon="settings" @click="manageTags">
        Manage Tags
    </ui-dropdown-link>
</template>

<script>
import useTaggingStore from '@/stores/tagging/tagging'
import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'

import { textSearch } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'stream', 'item' ],

    data: () => ({
        search: '',
        isSelectingGroup: false
    }),

    computed: {
        ...mapState(useTaggingStore, {
            tagGroups: 'tagGroups',
            selectedGroup: 'selectedGroup',

            tags(store) {
                let tags = this.search ? textSearch(this.search, store.tags, t => t.name) : store.tags

                tags = tags.sort((a, b) => a.name.localeCompare(b.name))

                let applied = tags.filter(t => this.stream ? store.isAppliedToStream(this.stream, t) : store.isAppliedTo(this.item, t))
                let notApplied = tags.filter(t => ! (this.stream ? store.isAppliedToStream(this.stream, t) : store.isAppliedTo(this.item, t)))

                return [ ...applied, ...notApplied ]
            }
        })
    },

    methods: {
        ...mapActions(useTaggingStore, [
            'clearTagsOn', 'clearTagsOnStream', 'isAppliedTo', 'isAppliedToStream', 'toggleTagOn', 'toggleTagOnStream'
        ]),

        ...mapActions(useMyModalsTagsManageStore, { manageTags: 'open' }),

        selectGroup(group) {
            useTaggingStore().selectGroup(group)
            this.isSelectingGroup = false
        }
    }
}
</script>
