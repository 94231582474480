import useThreadStore from '@/admin/stores/support/thread'

import { defineStore } from 'pinia'
import useAgentsStore from '@/admin/stores/support/agents'

export const useSupportThreadsThreadPage = defineStore({
    id: 'supportThreadsThreadPage',

    actions: {
        async beforeEnter(to) {
            useThreadStore().$reset()

            useAgentsStore().initialize()
            useThreadStore().initialize(to.params.threadId, true).then(() => {
                useThreadStore().startPolling()
            })
        },

        async beforeLeave() {
            useThreadStore().stopPolling()
        }
    }
})

export default useSupportThreadsThreadPage
