<template>
    <div>
        <ui-header>
            <template v-slot:subsection>
                <div class="pt-2">
                    <ui-stream-toolbar :store="$page.streamStore"></ui-stream-toolbar>
                </div>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 border-b border-gray-200 pb-6 mb-6">
                <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': `past:1|weeks`, 'filter[language]': $my.preferredLanguage } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="calendar" class="shrink-0 mr-2"></ui-icon>
                    Latest Content
                </router-link>
                <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': `past:1|weeks`, 'filter[language]': $my.preferredLanguage, 'sorting': 'interactions-desc' } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="zap" class="shrink-0 mr-2"></ui-icon>
                    Top Content
                </router-link>
                <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': `past:1|weeks`, 'filter[language]': $my.preferredLanguage, 'sorting': 'shares-desc' } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="share" class="shrink-0 mr-2"></ui-icon>
                    Viral Content
                </router-link>
                <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': `past:1|weeks`, 'filter[language]': $my.preferredLanguage, 'sorting': 'trending-desc' } }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="fire" class="shrink-0 mr-2"></ui-icon>
                    Trending Content
                </router-link>
            </div>

            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.content" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to the Content section!</h1>
                <p class="text-lg my-1">In this section you can <span class="font-medium">search through and interact with the content we collect</span> from social networks and the web.</p>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Perspectives</h2>
                        <p class="text-justify my-2">To search through all the collected content you will use a combination of text queries and filters. We call this combination a <span class="font-medium">perspective</span>. You can also save perspectives for later use.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Topics</h2>
                        <p class="text-justify my-2">To reuse your well-crafted text queries, you can save them under simple names. We call these named text queries <span class="font-medium">topics</span>. You can use the same topic in multiple perspectives.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can <span class="font-medium">build your first perspective</span> or you can choose from one of the <span class="font-medium">curated topics below</span>.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button icon="plus-square" color="blue" size="lg" :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' } }">
                        New Perspective
                    </ui-button>
                </div>
            </div>

            <ui-cards-section title="Pinned & Recent" :store="contentIndexCardsStore" key="indexCards">
            </ui-cards-section>

            <ui-cards-section title="Suggested Topics" :store="contentSuggestedTopicsCardsStore" key="suggestedTopics">
            </ui-cards-section>

            <div class="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 border-t border-gray-200 pt-6" v-if="! $my.showSidebar">
                <router-link :to="{ name: 'content.perspectives' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.sub.perspectives" class="shrink-0 text-xl mr-2"></ui-icon>
                    Perspectives
                </router-link>
                <router-link :to="{ name: 'content.topics' }" class="flex flex-1 h-10 px-6 items-center border border-blue-500 text-blue-500 hover:border-blue-800 hover:text-blue-800 font-medium rounded">
                    <ui-icon name="navigation.sub.topics" class="shrink-0 text-xl mr-2"></ui-icon>
                    Topics
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import UiCardsSection from '@/components/ui/cards/cards-section'
import UiStreamToolbar from '@/components/ui/stream/toolbar/toolbar'

import useContentIndexCardsStore from '@/stores/content/index-cards'
import useContentSuggestedTopicsStore from '@/stores/content/suggested-topics-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        UiCardsSection, UiStreamToolbar
    },

    data: () => ({
        showWelcomeMessage: false
    }),

    computed: {
        ...mapStores(useContentIndexCardsStore, useContentSuggestedTopicsStore),

        isShowingWelcomeMessage() {
            return this.showWelcomeMessage
                || (this.contentIndexCardsStore.isInitialized
                    && ! this.contentIndexCardsStore.items.length)
        }
    }
}
</script>
