import defineCards from '@/stores/reusable/cards'

import useMembersStore from '@/admin/stores/accounts/members'

import { sortAlphabetically } from '@/helpers'
import {markRaw} from 'vue'

export const useMembersCardsStore = defineCards({
    id: 'membersCards',

    source: useMembersStore(),

    layoutOptions: [
        {
            id: 'table',
            name: 'Table',
            icon: 'menu',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/table').default),
            settings: { showCharts: true }
        }
    ],
    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'asc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        }
    ]
})

export default useMembersCardsStore
