import useMySearchHistoryStore from '@/stores/me/search-history'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsSearchHistoryStore = defineStore({
    id: 'modalsSearchHistory',

    actions: {
        open(type = 'all') {
            useModal().show('search-history')

            useMySearchHistoryStore().load(type)
        },

        cancel() {
            useModal().hide('search-history')
        },

        apply(streamStore, item) {
            streamStore.appliedFilters = Object.entries(item.filters)
                .map(([filter, value]) => {
                    filter = streamStore.filters.find(f => f.id == filter)
                    value = streamStore.unserializeFilterFrom('api', filter, value)

                    return {filter, value}
                }).filter(v => v && v.value)

            streamStore.searchQuery = streamStore.appliedFilters.find(a => a.filter.search)?.value.query

            streamStore.triggerQueryChange()

            useModal().hide('search-history')
        },

        applyQuery(streamStore, oldStreamStore) {
            let oldSearchFilterValue = oldStreamStore.appliedFilters.find(a => a.filter.search)?.value.query

            streamStore.applySearchQuery(oldSearchFilterValue)

            streamStore.triggerQueryChange()

            useModal().hide('search-history')
        }
    }
})

export default useModalsSearchHistoryStore
