<template>
    <router-view></router-view>

    <Teleport to="#root">
        <targets-library-add-target-modal></targets-library-add-target-modal>
        <targets-list-duplicate-modal></targets-list-duplicate-modal>
        <targets-list-edit-modal></targets-list-edit-modal>
    </Teleport>
</template>

<script>
import TargetsLibraryAddTargetModal from './modals/library-add-target'
import TargetsListDuplicateModal from './modals/list-duplicate'
import TargetsListEditModal from './modals/list-edit'

export default {
    components: {
        TargetsLibraryAddTargetModal, TargetsListDuplicateModal, TargetsListEditModal
    }
}
</script>
