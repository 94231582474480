<template>
    <div class="flex-1 min-h-0 px-8 py-6 overflow-y-auto">
        <ui-form :store="workspacesFormsEditStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex space-x-6">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                    </div>

                    <div class="mt-6">
                        <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                            Description
                        </label>

                        <div>
                            <ui-input type="text" name="description" id="description" ref="descriptionInput" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                    </div>
                </div>

                <div class="w-26">
                    <div class="mt-6">
                        <ui-avatar-upload :src="inputs.workspace.avatarUrl" v-model="inputs.avatar"></ui-avatar-upload>
                    </div>
                </div>
            </div>

            <div class="flex mt-8">
                <ui-button color="red" :action="() => deleteWorkspace(inputs.workspace)">
                    Delete
                </ui-button>

                <ui-button :action="cancel" class="ml-auto">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="blue" :disabled="submitting" class="ml-4">
                    {{ inputs.workspace.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </div>
</template>

<script>
import useWorkspacesFormsEditStore from '@/stores/workspaces/forms/edit'
import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useWorkspacesFormsEditStore)
    },

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'deleteWorkspace' ]),
        ...mapActions(useWorkspacesFormsEditStore, [ 'cancel' ])
    }
}
</script>
