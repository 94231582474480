import useAgentsStore from '@/admin/stores/support/agents'
import useThreadsStore from '@/admin/stores/support/threads'

import { defineStore } from 'pinia'

export const useSupportThreadsIndexPage = defineStore({
    id: 'supportThreadsIndexPage',

    state: () => ({
        search: ''
    }),

    actions: {
        beforeEnter() {
            useAgentsStore().initialize()
            useThreadsStore().initialize(true)
        }
    }
})

export default useSupportThreadsIndexPage
