import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers'

export default {
    actions: {
        changeDataset() {
            useQuickSearchStore().show({
                families: [ 'perspectives', 'searchTopics', 'targetLists' ],
                limit: 50,
                perspectiveStarters: false,
                onSelect: result => {
                    if (result) {
                        this.setDataset({
                            datasetType: result.resultType,
                            datasetId: result.id,
                            label: result.title
                        })
                    }

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    { resultType: 'perspective-new-overlay', alwaysOpen: true },
                    ...sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id,
                        resultType: 'perspective',
                        title: item.name
                    })),
                    ...sortAlphabetically(useMySearchTopicsStore().items).map(item => ({
                        id: item.id,
                        resultType: 'search-topic',
                        title: item.name
                    })),
                    ...sortAlphabetically(useMyTargetListsStore().items).map(item => ({
                        id: item.id,
                        resultType: 'target-list',
                        title: item.name
                    }))
                ]
            })
        },

        setDataset(dataset) {
            if (this.series.length) {
                this.updateSeries(this.series[0], dataset)
            } else {
                this.addSeries(dataset)
            }
        }
    }
}
