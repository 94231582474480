import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter, route } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyDashboards from '@/stores/me/dashboards'

export const useModalsReportEditStore = defineForm({
    id: 'modalsReportEdit',

    inputs: () => ({
        report: {},
        name: null,
        description: null,

        configuration: {
            height: 1123,
            width: 794,

            displayHeader: true,
            displayHeaderImage: true,
            displayHeaderTitle: true,
            displayHeaderSideText: true,

            displayFooter: true,
            displayFooterPagination: true
        }
    }),

    submitRequest() {
        if (! this.inputs.report.id) {
            trackEvent('dashboards', 'new-dashboard-saved', 'report')
        }

        return api.url(this.inputs.report.id ? route('me dashboards update', { dashboard: this.inputs.report.id }) : route('me dashboards store'))
            .formData({
                _method: this.inputs.report.id ? 'put' : 'post',
                type: 'report',
                name: this.inputs.name,
                description: this.inputs.description,

                configuration: JSON.stringify(this.inputs.configuration)
            })
            .post()
    },

    onResponse(res) {
        useMyDashboards().reload()

        useModal().hide('report-edit')

        if (! this.inputs.report.id) {
            useRouter().push({ name: 'dashboard.reports.report', params: { report: res.data.id } })
        }
    },

    actions: {
        open(report) {
            this.reset()

            this.inputs.report = report || {}
            this.inputs.name = report?.name
            this.inputs.description = report?.description

            if (report?.configuration) {
                this.inputs.configuration = report.configuration
            }

            useModal().show('report-edit')
        },

        cancel() {
            useModal().hide('report-edit')
        },

        setDimensions(height, width) {
            this.inputs.configuration.height = height
            this.inputs.configuration.width = width
        },

        delete() {
            return api.route('me dashboards delete', { id: this.inputs.report.id })
                .delete()
                .res(res => {
                    useMyDashboards().reload()
                    useModal().hide('report-edit')
                    useRouter().push({ name: 'dashboards', params: { type: 'reports' } })
                })
        }
    }
})

export default useModalsReportEditStore
