import useMyAnalysesStore from '../stores/me/analyses'
import useBoardStore from '../stores/dashboards/board'
import useMyStore from '@/stores/me/my'
import useMyPerspectivesStore from '../stores/me/perspectives'
import useTargetListsStore from '../stores/me/target-lists'

import { defineStore } from 'pinia'

export const useDashboardsBoardPage = defineStore({
    id: 'dashboardsBoardPage',

    getters: {
        title: () => useBoardStore()?.board.name ? `${useBoardStore().board.name} | Dashboards` : ''
    },

    actions: {
        async beforeEnter(to) {
            useMyStore().showSidebar = false

            await Promise.all([
                useMyAnalysesStore().initialize(),
                useMyPerspectivesStore().initialize(),
                useTargetListsStore().initialize()
            ])

            useMyStore().showSidebar = false

            useBoardStore().initialize(to.params.board)
        },

        async beforeLeave() {
            useBoardStore().stopUpdating()
            useMyStore().showSidebar = true
        }
    }
})

export default useDashboardsBoardPage
