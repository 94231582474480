<template>
    <div>
        <ui-header :title="$page.perspective ? $page.perspective.name : null" avatar-type="perspective">
            <template v-slot:actions>
                <ui-button color="blue" icon="check-square" :action="savePerspective" v-if="$page.streamStore.hasUnsavedFilters">
                    Save
                </ui-button>

                <a href="#" class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full border border-blue-400 hover:border-blue-800 focus:border-blue-800 transition w-8 h-8 text-xl"  @click.prevent="duplicatePerspective" v-if="$page.streamStore.appliedPerspective && $page.streamStore.appliedPerspective.id" v-tooltip="'Duplicate'">
                    <ui-icon name="copy"></ui-icon>
                </a>

                <a href="#" class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full border border-blue-400 hover:border-blue-800 focus:border-blue-800 transition w-8 h-8 text-xl"  @click.prevent="openNotificationsManageModal($page.streamStore.appliedPerspective)" v-if="$page.streamStore.appliedPerspective && $page.streamStore.appliedPerspective.id" v-tooltip="'Notifications'">
                    <ui-icon name="notifications"></ui-icon>
                </a>

                <a href="#" class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full border border-blue-400 hover:border-blue-800 focus:border-blue-800 transition w-8 h-8 text-xl" @click.prevent="openEditModal" v-if="$page.streamStore.appliedPerspective && $page.streamStore.appliedPerspective.id" v-tooltip="'Settings'">
                    <ui-icon name="settings"></ui-icon>
                </a>

<!--                <ui-help-button v-tooltip="'Content'" page="content" section="content-perspectives"></ui-help-button>-->
            </template>

            <template v-slot:subsection>
                <div :class="{ 'pt-2': ! $page.perspective }">
                    <ui-stream-toolbar :store="$page.streamStore"></ui-stream-toolbar>
                </div>
            </template>
        </ui-header>

        <div class="pb-8 px-8">
            <ui-stream :store="$page.streamStore"></ui-stream>
        </div>
    </div>
</template>

<script>
import UiStream from '@/components/ui/stream/stream'
import UiStreamToolbar from '@/components/ui/stream/toolbar/toolbar'

import useContentModalsNotificationsManageStore from '@/stores/content/modals/notifications-manage'
import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'
import useContentModalsPerspectiveDuplicateStore from '@/stores/content/modals/perspective-duplicate'

import { mapActions } from 'pinia'

export default {
    components: {
        UiStream, UiStreamToolbar
    },

    methods: {
        ...mapActions(useContentModalsNotificationsManageStore, { openNotificationsManageModal: 'open' }),
        ...mapActions(useContentModalsPerspectiveEditStore, { _openEditModal: 'open' }),
        ...mapActions(useContentModalsPerspectiveDuplicateStore, { openDuplicateModal: 'open' }),

        async savePerspective() {
            if (this.$page.streamStore.appliedPerspective && this.$page.streamStore.appliedPerspective.id) {
                await this.$page.streamStore.savePerspective()
            } else {
                await this._openEditModal(this.$page.streamStore.appliedPerspective, this.$page.streamStore)
            }
        },

        async openEditModal() {
            await this._openEditModal(
                this.$page.streamStore.appliedPerspective
            )

            this.$page.refreshPerspective()
        },

        duplicatePerspective() {
            this.openDuplicateModal(this.$page.streamStore.appliedPerspective)
        }
    }
}
</script>
