import api from '@/api'

import { defineStore } from 'pinia'

export const useTargetsTargetHistoryStore = defineStore({
    id: 'targetsTargetHistory',

    state: () => ({
        target: null,
        events: null
    }),

    actions: {
        load(target) {
            if (this.target?.family != target.family && this.target?.id != target.id) this.$reset()

            this.target = target

            return api.route('monitor targets history', { family: this.target.family, id: this.target.id })
                .get().json(res => this.events = res.data)
        }
    }
})

export default useTargetsTargetHistoryStore
