import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetMembersStreamStore from '@/stores/targets/target/members-stream'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetMembersPage = defineStore({
    id: 'targetsTargetMembersPage',

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            let stream = useTargetsTargetMembersStreamStore()

            stream.initialize()
            stream.navigatedTo(to, {
                'group': this.target.id
            })

            stream.searchContext = `${this.target.title} members`
        }
    }
})

export default useTargetsTargetMembersPage
