import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import { content, features, targets } from '@/helpers/api-three-tables'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'
import useContentStreamStore from '@/stores/content/stream'

export const useConnectionsModalsNodeDetailsStore = defineStore({
    id: 'connections-modals-node-details',

    state: () => ({
        nodeId: null,
        graphStore: null,

        feature: null,
        group: null,
        source: null,
        content: null,

        isLoading: false
    }),

    actions: {
        open(family, nodeId, graphStore) {
            this.$reset()

            this.nodeId = nodeId
            this.graphStore = graphStore

            useModal().show('connections-node-details')

            this.isLoading = true

            if (family == 'feature') {
                features().nodes([ nodeId ]).details('full').get(features => {
                    this.feature = features[0]
                    this.isLoading = false
                })
            } else if (family == 'group') {
                targets().family('groups').nodes([ nodeId ]).include(['images']).get(targets => {
                    this.group = targets[0]
                    this.isLoading = false
                })
            } else if (family == 'content') {
                content().nodes([ nodeId ]).details('full').get(content => {
                    this.content = content[0]
                    this.isLoading = false
                    useContentModalsContentDetailStore().inline(this.content, useContentStreamStore())
                })
            } else {
                targets().family('sources').nodes([ nodeId ]).include(['images']).get(targets => {
                    this.source = targets[0]
                    this.isLoading = false
                })
            }
        },

        close() {
            useModal().hide('connections-node-details')
        },

        loadConnections() {
            this.graphStore.inspectNode(this.nodeId)
            this.close()
        },

        hideNode() {
            this.graphStore.hideNode(this.nodeId)
            this.close()
        }
    }
})

export default useConnectionsModalsNodeDetailsStore
