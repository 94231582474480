<template>
    <ui-dropdown align="right">
        <template v-slot:trigger>
            <div class="flex items-center space-x-2">
                <span class="text-gray-700 text-sm font-medium">Order by</span>
                <div class="inline-flex items-center px-3 pr-2 h-7 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                    <ui-icon :name="store.sorting.icon" class="mr-1"></ui-icon>
                    <span class="font-medium">{{store.sorting.name}}</span>
                    <ui-icon name="select" class="ml-2"></ui-icon>
                </div>
            </div>
        </template>

        <template v-slot:content>
            <template v-for="option, index in store.availableSortingOptions">
                <ui-dropdown-link :icon="option.icon" @click="store.setSorting(option.id)" :key="`ordering-${option.id}`" v-if="option.id">
                    {{option.name}}
                </ui-dropdown-link>

                <ui-dropdown-separator :key="`ordering-separator-${index}`" v-else></ui-dropdown-separator>
            </template>
        </template>
    </ui-dropdown>
</template>
