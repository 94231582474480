<template>
    <div>
        <div v-if="store.status == 'new'" class="bg-white w-full shadow rounded mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Topic Modelling" analysis!</h1>
            <p class="text-lg my-1">This analysis leverages a large language model to <span class="font-medium">visualize and compare various topics</span> found in the content.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data-set</h2>
                    <p class="text-justify my-2">Select a Perspective, Topic or Target List as a data-set, or define a new perspective inline.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Analysis Styles</h2>
                    <p class="text-justify my-2">Customize how the analysis looks by choosing form multiple analysis types like line or bar analysis and other options.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range & Grouping</h2>
                    <p class="text-justify my-2">Use the range & grouping controls to specify the shown date range and granularity. You can also drag select a part of the analysis to zoom in.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Add Series button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => store.changeDataset()">
                    Select Data-set
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-dataset :store="store"></settings-dataset>
                    <settings-topic-size :store="store"></settings-topic-size>
                </template>
            </analysis-toolbar>

            <div v-if="store.status == 'unknown'" class="bg-white w-full shadow rounded mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 mx-auto">
                <div class="flex flex-col items-center w-1/2">
                    <ui-spinner class="mb-2"></ui-spinner>
                    <span>We're checking the status of this analysis. This will only take a few seconds...</span>
                </div>
            </div>

            <div v-else-if="store.status == 'ready'" class="bg-white w-full shadow rounded mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 mx-auto">
                <p class="w-1/2 mb-4">
                    This analysis may take a while to build. When you're sure your series are configured properly,
                    start the building process and we'll notify you once the results are ready.
                </p>

                <div class="flex items-center mt-4">
                    <ui-button color="blue" icon="play" size="lg" :action="() => saveAndBuild()">
                        Start Building
                    </ui-button>
                </div>
            </div>

            <div v-else-if="store.status == 'building'" class="bg-white w-full shadow rounded mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 mx-auto">
                <p class="w-1/2 mb-4">
                    We're building your analysis. This may take a moment. Feel free to come back later. We'll also notify you once your data's ready.
                </p>

                <ui-progress class="my-4" :total-steps="store.estimatedBuildingTime ?? 999999" :completed-steps="store.elapsedBuildingTime">
                    <div class="p-4">
                        <div v-if="store.estimatedBuildingTime && store.elapsedBuildingTime && store.estimatedBuildingTime === store.elapsedBuildingTime">Finishing up, your analysis should be ready soon</div>
                        <div v-else-if="store.estimatedBuildingTime">Processing with <span class="font-medium">{{ store.remainingBuildingTime }}</span> remaining</div>
                        <div v-else>Waiting for an available processing slot</div>
                    </div>
                </ui-progress>

                <div class="flex items-center mt-4">
                    <ui-button color="red" icon="remove" size="lg" :action="() => store.stopBuilding()">
                        Stop Building
                    </ui-button>
                </div>
            </div>

            <div v-else-if="store.status === 'done' && store.hasValues" class="mt-8 px-4 py-8 w-full bg-white rounded-md shadow flex flex-wrap relative">
                <div class="absolute top-4 right-4 z-20">
                    <chart-styles-contextual-menu :store="store"></chart-styles-contextual-menu>
                </div>

                <highcharts-analysis v-for="options, i in store.breakdownAnalysisOptions" :key="i" class="hc h-full w-1/2 mb-4" :options="options" ref="analysis"></highcharts-analysis>
            </div>

            <div v-else class="h-64 flex flex-col items-center justify-center bg-white rounded-md shadow text-gray-700">
                <ui-icon name="frown" class="mb-2 text-3xl text-gray-600"></ui-icon>
                <p class="font-medium">Nothing to show yet.</p>
                <p>Please check back later or try different settings.</p>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <topic-breakdown-topic-size-modal>
        </topic-breakdown-topic-size-modal>
    </Teleport>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import ChartStylesContextualMenu from '@/components/analysis/contextual-menus/chart-styles'
import SettingsDataset from './settings/dataset'
import SettingsTopicSize from './settings/topic-size'
import TopicBreakdownTopicSizeModal from '@/components/analysis/modals/topic-breakdown/topic-size-modal'

import { mapActions } from 'pinia'
import { useRouter } from '@/helpers'

import { Chart as HighchartsAnalysis } from 'highcharts-vue'

import useAnalysisModalsAnalysisInfoStore from '@/stores/analysis/modals/analysis-info'

export default {
    components: {
        AnalysisToolbar, ChartStylesContextualMenu, HighchartsAnalysis, SettingsDataset, SettingsTopicSize, TopicBreakdownTopicSizeModal
    },

    methods: {
        ...mapActions(useAnalysisModalsAnalysisInfoStore, { openInfoModal: 'open' }),

        saveAndBuild() {
            if (this.store.id) {
                this.store.save()
                this.store.loadSeries(true, false)
            } else {
                this.openInfoModal(this.store.analysis, this.store, false).then(() => {
                    useRouter().replace({
                        name: 'analysis.analysis.details',
                        params: { type: this.store.type, id: this.store.id }
                    }).then(() => {
                        this.store.loadSeries(true, false)
                    })
                })
            }
        }
    },

    watch: {
        'store.series.length'() {
            this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
        }
    },

    mounted() {
        this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
    }
}
</script>
