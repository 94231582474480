<template>
    <ui-modal name="report-duplicate" classes="flex items-center justify-center" content-class="bg-white flex flex-col h-auto w-full" unstyled>
        <div class="flex items-center justify-between p-8 pt-9">
            <h1 class="font-semibold text-gray-900">
                Duplicate a Report
            </h1>

            <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="modalsReportDuplicateStore.cancel()">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>
        <div class="flex-1 min-h-0 p-8 pt-0">
            <ui-form :store="modalsReportDuplicateStore" v-slot="{ inputs, submitting, errors }">
                <label for="name" class="block text-sm font-medium text-gray-700">
                    Name
                </label>
                <div class="mt-2">
                    <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
                </div>

                <label for="description" class="block text-sm font-medium text-gray-700 mt-4">
                    Description
                </label>
                <div class="mt-2">
                    <ui-input type="textarea" id="description" rows="2" v-model="inputs.description" :errors="errors.description"></ui-input>
                </div>

                <div class="flex justify-end space-x-4 mt-8">
                    <ui-button type="submit" color="green" :disabled="submitting">
                        Duplicate
                    </ui-button>
                </div>
            </ui-form>
        </div>
    </ui-modal>
</template>

<script>
import { mapStores } from 'pinia'

import useModalsReportDuplicateStore from '@/stores/dashboards/modals/report-duplicate'

export default {
    computed: {
        ...mapStores(useModalsReportDuplicateStore)
    }
}
</script>
