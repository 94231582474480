import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyDashboards from '@/stores/me/dashboards'

export const useModalsReportDuplicateStore = defineForm({
    id: 'modalsReportDuplicate',

    inputs: () => ({
        report: {},
        name: null,
        description: null
    }),

    submitRequest() {
        trackEvent('dashboards', 'duplicated-dashboard-saved', 'report')

        return api.route('me dashboards duplicate', { id: this.inputs.report.id })
            .formData({
                _method: 'post',
                type: 'report',
                name: this.inputs.name,
                description: this.inputs.description
            })
            .post()
    },

    onResponse(res) {
        useMyDashboards().reload()

        useModal().hide('report-duplicate')

        useRouter().push({ name: 'dashboard.reports.report', params: { report: res.data.id } })
    },

    actions: {
        open(report) {
            this.reset()

            this.inputs.report = report
            this.inputs.name = report.name
            this.inputs.description = report.description

            useModal().show('report-duplicate')
        },

        cancel() {
            useModal().hide('report-duplicate')
        }
    }
})

export default useModalsReportDuplicateStore
