import api from '@/admin/api'
import { useModal } from '@/admin/helpers'

import useMyStore from '@/admin/stores/me/my'

import { defineStore } from 'pinia'

export const useThreadStore = defineStore({
    id: 'thread',

    state: () => ({
        newMessage: null,

        poller: null,

        sendingMessage: false,

        activeMessage: null,

        thread: null,
        threadLoaded: false,
        threadPromise: null
    }),

    actions: {
        async initialize(id, force = false) {
            await this.load(id, force)
        },

        async load(id, force = false) {
            if (this.threadLoaded && ! force) return Promise.resolve()
            if (this.threadPromise) return this.threadPromise

            return this.threadPromise = api.route('admin support threads details', { threadId: id } ).get().json(res => {
                this.thread = res.data
                this.threadLoaded = true
                this.threadPromise = null
            })
        },

        startPolling() {
            this.poller = setInterval(() => {
                this.load(this.thread.id, true)
            }, 5000)
        },

        stopPolling() {
            clearInterval(this.poller)
        },

        async markThreadAsResolved() {
            return api.route('admin support threads resolve', { threadId: this.thread.id } ).post().res()
        },

        async markThreadAsUnresolved() {
            return api.route('admin support threads unresolve', { threadId: this.thread.id } ).post().res(() => {
                this.load(this.thread.id, true)
            })
        },

        async sendMessage() {
            this.sendingMessage = true

            return api.route('admin support threads send-message', { threadId: this.thread.id } ).post({
                body: this.newMessage
            }).json(async () => {
                await this.load(this.thread.id, true)
                this.sendingMessage = false
                this.newMessage = null
            })
        },

        openThreadInfoModal() {
            useModal().show('thread-info')
        },

        updateThread(data) {
            api.route('admin support threads update', { threadId: this.thread.id } ).formData({
                _method: 'put',
                ...data
            }).post().res(() => {
                this.load(this.thread.id, true).then(() => {
                    useModal().hide('thread-info')
                })
            })
        },

        assignThreadToSelf() {
            api.route('admin support threads update', { threadId: this.thread.id } ).formData({
                _method: 'put',
                agentId: useMyStore().user.id
            }).post().res(() => {
                this.load(this.thread.id, true)
            })
        },

        openReminderModalForActiveMessage() {
            useModal().show('message-reminder-create')
        },

        setReminderForActiveMessage(remindAt) {
            api.route('admin support threads messages set-reminder', { messageId: this.activeMessage } ).post({
                remind_at: remindAt
            }).res(() => {
                this.load(this.thread.id, true).then(() => {
                    useModal().hide('message-reminder-create')
                })
            })
        },

        removeReminderForActiveMessage() {
            api.route('admin support threads remove-reminder', { messageId: this.activeMessage } ).post().res(() => {
                this.load(this.thread.id, true)
            })
        }
    }
})

export default useThreadStore
