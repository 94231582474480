import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsMyTargetsMonitoringRequestsStore from '@/stores/targets/my-targets/monitoring-requests'
import useTargetsMyTargetsStatsStore from '@/stores/targets/my-targets/stats'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsMyTargetsPage = defineStore({
    id: 'targetsMyTargetsPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'My Targets | Targets'
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize()
            useMyTargetListsStore().initialize()
            useMyRecentTargetsStore().initialize()

            useMyMonitoredTargetsStore().reload()
            useTargetsMyTargetsMonitoringRequestsStore().reload()
            useTargetsMyTargetsStatsStore().reload()
        }
    }
})

export default useTargetsMyTargetsPage
