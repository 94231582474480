<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="edit" @click="editReport(report)">
            Edit
        </ui-dropdown-link>

        <ui-dropdown-link icon="edit" @click="duplicateReport(report)">
            Duplicate
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useModalsReportEditStore from '@/stores/dashboards/modals/report-edit'
import useModalsReportDuplicateStore from '@/stores/dashboards/modals/report-duplicate'

import { mapActions } from 'pinia'

export default {
    props: [ 'report' ],

    methods: {
        ...mapActions(useModalsReportEditStore, {
            editReport: 'open'
        }),

        ...mapActions(useModalsReportDuplicateStore, {
            duplicateReport: 'open'
        })
    }
}
</script>
