import api from '@/api'

import { defineStore } from 'pinia'

export const useTargetsListSuggestionsStore = defineStore({
    id: 'targetsListSuggestions',

    state: () => ({
        target: null,

        isInitialized: false,
        isLoading: false,

        error: null,

        suggestedSources: [],
        suggestedGroups: []
    }),

    actions: {
        load(list, force = false) {
            if (force) this.$reset()
            if (this.list?.id != list.id) this.$reset()

            this.list = list
            this.error = null
            this.isLoading = true

            api.route('me lists suggestions', this.list.id)
                .query({ include: 'source.metrics' })
                .get()
                .error(500, err => this.error = err?.json?.message || 'unknown')
                .json(res => {
                    this.suggestedSources = res.data.sources
                    this.suggestedGroups = res.data.groups

                    this.isInitialized = true
                    this.isLoading = false
                })
        },

        reload() {
            this.load(this.list, true)
        }
    }
})

export default useTargetsListSuggestionsStore
