<template>
    <div class="w-full h-full relative group z-0" :class="{ 'ring-2 ring-blue': ! isBeingEdited && dashboardStore.isNew, 'bg-white shadow rounded': dashboardStore.selectedWidgetId || store.type.showBackground }">
        <div class="absolute z-50 right-1 flex items-center space-x-1 leading-none" :class=" store.type.inline && isBeingEdited ? 'bottom-1' : 'top-1' " v-if="dashboardStore.isEditing && (store.type.inline || ! dashboardStore.selectedWidgetId || ! isBeingEdited)">
            <a href="#" v-if="! store.uneditable && isBeingEdited" @click.prevent="dashboardStore.stopEditingWidget" class="p-2 bg-gray-800 hover:bg-gray-900 rounded-md" v-tooltip.bottom="'Cancel changes'">
                <ui-icon class="text-lg text-gray-200" name="x"></ui-icon>
            </a>

            <a href="#" v-if="! store.uneditable && isBeingEdited" @click.prevent="dashboardStore.saveWidgetBeingEdited" class="p-2 bg-gray-800 hover:bg-gray-900 rounded-md" v-tooltip.bottom="'Confirm changes'">
                <ui-icon class="text-lg text-green-200" name="check"></ui-icon>
            </a>

            <a href="#" v-if="! store.uneditable && ! isBeingEdited" @click.prevent="dashboardStore.editWidget(store)" class="p-2 bg-gray-800 hover:bg-gray-900 rounded-md" v-tooltip.bottom="'Edit widget'">
                <ui-icon class="text-lg text-blue-200" name="edit"></ui-icon>
            </a>

            <a href="#" v-if="! store.undeletable" @click.prevent="dashboardStore.deleteWidget(store)" class="p-2 bg-gray-800 hover:bg-gray-900 rounded-md" v-tooltip.bottom="'Remove widget'">
                <ui-icon class="text-lg text-red-200" name="trash"></ui-icon>
            </a>
        </div>

        <slot name="edit" :close="dashboardStore.stopEditingWidget" v-if="editable && dashboardStore.isEditing && isBeingEdited"></slot>

        <template v-else-if="! dashboardStore.isEditing && isBeingEdited">
            <slot name="expanded" v-if="store.isConfigured">
                <slot name="widget"></slot>
            </slot>
            <div class="w-full h-64 flex justify-center items-center text-4xl text-gray-500" v-else>
                <ui-icon :name="store.type.icon"></ui-icon>
            </div>
        </template>

        <template v-else>
            <div class="h-full" :class="{ 'border border-1 border-dashed border-gray-500': dashboardStore.isEditing }">
                <slot name="widget" v-if="store.isConfigured"></slot>
                <div class="w-full h-full flex justify-center items-center text-4xl text-gray-500" v-else>
                    <ui-icon :name="store.type.icon"></ui-icon>
                </div>
            </div>
        </template>

        <div class="opacity-0 group-hover:opacity-100 absolute inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 text-white transition duration-150 rounded" v-if="dashboardStore.selectedWidgetId !== store.$id && dashboardStore.isEditing"></div>
    </div>
</template>

<script>
export default {
    props: [ 'dashboardStore', 'editable' ],

    computed: {
        isBeingEdited() {
            return this.dashboardStore.selectedWidgetId === this.store.$id
        },

        maxWidth() {
            return this.dashboardStore.selectedWidget?.type.expandedWidth || 520
        }
    }
}
</script>
