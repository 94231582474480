<template>
    <ui-modal name="connections-node-details" width="100%" height="auto" :max-width="content ? 800 : 420" :max-height="800" unstyled>
        <div class="rounded-xl overflow-hidden bg-white flex flex-col" style="max-height:800px;">
            <ui-async :guards="! isLoading" class="flex flex-col min-h-0">
                <div class="overflow-y-auto min-h-0">
                    <target-information-details-card :target="group || source" :inline="true" v-if="group || source"></target-information-details-card>
                    <feature-information-details-card :feature="feature" :inline="true" v-else-if="feature"></feature-information-details-card>
                    <content-information-details-card :content="content" :inline="true" v-else-if="content"></content-information-details-card>
                </div>

                <div class="shrink-0">
                    <a :href="detailsUrl" target="_blank" class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900" v-if="source || group || feature || content">
                        <ui-icon name="info" class="mr-1"></ui-icon> Show details
                    </a>

                    <a href="#" @click.prevent="loadConnections" class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900" v-if="graphStore && !graphStore.inspectedNodeIds.includes(nodeId)">
                        <ui-icon name="share" class="mr-1"></ui-icon> Load connections
                    </a>

                    <a href="#" @click.prevent="hideNode" class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900" v-if="graphStore && graphStore.inspectedNodeIds[0] != nodeId">
                        <ui-icon name="eye-off" class="mr-1"></ui-icon> Hide node
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center py-24">
                        <ui-spinner></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>
    </ui-modal>
</template>

<script>
import FeatureInformationDetailsCard from '@/components/features/feature/information/details-card'
import TargetInformationDetailsCard from '@/components/targets/target/information/details-card'
import ContentInformationDetailsCard from '@/components/content/modals/content-detail'

import useConnectionsModalsNodeDetailsStore from '@/stores/connections/modals/node-details'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ContentInformationDetailsCard, FeatureInformationDetailsCard, TargetInformationDetailsCard
    },

    computed: {
        ...mapState(useConnectionsModalsNodeDetailsStore, [ 'feature', 'group', 'isLoading', 'source', 'graphStore', 'nodeId', 'content' ]),

        detailsUrl() {
            if (this.source) {
                return this.$router.resolve({
                    name: 'targets.target.information', params: { type: this.source.family, modelType: this.source.type, id: this.source.id }
                }).href
            } else if (this.group) {
                return this.$router.resolve({
                    name: 'targets.target.information', params: { type: this.group.family, modelType: this.group.type, id: this.group.id }
                }).href
            } else if (this.feature) {
                return this.$router.resolve({
                    name: 'features.feature.information', params: { type: this.feature.type, id: this.feature.id }
                }).href
            } else if (this.content) {
                return this.$router.resolve({
                    name: 'targets.target.content', params: { type: this.content.type, modelType: this.content.source.type, id: this.content.source.id, contentId: this.content.id }
                }).href
            }

            return ''
        }
    },

    methods: {
        ...mapActions(useConnectionsModalsNodeDetailsStore, [ 'loadConnections', 'hideNode' ])
    }
}
</script>
