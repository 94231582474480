<template>
    <div class="flex shrink-0 h-full bg-white shadow transition-all" :class="{ 'w-80': $my.showSidebar, 'w-14': ! $my.showSidebar }">
        <div class="flex flex-col w-14 bg-gray-900 px-2 py-4">
            <div class="flex flex-col divide-y divide-gray-800 space-y-4">
                <nav class="space-y-2">
                    <div class="mb-4">
                        <workspace-menu></workspace-menu>
                    </div>

                    <router-link :to="{ name: 'dashboards' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Dashboards'">
                        <ui-icon name="navigation.dashboards"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'content' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Content'">
                        <ui-icon name="navigation.content"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'targets' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Targets'" v-if="$hasFeatureTo(['access-sources'])">
                        <ui-icon name="navigation.targets"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'analysis' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Analysis'">
                        <ui-icon name="navigation.analysis"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'features', params: { type: 'image-cluster' }}" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Features'" v-if="$hasFeatureTo(['access-features'])">
                        <ui-icon name="navigation.features"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'connections'}" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Connections Explorer'">
                        <ui-icon name="share"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'settings.account' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Settings'">
                        <ui-icon name="navigation.settings"></ui-icon>
                    </router-link>
                </nav>
            </div>

            <nav class="mt-auto">
                <a href="#" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" :class="{'text-gray-50': $my.showSidebar}" v-tooltip.right="'Toggle Sidebar'" @click.prevent="$my.toggleSidebar">
                    <ui-icon name="sidebar"></ui-icon>
                </a>

                <div class="flex items-center justify-center mt-4">
                    <ui-icon name="g" class="text-gray-50 text-4xl"></ui-icon>
                </div>
            </nav>
        </div>

        <div class="flex-1 overflow-y-auto">
            <router-view name="navigation"></router-view>
        </div>
    </div>
</template>