<template>
    <div class="bg-white w-full overflow-hidden shadow rounded-sm flex flex-col h-96 px-6 py-4 mb-4" v-if="$page.target.monitored || $page.target.metrics.contentCount">
        <div class="flex items-center justify-between pb-4">
            <ui-dropdown align="left">
                <template v-slot:trigger>
                    <h3 class="font-semibold text-gray-900 cursor-pointer">
                        {{ { 'content': 'Content', 'audience': 'Audience' }[selectedSection] }}
                        <ui-icon name="chevron-down" class="ml-1"></ui-icon>
                    </h3>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="selectedSection = 'content'">
                        Content
                    </ui-dropdown-link>
                    <ui-dropdown-link @click="selectedSection = 'audience'">
                        Audience
                    </ui-dropdown-link>
                </template>
            </ui-dropdown>

            <ui-dropdown align="right" width="w-48">
                <template v-slot:trigger>
                    <button class="flex items-center text-gray-700 text-sm hover:text-gray-900 focus:outline-none hover:bg-gray-50 focus:text-gray-900 transition px-3 py-1 rounded-full" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                        {{selectedDateOption ? selectedDateOption.name : ''}}
                        <ui-icon name="chevron-down" class="ml-1"></ui-icon>
                    </button>
                </template>

                <template v-slot:content>
                    <ui-dropdown-link @click="selectDate(dateOption)" :key="index" v-for="dateOption, index in dateOptions">
                        <div class="w-4 text-center inline-block">
                            <ui-icon name="check" v-if="selectedDateOption == dateOption"></ui-icon>
                        </div>
                        {{dateOption.name}}
                    </ui-dropdown-link>
                </template>
            </ui-dropdown>
        </div>

        <div v-if="selectedSection === 'content'" class="w-full flex-1 min-h-0 relative">
            <ui-analysis :store="targetsTargetInformationTargetContentAnalysisStore"></ui-analysis>
        </div>
        <div v-if="selectedSection === 'audience'" class="w-full flex-1 min-h-0 relative">
            <ui-analysis :store="targetsTargetInformationTargetAudienceAnalysisStore"></ui-analysis>
        </div>
    </div>
</template>

<script>
import useTargetsTargetInformationTargetContentAnalysisStore from '@/stores/targets/target/information-target-content-analysis'
import useTargetsTargetInformationTargetAudienceAnalysisStore from '@/stores/targets/target/information-target-audience-analysis'

import { mapStores } from 'pinia'

export default {
    data: () => ({
        dateOptions: [
            { name: 'Past Day', date: { type: 'past', date: { past: 1, unit: 'days' } }, granularity: 'hour' },
            { name: 'Past Week', date: { type: 'past', date: { past: 1, unit: 'weeks' } }, granularity: 'day' },
            { name: 'Past Month', date: { type: 'past', date: { past: 1, unit: 'months' } }, granularity: 'day' },
            { name: 'Past Year', date: { type: 'past', date: { past: 1, unit: 'years' } }, granularity: 'week' }
        ],

        selectedSection: 'content',

        selectedDateOption: null
    }),

    computed: {
        ...mapStores(useTargetsTargetInformationTargetContentAnalysisStore),
        ...mapStores(useTargetsTargetInformationTargetAudienceAnalysisStore)
    },

    methods: {
        selectDate(dateOption) {
            this.selectedDateOption = dateOption

            this.targetsTargetInformationTargetContentAnalysisStore.setDate(dateOption.date)
            this.targetsTargetInformationTargetContentAnalysisStore.setGranularity(dateOption.granularity)

            this.targetsTargetInformationTargetAudienceAnalysisStore.setDate(dateOption.date)
            this.targetsTargetInformationTargetAudienceAnalysisStore.setGranularity(dateOption.granularity)
        }
    },

    mounted() {
        this.selectedDateOption = this.dateOptions[3]
    }
}
</script>
