<template>
    <div>
        <template v-if="store.itemsNew.data && store.itemsNew.data.length || store.itemsNew.pending">
            <div class="rounded bg-white shadow w-full divide-y divide-gray-200 overflow-hidden">
                <template v-if="store.itemsNew.data && store.itemsNew.data.length">
                    <template v-for="item in store.itemsNew.data">
                        <slot name="item" :item="item" :layout="store.layout"></slot>
                    </template>
                </template>

                <template v-else-if="store.itemsNew.pending">
                    <template v-for="v, i in Array.from({ length: 3 })">
                        <slot name="placeholder" :index="i" :layout="store.layout">
                            <stream-pending-placeholder :large="!! (i % 2)"></stream-pending-placeholder>
                        </slot>
                    </template>
                </template>
            </div>

            <div class="flex items-center px-2">
                <div class="flex-1 border-b border-blue-600"></div>
                <div class="py-6 px-2 text-center text-xs font-semibold text-blue-600 uppercase">
                    New Items
                </div>
                <div class="flex-1 border-b border-blue-600"></div>
            </div>
        </template>

        <div class="rounded bg-white shadow w-full divide-y divide-gray-200 overflow-hidden">
            <!-- Items -->
            <template v-if="store.items.data && store.items.data.length">
                <template v-for="item in store.items.data">
                    <slot name="item" :item="item" :layout="store.layout"></slot>
                </template>
            </template>

            <template v-else-if="store.items.pending">
                <template v-for="v, i in Array.from({ length: 10 })">
                    <slot name="placeholder" :index="i" :layout="store.layout">
                        <stream-pending-placeholder :large="!! (i % 2)"></stream-pending-placeholder>
                    </slot>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import StreamPendingPlaceholder from '../cards/pending-placeholder'

export default {
    components: { StreamPendingPlaceholder }
}
</script>
