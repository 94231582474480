<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-slot:widget>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <p v-if="store.uploadErrors" v-for="error, i in store.uploadErrors.file" :key="i" class="text-center text-red-600">
                    {{ error }}
                </p>

                <div v-else class="flex-1 min-h-0">
                    <img class="max-h-full max-w-full object-fill object-center" :src="store.url" alt="Image">
                </div>
            </div>
        </template>

        <template v-slot:expanded>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <p v-if="store.uploadErrors" v-for="error, i in store.uploadErrors.file" :key="i" class="text-center text-red-600">
                    {{ error }}
                </p>

                <div v-else class="flex-1 min-h-0 p-4">
                    <img class="object-contain" :src="store.url" alt="Image">
                </div>
            </div>
        </template>

        <template v-slot:edit="{ close }">
            <div class="overflow-y-auto flex flex-col items-center justify-center">
                <div class="shrink-0 mx-auto text-center p-4">
                    <input ref="file" class="flex-grow" type="file" @change="store.setFile($event.target.files[0])" hidden>
                    <ui-button @click.prevent="$refs.file.click()" size="lg">Choose a file</ui-button>
                    <p class="mt-1.5 text-gray-700">Up to 5MB</p>
                </div>

                <p v-if="store.uploadErrors" v-for="error, i in store.uploadErrors.file" :key="i" class="text-center text-red-600">
                    {{ error }}
                </p>

                <div v-if="! store.uploadErrors && (store.stagedUrl || store.url)" class="flex justify-center h-120 w-full p-4">
                    <img class="object-contain" :src="store.stagedUrl ?? store.url" alt="Image">
                </div>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'

export default {
    components: { DashboardWidget }
}
</script>
