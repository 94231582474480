<!-- This filter does not extends generic search-filter as it has a more "custom" UI instead of the standard dropdown -->

<template>
    <div>
        <a href="#" class="block" @click.prevent="store.getFilterValue(filter.id) ? store.removeFilter(filter.id) : store.addFilter(filter.id, true)">
            <div class="inline-flex items-center space-x-1 px-3 h-7 text-xs font-medium leading-4 bg-blue-50 text-blue-600 rounded-sm max-w-full" v-if="store.getFilterValue(filter.id)">
                <span class="inline-block w-3 h-3 text-xs rounded-full bg-red-500"></span>
                <span>{{filter.name}}</span>
            </div>

            <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-4 hover:bg-gray-50 text-gray-900 rounded-sm max-w-full" v-else>
                {{filter.name}}
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: [ 'filter' ]
}
</script>
