import api from '@/api'

import { defineStore } from 'pinia'
import { subYears } from 'date-fns'

export const defineContentPerspectivePrevalenceAnalysisStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        isLoaded: false,

        options: {
            chart: {
                backgroundColor: false,
                type: 'line',
                zoomType: 'xy',
                resetZoomButton: {
                    position: {
                        x: -40,
                        y: -5,
                    }
                },
                spacing: [0, 0, 0, 0]
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: '',
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [
                {
                    id: 'count',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent'
                },
                {
                    id: 'interactions',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent'
                }
            ],
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () {
                    if (this.series.index == 0) {
                        return `<strong>${this.y}</strong> items published<br>`
                    } else {
                        return `<strong>${this.y}</strong> interactions`
                    }
                },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' },
                xDateFormat: '%Y-%m-%d'
            },
            legend: {
                enabled: false
            },
            series: [],
            credits: {
                enabled: false
            }
        }
    }),

    actions: {
        loadSeries(filters) {
            let date = { type: 'after', date: { gte: subYears(new Date, 1) } }

            api.route('me analyses series').json(
                {
                    type: 'content-performance',
                    fresh: true,
                    configuration: {
                        date,
                        granularity: "day",
                        series: [
                            {
                                type: 'filters',
                                label: 'Count',
                                color: '#000',
                                meta: {
                                    aggregator: 'count',
                                    metric: 'all'
                                },
                                datasetType: 'inline-perspective',
                                datasetFilters: filters
                            },
                            {
                                type: 'filters',
                                label: 'Interactions',
                                color: '#000',
                                meta: {
                                    aggregator: 'summary',
                                    metric: 'interactions'
                                },
                                datasetType: 'inline-perspective',
                                datasetFilters: filters
                            }
                        ]
                    }
                }
            ).post().json(response => {
                let series = response.series.map(series => {
                    return series.values.map(point => ({
                        x: Date.parse(point.date),
                        y: parseFloat(point.value)
                    }))
                })

                this.options.series = [
                    {
                        color: '#9fc2f9',
                        data: series[0],
                        type: 'column',
                        yAxis: 0
                    },
                    {
                        color: '#2172f0',
                        data: series[1],
                        type: 'spline',
                        yAxis: 1
                    }
                ]

                this.isLoaded = true
            })
        },

        resetSeries() {
            this.options.series = []
            this.isLoaded = false
        }
    }
})

export default defineContentPerspectivePrevalenceAnalysisStore
