<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset">
        <template v-slot:applied>
            <div class="flex items-center">
                <div v-if="typeValue.length">
                    <ui-icon :name="typeValue[0].icon" class="mr-1" :class="typeValue[0].iconClass" v-if="typeValue.length === 1 && typeValue[0].icon"></ui-icon>

                    <span class="font-medium" v-if="typeValue.length > 1">
                        {{typeValue.length}} types
                    </span>
                    <span class="font-medium" v-else>
                        {{typeValue.length ? typeValue[0].name : typeValue.name}}
                    </span>
                </div>

                <div class="flex items-center font-medium" v-if="repliesValue">
                    <span v-if="typeValue.length" class="ml-1">+ replies</span>
                    <span v-else>All + replies</span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-96 overflow-y-auto">
                <template v-for="option, index in types">
                    <ui-dropdown-link :icon="option.icon" :checkbox="filter.filters[0].multiple" :selected="localTypeValues.find(lt => lt.id === option.id)" @click="toggleTypeValue(option)" :key="`link-${index}`" v-if="option.id">
                        {{option.name}}
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2" :key="`separator-${index}`" v-else></ui-dropdown-separator>
                </template>

                <ui-dropdown-separator class="my-2" :key="`separator-replies`"></ui-dropdown-separator>

                <ui-dropdown-link icon="message-square" :selected="localRepliesValue" @click="toggleRepliesValue" key="link-replies" keep-open>
                    Include comments
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.filters[0].id); store.removeFilter(filter.filters[1].id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import { textSearch } from '@/helpers'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        localTypeValues: [],
        localRepliesValue: false,
        search: ''
    }),

    computed: {
        types() {
            return textSearch(
                this.search,
                this.filter.filters[0].options.filter(this.filter.filters[0].filter || (t => true)),
                o => o.name || true
            )
        },

        typeValue() {
            return this.store.getFilterValue(this.filter.filters[0].id) ?? []
        },

        repliesValue() {
            return this.store.getFilterValue(this.filter.filters[1].id) ?? false
        },
    },

    methods: {
        toggleTypeValue(item) {
            this.localTypeValues.some(v => v.id === item.id) ? this.localTypeValues.splice(this.localTypeValues.findIndex(v => v.id === item.id), 1) : this.localTypeValues.push(item)
        },

        toggleRepliesValue() {
            this.localRepliesValue = ! this.localRepliesValue
        },

        reset() {
            this.localTypeValues = this.store.getFilterValue(this.filter.filters[0].id) ? [...this.store.getFilterValue(this.filter.filters[0].id)] : []
            this.localRepliesValue = this.store.getFilterValue(this.filter.filters[1].id) ?? false
            this.search = ''
        },

        apply() {
            this.store.withoutTriggeringUpdates(() => this.store.addFilter(this.filter.filters[1].id, this.localRepliesValue))

            this.localTypeValues.length
                ? this.store.addFilter(this.filter.filters[0].id, this.localTypeValues)
                : this.store.removeFilter(this.filter.filters[0].id)
        }
    }
}
</script>
