<template>
    <ui-dropdown align="right">
        <template v-slot:trigger>
            <div class="flex items-center space-x-2">
                <span class="text-gray-700 text-sm font-medium">View</span>
                <div class="inline-flex items-center px-3 pr-2 h-7 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                    <ui-icon :name="store.layout.icon" class="mr-1"></ui-icon>
                    <span class="font-medium">{{store.layout.name}}</span>
                    <ui-icon name="select" class="ml-2"></ui-icon>
                </div>
            </div>
        </template>

        <template v-slot:content>
            <ui-dropdown-link :icon="layout.icon" @click="store.setLayout(layout.id)" :disabled="! layout.available" :key="`layout-${layout.id}`" v-for="layout in store.layoutOptions">
                {{layout.name}}
            </ui-dropdown-link>

            <template v-if="store.stackingOptions?.length">
                <ui-dropdown-separator :key="`view-separator`"></ui-dropdown-separator>

                <ui-dropdown-link v-for="stacking in store.stackingOptions" :key="stacking.id" :selected="store.stacking == stacking" @click="store.toggleStacking(stacking.id)">
                    Stack by {{ stacking.name }}
                </ui-dropdown-link>
            </template>
        </template>
    </ui-dropdown>
</template>
