import api from '@/api'

import { defineStore } from 'pinia'

export const useMyAppsStore = defineStore({
    id: 'my-apps',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me apps').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async delete(app, force = false) {
            await api.route('me apps delete', app.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .res()

            return this.reload()
        }
    }
})

export default useMyAppsStore
