<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-facebook-user"
        avatar-badge-tooltip="Facebook User"
        avatar-image-class="ring-social-facebook"
        :parent-component="{ 'facebook-post': FacebookPostCard, 'facebook-group-post': FacebookGroupPostCard, 'facebook-user-post': _.type }"
        :hide-media-section="hideMediaSection"
    >
        <template v-slot:no-content>
            <div class="mx-5 last:rounded-b-sm text-gray-500" v-if="hasNoContent">
                We weren't able to collect the content of this post. This usually means its privacy settings didn't allow us to see it.
                You can try opening the original <a class="text-blue-700" :href="content.link" target="_blank">post on Facebook</a>.
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'
import FacebookGroupPostCard from './facebook-group-post'
import FacebookPostCard from './facebook-post'

import { markRaw } from 'vue'

export default {
    name: 'facebook-user-post-card',

    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    data: () => ({
        FacebookGroupPostCard: markRaw(FacebookGroupPostCard),
        FacebookPostCard: markRaw(FacebookPostCard)
    }),

    computed: {
        hasNoContent() {
            return !this.content.body
                && !this.content.attachment.url
                && !this.content.parent
                && ! ['added_photos', 'photo', 'added_video', 'video'].includes(this.content.subtype)
        },

        hideMediaSection() {
            return this.content.parent && this.content.parent.images.length
        }
    }
}
</script>
