import api from '@/api'

import { defineStore } from 'pinia'

export const useMySearchHistoryStore = defineStore({
    id: 'my-search-history',

    state: () => ({
        items: [],

        isLoading: false
    }),

    actions: {
        async load(type = 'all') {
            this.isLoading = true

            return this.loadingPromise = api.route('me search-history').query({ type }).get().json(res => {
                this.items = res.data

                this.isLoading = false
            })
        }
    }
})

export default useMySearchHistoryStore
