import useFeaturesLibraryTypesStore from '@/stores/features/library-types'
import useMyRecentFeaturesStore from '@/stores/me/recent-features'

import api from '@/api'

import { defineStore } from 'pinia'

export const useFeaturesFeaturePage = defineStore({
    id: 'featuresFeaturePage',

    state: () => ({
        type: null,
        feature: null
    }),

    getters: {
        title: store => store.feature ? `${store.feature.title} | Features` : ''
    },

    actions: {
        async afterEnter(to) {
            await Promise.all([
                this.load(to.params.type, to.params.id)
            ])

            useMyRecentFeaturesStore().reload()
        },

        load(type, id) {
            if (this.feature?.id == id) return

            this.type = useFeaturesLibraryTypesStore().find(type)

            return api.route('monitor features details', { id, details: 'full' })
                .get()
                .json(res => {
                    this.feature = res.data
                })
        }
    }
})

export default useFeaturesFeaturePage
