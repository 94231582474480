import useOrganizationSettingsFormStore from '@/admin/stores/accounts/organization-form'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsOrganizationCreateStore = defineStore({
    id: 'modalsOrganizationCreate',

    actions: {
        open() {
            useOrganizationSettingsFormStore().$reset()

            useModal().show('organization-create')
        },

        cancel() {
            useModal().hide('organization-create')
        }
    }
})

export default useModalsOrganizationCreateStore
