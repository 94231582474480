<template>
    <ui-dropdown-link icon="tags" :submenu="true" :submenu-align="align" submenu-width="w-56">
        Tags...

        <template v-slot:submenu>
            <tags-menu-content v-bind="$props"></tags-menu-content>
        </template>
    </ui-dropdown-link>
</template>

<script>
import TagsMenuContent from './contextual-menu-content'

export default {
    props: [ 'item', 'align' ],

    components: { TagsMenuContent }
}
</script>
