<template>
    <div class="ui-icon" :data-name="name" v-html="content"></div>
</template>

<script>
let icons = {
    'account': require('@/assets/icons/account.svg?raw'),
    'activity': require('@/assets/icons/activity.svg?raw'),
    'ai': require('@/assets/icons/ai.svg?raw'),
    'alert': require('@/assets/icons/alert.svg?raw'),
    'alert-circle': require('@/assets/icons/alert-circle.svg?raw'),
    'alert-triangle': require('@/assets/icons/alert-triangle.svg?raw'),
    'align-center': require('@/assets/icons/align-center.svg?raw'),
    'align-justify': require('@/assets/icons/align-justify.svg?raw'),
    'align-left': require('@/assets//icons/align-left.svg?raw'),
    'align-right': require('@/assets//icons/align-right.svg?raw'),
    'arrow-down': require('@/assets/icons/arrow-down.svg?raw'),
    'arrow-left': require('@/assets/icons/arrow-left.svg?raw'),
    'arrow-right-circle': require('@/assets/icons/arrow-right-circle.svg?raw'),
    'arrow-up': require('@/assets/icons/arrow-up.svg?raw'),
    'at-sign': require('@/assets/icons/at-sign.svg?raw'),
    'award': require('@/assets/icons/award.svg?raw'),
    'back': require('@/assets/icons/back.svg?raw'),
    'badge-content': require('@/assets/icons/badges/content.svg?raw'),
    'badge-feature-domain': require('@/assets/icons/badges/feature-domain.svg?raw'),
    'badge-feature-email': require('@/assets/icons/badges/feature-email.svg?raw'),
    'badge-feature-hashtag': require('@/assets/icons/badges/feature-hashtag.svg?raw'),
    'badge-feature-iban': require('@/assets/icons/badges/feature-iban.svg?raw'),
    'badge-feature-image': require('@/assets/icons/badges/feature-image.svg?raw'),
    'badge-feature-ip-address': require('@/assets/icons/badges/feature-ip-address.svg?raw'),
    'badge-feature-location': require('@/assets/icons/badges/feature-location.svg?raw'),
    'badge-feature-name': require('@/assets/icons/badges/feature-person-name.svg?raw'),
    'badge-feature-person-name': require('@/assets/icons/badges/feature-person-name.svg?raw'),
    'badge-feature-phone-number': require('@/assets/icons/badges/feature-phone-number.svg?raw'),
    'badge-feature-url': require('@/assets/icons/badges/feature-url.svg?raw'),
    'badge-feature-video': require('@/assets/icons/badges/feature-video.svg?raw'),
    'badge-source': require('@/assets/icons/badges/source.svg?raw'),
    'badge-target-facebook': require('@/assets/icons/badges/source-facebook.svg?raw'),
    'badge-target-facebook-page': require('@/assets/icons/badges/source-facebook.svg?raw'),
    'badge-target-facebook-user': require('@/assets/icons/badges/source-facebook.svg?raw'),
    'badge-target-facebook-group': require('@/assets/icons/badges/source-facebook-group.svg?raw'),
    'badge-target-web-feed': require('@/assets/icons/badges/source-web-feed.svg?raw'),
    'badge-target-web-site': require('@/assets/icons/badges/source-website.svg?raw'),
    'badge-target-youtube': require('@/assets/icons/badges/source-youtube.svg?raw'),
    'badge-target-youtube-channel': require('@/assets/icons/badges/source-youtube.svg?raw'),
    'badge-target-facebook-inactive': require('@/assets/icons/badges/source-facebook-inactive.svg?raw'),
    'badge-target-facebook-page-inactive': require('@/assets/icons/badges/source-facebook-inactive.svg?raw'),
    'badge-target-facebook-user-inactive': require('@/assets/icons/badges/source-facebook-inactive.svg?raw'),
    'badge-target-facebook-group-inactive': require('@/assets/icons/badges/source-facebook-group-inactive.svg?raw'),
    'badge-target-web-feed-inactive': require('@/assets/icons/badges/source-web-feed-inactive.svg?raw'),
    'badge-target-web-site-inactive': require('@/assets/icons/badges/source-website-inactive.svg?raw'),
    'badge-target-youtube-inactive': require('@/assets/icons/badges/source-youtube-inactive.svg?raw'),
    'badge-target-youtube-channel-inactive': require('@/assets/icons/badges/source-youtube-inactive.svg?raw'),
    'badges.feature-domain': require('@/assets/icons/badges/feature-domain.svg?raw'),
    'badges.feature-email': require('@/assets/icons/badges/feature-email.svg?raw'),
    'badges.feature-hashtag': require('@/assets/icons/badges/feature-hashtag.svg?raw'),
    'badges.feature-iban': require('@/assets/icons/badges/feature-iban.svg?raw'),
    'badges.feature-image': require('@/assets/icons/badges/feature-image.svg?raw'),
    'badges.feature-ip-address': require('@/assets/icons/badges/feature-ip-address.svg?raw'),
    'badges.feature-location': require('@/assets/icons/badges/feature-location.svg?raw'),
    'badges.feature-name': require('@/assets/icons/badges/feature-person-name.svg?raw'),
    'badges.feature-person-name': require('@/assets/icons/badges/feature-person-name.svg?raw'),
    'badges.feature-phone-number': require('@/assets/icons/badges/feature-phone-number.svg?raw'),
    'badges.feature-url': require('@/assets/icons/badges/feature-url.svg?raw'),
    'badges.feature-video': require('@/assets/icons/badges/feature-video.svg?raw'),
    'badges.target-facebook-group': require('@/assets/icons/badges/target-facebook-group.svg?raw'),
    'badges.target-facebook-page': require('@/assets/icons/badges/target-facebook-page.svg?raw'),
    'badges.target-facebook-user': require('@/assets/icons/badges/target-facebook-user.svg?raw'),
    'badges.target-instagram-profile': require('@/assets/icons/badges/target-instagram-profile.svg?raw'),
    'badges.target-telegram-channel': require('@/assets/icons/badges/target-telegram-channel.svg?raw'),
    'badges.target-telegram-group': require('@/assets/icons/badges/target-telegram-group.svg?raw'),
    'badges.target-telegram-user': require('@/assets/icons/badges/target-telegram-user.svg?raw'),
    'badges.target-tiktok-user': require('@/assets/icons/badges/target-tiktok-user.svg?raw'),
    'badges.target-twitter-user': require('@/assets/icons/badges/target-twitter-user.svg?raw'),
    'badges.target-vkontakte-community': require('@/assets/icons/badges/target-vkontakte-community.svg?raw'),
    'badges.target-vkontakte-user': require('@/assets/icons/badges/target-vkontakte-user.svg?raw'),
    'badges.target-web-feed': require('@/assets/icons/badges/target-web-feed.svg?raw'),
    'badges.target-web-site': require('@/assets/icons/badges/target-web-site.svg?raw'),
    'badges.target-youtube-channel': require('@/assets/icons/badges/target-youtube-channel.svg?raw'),
    'bell': require('@/assets/icons/bell.svg?raw'),
    'bell-off': require('@/assets/icons/bell-off.svg?raw'),
    'billing': require('@/assets/icons/billing.svg?raw'),
    'bold': require('@/assets/icons/bold.svg?raw'),
    'bookmark': require('@/assets/icons/bookmark.svg?raw'),
    'calendar': require('@/assets/icons/calendar.svg?raw'),
    'camera': require('@/assets/icons/camera.svg?raw'),
    'analysis': require('@/assets/icons/analysis.svg?raw'),
    'chat': require('@/assets/icons/chat.svg?raw'),
    'check': require('@/assets/icons/check.svg?raw'),
    'check-circle': require('@/assets/icons/check-circle.svg?raw'),
    'check-full-circle': require('@/assets/icons/check-full-circle.svg?raw'),
    'check-square': require('@/assets/icons/check-square.svg?raw'),
    'chevron-down': require('@/assets/icons/chevron-down.svg?raw'),
    'chevron-left': require('@/assets/icons/chevron-left.svg?raw'),
    'chevron-right': require('@/assets/icons/chevron-right.svg?raw'),
    'chevron-up': require('@/assets/icons/chevron-up.svg?raw'),
    'clear': require('@/assets/icons/clear.svg?raw'),
    'clipboard': require('@/assets/icons/clipboard.svg?raw'),
    'color-reset': require('@/assets/icons/color-reset.svg?raw'),
    'comment': require('@/assets/icons/comment.svg?raw'),
    'comments': require('@/assets/icons/comments.svg?raw'),
    'copy': require('@/assets/icons/copy.svg?raw'),
    'corner-down-right': require('@/assets/icons/corner-down-right.svg?raw'),
    'crosshair': require('@/assets/icons/crosshair.svg?raw'),
    'curated-list': require('@/assets/icons/curated-list.svg?raw'),
    'curated-lists.academy': require('@/assets/icons/curated-lists/academy.svg?raw'),
    'curated-lists.disinformation': require('@/assets/icons/curated-lists/disinformation.svg?raw'),
    'curated-lists.extremism': require('@/assets/icons/curated-lists/extremism.svg?raw'),
    'curated-lists.foreign': require('@/assets/icons/curated-lists/foreign.svg?raw'),
    'curated-lists.government': require('@/assets/icons/curated-lists/government.svg?raw'),
    'curated-lists.media': require('@/assets/icons/curated-lists/media.svg?raw'),
    'curated-lists.political': require('@/assets/icons/curated-lists/political.svg?raw'),
    'curated-lists.pseudo-media': require('@/assets/icons/curated-lists/pseudo-media.svg?raw'),
    'curated-lists.public': require('@/assets/icons/curated-lists/public.svg?raw'),
    'dashboard': require('@/assets/icons/dashboard.svg?raw'),
    'dots-vertical': require('@/assets/icons/dots-vertical.svg?raw'),
    'download': require('@/assets/icons/download.svg?raw'),
    'edit': require('@/assets/icons/edit.svg?raw'),
    'edit-2': require('@/assets/icons/edit-2.svg?raw'),
    'edit-dash': require('@/assets/icons/edit-dash.svg?raw'),
    'email': require('@/assets/icons/email.svg?raw'),
    'error': require('@/assets/icons/error.svg?raw'),
    'error-full-circle': require('@/assets/icons/error-full-circle.svg?raw'),
    'external-link': require('@/assets/icons/external-link.svg?raw'),
    'eye': require('@/assets/icons/eye.svg?raw'),
    'eye-off': require('@/assets/icons/eye-off.svg?raw'),
    'euro': require('@/assets/icons/euro.svg?raw'),
    'facebook': require('@/assets/icons/facebook.svg?raw'),
    'feature': require('@/assets/icons/feature.svg?raw'),
    'file-text': require('@/assets/icons/file-text.svg?raw'),
    'filter': require('@/assets/icons/filter.svg?raw'),
    'fire': require('@/assets/icons/fire.svg?raw'),
    'flag': require('@/assets/icons/flag.svg?raw'),
    'flag-bg': require('@/assets/icons/flags/bg.svg?raw'),
    'flag-by': require('@/assets/icons/flags/by.svg?raw'),
    'flag-cn': require('@/assets/icons/flags/cn.svg?raw'),
    'flag-cz': require('@/assets/icons/flags/cz.svg?raw'),
    'flag-de': require('@/assets/icons/flags/de.svg?raw'),
    'flag-dk': require('@/assets/icons/flags/dk.svg?raw'),
    'flag-ee': require('@/assets/icons/flags/ee.svg?raw'),
    'flag-es': require('@/assets/icons/flags/es.svg?raw'),
    'flag-fi': require('@/assets/icons/flags/fi.svg?raw'),
    'flag-fr': require('@/assets/icons/flags/fr.svg?raw'),
    'flag-gr': require('@/assets/icons/flags/gr.svg?raw'),
    'flag-hu': require('@/assets/icons/flags/hu.svg?raw'),
    'flag-ie': require('@/assets/icons/flags/ie.svg?raw'),
    'flag-il': require('@/assets/icons/flags/il.svg?raw'),
    'flag-in': require('@/assets/icons/flags/in.svg?raw'),
    'flag-it': require('@/assets/icons/flags/it.svg?raw'),
    'flag-jp': require('@/assets/icons/flags/jp.svg?raw'),
    'flag-kr': require('@/assets/icons/flags/kr.svg?raw'),
    'flag-lt': require('@/assets/icons/flags/lt.svg?raw'),
    'flag-lv': require('@/assets/icons/flags/lv.svg?raw'),
    'flag-nl': require('@/assets/icons/flags/nl.svg?raw'),
    'flag-no': require('@/assets/icons/flags/no.svg?raw'),
    'flag-pl': require('@/assets/icons/flags/pl.svg?raw'),
    'flag-pt': require('@/assets/icons/flags/pt.svg?raw'),
    'flag-ro': require('@/assets/icons/flags/ro.svg?raw'),
    'flag-rs': require('@/assets/icons/flags/rs.svg?raw'),
    'flag-ru': require('@/assets/icons/flags/ru.svg?raw'),
    'flag-sa': require('@/assets/icons/flags/sa.svg?raw'),
    'flag-se': require('@/assets/icons/flags/se.svg?raw'),
    'flag-si': require('@/assets/icons/flags/si.svg?raw'),
    'flag-sk': require('@/assets/icons/flags/sk.svg?raw'),
    'flag-tr': require('@/assets/icons/flags/tr.svg?raw'),
    'flag-ua': require('@/assets/icons/flags/ua.svg?raw'),
    'flag-uk': require('@/assets/icons/flags/uk.svg?raw'),
    'flag-us': require('@/assets/icons/flags/us.svg?raw'),
    'flag-xx': require('@/assets/icons/flags/xx.svg?raw'),
    'folder': require('@/assets/icons/folder.svg?raw'),
    'frown': require('@/assets/icons/frown.svg?raw'),
    'g': require('@/assets/icons/g.svg?raw'),
    'git-commit': require('@/assets/icons/git-commit.svg?raw'),
    'hash': require('@/assets/icons/hash.svg?raw'),
    'heart': require('@/assets/icons/heart.svg?raw'),
    'image': require('@/assets/icons/image.svg?raw'),
    'info': require('@/assets/icons/info.svg?raw'),
    'italic': require('@/assets/icons/italic.svg?raw'),
    'link': require('@/assets/icons/link.svg?raw'),
    'list': require('@/assets/icons/list.svg?raw'),
    'lists': require('@/assets/icons/lists.svg?raw'),
    'list-ordered': require('@/assets/icons/list-ordered.svg?raw'),
    'list-unordered': require('@/assets/icons/list-unordered.svg?raw'),
    'location': require('@/assets/icons/location.svg?raw'),
    'lock': require('@/assets/icons/lock.svg?raw'),
    'log-out': require('@/assets/icons/log-out.svg?raw'),
    'key': require('@/assets/icons/key.svg?raw'),
    'mail': require('@/assets/icons/mail.svg?raw'),
    'mattermost': require('@/assets/icons/mattermost.svg?raw'),
    'maximize': require('@/assets/icons/maximize.svg?raw'),
    'maximize-2': require('@/assets/icons/maximize-2.svg?raw'),
    'menu': require('@/assets/icons/menu.svg?raw'),
    'message-circle': require('@/assets/icons/message-circle.svg?raw'),
    'message-square': require('@/assets/icons/message-square.svg?raw'),
    'minimize': require('@/assets/icons/minimize.svg?raw'),
    'minimize-2': require('@/assets/icons/minimize-2.svg?raw'),
    'minus': require('@/assets/icons/minus.svg?raw'),
    'merge': require('@/assets/icons/merge.svg?raw'),
    'more-horizontal': require('@/assets/icons/more-horizontal.svg?raw'),
    'more-vertical': require('@/assets/icons/more-vertical.svg?raw'),
    'navigation.analysis': require('@/assets/icons/navigation/analysis.svg?raw'),
    'navigation.connections': require('@/assets/icons/navigation/connections.svg?raw'),
    'navigation.content': require('@/assets/icons/navigation/content.svg?raw'),
    'navigation.dashboards': require('@/assets/icons/navigation/dashboards.svg?raw'),
    'navigation.features': require('@/assets/icons/navigation/features.svg?raw'),
    'navigation.notifications': require('@/assets/icons/navigation/notifications.svg?raw'),
    'navigation.settings': require('@/assets/icons/navigation/settings.svg?raw'),
    'navigation.targets': require('@/assets/icons/navigation/targets.svg?raw'),
    'navigation.sub.analyses': require('@/assets/icons/navigation/sub/analyses.svg?raw'),
    'navigation.sub.bookmarks': require('@/assets/icons/navigation/sub/bookmarks.svg?raw'),
    'navigation.sub.curated-lists': require('@/assets/icons/navigation/sub/curated-lists.svg?raw'),
    'navigation.sub.library': require('@/assets/icons/navigation/sub/library.svg?raw'),
    'navigation.sub.overview': require('@/assets/icons/navigation/sub/overview.svg?raw'),
    'navigation.sub.perspectives': require('@/assets/icons/navigation/sub/perspectives.svg?raw'),
    'navigation.sub.target-lists': require('@/assets/icons/navigation/sub/target-lists.svg?raw'),
    'navigation.sub.topics': require('@/assets/icons/navigation/sub/topics.svg?raw'),
    'notifications': require('@/assets/icons/notifications.svg?raw'),
    'notifications-active': require('@/assets/icons/notifications-active.svg?raw'),
    'palette': require('@/assets/icons/palette.svg?raw'),
    'pencil': require('@/assets/icons/pencil.svg?raw'),
    'person': require('@/assets/icons/person.svg?raw'),
    'perspective': require('@/assets/icons/perspective.svg?raw'),
    'phone': require('@/assets/icons/phone.svg?raw'),
    'percent': require('@/assets/icons/percent.svg?raw'),
    'play': require('@/assets/icons/play.svg?raw'),
    'play-circle': require('@/assets/icons/play-circle.svg?raw'),
    'plus': require('@/assets/icons/plus.svg?raw'),
    'plus-circle': require('@/assets/icons/plus-circle.svg?raw'),
    'plus-full-circle': require('@/assets/icons/plus-full-circle.svg?raw'),
    'plus-square': require('@/assets/icons/plus-square.svg?raw'),
    'pin-off': require('@/assets/icons/pin-off.svg?raw'),
    'pin-on': require('@/assets/icons/pin-on.svg?raw'),
    'printer': require('@/assets/icons/printer.svg?raw'),
    'preferences': require('@/assets/icons/preferences.svg?raw'),
    'quotes': require('@/assets/icons/quotes.svg?raw'),
    'remove': require('@/assets/icons/remove.svg?raw'),
    'repeat': require('@/assets/icons/repeat.svg?raw'),
    'resolution': require('@/assets/icons/resolution.svg?raw'),
    'search': require('@/assets/icons/search.svg?raw'),
    'select': require('@/assets/icons/select.svg?raw'),
    'select-multiple': require('@/assets/icons/select-multiple.svg?raw'),
    'sentiment': require('@/assets/icons/sentiment.svg?raw'),
    'server': require('@/assets/icons/server.svg?raw'),
    'settings': require('@/assets/icons/settings.svg?raw'),
    'share': require('@/assets/icons/share.svg?raw'),
    'share-2': require('@/assets/icons/share-2.svg?raw'),
    'shares': require('@/assets/icons/shares.svg?raw'),
    'shield': require('@/assets/icons/shield.svg?raw'),
    'sidebar': require('@/assets/icons/sidebar.svg?raw'),
    'slash': require('@/assets/icons/slash.svg?raw'),
    'star': require('@/assets/icons/star.svg?raw'),
    'stream': require('@/assets/icons/stream.svg?raw'),
    'sources': require('@/assets/icons/sources.svg?raw'),
    'subscription': require('@/assets/icons/subscription.svg?raw'),
    'sun': require('@/assets/icons/sun.svg?raw'),
    'tag': require('@/assets/icons/tag.svg?raw'),
    'tags': require('@/assets/icons/tags.svg?raw'),
    'tag-filled': require('@/assets/icons/tag-filled.svg?raw'),
    'target': require('@/assets/icons/target.svg?raw'),
    'team': require('@/assets/icons/team.svg?raw'),
    'team-alt': require('@/assets/icons/team-alt.svg?raw'),
    'text-accents': require('@/assets/icons/text-accents.svg?raw'),
    'text-case': require('@/assets/icons/text-case.svg?raw'),
    'text-size': require('@/assets/icons/text-size.svg?raw'),
    'text-word': require('@/assets/icons/text-word.svg?raw'),
    'thumbs-up': require('@/assets/icons/thumbs-up.svg?raw'),
    'time': require('@/assets/icons/time.svg?raw'),
    'topic': require('@/assets/icons/topic.svg?raw'),
    'translate': require('@/assets/icons/translate.svg?raw'),
    'trash': require('@/assets/icons/trash.svg?raw'),
    'trending-down': require('@/assets/icons/trending-down.svg?raw'),
    'trending-up': require('@/assets/icons/trending-up.svg?raw'),
    'type': require('@/assets/icons/type.svg?raw'),
    'umbrella': require('@/assets/icons/umbrella.svg?raw'),
    'unlock': require('@/assets/icons/unlock.svg?raw'),
    'upload': require('@/assets/icons/upload.svg?raw'),
    'user': require('@/assets/icons/user.svg?raw'),
    'users': require('@/assets/icons/users.svg?raw'),
    'view-cards': require('@/assets/icons/view-cards.svg?raw'),
    'view-grid': require('@/assets/icons/view-grid.svg?raw'),
    'view-list': require('@/assets/icons/view-list.svg?raw'),
    'warning': require('@/assets/icons/warning.svg?raw'),
    'wind': require('@/assets/icons/wind.svg?raw'),
    'web': require('@/assets/icons/web.svg?raw'),
    'web-feed': require('@/assets/icons/web-feed.svg?raw'),
    'wrench': require('@/assets/icons/wrench.svg?raw'),
    'x': require('@/assets/icons/x.svg?raw'),
    'x-rounded': require('@/assets/icons/x-rounded.svg?raw'),
    'zap': require('@/assets/icons/zap.svg?raw'),
    'zoom-in': require('@/assets/icons/zoom-in.svg?raw'),
    'zoom-out': require('@/assets/icons/zoom-out.svg?raw'),
    'underline': require('@/assets/icons/underline.svg?raw'),
    'video': require('@/assets/icons/video.svg?raw'),
    'globe': require('@/assets/icons/globe.svg?raw'),
    't_t': require('@/assets/icons/T_t.svg?raw'),
    'a_underlined': require('@/assets/icons/a_underlined.svg?raw'),
    'divide-square': require('@/assets/icons/divide-square.svg?raw'),
    'reactions-angry': require('@/assets/icons/reactions/angry.svg?raw'),
    'reactions-thankful': require('@/assets/icons/reactions/thankful.svg?raw'),
    'reactions-haha': require('@/assets/icons/reactions/haha.svg?raw'),
    'reactions-like': require('@/assets/icons/reactions/like.svg?raw'),
    'reactions-love': require('@/assets/icons/reactions/love.svg?raw'),
    'reactions-sad': require('@/assets/icons/reactions/sad.svg?raw'),
    'reactions-wow': require('@/assets/icons/reactions/wow.svg?raw'),
    'reactions-yt-like': require('@/assets/icons/reactions/yt-like.svg?raw'),
    'reactions-yt-dislike': require('@/assets/icons/reactions/yt-dislike.svg?raw'),
    'face-frown': require('@/assets/icons/face-frown.svg?raw'),
    'face-smile': require('@/assets/icons/face-smile.svg?raw'),
    'face-neutral': require('@/assets/icons/face-neutral.svg?raw'),
    'overperforming': require('@/assets/icons/zap-fast.svg?raw'),
    'views': require('@/assets/icons/eye2?raw'),
    'interactions': require('@/assets/icons/hearts.svg?raw'),
    'info-circle': require('@/assets/icons/info-circle.svg?raw'),
    'user-tag': require('@/assets/icons/tag-02.svg?raw'),
}

export default {
    name: 'UIIcon',
    props: { name: {}, asImage: Boolean, filled: Boolean },
    computed: {
        content() {
            if (this.asImage) {
                return `<img src="data:image/svg+xml;base64,${btoa(icons[this.name])}">`
            }

            if (! icons[this.name]) {
                return console.error(`Icon not found "${this.name}".`)
            }

            return this.addStyles(this.makeIdsUnique(icons[this.name]))
        }
    },

    methods: {
        makeIdsUnique(svg) {
            let ids = svg.match(/id=".*?"/g)

            if (! ids) {
                return svg
            }

            ids.forEach(id => {
                let newId = `-svg-${Date.now()}` + Math.floor(Math.random() * 1000)
                let originalId = id.match(/id="(.*?)"/)[1]

                svg = svg.replace(new RegExp(id, 'g'), `id="${newId}"`)
                svg = svg.replace(new RegExp(`#${originalId}`, 'g'), `#${newId}`)
            })

            return svg
        },

        addStyles(svg) {
            return this.filled ? svg.replace(/<svg /, '<svg style="fill: currentColor;" ') : svg
        }
    }
}
</script>

<style>
.ui-icon {
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: -0.125em;
}

.ui-icon svg, .ui-icon img {
    display: block;
    height: 100%;
    width: 100%;
}
</style>
