import { route } from '@/admin/helpers'
import api from '@/api'

let adminApi = api

adminApi.route = function (name, params) {
    return this.url(route(name, params))
}

export default adminApi
