<template>
    <router-link :to="{ name: 'support.threads.thread', params: { threadId: thread.id } }" class="block">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <div v-if="! thread.resolvedAt && ! thread.agent" v-tooltip="'Unresolved & Unassigned'" class="h-4 w-4 bg-red-400 rounded-full mr-4"></div>
                <div v-else-if="! thread.resolvedAt && thread.agent" v-tooltip="'Unresolved & Unassigned'" class="h-4 w-4 bg-green-400 rounded-full mr-4"></div>
                <div v-else class="h-4 w-4 bg-none mr-4"></div>

                <img :src="thread.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight space-x-1.5">
                        <span>{{ thread.name }}</span>
                        <span v-if="thread.internalName">&middot; ({{ thread.internalName }})</span>
                    </h1>
                    <div class="flex items-center text-gray-800 text-xs leading-tight">
                        <div>By <span class="font-medium">{{ thread.customer.name }}</span></div>
                        <div v-if="thread.agent">
                            <span class="px-1">&middot;</span>
                            <span>Assigned to </span>
                            <span class="font-medium">{{ thread.agent.name }}</span>
                        </div>
                        <div v-if="thread.resolvedAt">
                            <span class="px-1">&middot;</span>
                            <span>Resolved at </span>
                            <span class="font-medium">{{ $dateTime(thread.resolvedAt) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <thread-contextual-menu :thread="thread" plain></thread-contextual-menu>
                </slot>
            </div>

            <div class="flex items-center space-x-5 px-4 w-96 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                <div class="w-36">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Latest message on
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $dateTime(thread.latestMessage.createdAt) }}
                    </div>
                </div>

                <div class="w-36">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Started on
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $dateTime(thread.createdAt) }}
                    </div>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(thread)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import ThreadContextualMenu from '@/admin/components/support/contextual-menus/thread'

export default {
    props: { thread: {}, cardsStore: {} },

    components: { ThreadContextualMenu }
}
</script>
