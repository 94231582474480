<template>
    <ui-async :guards="workspace">
        <div class="pb-6 px-8 max-w-11xl mx-auto">
            <div v-if="workspaceSettingsFormStore.submitted" class="border border-green-300 bg-green-50 text-green-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
                Workspace successfully updated.
            </div>

            <ui-form :store="workspaceSettingsFormStore" v-slot="{ inputs, submitting, errors }" class="bg-white shadow rounded-sm">
                <div class="px-4 py-5 sm:p-8 space-y-8 divide-y divide-gray-300">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Details</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change the basic workspace information
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="name" class="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-gray-50 flex px-4 py-5 sm:p-8 rounded-b-lg border-t border-gray-100 flex justify-end">
                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Save
                    </ui-button>
                </div>
            </ui-form>

            <div class="mt-6">
                <ui-cards-toolbar :store="workspaceMembersCardsStore"></ui-cards-toolbar>

                <ui-cards :store="workspaceMembersCardsStore">
                    <template v-slot:item="{ item }">
                        <member-card :organization="organization" :workspace="workspace" :member="item" :cards-store="workspaceMembersCardsStore"></member-card>
                    </template>
                </ui-cards>

                <div class="mt-6">
                    <div class="flex items-center">
                        <span>&bull;&bull;&bull;&bull;</span>
                        <span class="text-sm text-gray-500 ml-1.5">Organization Admin - Manages the organization. Can add existing members to all workspaces and even new members to this organization. Each organization needs at least one admin.</span>
                    </div>

                    <div class="flex items-center">
                        <span>&bull;&bull;&bull;</span>
                        <span class="text-sm text-gray-500 ml-1.5">Workspace Owner - Owns the workspace. Can add existing members within the organization to this workspace. Each workspace needs to have an owner.</span>
                    </div>
                    <div class="flex items-center">
                        <span>&bull;&bull;</span>
                        <span class="text-sm text-gray-500 ml-1.5">Workspace Admin - Helps with the workspace management. Can add existing members within the organization to this workspace.</span>
                    </div>
                    <div class="flex items-center">
                        <span>&bull;</span>
                        <span class="text-sm text-gray-500 ml-1.5">Workspace Member - Has access to the workspace, but cannot manage the members.</span>
                    </div>
                </div>
            </div>

            <Teleport to="#header-actions">
                <ui-button icon="plus-square" color="blue" :action="openWorkspaceMemberCreateModal">
                    New Workspace Member
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off" spellcheck="false"
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
                </form>
            </Teleport>

            <Teleport to="#root">
                <workspace-member-create-modal></workspace-member-create-modal>
            </Teleport>
        </div>
    </ui-async>
</template>

<script>
import MemberCard from '@/admin/components/accounts/stream-cards/member'
import WorkspaceMemberCreateModal from '@/admin/components/accounts/modals/workspace-member-create'

import useWorkspaceMembersCardsStore from '@/admin/stores/accounts/workspace-members-cards'
import useWorkspaceSettingsFormStore from '@/admin/stores/accounts/workspace-form'
import useOrganizationStore from '@/admin/stores/accounts/organization'
import useWorkspaceStore from '@/admin/stores/accounts/workspace'
import useModalsWorkspaceMemberCreateStore from '@/admin/stores/accounts/modals/workspace-member-create'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        MemberCard,
        WorkspaceMemberCreateModal
    },

    computed: {
        ...mapStores(useWorkspaceSettingsFormStore),
        ...mapStores(useWorkspaceMembersCardsStore),

        ...mapState(useOrganizationStore, [
            'organization'
        ]),

        ...mapState(useWorkspaceStore, [
            'workspace'
        ])
    },

    methods: {
        ...mapActions(useModalsWorkspaceMemberCreateStore, { openWorkspaceMemberCreateModal: 'open' })
    }
}
</script>
