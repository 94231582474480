import defineCards from '@/stores/reusable/cards'

import useAccountsOrganizationsOrganizationLicensesPage from '@/admin/pages/accounts-organizations-organization-licenses'
import useLicensesStore from '@/admin/stores/accounts/licenses'

import { sortAlphabetically, textSearch } from '@/helpers'
import { markRaw } from 'vue'

export const useLicensesCardsStore = defineCards({
    id: 'licensesCards',

    source: useLicensesStore(),
    filter: items => textSearch(useAccountsOrganizationsOrganizationLicensesPage().search, items, i => i.name),

    layoutOptions: [
        {
            id: 'table',
            name: 'Table',
            icon: 'menu',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/table').default),
            settings: { showCharts: true }
        }
    ],
    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'asc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        },
        {
            id: 'expiration', name: 'Expiration', icon: 'time',
            apply: items => items.sort((a, b) => new Date(b.validTo) - new Date(a.validTo))
        }
    ]
})

export default useLicensesCardsStore
