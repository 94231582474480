<template>
    <div class="max-h-full overflow-y-auto" ref="report" @scroll="contentScrolled">
        <report-header></report-header>

        <div class="px-8 mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.dashboards" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to your new Report!</h1>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Widgets</h2>
                        <p class="text-justify my-2">Click on the "New Widget" button to add widgets. Various types of widgets are available, like analyses, streams or keyword clouds, with more coming soon.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Export</h2>
                        <p class="text-justify my-2">Click on the "Export" button to get a static snapshot of your report as a PDF. You can keep it or share it with other people.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Edit</h2>
                        <p class="text-justify my-2">Click on the "Edit" button to rearrange or resize your widgets. Drag the widgets to change their position or drag the bottom-right corner to resize the widget.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can try to <span class="font-medium">add your first page</span>.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button :action="() => { addPage(); edit() }" color="green" size="lg" icon="plus-square">
                        Add page
                    </ui-button>
                </div>
            </div>

            <ui-async :guards="configuration && contents">
                <div class="relative flex flex-col mx-auto p-4 bg-white shadow-sm mb-4" :class="{ 'border border-2 border-gray-800': isEditing && i + 1 === activePage }" :style="{ height: configuration.height + 'px', width: configuration.width + 'px' }" v-for="content, i in contents.slice(1)" :key="i">
                    <div v-if="isEditing && i + 1 === activePage" @click="removePage(i + 1)" class="flex items-center justify-center space-x-1 absolute w-32 top-4 -right-32 p-2 cursor-pointer bg-gray-800 leading-none font-medium text-center text-white">
                        <ui-icon name="trash"></ui-icon>
                        <span>Remove page</span>
                    </div>

                    <div class="h-28" v-if="configuration.displayHeader">
                        <grid-layout
                            v-model:layout="contents[0]"
                            :col-num="36"
                            :row-height="5"
                            :max-rows="20"
                            :prevent-collision="true"
                            :auto-size="false"
                            :is-draggable="isEditing"
                            :is-resizable="isEditing"
                            :is-mirrored="false"
                            :vertical-compact="false"
                            :margin="[0, 0]"
                            :use-css-transforms="true"
                            @layout-ready="layoutUpdated"
                            @layout-updated="layoutUpdated"
                            ref="gridLayout"
                            v-if="contents && contents.length"
                        >
                            <grid-item v-if="configuration.displayHeaderImage" :x="contents[0][0].x" :y="contents[0][0].y" :w="contents[0][0].w" :h="contents[0][0].h" :min-w="contents[0][0].minW" :min-h="contents[0][0].minH" :i="contents[0][0].i" :static="false">
                                <image-widget :store="contents[0][0]" :dashboard-store="reportStore"></image-widget>
                            </grid-item>

                            <grid-item v-if="configuration.displayHeaderTitle" :x="contents[0][1].x" :y="contents[0][1].y" :w="contents[0][1].w" :h="contents[0][1].h" :min-w="contents[0][1].minW" :min-h="contents[0][1].minH" :i="contents[0][1].i" :static="contents[0][1].type.inline && selectedWidgetId === contents[0][1].$id">
                                <text-widget :store="contents[0][1]" :dashboard-store="reportStore" :editable="true"></text-widget>
                            </grid-item>

                            <grid-item v-if="configuration.displayHeaderSideText" :x="contents[0][2].x" :y="contents[0][2].y" :w="contents[0][2].w" :h="contents[0][2].h" :min-w="contents[0][2].minW" :min-h="contents[0][2].minH" :i="contents[0][2].i" :static="contents[0][2].type.inline && selectedWidgetId === contents[0][2].$id">
                                <text-widget :store="contents[0][2]" :dashboard-store="reportStore" :editable="true"></text-widget>
                            </grid-item>
                        </grid-layout>
                    </div>

                    <div class="h-full max-h-full overflow-none">
                        <grid-layout
                            v-model:layout="contents[i + 1]"
                            :col-num="12"
                            :row-height="20"
                            :max-rows="Math.round(configuration.height / 24) + (configuration.displayHeader ? 0 : 8)"
                            :is-draggable="isEditing"
                            :is-resizable="isEditing"
                            :is-mirrored="false"
                            :vertical-compact="false"
                            :margin="[0, 0]"
                            :use-css-transforms="true"
                            @layout-ready="layoutUpdated"
                            @layout-updated="layoutUpdated"
                            ref="gridLayout"
                            v-if="content && content.length"
                        >
                            <grid-item
                                v-for="widget in contents[i + 1]" :x="widget.x" :y="widget.y" :w="widget.w" :h="widget.h" :min-w="widget.type.minW" :min-h="widget.type.minH" :i="widget.i" :static="widget.type.inline && selectedWidgetId === widget.$id" :key="widget.i">

                                <analysis-content-performance-widget :store="widget" :dashboard-store="reportStore" v-if="widget.type.id == 'analysis-content-performance'"></analysis-content-performance-widget>
                                <analysis-content-breakdown-by-source-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-source'"></analysis-content-breakdown-by-source-widget>
                                <analysis-content-breakdown-by-group-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-group'"></analysis-content-breakdown-by-group-widget>
                                <analysis-content-publish-times-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-publish-times'"></analysis-content-publish-times-widget>
                                <analysis-content-pull-push-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-pull-push'"></analysis-content-pull-push-widget>
                                <analysis-content-sentiment-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-sentiment'"></analysis-content-sentiment-widget>
                                <analysis-content-top-platforms-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-top-platforms'"></analysis-content-top-platforms-widget>
                                <analysis-content-keywords-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-keywords'"></analysis-content-keywords-widget>
                                <analysis-target-publish-times-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-target-publish-times'"></analysis-target-publish-times-widget>
                                <analysis-target-audience-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-target-audience'"></analysis-target-audience-widget>
                                <keywords-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'keywords'"></keywords-widget>
                                <stream-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'stream'"></stream-widget>
                                <image-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'image'"></image-widget>
                                <text-widget :store="widget" :dashboard-store="reportStore" :editable="true" v-else-if="widget.type.id == 'text'"></text-widget>

                            </grid-item>
                        </grid-layout>
                    </div>

                    <div class="flex items-end justify-between">
                        <p v-if="configuration.displayFooter && configuration.displayFooterPagination">
                            Page {{ i + 1 }} of {{ contents.length - 1 }}
                        </p>

                        <div class="ml-auto">
                            <img class="h-10" src="/gerulata.png" alt="Gerulata">
                        </div>
                    </div>
                </div>

                <div v-if="isEditing" class="flex items-center justify-center space-x-2 bg-gray-200 hover:bg-gray-300 h-18 mb-16 mx-auto p-4 cursor-pointer" :style="{ width: configuration.width + 'px' }" @click="addPage">
                    <ui-icon name="plus"></ui-icon>
                    <p>Add page</p>
                </div>
            </ui-async>

            <report-add-widget v-if="isEditing"></report-add-widget>

            <dashboard-expanded-widget :dashboard-store="reportStore"></dashboard-expanded-widget>
        </div>

        <Teleport to="#root">
            <report-edit-modal></report-edit-modal>
            <report-export-modal></report-export-modal>
            <add-analysis :dashboard-store="reportStore"></add-analysis>
        </Teleport>
    </div>
</template>

<script>
import AddAnalysis from '@/components/dashboards/modals/add-analysis'
import DashboardExpandedWidget from './widgets/expanded-widget'
import ReportHeader from './report-header'
import ReportAddWidget from './report-add-widget'
import ReportEditModal from './modals/report-edit'
import ReportExportModal from './modals/report-export'
import AnalysisContentPerformanceWidget from './widgets/analysis-content-performance'
import AnalysisContentBreakdownBySourceWidget from './widgets/analysis-content-breakdown-by-source'
import AnalysisContentBreakdownByGroupWidget from './widgets/analysis-content-breakdown-by-group'
import AnalysisContentPublishTimesWidget from './widgets/analysis-content-publish-times'
import AnalysisContentPullPushWidget from './widgets/analysis-content-pull-push'
import AnalysisContentSentimentWidget from './widgets/analysis-content-sentiment'
import AnalysisContentTopPlatformsWidget from './widgets/analysis-content-top-platforms'
import AnalysisContentKeywordsWidget from './widgets/analysis-content-keywords'
import AnalysisTargetPublishTimesWidget from './widgets/analysis-target-publish-times'
import AnalysisTargetAudienceWidget from './widgets/analysis-target-audience'
import KeywordsWidget from './widgets/keywords'
import StreamWidget from './widgets/stream'
import ImageWidget from './widgets/image'
import TextWidget from './widgets/text'

import installVueGrid from 'vue-grid-layout'
let vueGrid = { component(name, component) { this[name] = component } }
installVueGrid(vueGrid)

import useReportStore from '@/stores/dashboards/report'

import {mapActions, mapState, mapStores, mapWritableState} from 'pinia'

export default {
    components: {
        AddAnalysis,
        DashboardExpandedWidget,
        ReportHeader,
        ReportAddWidget,
        ReportEditModal,
        ReportExportModal,
        GridLayout: vueGrid['grid-layout'],
        GridItem: vueGrid['grid-item'],
        AnalysisContentPerformanceWidget,
        AnalysisContentBreakdownBySourceWidget,
        AnalysisContentBreakdownByGroupWidget,
        AnalysisContentPublishTimesWidget,
        AnalysisContentPullPushWidget,
        AnalysisContentSentimentWidget,
        AnalysisContentTopPlatformsWidget,
        AnalysisContentKeywordsWidget,
        AnalysisTargetPublishTimesWidget,
        AnalysisTargetAudienceWidget,
        KeywordsWidget,
        StreamWidget,
        ImageWidget,
        TextWidget
    },

    computed: {
        ...mapWritableState(useReportStore, [
            'contents'
        ]),

        ...mapState(useReportStore, [
            'report',
            'configuration',
            'activePage',
            'isEditing',
            'selectedWidgetId'
        ]),

        ...mapStores(useReportStore),

        isShowingWelcomeMessage() {
            return this.contents && this.contents.length === 1
        }
    },

    methods: {
        ...mapActions(useReportStore, [ 'edit', 'setActivePage', 'addPage', 'removePage' ]),

        contentScrolled() {
            const activePage = Math.floor(this.$refs.report.scrollTop / ( this.configuration.height - 50 )) + 1

            activePage <= this.contents.length - 1 ? this.setActivePage(activePage) : this.setActivePage(this.contents.length - 1)
        },

        layoutUpdated() { useReportStore().layoutUpdated() }
    }
}
</script>
