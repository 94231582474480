import api from '@/admin/api'

import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'

import { defineStore } from 'pinia'

export const useMemberStore = defineStore({
    id: 'member',

    state: () => ({
        member: null,
        memberLoaded: false,
        memberPromise: null
    }),

    actions: {
        async initialize(organizationId, memberId, force = false) {
            await this.load(organizationId, memberId, force)
        },

        async load(organizationId, memberId, force = false) {
            if (this.memberLoaded && ! force) return Promise.resolve()
            if (this.memberPromise) return this.memberPromise

            this.$reset()

            useMemberSettingsFormStore().$reset()

            return this.memberPromise = api.route('admin accounts organizations members details', { organizationId: organizationId, memberId: memberId } ).get().json(res => {
                this.member = res.data
                this.memberLoaded = true
                this.memberPromise = null

                useMemberSettingsFormStore().organizationId = organizationId
                useMemberSettingsFormStore().memberId = memberId

                useMemberSettingsFormStore().inputs.name = this.member.name
                useMemberSettingsFormStore().inputs.email = this.member.email
            })
        },

        impersonate() {
            api.route('admin accounts users impersonate', { userId: this.member.id }).post().json(res => {
                window.open(`${process.env.VUE_APP_APP_URL}/?impersonation-token=${res.impersonationToken}`, '_blank')
            })
        },

        disableTwoFactorAuth() {
            api.route('admin accounts users delete-two-factor-auth', { userId: this.member.id })
                .formData({ _method: 'delete' })
                .post()
                .res(() => {
                    this.load(this.member.organization.id, this.member.id, true)
                })
        }
    }
})

export default useMemberStore
