import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyTargetListsStore from "@/stores/me/target-lists"
import useTargetsListSuggestionsStore from "@/stores/targets/list-suggestions"
import useTargetsListStreamStore from "@/stores/targets/list-stream"

export const useTargetsModalsListMergeStore = defineForm({
    id: 'targetsModalsListMerge',

    state: () => ({
        isListDetail: false,
    }),

    inputs: () => ({
        deleteSelected: false,
        sourceList: {},
        targetList: {}
    }),

    submitRequest() {
        trackEvent('targets', 'target-lists-merged')

        return api.route('me lists merge', { id: this.inputs.targetList.id })
            .formData({
                _method: 'post',
                remove: this.inputs.deleteSelected ? 1 : 0,
                sourceList: this.inputs.sourceList.id
            })
            .post()
    },

    async onResponse(res) {
        await useMyTargetListsStore().reload()

        if (this.isListDetail) {
            await useTargetsListStreamStore().load()
            useTargetsListSuggestionsStore().reload()
        }

        useModal().hide('targets-list-merge')
    },

    actions: {
        open(list = null, isListDetail = false) {
            this.reset()
            this.isListDetail = isListDetail
            this.inputs.targetList = list
            useModal().show('targets-list-merge')
        },

        cancel() {
            useModal().hide('targets-list-merge')
        },

        select(list) {
            this.inputs.sourceList = list
        }
    }
})

export default useTargetsModalsListMergeStore
