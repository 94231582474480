import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentYoutubeVideoReactionsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentYoutubeVideoReactionsAnalysis',
    name: 'content.youtube-video-reactions',
    title: 'Reactions',
    color: 'blue-600'
})

export default useTargetsTargetContentYoutubeVideoReactionsAnalysisStore
