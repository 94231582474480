<template>
    <div>
        <board-header></board-header>

        <div class="px-8 w-full mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.dashboards" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to your new Board!</h1>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Widgets</h2>
                        <p class="text-justify my-2">Click on the "New Widget" button to add widgets. Various types of widgets are available, like analyses, streams or keyword clouds, with more coming soon.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Interact</h2>
                        <p class="text-justify my-2">Interact with the widgets by hovering on the analyses to see the detailed value. You can also follow the links in some of the widgets, like content stream.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Edit</h2>
                        <p class="text-justify my-2">Click on the "Edit" button to rearrange or resize your widgets. Drag the widgets to change their position or drag the bottom-right corner to resize the widget.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can try to <span class="font-medium">add your first widget</span>.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button :action="edit" color="green" size="lg" icon="plus-square">
                        Edit
                    </ui-button>
                </div>
            </div>

            <ui-async :guards="contents">
                <grid-layout
                    v-model:layout="contents"
                    :col-num="100"
                    :row-height="1"
                    :is-draggable="isEditing"
                    :is-resizable="isEditing"
                    :is-mirrored="false"
                    :vertical-compact="false"
                    :margin="[10, 10]"
                    :use-css-transforms="true"
                    @layout-ready="layoutUpdated"
                    @layout-updated="layoutUpdated"
                    ref="gridLayout"
                    v-if="contents && contents.length">
                    <grid-item
                        v-for="widget in contents" :x="widget.x" :y="widget.y" :w="widget.w" :h="widget.h" :min-w="widget.type.minW" :min-h="widget.type.minH" :i="widget.i" :static="widget.type.inline && selectedWidgetId === widget.$id" :key="widget.i">

                        <analysis-content-performance-widget :store="widget" :dashboard-store="boardStore" v-if="widget.type.id == 'analysis-content-performance'"></analysis-content-performance-widget>
                        <analysis-content-breakdown-by-source-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-source'"></analysis-content-breakdown-by-source-widget>
                        <analysis-content-breakdown-by-group-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-group'"></analysis-content-breakdown-by-group-widget>
                        <analysis-content-publish-times-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-publish-times'"></analysis-content-publish-times-widget>
                        <analysis-content-pull-push-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-pull-push'"></analysis-content-pull-push-widget>
                        <analysis-content-sentiment-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-sentiment'"></analysis-content-sentiment-widget>
                        <analysis-content-top-platforms-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-top-platforms'"></analysis-content-top-platforms-widget>
                        <analysis-content-keywords-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-content-keywords'"></analysis-content-keywords-widget>
                        <analysis-target-publish-times-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-target-publish-times'"></analysis-target-publish-times-widget>
                        <analysis-target-audience-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'analysis-target-audience'"></analysis-target-audience-widget>
                        <keywords-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'keywords'"></keywords-widget>
                        <heading-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'heading'"></heading-widget>
                        <stream-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'stream'"></stream-widget>
                        <image-widget :store="widget" :dashboard-store="boardStore" v-else-if="widget.type.id == 'image'"></image-widget>
                        <text-widget :store="widget" :dashboard-store="boardStore" :editable="true" v-else-if="widget.type.id == 'text'"></text-widget>

                    </grid-item>
                </grid-layout>
            </ui-async>

            <board-add-widget v-if="isEditing"></board-add-widget>

            <dashboard-expanded-widget :dashboard-store="boardStore"></dashboard-expanded-widget>
        </div>

        <Teleport to="#root">
            <board-edit-modal v-if="isEditing"></board-edit-modal>
            <add-analysis :dashboard-store="boardStore"></add-analysis>
        </Teleport>
    </div>
</template>

<script>
import AddAnalysis from '@/components/dashboards/modals/add-analysis'
import DashboardExpandedWidget from './widgets/expanded-widget'
import BoardHeader from './board-header'
import BoardAddWidget from './board-add-widget'
import BoardEditModal from './modals/board-edit'
import AnalysisContentPerformanceWidget from './widgets/analysis-content-performance'
import AnalysisContentBreakdownBySourceWidget from './widgets/analysis-content-breakdown-by-source'
import AnalysisContentBreakdownByGroupWidget from './widgets/analysis-content-breakdown-by-group'
import AnalysisContentPublishTimesWidget from './widgets/analysis-content-publish-times'
import AnalysisContentPullPushWidget from './widgets/analysis-content-pull-push'
import AnalysisContentSentimentWidget from './widgets/analysis-content-sentiment'
import AnalysisContentTopPlatformsWidget from './widgets/analysis-content-top-platforms'
import AnalysisContentKeywordsWidget from './widgets/analysis-content-keywords'
import AnalysisTargetPublishTimesWidget from './widgets/analysis-target-publish-times'
import AnalysisTargetAudienceWidget from './widgets/analysis-target-audience'
import KeywordsWidget from './widgets/keywords'
import HeadingWidget from './widgets/heading'
import StreamWidget from './widgets/stream'
import ImageWidget from './widgets/image'
import TextWidget from './widgets/text'

import installVueGrid from 'vue-grid-layout'
let vueGrid = { component(name, component) { this[name] = component } }
installVueGrid(vueGrid)

import useBoardStore from '@/stores/dashboards/board'

import {mapActions, mapState, mapStores, mapWritableState} from 'pinia'

export default {
    components: {
        AddAnalysis,
        DashboardExpandedWidget,
        BoardHeader,
        BoardAddWidget,
        BoardEditModal,
        GridLayout: vueGrid['grid-layout'],
        GridItem: vueGrid['grid-item'],
        AnalysisContentPerformanceWidget,
        AnalysisContentBreakdownBySourceWidget,
        AnalysisContentBreakdownByGroupWidget,
        AnalysisContentPublishTimesWidget,
        AnalysisContentPullPushWidget,
        AnalysisContentSentimentWidget,
        AnalysisContentTopPlatformsWidget,
        AnalysisContentKeywordsWidget,
        AnalysisTargetPublishTimesWidget,
        AnalysisTargetAudienceWidget,
        KeywordsWidget,
        HeadingWidget,
        StreamWidget,
        ImageWidget,
        TextWidget
    },

    computed: {
        ...mapWritableState(useBoardStore, [
            'contents'
        ]),

        ...mapState(useBoardStore, [
            'board',
            'contents',
            'isEditing',
            'selectedWidgetId'
        ]),

        ...mapStores(useBoardStore),

        isShowingWelcomeMessage() {
            return this.contents && ! this.contents.length
        }
    },

    methods: {
        ...mapActions(useBoardStore, [ 'edit' ]),

        layoutUpdated() { useBoardStore().layoutUpdated() }
    }
}
</script>
