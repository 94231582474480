<template>
    <div class="rounded bg-white shadow hover:shadow-lg">
        <div class="flex items-center relative p-4 pl-5 pb-3">
            <div class="shrink-0 relative">
                <img class="rounded-full w-8 h-8 shadow" :src="workspace.avatarUrl" v-if="workspace.avatarUrl">
                <ui-avatar :name="workspace.name" class="w-8 h-8" v-else></ui-avatar>
            </div>

            <div class="flex-1 min-w-0 px-4">
                <h1 class="text-lg font-semibold truncate leading-tight">
                    <span v-tooltip="workspace.name">{{workspace.name}}</span>
                </h1>
                <div class="text-gray-800 text-xs leading-tight flex items-center">
                    {{workspace.description}}
                </div>
                <div class="flex items-center space-x-2 mt-1">
                    <div class="flex -space-x-0.5 relative z-0 overflow-hidden">
                        <img class="relative inline-block h-5 w-5 rounded-full ring-2 ring-white" :class="`z-${50 - index * 10}`" :src="user.avatarUrl" v-tooltip="user.name" :key="user.id" v-for="user, index in workspace.users.slice(0, 6)">
                    </div>
                    <div class="font-medium text-xs text-gray-800" v-if="workspace.users.length > 6">
                        +{{workspace.users.length - 6}} users
                    </div>
                </div>
            </div>

            <div class="flex items-center space-x-0.5 shrink-0">
                <button class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full hover:bg-blue-50 transition w-6 h-6 text-sm" v-tooltip="'Edit Workspace'" @click="editWorkspace(workspace)" v-if="workspace.owned">
                    <ui-icon name="edit"></ui-icon>
                </button>

                <button class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full hover:bg-blue-50 transition w-6 h-6 text-sm" v-tooltip="'Manage Users'" @click="manageUsers(workspace)">
                    <ui-icon name="users"></ui-icon>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { mapActions } from 'pinia'

export default {
    props: [ 'workspace' ],

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'editWorkspace', 'manageUsers' ])
    }
}
</script>
