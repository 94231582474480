<template>
    <ui-modal name="message-reminder-create" title="Set a Reminder" :max-width="320" @opened="opened" @closed="closed">
        <div class="w-full flex justify-center">
            <ui-date-picker v-if="active" inline :show-second="false" type="datetime" v-model="selectedDateValue"></ui-date-picker>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="green" :action="() => setReminderForActiveMessage(selectedDateValue)">
                Create
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useThreadStore from '@/admin/stores/support/thread'

import { mapActions, mapState } from 'pinia'

export default {
    data() {
        return {
            active: false,
            selectedDateValue: null
        }
    },

    methods: {
        opened() {
            // This resets the date picker (if it's in the hour/minute picking stage)
            this.active = true

            this.selectedDateValue = null
        },

        closed() {
            // This resets the date picker (if it's in the hour/minute picking stage)
            this.active = false
        },

        ...mapActions(useThreadStore, [
            'setReminderForActiveMessage'
        ])
    }
}
</script>
