<template>
    <div class="flex items-center justify-between px-4 pt-3">
        <h1 class="text-xl font-semibold">Support</h1>
    </div>

    <navigation-section
        :items="topNavigation">
    </navigation-section>
</template>

<script>
export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.overview', to: { name: 'support' } },
            { name: 'Messages', icon: 'chat', to: { name: 'support.threads' } }
        ]
    })
}
</script>
