import useTargetsTargetContentFacebookPostActivityAnalysisStore from './content-facebook-post-activity-analysis'
import useTargetsTargetContentFacebookPostCommentsAnalysisStore from './content-facebook-post-comments-analysis'
import useTargetsTargetContentFacebookPostReactionsAnalysisStore from './content-facebook-post-reactions-analysis'
import useTargetsTargetContentFacebookPostSharesStore from './content-facebook-post-shares'
import useTargetsTargetContentFacebookPostSharesAnalysisStore from './content-facebook-post-shares-analysis'
import useTargetsTargetContentFacebookPostViewsAnalysisStore from './content-facebook-post-views-analysis'
import useTargetsTargetContentVKontaktePostActivityAnalysisStore from './content-vkontakte-post-activity-analysis'
import useTargetsTargetContentVKontaktePostCommentsAnalysisStore from './content-vkontakte-post-comments-analysis'
import useTargetsTargetContentVKontaktePostLikesAnalysisStore from './content-vkontakte-post-likes-analysis'
import useTargetsTargetContentVKontaktePostRepostsAnalysisStore from './content-vkontakte-post-reposts-analysis'
import useTargetsTargetContentVKontaktePostViewsAnalysisStore from './content-vkontakte-post-views-analysis'
import useTargetsTargetContentYoutubeVideoActivityAnalysisStore from './content-youtube-video-activity-analysis'
import useTargetsTargetContentYoutubeVideoCommentsAnalysisStore from './content-youtube-video-comments-analysis'
import useTargetsTargetContentYoutubeVideoReactionsAnalysisStore from './content-youtube-video-reactions-analysis'
import useTargetsTargetContentYoutubeVideoViewsAnalysisStore from './content-youtube-video-views-analysis'
import useTargetsTargetContentStreamStore from './content-stream'

import { content } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export const useTargetsTargetContentStore = defineStore({
    id: 'targetsTargetContent',

    state: () => ({
        content: null
    }),

    actions: {
        async load(contentId) {
            useTargetsTargetContentStreamStore().initialize()

            this.content = await content().find(contentId)

            if (this.content.type == 'facebook-post') {
                let facebookPostActivityAnalysis = useTargetsTargetContentFacebookPostActivityAnalysisStore()
                facebookPostActivityAnalysis.setContent(this.content)
                facebookPostActivityAnalysis.loadSeries()

                let facebookPostCommentsAnalysis = useTargetsTargetContentFacebookPostCommentsAnalysisStore()
                facebookPostCommentsAnalysis.filterAppends = { modelId: this.content.modelId }
                facebookPostCommentsAnalysis.load()

                let facebookPostReactionsAnalysis = useTargetsTargetContentFacebookPostReactionsAnalysisStore()
                facebookPostReactionsAnalysis.filterAppends = { modelId: this.content.modelId }
                facebookPostReactionsAnalysis.load()

                let facebookPostSharesAnalysis = useTargetsTargetContentFacebookPostSharesAnalysisStore()
                facebookPostSharesAnalysis.filterAppends = { modelId: this.content.modelId }
                facebookPostSharesAnalysis.load()

                let facebookPostViewsAnalysis = useTargetsTargetContentFacebookPostViewsAnalysisStore()
                facebookPostViewsAnalysis.filterAppends = { modelId: this.content.modelId }
                facebookPostViewsAnalysis.load()

                useTargetsTargetContentFacebookPostSharesStore().load(this.content)
            } else if (this.content.type == 'vkontakte-post') {
                let vkontaktePostActivityAnalysis = useTargetsTargetContentVKontaktePostActivityAnalysisStore()
                vkontaktePostActivityAnalysis.setContent(this.content)
                vkontaktePostActivityAnalysis.loadSeries()

                let vkontaktePostCommentsAnalysis = useTargetsTargetContentVKontaktePostCommentsAnalysisStore()
                vkontaktePostCommentsAnalysis.filterAppends = { modelId: this.content.modelId }
                vkontaktePostCommentsAnalysis.load()

                let vkontaktePostLikesAnalysis = useTargetsTargetContentVKontaktePostLikesAnalysisStore()
                vkontaktePostLikesAnalysis.filterAppends = { modelId: this.content.modelId }
                vkontaktePostLikesAnalysis.load()

                let vkontaktePostRepostsAnalysis = useTargetsTargetContentVKontaktePostRepostsAnalysisStore()
                vkontaktePostRepostsAnalysis.filterAppends = { modelId: this.content.modelId }
                vkontaktePostRepostsAnalysis.load()

                let vkontaktePostViewsAnalysis = useTargetsTargetContentVKontaktePostViewsAnalysisStore()
                vkontaktePostViewsAnalysis.filterAppends = { modelId: this.content.modelId }
                vkontaktePostViewsAnalysis.load()
            } else if (this.content.type == 'youtube-video') {
                let youtubeVideoActivityAnalysis = useTargetsTargetContentYoutubeVideoActivityAnalysisStore()
                youtubeVideoActivityAnalysis.setContent(this.content)
                youtubeVideoActivityAnalysis.loadSeries()

                let youtubeVideoCommentsAnalysis = useTargetsTargetContentYoutubeVideoCommentsAnalysisStore()
                youtubeVideoCommentsAnalysis.filterAppends = { modelId: this.content.modelId }
                youtubeVideoCommentsAnalysis.load()

                let youtubeVideoReactionsAnalysis = useTargetsTargetContentYoutubeVideoReactionsAnalysisStore()
                youtubeVideoReactionsAnalysis.filterAppends = { modelId: this.content.modelId }
                youtubeVideoReactionsAnalysis.load()

                let youtubeVideoViewsAnalysis = useTargetsTargetContentYoutubeVideoViewsAnalysisStore()
                youtubeVideoViewsAnalysis.filterAppends = { modelId: this.content.modelId }
                youtubeVideoViewsAnalysis.load()
            }
        }
    }
})

export default useTargetsTargetContentStore
