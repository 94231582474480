import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMySearchTopicsStore from '@/stores/me/search-topics'

export const useContentModalsTopicEditStore = defineForm({
    id: 'contentModalsTopicEdit',

    inputs: () => ({
        topic: {},
        name: null,
        query: null,
        language: null
    }),

    submitRequest() {
        if (! this.inputs.topic.id) {
            trackEvent('content', 'new-topic-saved')
        }

        let route = this.inputs.topic.id
            ? [ 'me topics update', [ this.inputs.topic.id ] ]
            : [ 'me topics store' ]

        return api.route(...route)
            .formData({
                _method: this.inputs.topic.id ? 'put' : 'post',
                name: this.inputs.name,
                query: this.inputs.query,
                language: this.inputs.language
            })
            .post()
    },

    async onResponse(res) {
        await useMySearchTopicsStore().reload()

        useModal().hide('content-topic-edit')

        this.fulfillPromise({
            topic: res.data
        })
    },

    actions: {
        open(topic = {}) {
            this.reset()

            this.inputs.topic = topic
            this.inputs.name = topic.name
            this.inputs.query = topic.query
            this.inputs.language = topic.language

            useModal().show('content-topic-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('content-topic-edit')

            this.fulfillPromise()
        },

        async delete() {
            await useMySearchTopicsStore().delete(this.inputs.topic)

            useModal().hide('content-topic-edit')

            this.fulfillPromise()
        }
    }
})

export default useContentModalsTopicEditStore
