<template>
    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: perspective.id } }" class="block" v-if="mode == 'card'">
        <div class="rounded bg-white shadow hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4 pb-3">
                <ui-avatar type="perspective" :name="perspective.name" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{ perspective.name }}
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        Perspective
                        ·
                        <span>{{ filtersCount }} filters</span>
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :perspective="perspective" plain></contextual-menu>
                </div>
            </div>

            <div class="relative mt-2" :class="{ 'h-16': pointStats, 'h-32': ! pointStats }" v-if="cardsStore.layoutSettings.showCharts">
                <div class="h-full flex flex-col items-center justify-center text-sm text-gray-700" v-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>

                <analysis class="h-16" :options="analysisOptions" ref="analysis" @mousemove="chartMouseMove" @mouseleave="chartMouseLeave" v-else-if="shouldRenderChart"></analysis>

                <div class="absolute -bottom-px z-10" :style="pointMarkerStyle" v-if="pointMarkerStyle">
                    <div class="w-0 h-0 absolute left-0 bottom-px border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-gray-200"></div>
                    <div class="w-0 h-0 absolute left-0 bottom-0 border-r-[5px] border-b-[6px] border-l-[5px] border-transparent border-b-white"></div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-16 border-t border-gray-100 rounded-b" v-if="cardsStore.layoutSettings.showCharts && pointStats">
                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Content
                    </div>
                    <div class="font-semibold text-sm">
                        {{pointStats.publishedTotal !== null && pointStats.publishedTotal !== undefined ? $number(pointStats.publishedTotal) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': pointStats.publishedPerWeek > perspective.metrics.publishedPerWeek, 'text-red-600': pointStats.publishedPerWeek < perspective.metrics.publishedPerWeek }">
                        {{pointStats.publishedPerWeek !== null && pointStats.publishedPerWeek !== undefined ? $number(Math.round(pointStats.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters.
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': pointStats.interactionsPerWeek > perspective.metrics.interactionsPerWeek, 'text-red-600': pointStats.interactionsPerWeek < perspective.metrics.interactionsPerWeek }">
                        {{pointStats.interactionsPerWeek !== null && pointStats.interactionsPerWeek !== undefined ? $number(Math.round(pointStats.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate
                    </div>
                    <div class="font-semibold text-sm" :class="{ 'text-green-600': pointStats.interactionsPerWeek / pointStats.publishedPerWeek > perspective.metrics.interactionsPerWeek / perspective.metrics.publishedPerWeek, 'text-red-600': pointStats.interactionsPerWeek / pointStats.publishedPerWeek < perspective.metrics.interactionsPerWeek / perspective.metrics.publishedPerWeek }">
                        {{pointStats.interactionsPerWeek !== null && pointStats.interactionsPerWeek !== undefined && pointStats.publishedPerWeek !== null && pointStats.publishedPerWeek !== undefined && pointStats.publishedPerWeek != 0 ? $number(Math.round(pointStats.interactionsPerWeek / pointStats.publishedPerWeek * 100) / 100) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-16 bg-gray-50 border-t border-gray-75 rounded-b">
                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Content
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.publishedTotal !== null && perspective.metrics.publishedTotal !== undefined ? $number(perspective.metrics.publishedTotal) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.publishedPerWeek !== null && perspective.metrics.publishedPerWeek !== undefined ? $number(Math.round(perspective.metrics.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.interactionsPerWeek !== null && perspective.metrics.interactionsPerWeek !== undefined ? $number(Math.round(perspective.metrics.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div class="flex-1">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.interactionsPerWeek !== null && perspective.metrics.interactionsPerWeek !== undefined && perspective.metrics.publishedPerWeek !== null && perspective.metrics.publishedPerWeek !== undefined && perspective.metrics.publishedPerWeek != 0 ? $number(Math.round(perspective.metrics.interactionsPerWeek / perspective.metrics.publishedPerWeek * 100) / 100) + '%' : '-'}}
                    </div>
                </div>
            </div>

            <div class="flex items-center relative px-4 h-8 bg-gray-50 border-t border-gray-75 rounded-b space-x-3 text-xs text-gray-500" v-if="hasNotificationsEnabled || perspective.recent || perspective.pinned">
                <span class="flex items-center space-x-1" v-if="perspective.recent">
                    <ui-icon name="time"></ui-icon>
                    <span>Recent</span>
                </span>

                <span class="flex items-center space-x-1" v-if="perspective.pinned">
                    <ui-icon name="pin-off"></ui-icon>
                    <span>Pinned</span>
                </span>

                <span class="flex items-center space-x-1" v-if="hasNotificationsEnabled">
                    <ui-icon name="bell"></ui-icon>
                    <span>Notifications</span>
                </span>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(perspective)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>

    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: perspective.id } }" class="block" v-else-if="mode == 'row'">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <ui-avatar type="perspective" :name="perspective.name" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{ perspective.name }}
                    </h1>
                    <div class="text-gray-800 text-xs truncate leading-tight">
                        Perspective
                        ·
                        <span>{{ filtersCount }} filters</span>
                    </div>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <contextual-menu :perspective="perspective" plain></contextual-menu>
                </slot>
            </div>

            <div class="flex items-center w-64 mr-6 relative" v-if="cardsStore.layoutSettings.showCharts">
                <div class="h-full w-full flex flex-col items-center justify-center text-center text-sm text-gray-700" v-if="! analysisSeries">
                    <div class="font-semibold">Analysis in progress.</div>
                    <div class="text-xs">Activity chart will be available shortly.</div>
                </div>
                <analysis class="h-full w-full" :options="analysisOptions" ref="analysis" v-else-if="shouldRenderChart"></analysis>
            </div>

            <div class="flex items-center space-x-5 px-4 w-96 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Content
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.publishedTotal !== null && perspective.metrics.publishedTotal !== undefined ? $number(perspective.metrics.publishedTotal) : '-'}}
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Activity ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.publishedPerWeek !== null && perspective.metrics.publishedPerWeek !== undefined ? $number(Math.round(perspective.metrics.publishedPerWeek)) : '-'}}
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Inters. ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.interactionsPerWeek !== null && perspective.metrics.interactionsPerWeek !== undefined ? $number(Math.round(perspective.metrics.interactionsPerWeek)) : '-'}}
                    </div>
                </div>

                <div>
                    <div class="text-2xs text-gray-700 leading-tight">
                        Int. Rate ⌀
                    </div>
                    <div class="font-semibold text-sm">
                        {{perspective.metrics.interactionsPerWeek !== null && perspective.metrics.interactionsPerWeek !== undefined && perspective.metrics.publishedPerWeek !== null && perspective.metrics.publishedPerWeek !== undefined && perspective.metrics.publishedPerWeek != 0 ? $number(Math.round(perspective.metrics.interactionsPerWeek / perspective.metrics.publishedPerWeek * 100) / 100) + '%' : '-'}}
                    </div>
                </div>

                <div class="flex items-center justify-end flex-1 space-x-2 text-gray-500">
                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Notifications enabled'" v-if="hasNotificationsEnabled">
                        <ui-icon name="bell"></ui-icon>
                    </span>

                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Recently used'" v-if="perspective.recent">
                        <ui-icon name="time"></ui-icon>
                    </span>

                    <span class="w-6 h-6 rounded-full inline-flex items-center justify-center" v-tooltip="'Pinned'" v-if="perspective.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(perspective)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import ContextualMenu from '@/components/content/contextual-menus/perspective'

import { Chart as Analysis } from 'highcharts-vue'

import { colorHash } from '@/helpers'
import { utcTimestamp } from '@/helpers/datetime'

import { group } from 'd3-array'
import { utcMondays } from 'd3-time'
import { format, startOfWeek, sub, parseISO } from 'date-fns'
import debounce from 'just-debounce-it'

export default {
    props: { cardsStore: {}, mode: { default: 'card' }, perspective: {} },

    components: { Analysis, ContextualMenu },

    data: () => ({
        shouldRenderChart: false,
        intersectionObserver: null,
        selectedPoint: 4
    }),

    computed: {
        analysisOptions() {
            return {
                chart: {
                    backgroundColor: false,
                    spacing: [0, 15, 0, 15]
                },
                boost: {
                    useGPUTranslations: true
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: 'datetime',
                    visible: false,
                    min: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 })),
                    max: utcTimestamp(sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 1 }))
                },
                yAxis: [
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.perspective.activitySeries,
                            align: 'left',
                            padding: 0,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.perspective.activitySeries ? null : 10
                    },
                    {
                        title: { text: '' },
                        labels: {
                            enabled: !! this.perspective.activitySeries,
                            align: 'right',
                            padding: 0,
                            x: 0,
                            y: 14,
                            zIndex: 1,
                            style: { color: '#b8c3c9', fontSize: '9px' }
                        },
                        opposite: true,
                        tickPixelInterval: 50,
                        gridLineColor: '#f0f3f5',
                        max: this.perspective.activitySeries ? null : 10
                    }
                ],
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    distance: 0,
                    padding: 4,
                    formatter: function () { return `<span style="font-size:10px;font-weight:600;">${format(this.x, 'yyyy-MM-dd')}</span>` },
                    shadow: false,
                    shape: 'rect',
                    style: { color: '#fff', textAlign: 'center' }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            fillColor: '#FFFFFF',
                            radius: 4,
                            lineWidth: 2,
                            lineColor: null,
                        }
                    },
                    series: {
                        states: {
                            inactive: {
                                enabled: false
                            }
                        }
                    }
                },
                series: [
                    {
                        type: 'line',
                        name: 'Interactions',
                        color: this.analysisColor,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[1],
                        yAxis: 0
                    },
                    {
                        type: 'column',
                        name: 'Activity',
                        color: this.analysisColor,
                        opacity: 0.4,
                        fillOpacity: 0.1,
                        data: this.analysisSeries[0],
                        yAxis: 1
                    }
                ],
                credits: {
                    enabled: false
                }
            }
        },

        analysisColor() {
            return this.perspective.activitySeries
                ? colorHash(this.perspective.name)
                : '#e0e5e8'
        },

        analysisSeries() {
            if (! (this.perspective.activitySeries?.[0] instanceof Array)) return

            return this.perspective.activitySeries.map(series => {
                let data = series.map(({x, y}) => ({
                    x: parseISO(x), y: y ? Math.round(parseFloat(y) * 10000) / 10000 : 0
                }))

                let m = group(data, ({x, y}) => x)
                let timeRange = utcMondays(
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 5 }),
                    sub(startOfWeek(new Date, {weekStartsOn: 1}), { weeks: 0 }),
                    1
                )

                return timeRange.map(date => m.get(date)?.[0] || { x: date, y: 0 })
            })
        },

        pointStats() {
            if (! this.analysisSeries) return

            return {
                publishedTotal: this.perspective?.metrics?.publishedTotal,
                publishedPerWeek: this.analysisSeries?.[1]?.[this.selectedPoint]?.y,
                interactionsPerWeek: this.analysisSeries?.[0]?.[this.selectedPoint]?.y
            }
        },

        pointMarkerStyle() {
            if (! this.analysisSeries) return

            return `left: calc(10px + (${this.selectedPoint} * 2 + 1) * ((100% - 30px) / 10));`
        },

        filtersCount() {
            return Object.keys(this.perspective.filters).length
        },

        hasNotificationsEnabled() {
            return this.perspective.subscriptions.some(s => s.enabled && Object.keys(s.channels).length)
        }
    },

    methods: {
        chartMouseMove(ev) {
            this.updateSelectedPoint(ev.offsetX, ev.currentTarget) // this is intentionally done in two steps to preserve the currentTarget reference
        },

        chartMouseLeave() {
            this.selectedPoint = 4
        },

        updateSelectedPoint: debounce(function(offsetX, currentTarget) {
            let chartContainerWidth = currentTarget.offsetWidth

            if (offsetX <= 15) return this.selectedPoint = 0
            if (offsetX >= chartContainerWidth - 15) return this.selectedPoint = 4

            this.selectedPoint = Math.floor((offsetX - 15) / (chartContainerWidth - 30) * 5)
        }, 5)
    },

    mounted() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            if (entries.some(e => e.isIntersecting)) {
                this.shouldRenderChart = true
                this.intersectionObserver.disconnect()
            }
        })
        this.intersectionObserver.observe(this.$el)
    },

    unmounted() {
        this.intersectionObserver?.disconnect()
    }
}
</script>
