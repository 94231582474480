<template>
    <ui-dropdown align="right" width="w-auto">
        <template v-slot:trigger>
            <div class="flex items-center space-x-2">
                <span class="text-gray-700 text-sm font-medium">Granularity</span>
                <div class="inline-flex items-center px-3 pr-2 h-7 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                    <span class="font-medium capitalize">{{ store.granularity }}</span>
                    <ui-icon name="select" class="ml-2"></ui-icon>
                </div>
            </div>
        </template>

        <template v-slot:content>
            <div class="w-24">
                <ui-dropdown-link v-for="granularity in store.granularities ?? [ 'hour', 'day', 'week', 'month', 'year' ]" :key="granularity" @click="store.setGranularity(granularity)">
                    By {{ granularity }}
                </ui-dropdown-link>
            </div>
        </template>
    </ui-dropdown>
</template>
