import useWorkspacesStore from '@/admin/stores/accounts/workspaces'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationWorkspacesPage = defineStore({
    id: 'accountsOrganizationsOrganizationWorkspacesPage',

    state: () => ({
        search: ''
    }),

    actions: {
        beforeEnter(to) {
            useWorkspacesStore().initialize(to.params.organizationId, true)
        }
    }
})

export default useAccountsOrganizationsOrganizationWorkspacesPage
