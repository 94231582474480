import { defineStore } from 'pinia'

import useMyAppsStore from '@/stores/me/apps';

export const useSettingsAppsPage = defineStore({
    id: 'settingsAppsPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'Apps | Settings'
    },

    actions: {
        beforeEnter() {
            useMyAppsStore().initialize()
        }
    }
})

export default useSettingsAppsPage
