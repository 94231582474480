<template>
    <div class="px-8">
        <Teleport to="#header-bottom">
            <stream-toolbar :store="targetsTargetMembersStreamStore"></stream-toolbar>
        </Teleport>

        <ui-stream :store="targetsTargetMembersStreamStore">
            <template v-slot:item="{ item, layout }">
                <target-card :target="item" :cards-store="targetsListDummyCardsStore" :mode="layout.id == 'table' ? 'row' : 'card'"></target-card>
            </template>

            <template v-slot:placeholder="{ layout }">
                <target-placeholder-card v-if="layout.id == 'grid'"></target-placeholder-card>
                <target-placeholder-row v-if="layout.id == 'table'"></target-placeholder-row>
            </template>
        </ui-stream>
    </div>
</template>

<script>
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import TargetCard from '@/components/targets/stream-cards/target'
import TargetPlaceholderCard from '@/components/targets/stream-cards/target-placeholder'
import TargetPlaceholderRow from '@/components/targets/stream-cards/target-placeholder-row'
import UiStream from '@/components/ui/stream/stream'

import useTargetsListDummyCardsStore from '@/stores/targets/list-dummy-cards'
import useTargetsTargetMembersStreamStore from '@/stores/targets/target/members-stream'

import { mapStores } from 'pinia'

export default {
    components: { StreamToolbar, TargetCard, TargetPlaceholderCard, TargetPlaceholderRow, UiStream },

    computed: {
        ...mapStores(useTargetsListDummyCardsStore, useTargetsTargetMembersStreamStore)
    }
}
</script>
