<template>
    <ui-dropdown @opened="reset" align="right" width="w-auto" ref="dropdown">
        <template v-slot:trigger>
            <div class="flex items-center space-x-2">
                <span class="text-gray-700 text-sm font-medium">Date Range</span>
                <div class="inline-flex items-center px-3 pr-2 h-7 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                    <span v-if="store.date.type == 'in-range'">
                        <span class="font-medium">
                            {{ $date(store.date.date.gte) }} - {{ $date(store.date.date.lte) }}
                        </span>
                    </span>

                    <span v-else-if="store.date.type == 'before'">
                        <span class="font-medium">Before {{ $date(store.date.date.lte) }}</span>
                    </span>

                    <span v-else-if="store.date.type == 'after'">
                        <span class="font-medium">After {{ $date(store.date.date.gte) }}</span>
                    </span>

                    <span v-else-if="store.date.type == 'past'">
                        <span class="font-medium"
                            v-if="store.date.date.past == 1 && store.date.date.unit == 'weeks'">Past week</span>
                        <span class="font-medium"
                            v-else-if="store.date.date.past == 1 && store.date.date.unit == 'months'">Past month</span>
                        <span class="font-medium"
                            v-else-if="store.date.date.past == 1 && store.date.date.unit == 'years'">Past year</span>

                        <span class="font-medium" v-else>Past {{ store.date.date.past }} {{ store.date.date.unit }}</span>
                    </span>

                    <ui-icon name="select" class="ml-2"></ui-icon>
                </div>
            </div>
        </template>

        <template v-slot:content="{ close }">
            <div v-if="selectedType == 'before'" @click.stop class="p-4">
                <label class="block text-xs font-medium text-gray-700 uppercase">
                    Before
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline v-model="selectedDateValue"></ui-date-picker>
                </div>

                <div class="flex justify-end mt-2">
                    <ui-button @click="store.setDate({ type: 'before', date: { lte: selectedDateValue } }); close()" color="blue">
                        Apply
                    </ui-button>
                </div>
            </div>

            <div v-else-if="selectedType == 'after'" @click.stop class="p-4">
                <label class="block text-xs font-medium text-gray-700 uppercase">
                    After
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline v-model="selectedDateValue"></ui-date-picker>
                </div>

                <div class="flex justify-end mt-2">
                    <ui-button @click="store.setDate({ type: 'after', date: { gte: selectedDateValue } }); close()" color="blue">
                        Apply
                    </ui-button>
                </div>
            </div>

            <div v-else-if="selectedType == 'in-range'" @click.stop class="p-4">
                <label class="block text-xs font-medium text-gray-700 uppercase">
                    In range
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline range v-model="selectedDateValue"></ui-date-picker>
                </div>

                <div class="flex justify-end mt-2">
                    <ui-button @click="store.setDate({ type: 'in-range', date: { gte: selectedDateValue[0], lte: selectedDateValue[1] } }); close()" color="blue">
                        Apply
                    </ui-button>
                </div>
            </div>

            <div v-if="selectedType == 'past-days'" @click.stop class="w-48 p-4">
                <label class="block mb-2 text-xs font-medium text-gray-700 uppercase">
                    Past X days
                </label>

                <div class="flex items-center">
                    <ui-input class="flex-1" type="number" min="1" v-model.number="selectedDateValue"></ui-input>
                </div>

                <div class="flex justify-end mt-4">
                    <ui-button @click="store.setDate({ type: 'past', date: { past: selectedDateValue, unit: 'days' } }); close()" color="blue">
                        Apply
                    </ui-button>
                </div>
            </div>

            <div v-else-if="!selectedType" class="w-48">
                <ui-dropdown-link
                    :selected="store.date.type == 'past' && store.date.date.past == 1 && store.date.date.unit == 'weeks'"
                    @click="store.setDate({ type: 'past', date: { past: 1, unit: 'weeks' } })">
                    Past week
                </ui-dropdown-link>

                <ui-dropdown-link
                    :selected="store.date.type == 'past' && store.date.date.past == 1 && store.date.date.unit == 'months'"
                    @click="store.setDate({ type: 'past', date: { past: 1, unit: 'months' } })">
                    Past month
                </ui-dropdown-link>

                <ui-dropdown-link
                    :selected="store.date.type == 'past' && store.date.date.past == 1 && store.date.date.unit == 'years'"
                    @click="store.setDate({ type: 'past', date: { past: 1, unit: 'years' } })">
                    Past year
                </ui-dropdown-link>

                <ui-dropdown-link :selected="store.date.type == 'past' && store.date.date.past !== 1"
                    @click="selectedType = 'past-days'" keep-open>
                    Past X days...
                </ui-dropdown-link>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link :selected="store.date.type == 'before'"
                    @click="selectedType = 'before'" keep-open>
                    Before...
                </ui-dropdown-link>

                <ui-dropdown-link :selected="store.date.type == 'after'"
                    @click="selectedType = 'after'" keep-open>
                    After...
                </ui-dropdown-link>

                <ui-dropdown-link :selected="store.date.type == 'in-range'"
                    @click="selectedType = 'in-range'" keep-open>
                    In range...
                </ui-dropdown-link>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    data: () => ({
        selectedType: null,
        selectedDateValue: null
    }),

    methods: {
        reset() {
            this.selectedType = null
            this.selectedDateValue = null
        }
    }
}
</script>
