import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'

export const useMyPerspectivesStore = defineStore({
    id: 'my-perspectives',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me perspectives').query({ type: 'juno' }).get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(perspective, data) {
            await api.route('me perspectives update', perspective.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async delete(perspective, force = false) {
            await api.route('me perspectives delete', perspective.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .forbidden(async (res) => {
                    if (res.json.error == 'has-dependents') {
                        return useDeleteConfirmationModal().open('Perspective', res.json.dependents)
                            .then(() => this.delete(perspective, true))
                    }
                })
                .res()

            return this.reload()
        },

        async safeDelete(perspective) {
            try {
                return await this.delete(perspective)
            } catch (e) {}
        },

        async duplicate(perspective) {
            await api.route('me perspectives store')
                .formData({
                    name: perspective.name,
                    filters: JSON.stringify(perspective.filters),
                    topicId: perspective.topicId
                })
                .post()
                .json()

            return this.reload()
        },

        new() {
            return {
                name: 'Untitled Perspective',
                avatarUrl: 'https://avatars.staging.gerulata.com/api/?name=Untitled+Perspective&color=9D174D&background=FBCFE8',
                filters: {},
                subscriptions: { me: { types: [] }, workspace: { types: [] } }
            }
        }
    }
})

export default useMyPerspectivesStore
