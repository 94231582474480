<template>
    <ui-contextual-menu v-if="store.styleOptions.length">
        <template :key="option.id" v-for="option, index in store.styleOptions">
            <ui-dropdown-separator v-if="index"></ui-dropdown-separator>

            <template v-if="option.type == 'options'">
                <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                    <ui-icon :name="option.icon"></ui-icon> {{option.name}}
                </div>
                <ui-dropdown-link :selected="isSelected(option.id, value.id)" @click="store.setStyle(option.id, value.id)" :key="value.id" v-for="value in option.options">
                    {{value.name}}
                </ui-dropdown-link>
            </template>

            <ui-dropdown-link :selected="isSelected(option.id, 'enabled')" @click="store.setStyle(option.id, isSelected(option.id, 'enabled') ? 'disabled' : 'enabled')" v-else-if="option.type == 'toggle'">
                {{option.name}}
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
export default {
    methods: {
        isSelected(optionId, valueId) {
            return this.store.effectiveStyles[optionId] == valueId
        }
    }
}
</script>
