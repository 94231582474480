import useMyStore from '@/stores/me/my'

import { format as formatDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {formatRelative, formatDistance} from 'date-fns'
import { enUS } from 'date-fns/locale'

export function UTCToZoned(value) {
    return utcToZonedTime(value instanceof Date ? value : new Date(value), useMyStore().timezone)
}

export function zonedToUTC(value) {
    return zonedTimeToUtc(value instanceof Date ? value : new Date(value), useMyStore().timezone)
}

export function date(value, format = 'yyyy-MM-dd') {
    return formatDate(UTCToZoned(value), format, { timeZone: useMyStore().timezone })
}

export function dateTime(value, format = 'd MMM yyyy H:mm') {
    return date(value, format)
}

export function relativeTime(date, baseDate, format = null) {
    const formatRelativeLocale = format || {
        lastWeek: "'last' eeee 'at' k:mm",
        yesterday: "'yesterday at' k:mm",
        today: "'today at' k:mm",
        tomorrow: "'tomorrow at' k:mm",
        nextWeek: "eeee 'at' k:mm",
        other: 'P'
    };

    const locale = {
        ...enUS,
        formatRelative: token => formatRelativeLocale[token],
    };

    return formatRelative(date, baseDate, { locale })
}

export function distanceTime(date, baseDate) {
    return formatDistance(date, baseDate)
}

export function utcTimestamp(value) {
    let d = value instanceof Date ? value : new Date(value)
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds())
}
