<template>
    <div>
        <ui-header title="Messages">
            <template v-slot:actions>
                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off" spellcheck="false"
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
                </form>
            </template>
        </ui-header>

        <div class="pb-6 px-8 max-w-11xl mx-auto">
            <ui-cards-toolbar :store="threadsCardsStore"></ui-cards-toolbar>

            <ui-cards :store="threadsCardsStore">
                <template v-slot:item="{ item }">
                    <thread-card :thread="item" :cards-store="threadsCardsStore"></thread-card>
                </template>
            </ui-cards>
        </div>
    </div>
</template>

<script>
import ThreadCard from '@/admin/components/support/stream-cards/thread'

import useThreadsCardsStore from '@/admin/stores/support/threads-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        ThreadCard
    },

    computed: {
        ...mapStores(useThreadsCardsStore)
    }
}
</script>
