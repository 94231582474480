import useMyNotificationsStore from '@/stores/me/notifications'
import useTargetsMyTargetsMonitoringRequestsStore from '@/stores/targets/my-targets/monitoring-requests'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { hasFeatureTo, useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

export const useTargetsModalsLibraryAddTargetStore = defineForm({
    id: 'targetsModalsLibraryAddTarget',

    state: {
        types: [
            {
                name: 'Facebook Page',
                id: 'facebook',
                icon: 'badges.target-facebook-page',
                permission: 'access-targets-facebook-page',
                placeholder: 'https://facebook.com/projektn.sk, @projektn.sk'
            },
            {
                name: 'Facebook Group',
                id: 'facebook-group',
                icon: 'badges.target-facebook-group',
                permission: 'access-targets-facebook-group',
                placeholder: 'https://facebook.com/groups/vyvojari, @vyvojari'
            },
            {
                name: 'Instagram Profile',
                id: 'instagram-profile',
                icon: 'badges.target-instagram-profile',
                permission: 'access-targets-instagram-profile',
                placeholder: 'https://www.instagram.com/delniq, @delniq'
            },
            {
                name: 'Telegram Channel or Group',
                id: 'telegram',
                icon: 'badges.target-telegram-channel',
                permission: 'access-targets-telegram-channel',
                placeholder: 'https://t.me/michalsharksvobodnykanal, @michalsharksvobodnykanal'
            },
            {
                name: 'TikTok User',
                id: 'tiktok',
                icon: 'badges.target-tiktok-user',
                permission: 'access-targets-tiktok-user',
                placeholder: 'https://www.tiktok.com/@tvmarkiza, @tvmarkiza'
            },
            {
                name: 'Twitter User',
                id: 'twitter',
                icon: 'badges.target-twitter-user',
                permission: 'access-targets-twitter-user',
                placeholder: 'https://twitter.com/nasa, @nasa'
            },
            {
                name: 'VKontakte User, Page or Community',
                id: 'vkontakte',
                icon: 'badges.target-vkontakte-community',
                permission: 'access-targets-vkontakte-community',
                placeholder: 'https://vk.com/necenzurovane, @necenzurovane'
            },
            {
                name: 'Web Feed',
                id: 'web-feeds',
                icon: 'badges.target-web-feed',
                permission: 'access-targets-web-feed',
                placeholder: 'https://dennikn.sk/feed'
            },
            {
                name: 'Youtube Channel',
                id: 'youtube',
                icon: 'badges.target-youtube-channel',
                permission: 'access-targets-youtube-channel',
                placeholder: 'https://www.youtube.com/channel/wellstuffedbeaver, @wellstuffedbeaver'
            }
        ],

        showListsInput: false
    },

    getters: {
        availableTypes(store) {
            return store.types.filter(t => hasFeatureTo([ t.permission ]))
        }
    },

    inputs: () => ({
        step: 'type',
        type: null,
        value: null,
        lists: [],
        listsSearch: ''
    }),

    submitRequest() {
        trackEvent('targets', 'new-monitoring-request-saved', this.inputs.type.id)

        return api.route('me targets monitoring-requests store')
            .formData({
                _method: 'post',
                type: this.inputs.type.id,
                value: this.inputs.value,
                lists: this.inputs.lists
            })
            .post()
            .error(400, err => {
                this.inputs.step = 'error'
                this.errors = {
                    value: err.json.error
                }
            })
    },

    async onResponse(res) {
        useMyNotificationsStore().pushToast({
            type: 'info',
            title: 'Adding your target...',
            text: 'This might take a few minutes, feel free to continue using the app.',
            lifetime: 10
        })

        useTargetsMyTargetsMonitoringRequestsStore().reload()

        this.inputs.step = 'done'
    },

    actions: {
        open() {
            this.reset()

            useModal().show('targets-library-add-target')
        },

        close() {
            useModal().hide('targets-library-add-target')
        }
    }
})

export default useTargetsModalsLibraryAddTargetStore
