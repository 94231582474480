<template>
    <ui-modal name="add-analysis" :title="'Add an Analysis'" :max-width="520" :max-height="640">
        <div class="flex flex-col items-center max-w-xl mx-auto max-h-80">
            <div class="relative w-4/5 text-gray-400 focus-within:text-gray-600">
                <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none text-xl">
                    <ui-icon name="search"></ui-icon>
                </div>
                <input id="search_field" placeholder="Search analyses..." type="search" autocomplete="off" v-model="search" class="block border-0 w-full h-8 pl-8 pr-2 py-2 rounded-md bg-gray-100 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm shadow-inner">
            </div>

            <div class="flex-1 overflow-y-auto w-full mt-4 text-sm divide-y divide-gray-100 pb-6">
                <a href="#" class="flex items-center px-2 py-1.5 rounded-md hover:bg-blue-50 transition duration-150" @click.prevent="setAnalysis(analysis)" :key="analysis.id" v-for="analysis in analyses">
                    <img :src="analysis.avatarUrl" class="h-6 w-6 rounded-full mr-2">
                    <div>
                        <p class="truncate">{{ analysis.name }}</p>
                        <p class="text-xs text-gray-700">{{ findAnalysis(analysis.type).name }}</p>
                    </div>
                    <div class="w-5 h-5 rounded-full inline-flex justify-center items-center ml-auto" :class="{ 'bg-blue-500 text-white': analysis == selectedAnalysis }">
                        <ui-icon name="check"></ui-icon>
                    </div>
                </a>
            </div>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" :color="'blue'" :action="() => addAnalysis(dashboardStore)">
                Add
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>

import useModalsAddAnalysisStore from '@/stores/dashboards/modals/add-analysis'
import useAnalysisTypesStore from '@/stores/analysis/analysis-types'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    props: [ 'dashboardStore' ],

    methods: {
        ...mapActions(useModalsAddAnalysisStore, [ 'setAnalysis', 'addAnalysis' ]),
        ...mapActions(useAnalysisTypesStore, { findAnalysis: 'find' })
    },

    computed: {
        ...mapWritableState(useModalsAddAnalysisStore, [ 'search' ]),
        ...mapState(useModalsAddAnalysisStore, [ 'analyses', 'selectedAnalysis' ])
    }
}
</script>
