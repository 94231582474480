import api from "@/api";

import useMyStore from '@/stores/me/my'

import { defineForm } from "@/stores/reusable/form";
import { useModal } from "@/helpers";

import { nextTick } from 'vue'

export const useAccountSettingsFormStore = defineForm({
    id: 'accountSettingsForm',

    state: {
        requiresPasswordConfirmation: false
    },

    inputs: () => ({
        name: null,
        email: null,
        profile_photo: null,
        password: null,
        newsletter_subscription: null,
        platform_updates_subscription: null,
        current_password: null,
        password_confirmation: null,

        timezone: useMyStore().timezone, // @todo: Rework
        preferred_content_language: useMyStore().preferredLanguage // @todo: Rework
    }),

    submitRequest() {
        return api.route('settings account update')
            .formData({
                _method: 'put',
                name: this.inputs.name,
                email: this.inputs.email,
                profile_photo: this.inputs.profile_photo,
                password: this.inputs.password,
                current_password: this.inputs.current_password,
                newsletter_subscription: this.inputs.newsletter_subscription,
                platform_updates_subscription: this.inputs.platform_updates_subscription,
                product_updates_subscription: this.inputs.product_updates_subscription,
                password_confirmation: this.inputs.password_confirmation,
                timezone: this.inputs.timezone,
                preferred_content_language: this.inputs.preferred_content_language
            })
            .post()
    },

    async onResponse(res) {
        nextTick(() => {
            window.scrollTo(0, 0);
        })
    },

    actions: {
        requirePasswordConfirmation() {
            this.requiresPasswordConfirmation = true
        },

        save() {
            if (this.requiresPasswordConfirmation && !this.inputs.current_password) {
                return useModal().show('password-confirmation')
            }

            this.submit()
            useModal().hide('password-confirmation')
            this.inputs.current_password = null
        }
    }
})

export default useAccountSettingsFormStore
