import defineCards from '@/stores/reusable/cards'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { sortNaturally } from '@/helpers'

export const useTargetsIndexCardsStore = defineCards({
    id: 'targetsIndexCards',

    source: useMyTargetListsStore(),

    filter: items => items.filter(item => item.pinned || item.recent),

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: 'asc',
            apply: items => sortNaturally(items)
        }
    ]
})

export default useTargetsIndexCardsStore
