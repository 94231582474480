import {defineStore} from 'pinia'

export const useSettingsStore = defineStore({
    id: 'settings',

    state: () => ({
        topNavigation: [
            { id: 'account', name: 'Account', icon: 'account', to: { name: 'settings.account' } },
            { id: 'apps', name: 'Apps', icon: 'key', to: { name: 'settings.apps' } }
        ]
    })
})

export default useSettingsStore
