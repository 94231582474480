import useLicensesStore from '@/admin/stores/accounts/licenses'

import api from "@/admin/api"

import { dateTime, zonedToUTC } from "@/helpers/datetime"
import { defineForm } from "@/stores/reusable/form"

import { nextTick } from 'vue'

export const useLicenseSettingsFormStore = defineForm({
    id: 'licenseSettingsForm',

    state: {
        organizationId: null,
        licenseId: null,

        availableFeatures: [
            'register-via-facebook',
            'register-via-email',

            'access-features',
            'access-sources',
            'access-groups',

            'access-content-facebook-post',
            'access-content-facebook-post-comment',
            'access-content-facebook-group-post',
            'access-content-facebook-user-post',
            'access-content-instagram-post',
            'access-content-instagram-post-comment',
            'access-content-telegram-channel-post',
            'access-content-telegram-group-message',
            'access-content-tiktok-video',
            'access-content-twitter-tweet',
            'access-content-vkontakte-post',
            'access-content-web-article',
            'access-content-web-resource',
            'access-content-youtube-video',
            'access-content-youtube-video-comment',

            'access-targets-facebook-group',
            'access-targets-facebook-page',
            'access-targets-facebook-user',
            'access-targets-instagram-profile',
            'access-targets-telegram-user',
            'access-targets-telegram-channel',
            'access-targets-telegram-group',
            'access-targets-tiktok-user',
            'access-targets-twitter-user',
            'access-targets-vkontakte-community',
            'access-targets-vkontakte-user',
            'access-targets-web-feed',
            'access-targets-web-site',
            'access-targets-youtube-channel'
        ]
    },

    inputs: () => ({
        name: null,
        validFrom: null,
        validTo: null,

        billingType: null,
        billing: {
            free: {},
            paid: {
                customer: null,
                items: [
                    { item: null, quantity: 1 }
                ],
                taxType: null,
                period: 'monthly'
            }
        },

        features: [],
        budgets: {
            monitoredTargets: null,
            imageTextExtraction: null,
            videoDownload: null,
            videoTranscription: null
        }
    }),

    actions: {
        addNewItem() {
            this.inputs.billing.paid.items.push({ item: null, quantity: 1 })
        },

        removeItem(index) {
            this.inputs.billing.paid.items.splice(index, 1)
        },

        toggleFeature(feature) {
            this.inputs.features.includes(feature) ? this.inputs.features.splice(this.inputs.features.indexOf(feature), 1) : this.inputs.features.push(feature)
        },

        enableAllFeatures() {
            this.inputs.features = [ ...this.availableFeatures ]
        },

        disableAllFeatures() {
            this.inputs.features = []
        }
    },

    submitRequest() {
        return api.route(this.licenseId
                ? 'admin accounts organizations licenses update'
                : 'admin accounts organizations licenses store',
            { organizationId: this.organizationId, licenseId: this.licenseId }
        ).json({
            _method: this.licenseId ? 'put' : 'post',
            name: this.inputs.name,
            validFrom: dateTime(zonedToUTC(this.inputs.validFrom)),
            validTo: dateTime(zonedToUTC(this.inputs.validTo)),

            preferences: {
                billingType: this.inputs.billingType,
                billing: this.inputs.billing,

                features: this.inputs.features,
                budgets: this.inputs.budgets
            }
        }).post()
    },

    async onResponse(res) {
        await useLicensesStore().load(this.organizationId, true)

        nextTick(() => {
            window.scrollTo(0, 0);
        })
    }
})

export default useLicenseSettingsFormStore
