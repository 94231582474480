<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-slot:widget>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <div class="flex-1 min-h-0">
                    <div class="editor-text" v-html="text"></div>
                </div>
            </div>
        </template>

        <template v-slot:expanded>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <div class="flex-1 min-h-0 p-4">
                    <div class="editor-text" v-html="text"></div>
                </div>
            </div>
        </template>

        <template v-slot:edit>
            <div class="h-full w-full border border-1 border-dashed border-gray-500">
                <editor class="h-full" v-model="store.editForm.text"></editor>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>

import DashboardWidget from './widget'
import Editor from '@/components/ui/editor'
import { sanitizeHTML } from '@/helpers'

export default {
    components: { Editor, DashboardWidget },

    computed: {
        text() {
            return sanitizeHTML(this.store.text)
        }
    }
}
</script>

<style>
.editor-text { white-space: break-spaces }

.editor-text h1 { font-size: 24px; line-height: 30px; padding-bottom: 0.4rem }
.editor-text h2 { font-size: 20px; line-height: 26px; padding-bottom: 0.4rem }
.editor-text h3 { font-size: 16px; line-height: 22px; padding-bottom: 0.4rem }

.editor-text p { padding-bottom: 0.33rem }

.editor-text p:after {
    content: "";
    display: inline-block;
    width: 0;
}

.editor-text ul { list-style-type: disc; padding: 0 1rem }
.editor-text ol { list-style-type: decimal; padding: 0 1rem }

.editor-text li {
    padding-bottom: 2px;
}
</style>
