<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="trash" @click="deleteWorkspace(organization.id, workspace)">
            Delete
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useWorkspacesStore from '@/admin/stores/accounts/workspaces'

import { mapActions } from 'pinia'

export default {
    props: [ 'organization', 'workspace' ],

    methods: {
        ...mapActions(useWorkspacesStore, {
            'deleteWorkspace': 'delete'
        })
    }
}
</script>
