<template>
    <ui-async :guards="member">
        <div class="pb-6 px-8 max-w-11xl mx-auto">
            <div v-if="memberSettingsFormStore.submitted" class="border border-green-300 bg-green-50 text-green-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
                Member successfully updated.
            </div>

            <ui-form :store="memberSettingsFormStore" v-slot="{ inputs, submitting, errors }" class="bg-white shadow rounded-sm">
                <div class="px-4 py-5 sm:p-8 space-y-8 divide-y divide-gray-300">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Details</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change the basic member information
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="name" class="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                                    </div>
                                </div>

                                <div>
                                    <label for="email" class="block text-sm font-medium text-gray-700">
                                        E-mail
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="email" name="email" id="email" v-model="inputs.email" :errors="errors.email"></ui-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Security</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change member security settings
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="password" class="block text-sm font-medium text-gray-700">New Password</label>
                                    <div class="mt-1">
                                        <ui-input type="password" name="password" id="password" autocomplete="new-password" v-model="inputs.password" :errors="errors.password"></ui-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-gray-50 flex px-4 py-5 sm:p-8 rounded-b-lg border-t border-gray-100 flex justify-end space-x-2">
                    <ui-button v-if="member.hasTwoFactorAuth" color="red" icon="key" :action="() => disableTwoFactorAuth()">
                        Disable Two Factor Auth
                    </ui-button>

                    <ui-button color="blue" icon="user" :action="() => impersonate()">
                        Impersonate
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Save
                    </ui-button>
                </div>
            </ui-form>
        </div>
    </ui-async>
</template>

<script>
import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'
import useMemberStore from '@/admin/stores/accounts/member'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useMemberSettingsFormStore),

        ...mapState(useMemberStore, [
            'member'
        ])
    },

    methods: {
        ...mapActions(useMemberStore, [
            'impersonate',
            'disableTwoFactorAuth'
        ]),
    }
}
</script>
