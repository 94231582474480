<template>
    <div class="pb-8 px-8">
        <Teleport to="#header-bottom">
            <stream-toolbar :store="featuresFeatureStreamStore"></stream-toolbar>
        </Teleport>

        <ui-stream :store="featuresFeatureStreamStore"></ui-stream>
    </div>
</template>

<script>
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import UiStream from '@/components/ui/stream/stream'

import useFeaturesFeatureStreamStore from '@/stores/features/feature/stream'

import { mapStores } from 'pinia'

export default {
    components: { StreamToolbar, UiStream },

    computed: {
        ...mapStores(useFeaturesFeatureStreamStore)
    }
}
</script>
