<template>
    <div class="px-8 flex justify-center space-x-4 max-w-7xl mx-auto">
        <div class="w-1/3 space-y-4">
            <details-card :target="$page.target"></details-card>
            <history-card :compact="true"></history-card>
            <connections-card v-if="$page.type == 'sources'"></connections-card>
            <top-groups-card></top-groups-card>
            <top-sources-card></top-sources-card>
        </div>

        <div class="w-2/3">
            <mirror-card></mirror-card>
            <inactive-card></inactive-card>
            <activity-card></activity-card>
            <statistics-card></statistics-card>
            <heatmap-card></heatmap-card>
            <top-posts-section></top-posts-section>
        </div>

        <Teleport to="#root">
            <target-information-lightbox :store="targetsTargetInformationLightboxStore"></target-information-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ActivityCard from './information/activity-card'
import ConnectionsCard from './information/connections-card'
import DetailsCard from './information/details-card'
import HeatmapCard from './information/heatmap-card'
import HistoryCard from './history/history-card'
import InactiveCard from './information/inactive-card'
import MirrorCard from './information/mirror-card'
import StatisticsCard from './information/statistics-card'
import TargetInformationLightbox from '@/components/ui/stream/lightbox/lightbox'
import TopGroupsCard from './information/top-groups-card'
import TopPostsSection from './information/top-posts-section'
import TopSourcesCard from './information/top-sources-card'

import useTargetsTargetInformationLightboxStore from '@/stores/targets/target/information-lightbox'

import { mapStores } from 'pinia'

export default {
    components: {
        ActivityCard, ConnectionsCard, DetailsCard, HeatmapCard, HistoryCard, InactiveCard, MirrorCard, StatisticsCard,
        TargetInformationLightbox, TopGroupsCard, TopSourcesCard, TopPostsSection
    },

    computed: {
        ...mapStores(
            useTargetsTargetInformationLightboxStore
        )
    }
}
</script>
