<template>
    <div
        v-if="groups && groups.length"
        class="bg-white w-full overflow-hidden shadow rounded-sm px-6 py-4"
    >
        <div class="mb-6">
            <h3 class="font-semibold text-gray-900">
                Most Active In Groups
            </h3>
        </div>

        <div>
            <router-link
                :to="{ name: 'targets.target.information', params: { type: 'group', modelType: group.type, id: group.id } }"
                target="_blank"
                v-for="group in groups"
                :key="group.id"
                class="flex items-center justify-between py-1"
            >
                <div class="flex items-center">
                    <img :src="group.avatarUrl" class="h-5 w-5 object-cover rounded-full mr-2">
                    <p class="tracking-tight">{{ group.name }}</p>
                </div>
                <div>
                    <span class="font-medium">{{ group.count }}</span> <span>posts</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import useTargetsTargetInformationTopGroupsStore from '@/stores/targets/target/information-top-groups'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useTargetsTargetInformationTopGroupsStore, [ 'groups' ])
    }
}
</script>
