<template>
    <div class="bg-white rounded shadow p-3 mb-4">
        <div class="flex items-center justify-between mt-2 px-2">
            <div class="flex justify-center items-center text-gray-900">
                <ui-dropdown align="left" position="top" unstyled>
                    <template v-slot:trigger>
                        <div class="text-center px-4 cursor-pointer">
                            <ui-spinner class="text-xl mb-2" type="clip" v-if="store.statistics.values.aggregates.data === null"></ui-spinner>
                            <p v-else class="text-2xl font-semibold leading-snug">{{ $number(store.statistics.values.aggregates.data['results:count']) }}</p>
                            <p class="flex items-center text-xs text-gray-700 tracking-tight leading-snug">
                                <ui-icon name="hash" class="mr-0.5"></ui-icon> Results
                            </p>
                        </div>
                    </template>

                    <template v-slot:content>
                        <div class="rounded-lg shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left space-y-3 whitespace-nowrap">
                            <div v-if="store.statistics.values.aggregates.data['sources:count']">
                                By <span class="font-bold">{{ $number(store.statistics.values.aggregates.data['sources:count']) }}</span> sources
                            </div>
                            <div v-if="store.statistics.values.aggregates.data['groups:count']">
                                In <span class="font-bold">{{ $number(store.statistics.values.aggregates.data['groups:count']) }}</span> groups
                            </div>
                        </div>
                    </template>
                </ui-dropdown>

                <ui-dropdown align="left" position="top" unstyled>
                    <template v-slot:trigger>
                        <div class="text-center px-4 cursor-pointer">
                            <ui-spinner class="text-xl mb-2" type="clip" v-if="store.statistics.values.aggregates.data === null"></ui-spinner>
                            <p v-else class="text-2xl font-semibold leading-snug">{{ $number(store.statistics.values.aggregates.data['interactions:sum']) }}</p>
                            <p class="flex items-center text-xs text-gray-700 tracking-tight leading-snug">
                                <ui-icon name="zap" class="mr-1"></ui-icon> Interactions
                            </p>
                        </div>
                    </template>

                    <template v-slot:content>
                        <div class="rounded-lg shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left whitespace-nowrap">
                            <div class="space-y-3" v-if="hasInteractionsDetails">
                                <div class="flex items-center" v-if="store.statistics.values.aggregates.data['reactions:sum']">
                                    <ui-icon name="thumbs-up" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(store.statistics.values.aggregates.data['reactions:sum']) }}</span> reactions
                                    </div>
                                </div>

                                <div class="flex items-center space-x-2 text-sm" v-if="facebookReactions.length">
                                    <div class="flex items-center" :key="reaction[0]" v-for="reaction in facebookReactions">
                                        <ui-icon :name="`reactions-${reaction[0]}`" class="text-lg" as-image></ui-icon>
                                        <span class="font-medium ml-1">{{ $number(reaction[1]) }}</span>
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="store.statistics.values.aggregates.data['comments:sum']">
                                    <ui-icon name="comment" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(store.statistics.values.aggregates.data['comments:sum']) }}</span> comments
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="store.statistics.values.aggregates.data['shares:sum']">
                                    <ui-icon name="share" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(store.statistics.values.aggregates.data['shares:sum']) }}</span> shares
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No interactions details.
                            </div>
                        </div>
                    </template>
                </ui-dropdown>

                <ui-dropdown align="left" position="top" unstyled v-if="store.statistics.values.aggregates.data">
                    <template v-slot:trigger>
                        <div class="px-4 cursor-pointer text-center">
                            <div class="my-2 h-1.5 bg-gray-300 rounded-full overflow-hidden flex w-24">
                                <div class="h-1.5 bg-emerald-500" :style="`width: ${store.statistics.values.aggregates.data['contentSentiment:sum'].positive / contentWithSentimentCount * 100}%;`"></div>
                                <div class="h-1.5 bg-indigo-500" :style="`width: ${store.statistics.values.aggregates.data['contentSentiment:sum'].neutral / contentWithSentimentCount * 100}%;`"></div>
                                <div class="h-1.5 bg-rose-500" :style="`width: ${store.statistics.values.aggregates.data['contentSentiment:sum'].negative / contentWithSentimentCount * 100}%;`"></div>
                            </div>
                            <div class="text-xs text-gray-700 tracking-tight leading-none">
                                Content Sentiment
                            </div>
                        </div>
                    </template>

                    <template v-slot:content>
                        <div class="rounded-md shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left whitespace-nowrap">
                            <div v-if="store.statistics.values.aggregates.data['contentSentiment:sum'].positive / (store.statistics.values.aggregates.data['contentSentiment:sum'].positive + store.statistics.values.aggregates.data['contentSentiment:sum'].negative) > 0.5">
                                <span class="font-semibold">Positive</span> sentiment
                            </div>
                            <div v-else-if="store.statistics.values.aggregates.data['contentSentiment:sum'].negative / (store.statistics.values.aggregates.data['contentSentiment:sum'].positive + store.statistics.values.aggregates.data['contentSentiment:sum'].negative) > 0.5">
                                <span class="font-semibold">Negative</span> sentiment
                            </div>
                            <div v-else-if="(store.statistics.values.aggregates.data['contentSentiment:sum'].positive + store.statistics.values.aggregates.data['contentSentiment:sum'].negative) / repliesWithSentimentCount > 0.5">
                                <span class="font-semibold">Mixed</span> sentiment
                            </div>

                            <div class="text-sm text-gray-100 leading-4 mt-2">
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['contentSentiment:sum'].positive)}}</span> positive items
                                </div>
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['contentSentiment:sum'].negative)}}</span> negative items
                                </div>
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['contentSentiment:sum'].neutral)}}</span> neutral items
                                </div>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>

                <ui-dropdown align="left" position="top" unstyled v-if="store.statistics.values.aggregates.data">
                    <template v-slot:trigger>
                        <div class="px-4 cursor-pointer text-center">
                            <div class="my-2 h-1.5 bg-gray-300 rounded-full overflow-hidden flex w-24">
                                <div class="h-1.5 bg-emerald-500" :style="`width: ${store.statistics.values.aggregates.data['repliesSentiment:sum'].positive / repliesWithSentimentCount * 100}%;`"></div>
                                <div class="h-1.5 bg-indigo-500" :style="`width: ${store.statistics.values.aggregates.data['repliesSentiment:sum'].neutral / repliesWithSentimentCount * 100}%;`"></div>
                                <div class="h-1.5 bg-rose-500" :style="`width: ${store.statistics.values.aggregates.data['repliesSentiment:sum'].negative / repliesWithSentimentCount * 100}%;`"></div>
                            </div>
                            <div class="text-xs text-gray-700 tracking-tight leading-none">
                                Replies Sentiment
                            </div>
                        </div>
                    </template>

                    <template v-slot:content>
                        <div class="rounded-md shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left whitespace-nowrap">
                            <div v-if="store.statistics.values.aggregates.data['repliesSentiment:sum'].positive / (store.statistics.values.aggregates.data['repliesSentiment:sum'].positive + store.statistics.values.aggregates.data['repliesSentiment:sum'].negative) > 0.5">
                                <span class="font-semibold">Positive</span> sentiment
                            </div>
                            <div v-else-if="store.statistics.values.aggregates.data['repliesSentiment:sum'].negative / (store.statistics.values.aggregates.data['repliesSentiment:sum'].positive + store.statistics.values.aggregates.data['repliesSentiment:sum'].negative) > 0.5">
                                <span class="font-semibold">Negative</span> sentiment
                            </div>
                            <div v-else-if="(store.statistics.values.aggregates.data['repliesSentiment:sum'].positive + store.statistics.values.aggregates.data['repliesSentiment:sum'].negative) / repliesWithSentimentCount > 0.5">
                                <span class="font-semibold">Mixed</span> sentiment
                            </div>

                            <div class="text-sm text-gray-100 leading-4 mt-2">
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['repliesSentiment:sum'].positive)}}</span> positive replies
                                </div>
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['repliesSentiment:sum'].negative)}}</span> negative replies
                                </div>
                                <div>
                                    <span class="font-semibold">{{$number(store.statistics.values.aggregates.data['repliesSentiment:sum'].neutral)}}</span> neutral replies
                                </div>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>
            </div>

            <div class="text-xs space-x-6 text-gray-900 text-center px-4 mb-2">
                <a href="#" @click.prevent="selectedTab = 'timeline'" class="pb-0.5" :class="{'font-medium text-blue-600 border-b border-blue-700': shownTab == 'timeline'}">Timeline</a>
                <!-- <a href="#" @click.prevent="selectedTab = 'keywords'" class="pb-0.5" :class="{'font-medium text-blue-600 border-b border-blue-700': shownTab == 'keywords'}">Keywords</a> -->
                <a href="#" @click.prevent="selectedTab = 'platforms'" class="pb-0.5" :class="{'font-medium text-blue-600 border-b border-blue-700': shownTab == 'platforms'}">Platforms</a>
                <a href="#" @click.prevent="selectedTab = 'heatmap'" class="pb-0.5" :class="{'font-medium text-blue-600 border-b border-blue-700': shownTab == 'heatmap'}">Heatmap</a>
            </div>
        </div>

        <div v-if="shownTab == 'timeline'">
            <div class="h-28" v-if="store.statistics.analyses.contentPerspectivePrevalenceAnalysis">
                <ui-async :guards="store.statistics.analyses.contentPerspectivePrevalenceAnalysis.store().isLoaded">
                    <analysis :options="store.statistics.analyses.contentPerspectivePrevalenceAnalysis.store().options" ref="analysis" class="h-28"></analysis>
                </ui-async>
            </div>
        </div>

        <div v-if="shownTab == 'keywords'">
            <div class="pt-4">

            </div>
        </div>

        <div v-if="shownTab == 'platforms'">
            <div class="pt-4">
                <content-platforms-tab :store="store"></content-platforms-tab>
            </div>
        </div>

        <div v-if="shownTab == 'heatmap'">
            <div class="pt-4">
                <daily-heatmap :cell-height="16" :store="store.statistics.analyses.contentActivityByDayAnalysis.store()"></daily-heatmap>
            </div>
        </div>
    </div>
</template>

<script>
import ContentPlatformsTab from './content-platforms'
import DailyHeatmap from "@/components/analysis/analyses/heatmaps/daily"

import { Chart as Analysis } from 'highcharts-vue'

export default {
    components: {
        Analysis, ContentPlatformsTab, DailyHeatmap
    },

    data: () => ({
        selectedTab: null
    }),

    computed: {
        shownTab() {
            return this.selectedTab || 'timeline'
        },

        hasInteractionsDetails() {
            return this.store.statistics.values.aggregates.data['reactions:sum']
                || this.store.statistics.values.aggregates.data['comments:sum']
                || this.store.statistics.values.aggregates.data['shares:sum']
        },

        contentWithSentimentCount() {
            return this.store.statistics.values.aggregates.data['contentSentiment:sum'].positive
                + this.store.statistics.values.aggregates.data['contentSentiment:sum'].negative
                + this.store.statistics.values.aggregates.data['contentSentiment:sum'].neutral
        },

        repliesWithSentimentCount() {
            return this.store.statistics.values.aggregates.data['repliesSentiment:sum'].positive
                + this.store.statistics.values.aggregates.data['repliesSentiment:sum'].negative
                + this.store.statistics.values.aggregates.data['repliesSentiment:sum'].neutral
        },

        facebookReactions() {
            let reactions = Object.entries(this.store.statistics.values.aggregates.data['platformMetrics:sum'].facebook)
                .filter(([ key, value ]) => value > 0)

            reactions.sort(([ keyA, valueA ], [ keyB, valueB ]) => valueB - valueA)

            return reactions
        }
    }
}
</script>
