<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store">
        <template v-slot:applied>
            <ui-icon :name="filter.icon" class="mr-1"></ui-icon>
            {{value.name}}
        </template>

        <template v-slot:options>
            <div class="w-64 p-2 text-center text-sm">
                <div class="mb-2 font-medium">
                    {{value.name}}
                </div>

                <div class="mb-2 text-xs text-gray-600">
                    Sent at {{$dateTime(value.sentAt)}}
                </div>

                <div class="text-xs text-gray-500">
                    Showing only content associated with this notification.
                </div>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    computed: {
        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    }
}
</script>
