<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="refresh">
        <template v-slot:applied>
            <div class="flex items-center space-x-1">
                <template :key="index" v-for="filter, index in filter.filters">
                    <!-- Metrics -->
                    <div v-if="filter.type === 'metrics' && store.getFilterValue(filter.id)">
                        <div v-if="store.getFilterValue(filter.id).length > 1" class="inline-flex items-center leading-none space-x-1">
                            <ui-icon name="info"></ui-icon>
                            <span class="font-medium">{{ store.getFilterValue(filter.id).length }} Metrics</span>
                        </div>

                        <div v-else class="inline-flex items-center leading-none space-x-1">
                            <ui-icon :name="store.getFilterValue(filter.id)[0].metric.icon"></ui-icon>

                            <span class="font-medium" v-if="! store.getFilterValue(filter.id)[0].gte">&lt; {{store.getFilterValue(filter.id)[0].lte}}</span>
                            <span class="font-medium" v-else-if="! store.getFilterValue(filter.id)[0].lte">&gt; {{store.getFilterValue(filter.id)[0].gte}}</span>
                            <span class="font-medium" v-else>{{store.getFilterValue(filter.id)[0].gte}} - {{store.getFilterValue(filter.id)[0].lte}}</span>
                        </div>
                    </div>

                    <!-- Labels -->
                    <div v-if="filter.type === 'labels' && store.getFilterValue(filter.id)" class="inline-flex space-x-1">
                        <div class="inline-flex items-center space-x-1 text-xs font-medium leading-4 bg-blue-50 py-0.5" :key="index" v-for="value, index in store.getFilterValue(filter.id)">
                            <span class="inline-block w-3 h-3 text-xs leading-4 rounded-full" :class="`bg-${value.color.dark}`"></span>
                            <span>{{value.title}}</span>
                        </div>
                    </div>

                    <!-- Sentiment -->
                    <div v-if="filter.type === 'sentiments' && store.getFilterValue(filter.id)" class="inline-flex space-x-1">
                        <div class="inline-flex items-center space-x-1 text-xs font-medium leading-4 bg-blue-50 py-0.5" :key="index" v-for="value, index in store.getFilterValue(filter.id)">
                            <div class="flex justify-center items-center text-center" :class="value.color">
                                <ui-icon :name="value.icon" />
                            </div>
                            <span>{{value.name}}</span>
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <div :key="index" v-for="filter, index in filter.filters">
                    <ui-dropdown-separator v-if="index > 0 && !activeSubmenuFilterId" :key="index" class="my-2"></ui-dropdown-separator>

                    <!-- Metrics -->
                    <template v-if="filter.type === 'metrics'">
                        <div class="w-64" v-if="activeSubmenuFilterId === filter.id && localFilters.find(f => f.id === filter.id) && localFilters.find(f => f.id === filter.id).selectedMetric">
                            <label class="block mb-2 px-4 text-gray-600 text-xs font-semibold uppercase">
                                {{localFilters.find(f => f.id === filter.id).selectedMetric.name}}
                            </label>

                            <div class="flex items-stretch px-4">
                                <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-sm placeholder-gray-400 border-gray-300 border-r-0 rounded-r-none text-center" placeholder="Min" v-model="localFilters.find(f => f.id === filter.id).min">
                                <div class="flex items-center border-t border-b border-gray-300 text-gray-400 px-1">&ndash;</div>
                                <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-sm placeholder-gray-400 border-gray-300 border-l-0 rounded-l-none text-center" placeholder="Max" v-model="localFilters.find(f => f.id === filter.id).max">
                            </div>

                            <div class="px-4 mt-0.5 text-xs text-red-600" v-if="! isMinMaxValueValid">Minimum value must be equal to or smaller than the maximum value</div>
                        </div>

                        <div class="w-64" v-else>
                            <div :key="index" v-for="metric, index in filter.metrics">
                                <ui-dropdown-link :checkbox="filter.multiple" :selected="store.getFilterValue(filter.id) && store.getFilterValue(filter.id).some(v => v.metric.id === metric.id)" :icon="metric.icon" @click="activeSubmenuFilterId = filter.id; localFilters.find(f => f.id === filter.id).selectedMetric = metric" keep-open :key="index" v-if="metric.id">
                                    {{metric.name}}
                                </ui-dropdown-link>
                            </div>
                        </div>
                    </template>

                    <!-- Labels -->
                    <template v-if="filter.type === 'labels'">
                        <div class="w-64" v-if="!activeSubmenuFilterId">
                            <ui-dropdown-link :checkbox="filter.multiple" :selected="localFilters.find(f => f.id === filter.id) && localFilters.find(f => f.id === filter.id).selectedLabels.includes(label)" @click="toggleLocalLabel(filter.id, label)" :icon="true" :key="label.id" v-for="label in labels">
                                <template v-slot:icon>
                                    <div class="w-4 flex justify-center items-center text-center">
                                        <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${label.color.dark}`"></span>
                                    </div>
                                </template>
                                <template v-slot:default>
                                    {{label.title}}
                                </template>
                            </ui-dropdown-link>
                        </div>
                    </template>

                    <!-- Sentiments -->
                    <template v-if="filter.type === 'sentiments'">
                        <div class="w-64" v-if="!activeSubmenuFilterId">
                            <ui-dropdown-link :checkbox="filter.multiple" :selected="localFilters.find(f => f.id === filter.id) && localFilters.find(f => f.id === filter.id).selectedSentiments.some(s => s.id === sentiment.id)" @click="toggleLocalSentiment(filter.id, sentiment)" :icon="true" :key="sentiment.id" v-for="sentiment in filter.sentiments">
                                <template v-slot:icon>
                                    <div class="w-4 flex justify-center items-center text-center" :class="sentiment.color">
                                        <ui-icon :name="sentiment.icon" />
                                    </div>
                                </template>
                                <template v-slot:default>
                                    {{sentiment.name}}
                                </template>
                            </ui-dropdown-link>
                        </div>
                    </template>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="reset(); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm" :disabled="! isApplicable">
                    Apply
                </ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import {mapState} from "pinia";

import useMyLabels from "@/stores/me/labels";

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        activeSubmenuFilterId: null,

        localFilters: []
    }),

    computed: {
        ...mapState(useMyLabels, [ 'labels' ]),

        isMinMaxValueValid() {
            let min = this.localFilters.find(f => f.id === this.activeSubmenuFilterId).min
            let max = this.localFilters.find(f => f.id === this.activeSubmenuFilterId).max

            return min && max ? this.activeSubmenuFilterId && min <= max : true
        },

        isApplicable() {
            return ! this.activeSubmenuFilterId
                || (this.isMinMaxValueValid && (
                    this.localFilters.find(f => f.id === this.activeSubmenuFilterId).min || this.localFilters.find(f => f.id === this.activeSubmenuFilterId).max)
                )
        }
    },

    methods: {
        toggleLocalLabel(filterId, item) {
            this.localFilters.find(f => f.id === filterId).selectedLabels.some(l => l.id === item.id)
                ? this.localFilters.find(f => f.id === filterId).selectedLabels.splice(this.localFilters.find(f => f.id === filterId).selectedLabels.findIndex(l => l.id === item.id), 1)
                : this.localFilters.find(f => f.id === filterId).selectedLabels.push(item)
        },

        toggleLocalSentiment(filterId, item) {
            this.localFilters.find(f => f.id === filterId).selectedSentiments.some(l => l.id === item.id)
                ? this.localFilters.find(f => f.id === filterId).selectedSentiments.splice(this.localFilters.find(f => f.id === filterId).selectedSentiments.findIndex(l => l.id === item.id), 1)
                : this.localFilters.find(f => f.id === filterId).selectedSentiments.push(item)
        },

        reset() {
            this.activeSubmenuFilterId
                ? this.store.removeFilter(this.activeSubmenuFilterId)
                : this.store.removeFilters(this.filter.filters)
        },

        refresh() {
            this.activeSubmenuFilterId = null
            this.localFilters = []

            this.filter.filters.forEach(f => {
                if (f.type === 'metrics') { this.localFilters.push({
                    id: f.id,
                    selectedMetric: null,
                    min: '',
                    max: ''
                }) }

                if (f.type === 'labels') { this.localFilters.push({
                    id: f.id,
                    selectedLabels: this.store.getFilterValue(f.id) ? [...this.store.getFilterValue(f.id)] : []
                }) }

                if (f.type === 'sentiments') { this.localFilters.push({
                    id: f.id,
                    selectedSentiments: this.store.getFilterValue(f.id) ? [...this.store.getFilterValue(f.id)] : []
                }) }
            })
        },

        apply() {
            this.filter.filters.forEach(f => {
                if (f.type === 'metrics' && this.activeSubmenuFilterId === f.id) {
                    let localValue = this.localFilters.find(lf => lf.id === f.id)

                    this.store.addFilter(f.id, [{
                        metric: localValue.selectedMetric, gte: localValue.min, lte: localValue.max
                    }])
                }

                if (f.type === 'labels') { this.localFilters.find(lf => lf.id === f.id) && this.localFilters.find(lf => lf.id === f.id).selectedLabels.length
                    ? this.store.addFilter(f.id, this.localFilters.find(lf => lf.id === f.id).selectedLabels)
                    : this.store.removeFilter(f.id)
                }

                if (f.type === 'sentiments') { this.localFilters.find(lf => lf.id === f.id) && this.localFilters.find(lf => lf.id === f.id).selectedSentiments.length
                    ? this.store.addFilter(f.id, this.localFilters.find(lf => lf.id === f.id).selectedSentiments)
                    : this.store.removeFilter(f.id)
                }
            })
        }
    }
}
</script>
