import api from '@/api'
import { targets } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export const useMyBookmarksStore = defineStore({
    id: 'my-bookmarks',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = targets()
                .filters('list', 'bookmarks')
                .limit(200)
                .include([ 'source.metrics', 'group.metrics' ])
                .get()
                .then(targets => {
                    this.items = targets.map(target => ({ ...target, bookmarked: true }))

                    this.isLoading = false
                    this.isInitialized = true

                    this.loadingPromise = null
                })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        isBookmarked(target) {
            return this.items?.find(t => t.id == target.id)
        },

        async bookmark(target) {
            let route = target.family == 'groups' ? 'me lists groups store' : 'me lists sources store'

            await api.route(route, { id: 'bookmarks', targetId: target.id }).post().res()

            return this.reload()
        },

        async unbookmark(target) {
            let route = target.family == 'groups' ? 'me lists groups delete' : 'me lists sources delete'

            await api.route(route, { id: 'bookmarks', targetId: target.id }).formData({ _method: 'delete' }).post().res()

            return this.reload()
        }
    }
})

export default useMyBookmarksStore
