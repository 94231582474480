<template>
    <ui-header :title="board.name || 'Dashboard'"
        :avatar="board.avatarUrl"
        :back="{ name: 'dashboards', params: { type: 'boards' } }">
        <template v-slot:actions>
            <ui-button color="blue" icon="check-square" v-if="isEditing" :action="save">
                Save
            </ui-button>

            <ui-button color="blue" @click.prevent v-if="! isEditing && isBeingSaved">
                <ui-spinner color="white" type="clip" class="text-lg mr-1"></ui-spinner>
                Saving...
            </ui-button>

            <ui-button color="blue" icon="edit" v-if="! isEditing" :action="edit">
                Edit
            </ui-button>
        </template>
    </ui-header>
</template>

<script>
import useBoardStore from '@/stores/dashboards/board'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useBoardStore, [ 'board', 'isBeingSaved', 'isEditing' ])
    },

    methods: {
        ...mapActions(useBoardStore, [ 'edit', 'save' ])
    }
}
</script>
