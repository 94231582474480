<template>
    <tr class="border-b border-gray-200 last:border-none">
        <td class="py-4 px-6 font-semibold">
            {{type.name}}
        </td>
        <td class="py-4 px-6 text-sm text-gray-700 font-medium">
            {{type.description}}
        </td>
        <td class="py-4 px-6 w-32">
            <ui-button light color="blue" :to="{ name: 'analysis.analysis.details', params: { type: type.id, id: 'new' } }">
                Choose
            </ui-button>
        </td>
    </tr>
</template>

<script>
export default {
    props: [ 'type' ]
}
</script>
