<template>
    <div>
        <component :is="store.layout.component" :store="store">
            <template v-slot:item="{ item }">
                <slot name="item" :item="item" :cards-store="store">
                    <target-card :target="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'sources' || item.family == 'groups'"></target-card>

                    <perspective-card :perspective="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'perspectives'"></perspective-card>

                    <topic-card :topic="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'topics'"></topic-card>

                    <list-card :list="item" :cards-store="store" :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[targets]': `list|${item.id}|${item.name}`, 'filter[date]': 'past:1|months' } }" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'lists'"></list-card>

                    <analysis-card :analysis="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'analyses'"></analysis-card>

                    <board-card :board="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'dashboards' && item.type == 'board'"></board-card>
                    <report-card :report="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'dashboards' && item.type == 'report'"></report-card>
                </slot>
            </template>

            <template v-slot:placeholder="{ item }">
                <slot name="placeholder" :item="item" :cards-store="store">
                    <perspective-placeholder-card v-if="store.layout.id == 'grid'"></perspective-placeholder-card>
                    <perspective-placeholder-row v-if="store.layout.id == 'table'"></perspective-placeholder-row>
                </slot>
            </template>
        </component>
    </div>
</template>

<script>
import AnalysisCard from '@/components/analysis/stream-cards/analysis-card'
import BoardCard from '@/components/dashboards/stream-cards/board-card'
import ListCard from '@/components/targets/stream-cards/list'
import PerspectiveCard from '@/components/content/stream-cards/perspective'
import PerspectivePlaceholderCard from '@/components/content/stream-cards/perspective-placeholder'
import PerspectivePlaceholderRow from '@/components/content/stream-cards/perspective-placeholder-row'
import ReportCard from '@/components/dashboards/stream-cards/report-card'
import TargetCard from '@/components/targets/stream-cards/target'
import TopicCard from '@/components/content/stream-cards/topic'

import { breakpoint } from '@/helpers'

export default {
    components: {
        AnalysisCard, BoardCard, ListCard, PerspectiveCard, PerspectivePlaceholderCard, PerspectivePlaceholderRow,
        ReportCard, TargetCard, TopicCard
    },

    data: () => ({
        breakpoint: breakpoint
    }),

    watch: {
        breakpoint() {
            this.store.refreshLayouts(this.breakpoint.w)
        }
    },

    mounted() {
        this.store.refreshLayouts(this.breakpoint.w)
    }
}
</script>
