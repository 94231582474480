import { defineContentStreamStore } from '@/stores/reusable/stream'

export const useTargetsTargetStreamStore = defineContentStreamStore({
    id: 'targetsTargetStream',

    polling: true,

    supportsPerspectives: false
})

export default useTargetsTargetStreamStore
