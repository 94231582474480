<template>
    <ui-async :guards="organization">
        <ui-header :title="organization.name" :avatar="organization.avatarUrl" :back="{ name: 'accounts.organizations' }">
            <template v-slot:navigation>
                <router-link :to="{ name: 'accounts.organizations.organization.information', params: { organizationId: organization.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Information
                </router-link>
                <router-link :to="{ name: 'accounts.organizations.organization.licenses', params: { organizationId: organization.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-3" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Licenses
                </router-link>
                <router-link :to="{ name: 'accounts.organizations.organization.workspaces', params: { organizationId: organization.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-3" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Workspaces
                </router-link>
                <router-link :to="{ name: 'accounts.organizations.organization.members', params: { organizationId: organization.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Members
                </router-link>
            </template>

            <template v-slot:actions>
                <div id="header-actions"></div>
            </template>
        </ui-header>

        <router-view></router-view>
    </ui-async>
</template>

<script>
import useOrganizationStore from '@/admin/stores/accounts/organization'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useOrganizationStore, [
            'organization'
        ])
    }
}
</script>
