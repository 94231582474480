import defineCards from '@/stores/reusable/cards'
import useMyBookmarksStore from '@/stores/me/bookmarks'

export const useTargetsIndexBookmarksCardsStore = defineCards({
    id: 'targetsIndexBookmarksCards',

    source: useMyBookmarksStore(),

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: 'desc',
            apply: items => items.sort((a, b) => b.title.localeCompare(a.title))
        }
    ]
})

export default useTargetsIndexBookmarksCardsStore
