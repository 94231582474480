import api from '../../../api'
import useMyPerspectivesStore from '../../me/perspectives'

import { defineStore } from 'pinia'

export const defineKeywordsWidget = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,
            type: settings.type,
            undeletable: settings.undeletable ?? false,
            uneditable: settings.uneditable ?? false,

            perspectiveId: settings.perspectiveId,

            period: 3,
            showPerspectiveName: true,

            keywords: [],

            editForm: {
                period: null,
                showPerspectiveName: null,
                search: '',
                selectedPerspective: null
            },

            isNew: settings.isNew || false,
            isLoading: false,

            refreshInterval: 30,
            lastRefresh: null
        }),

        getters: {
            perspective(store) {
                let perspectives = useMyPerspectivesStore()

                return perspectives.items.find(l => l.id == store.perspectiveId)
            },

            editFormPerspectives(store) {
                let perspectives = useMyPerspectivesStore()

                return store.editForm.search
                    ? perspectives.items.filter(ch => ch.name.toLowerCase().includes(store.editForm.search.toLowerCase()))
                    : perspectives.items
            },

            isConfigured() {
                return this.perspective
            }
        },

        actions: {
            initialize() {
                this.refresh()
                return this
            },

            load() {
                this.isLoading = true

                api.route('me perspectives keywords', [ this.perspectiveId ])
                    .query({ period: this.period })
                    .get()
                    .json(res => {
                        this.keywords = res.data
                        this.isLoading = false
                    })
            },

            edit() {
                this.editForm.period = this.period
                this.editForm.showPerspectiveName = this.showPerspectiveName
                this.editForm.search = ''
                this.editForm.selectedPerspective = this.perspective
            },

            save() {
                this.perspectiveId = this.editForm.selectedPerspective?.id
                this.showPerspectiveName = this.editForm.showPerspectiveName
                this.period = this.editForm.period
                this.load()
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                this.$dispose()
            },

            refresh() {
                if (! this.isConfigured) return

                this.load()
                this.lastRefresh = + new Date
            },

            layoutUpdated() {
            },

            serialize() {
                return {
                    perspectiveId: this.perspective?.id,
                    period: this.period,
                    showPerspectiveName: this.showPerspectiveName
                }
            },

            dependencies() {
                return {
                    'perspectives': [ this.perspective?.id ]
                }
            }
        }
    })
}

export default defineKeywordsWidget
