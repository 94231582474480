import useContentStreamStore from '@/stores/content/stream'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTaggingStore from '@/stores/tagging/tagging'

import { defineStore } from 'pinia'

export const useContentPerspectivesPerspectivePage = defineStore({
    id: 'contentPerspectivesPerspectivePage',

    state: () => ({
        perspective: {}
    }),

    getters: {
        title: store => store.perspective?.name ? `${store.perspective.name} | Content` : '',

        streamStore(state) {
            return useContentStreamStore()
        }
    },

    actions: {
        async afterEnter(to, from) {
            useMyBookmarksStore().initialize()

            await Promise.all([
                useMyPerspectivesStore().initialize(),
                useMySearchTopicsStore().initialize(),
                useTaggingStore().initialize(),
                useMyTargetListsStore().initialize()
            ])

            this.initializeStream(to)
        },

        async afterLeave(to, from) {
            if (to.path != from.path) this.streamStore.reset()
        },

        initializeStream(to) {
            let contentStream = this.streamStore
            let myPerspectives = useMyPerspectivesStore()

            this.perspective = to.params.perspectiveId != 'new' ? myPerspectives.find(to.params.perspectiveId) : null

            // Add perspective ID to the query to be applied by Stream.navigatedTo
            if (this.perspective) to.query.perspective = this.perspective.id.toString()

            contentStream.initialize()
            contentStream.navigatedTo(to)

            contentStream.searchContext = this.selectedTargetList?.name || 'All Content'
        },

        refreshPerspective() {
            this.perspective = this.streamStore.appliedPerspective = useMyPerspectivesStore().find(this.perspective.id)
        }
    }
})

export default useContentPerspectivesPerspectivePage
