import defineCards from '@/stores/reusable/cards'
import useListSuggestionsStore from '@/stores/targets/list-suggestions'
import useTargetsMyListsListPage from '@/pages/targets-my-lists-list'

import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const useTargetsListSuggestionsCardsStore = defineCards({
    id: 'targetsListSuggestionsCards',

    source: defineStore({
        id: 'targetsListSuggestionsCardsSource',
        getters: {
            items() {
                return useTargetsMyListsListPage().type == 'groups'
                    ? useListSuggestionsStore().suggestedGroups : useListSuggestionsStore().suggestedSources
            },
            isInitialized() {
                return useListSuggestionsStore().isInitialized
            },
            isLoading() {
                return useListSuggestionsStore().isLoading
            }
        }
    })(),

    layoutOptions: [
        {
            id: 'grid',
            name: 'Grid',
            icon: 'view-grid',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/grid').default),
            settings: { showCharts: true, rows: 5 }
        },
        {
            id: 'table',
            name: 'Table',
            icon: 'menu',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/table').default),
            settings: { rows: 15 }
        }
    ],

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: 'desc',
            apply: items => items
        }
    ]
})

export default useTargetsListSuggestionsCardsStore
