<template>
    <div class="pb-6 px-8 max-w-11xl mx-auto">
        <ui-cards-toolbar :store="membersCardsStore"></ui-cards-toolbar>

        <ui-cards :store="membersCardsStore">
            <template v-slot:item="{ item }">
                <member-card :organization="organization" :member="item" :cards-store="membersCardsStore"></member-card>
            </template>
        </ui-cards>

        <div class="mt-6">
            <div class="flex items-center">
                <span>&bull;&bull;&bull;&bull;</span>
                <span class="text-sm text-gray-500 ml-1.5">Organization Admin - Manages the organization. Can add existing members to all workspaces and even new members to this organization. Each organization needs at least one admin.</span>
            </div>

            <div class="flex items-center">
                <span>&bull;&bull;&bull;</span>
                <span class="text-sm text-gray-500 ml-1.5">Workspace Owner - Owns the workspace. Can add existing members within the organization to this workspace. Each workspace needs to have an owner.</span>
            </div>
            <div class="flex items-center">
                <span>&bull;&bull;</span>
                <span class="text-sm text-gray-500 ml-1.5">Workspace Admin - Helps with the workspace management. Can add existing members within the organization to this workspace.</span>
            </div>
            <div class="flex items-center">
                <span>&bull;</span>
                <span class="text-sm text-gray-500 ml-1.5">Workspace Member - Has access to the workspace, but cannot manage the members.</span>
            </div>
        </div>
    </div>
</template>

<script>
import MemberCard from '@/admin/components/accounts/stream-cards/member'

import useMembersCardsStore from '@/admin/stores/accounts/members-cards'
import useOrganizationStore from '@/admin/stores/accounts/organization'

import { mapState, mapStores } from 'pinia'

export default {
    components: {
        MemberCard
    },

    computed: {
        ...mapStores(useMembersCardsStore),

        ...mapState(useOrganizationStore, [
            'organization'
        ])
    }
}
</script>
