import api from '@/api'
import { defineStore } from 'pinia'

export const defineContentPerspectiveActivityByDayAnalysisStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        loadSeriesPromise: null,

        isLoading: false,

        styles: { 'heatmap.range': 'single' },

        series: []
    }),

    actions: {
        loadSeries(filters) {
            let date = { type: 'after', date: { gte: new Date(new Date().setFullYear(new Date().getFullYear() - 1)) } }

            this.isLoading = true

            this.loadSeriesPromise = api.route('me analyses series').json(
                {
                    type: 'content-publish-times',
                    fresh: true,
                    configuration: {
                        date,
                        granularity: 'daily',
                        series: [
                            {
                                datasetType: 'inline-perspective',
                                label: 'Untitled Perspective',
                                color: '#000000',
                                meta: { type: 'daily' },
                                datasetFilters: filters
                            }
                        ]
                    }
                }
            ).post().json(response => {
                this.series = response.series
                this.isLoading = false
            })
        },

        resetSeries() {
            this.series = []
        }
    }
})

export default defineContentPerspectiveActivityByDayAnalysisStore
