import defineAnalysisContentPerformanceWidget from './widgets/analysis-content-performance'
import defineAnalysisContentBreakdownBySourceWidget from './widgets/analysis-content-breakdown-by-source'
import defineAnalysisContentBreakdownByGroupWidget from './widgets/analysis-content-breakdown-by-group'
import defineAnalysisContentPublishTimesWidget from './widgets/analysis-content-publish-times'
import defineAnalysisContentPullPushWidget from "./widgets/analysis-content-pull-push"
import defineAnalysisContentSentimentWidget from "./widgets/analysis-content-sentiment"
import defineAnalysisContentTopPlatformsWidget from './widgets/analysis-content-top-platforms'
import defineAnalysisContentKeywordsWidget from '@/stores/dashboards/widgets/analysis-content-keywords'
import defineAnalysisTargetPublishTimesWidget from './widgets/analysis-target-publish-times'
import defineAnalysisTargetAudienceWidget from '@/stores/dashboards/widgets/analysis-target-audience'
import defineKeywordsWidget from './widgets/keywords'
import defineHeadingWidget from './widgets/heading'
import defineStreamWidget from './widgets/stream'
import defineImageWidget from './widgets/image'
import defineTextWidget from './widgets/text'

import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'
import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export const useBoardStore = defineStore({
    id: 'board',

    state: () => ({
        board: {},
        boardLoaded: false,
        boardPromise: null,

        contents: null,

        isBeingSaved: false,
        isEditing: false,

        selectedWidget: null,
        selectedWidgetId: null,

        widgetBeingDeleted: null,
        widgetBeingDeletedId: null,

        lastWidgetId: 0,

        widgetTypes: [
            {
                id: 'analysis-content-performance',
                name: 'Content Performance Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 25,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentPerformanceWidget
            },
            {
                id: 'analysis-content-breakdown-by-source',
                name: 'Top Sources Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 40, minH: 70,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentBreakdownBySourceWidget
            },
            {
                id: 'analysis-content-breakdown-by-group',
                name: 'Top Groups Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 40, minH: 70,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentBreakdownByGroupWidget
            },
            {
                id: 'analysis-content-publish-times',
                name: 'Content Publish Times Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 20,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentPublishTimesWidget
            },
            {
                id: 'analysis-content-pull-push',
                name: 'Content Pull/Push Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 25,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentPullPushWidget
            },
            {
                id: 'analysis-content-sentiment',
                name: 'Content Sentiment',
                icon: 'analysis',
                showBackground: true,
                minW: 40, minH: 35,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentSentimentWidget
            },
            {
                id: 'analysis-content-top-platforms',
                name: 'Top Platforms Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 40, minH: 70,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentTopPlatformsWidget
            },
            {
                id: 'analysis-content-keywords',
                name: 'Content Keywords Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 25,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentKeywordsWidget
            },
            {
                id: 'analysis-target-publish-times',
                name: 'Target Publish Times Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 18,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisTargetPublishTimesWidget
            },
            {
                id: 'analysis-target-audience',
                name: 'Target Audience Analysis',
                icon: 'analysis',
                showBackground: true,
                minW: 25, minH: 25,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisTargetAudienceWidget
            },
            {
                id: 'keywords',
                name: 'Keywords',
                icon: 'align-center',
                showBackground: true,
                minW: 15, minH: 25,
                defineStore: defineKeywordsWidget
            },
            {
                id: 'stream',
                name: 'Stream',
                icon: 'stream',
                showBackground: true,
                minW: 20, minH: 50,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineStreamWidget
            },
            {
                id: 'image',
                name: 'Image',
                icon: 'image',
                minW: 10, minH: 10,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineImageWidget
            },
            {
                id: 'heading',
                name: 'Heading',
                icon: 'text-size',
                minW: 7, minH: 7, startingW: 14, startingH: 7,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineHeadingWidget
            },
            {
                id: 'text',
                name: 'Text',
                icon: 'text-word',
                showBackground: true,
                inline: true,
                minW: 10, minH: 5, startingW: 20, startingH: 10,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineTextWidget
            }
        ]
    }),

    actions: {
        async initialize(id) {
            this.$reset()

            await this.load(id)

            this.startUpdating()
        },

        async load(id, force = false) {
            if (this.boardLoaded && ! force) return Promise.resolve()
            if (this.boardPromise) return this.boardPromise

            return this.boardPromise = api.route('me dashboards details', { id }).get().json(res => {
                this.board = res.data
                this.boardLoaded = true
                this.boardPromise = null

                this.contents = this.board.contents.map(w => this.loadWidget(w))
            })
        },

        async reload() {
            if (! this.board.id) return

            return this.load(this.board.id, true)
        },

        edit() {
            useModalsBoardEditStore().cancel()

            this.isEditing = true
        },

        save() {
            this.isBeingSaved = true

            this.contents.forEach(w => w.isNew = false)

            if (this.selectedWidget) {
                this.saveWidgetBeingEdited()
            }

            let contents = JSON.stringify(this.contents.map(w => ({
                x: w.x, y: w.y,
                w: w.w, h: w.h,
                type: w.type.id,
                state: w.serialize()
            })))

            let dependencies = JSON.stringify(this.contents
                .map(w => w.dependencies())
                .reduce((allDeps, deps) => {
                    Object.entries(deps).forEach(([ relation, ids ]) => {
                        allDeps[relation] = [ ...(allDeps[relation] || []), ...ids.filter(v => v) ]
                    })
                    return allDeps
                }, {}))

            return api.route('me dashboards update', { id: this.board.id }).formData({
                _method: 'put',
                name: this.board.name,
                description: this.board.description,
                contents,
                dependencies
            }).post().json(res => {
                this.selectedWidgetId = null
                this.selectedWidget = null
                this.isBeingSaved = false
                this.isEditing = false
            })
        },

        addWidget(type, edit = true) {
            let lastNonEmptyRow = Math.max(...this.contents.map(w => w.y + w.h - 1))

            let widget = this.createWidget(type, {
                x: 0, y: lastNonEmptyRow > 0 ? lastNonEmptyRow + 1 : 0,
                w: type.startingW ?? type.minW, h: type.startingH ?? type.minH,
                isNew: true
            })

            this.contents.push(widget)

            if (edit) {
                this.editWidget(widget)
                this.edit()
            }

            return widget
        },

        loadWidget(widget) {
            let type = this.widgetTypes.find(t => t.id == widget.type)

            return this.createWidget(type, { ...widget, ...widget.state })
        },

        createWidget(type, settings) {
            let index = this.lastWidgetId++

            let store = type.defineStore({
                id: `dashboard${this.board.id}Widgets${index}`,
                i: `board-${this.board.id}-${type.id}-${index}`,
                ...settings,
                type
            })

            return store().initialize()
        },

        async expandWidget(widget) {
            this.selectedWidgetId = widget.$id
            this.selectedWidget = widget

            await nextTick()

            useModal().show('dashboard-expanded-widget')
        },

        closeExpandedWidget() {
            this.selectedWidgetId = null
            this.selectedWidget = null

            useModal().hide('dashboard-expanded-widget')
        },

        async editWidget(widget) {
            if (! widget.type.inline) {
                await this.expandWidget(widget)
            }

            this.selectedWidgetId = widget.$id
            this.selectedWidget = widget
            this.selectedWidget.edit()
        },

        stopEditingWidget() {
            this.closeExpandedWidget()

            this.selectedWidgetId = null
            this.selectedWidget = null

            useModal().hide('dashboard-expanded-widget')
        },

        async saveWidgetBeingEdited() {
            await this.selectedWidget.save()

            this.selectedWidgetId = null
            this.selectedWidget = null
        },

        async deleteWidget(widget) {
            this.widgetBeingDeleted = widget

            await useDeleteConfirmationModal().open('Dashboard widget')
                .then(() => {
                    this.contents = this.contents.filter(w => w !== this.widgetBeingDeleted)
                    this.widgetBeingDeleted.destroy()
                })

            this.widgetBeingDeleted = null
        },

        stopDeletingWidget() {
            this.widgetBeingDeleted = null
        },

        startUpdating() {
            this.refreshInterval = setInterval(() => {
                this.contents.forEach(widget => {
                    if (widget.lastRefresh + widget.refreshInterval * 60000 < + new Date) {
                        widget.refresh()
                    }
                })
            }, 60000)
        },

        stopUpdating() {
            clearInterval(this.refreshInterval)
        },

        layoutUpdated() {
            this.contents.forEach(w => w.layoutUpdated())
        }
    }
})

export default useBoardStore
