import api from '@/api'

import useMyNotificationsStore from '@/stores/me/notifications'

import { defineStore } from 'pinia'

export const useSupportChatStore = defineStore({
    id: 'supportChat',

    state: () => ({
        isShown: false,
        isInitialized: false,
        isThreadLoading: false,
        isCreatingNewThread: false,
        areNotificationsSnoozed: false,

        threads: [],
        thread: null,

        errors: {
            name: null,
            message: null
        },

        newThreadCuratedName: '',
        newThreadName: '',
        message: '',

        timeout: null
    }),

    actions: {
        async initialize() {
            if (this.isInitialized) return

            await this.load()
            this.poll()

            this.isInitialized = true
        },

        load() {
            api.route('support chat threads')
                .get()
                .json(res => {
                    this.threads = res.data

                    if (! this.isShown && ! this.areNotificationsSnoozed && this?.threads.some(t => t.unseenAgentMessagesCount)) {
                        this.triggerNotification()
                    }

                    if (this.threads.length === 0) {
                        this.newThread()
                    }
                })
        },

        newThread() {
            this.isCreatingNewThread = true
        },

        saveNewThread() {
            const name = this.newThreadCuratedName === 'Other issues' ? this.newThreadName : this.newThreadCuratedName

            this.errors = { name: null, message: null }
            if (! name) { this.errors.name = ['The name field is required!'] }
            if (! this.message) { this.errors.message = ['The message field is required!'] }
            if (! name || ! this.message) { return }

            api.route('support chat threads store')
                .formData({
                    name: name,
                    body: this.message,
                    currentUrl: window.location.href,
                    userAgent: window.navigator.userAgent
                })
                .post()
                .json(res => {
                    this.resetThread()
                    this.loadThread(res.data.id)
                    this.message = ''
                })
        },

        loadThread(id) {
            this.isThreadLoading = true

            api.route('support chat threads details', { id })
                .get()
                .json(res => {
                    this.thread = res.data
                    this.isThreadLoading = false

                    this.markThreadAsRead()
                })
        },

        refreshThread() {
            if (! this.thread.id) { return }

            api.route('support chat threads details', { id: this.thread.id })
                .get()
                .json(res => {
                    this.thread = res.data

                    this.markThreadAsRead()
                })
        },

        resetThread() {
            this.thread = null
            this.isCreatingNewThread = false
        },

        markThreadAsRead() {
            api.route('support chat threads mark-as-read', { id: this.thread.id })
                .post()
                .res(() => {
                    //
                })
        },

        sendMessage() {
            this.errors = { name: null, message: null }
            if (! this.message) { return this.errors.message = ['The message field is required!'] }

            api.route('support chat threads send-message', { id: this.thread.id })
                .formData({
                    body: this.message,
                    currentUrl: window.location.href,
                    userAgent: window.navigator.userAgent
                })
                .post()
                .json(res => {
                    this.thread.messages.push(res.data)
                })

            this.message = ''
        },

        triggerNotification() {
            useMyNotificationsStore().pushToast({
                uniqueId: 'new-support-message',
                type: 'info',
                title: 'New support message',
                text: 'You have a new support message.',
                action: 'Show',
                onAction: toast => {
                    this.toggle()
                },
                onDismiss: () => {
                    this.snoozeNotifications(3600)
                }
            })
        },

        snoozeNotifications(seconds) {
            this.areNotificationsSnoozed = true

            setTimeout(() => {
                this.areNotificationsSnoozed = false
            }, seconds * 1000)
        },

        poll() {
            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                this.load()

                if (this.thread) {
                    this.refreshThread()
                }

                this.poll()
            }, this.isShown ? 5000 : 2 * 60000)
        },

        toggle() {
            this.isShown = ! this.isShown

            if (this.isShown) {
                this.poll()
            }
        }
    }
})

export default useSupportChatStore
