<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store">
        <template v-slot:applied>
            <template v-if="value.type == 'contains-image'">
                <ui-icon name="image" class="mr-1"></ui-icon>
                <span>Contains Image</span>
            </template>
            <template v-else-if="value.type == 'contains-similar-image'">
                <ui-icon name="image" class="mr-1"></ui-icon>
                <span>Contains similar Image</span>
            </template>
            <template v-else-if="value.type == 'contains-video'">
                <ui-icon name="video" class="mr-1"></ui-icon>
                <span>Contains Video</span>
            </template>
        </template>

        <template v-slot:options>
            <div class="w-48">
                <ui-dropdown-link icon="image" :checkbox="filter.multiple" :selected="value && value.type == 'contains-image'" @click="store.addFilter(filter.id, { type: 'contains-image', id: 0 })">
                    Contains Image
                </ui-dropdown-link>

                <ui-dropdown-link icon="video" :checkbox="filter.multiple" :selected="value && value.type == 'contains-video'" @click="store.addFilter(filter.id, { type: 'contains-video', id: 0 })">
                    Contains Video
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    computed: {
        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    }
}
</script>
