<template>
    <div class="relative" v-if="store.showsResultsBar">
        <div class="px-1 mb-8 mx-auto">
            <div class="flex justify-between items-center">
                <div class="flex items-center space-x-6">
                    <ui-async :guards="store.itemsCountTotal.data" :spinner="{ type: 'clip' }" v-if="store.appliedFilters.length">
                        Showing
                        <span class="font-medium">{{ $number(store.itemsCountTotal.data) }}{{ ! store.exactCount && store.itemsCountTotal.data == 10000 ? '+' : '' }}</span>
                        results

                        <template v-slot:loading>
                            Retrieving results count...
                        </template>
                    </ui-async>

                    <a href="#" class="inline-flex items-center text-sm" :class="{ 'text-blue-800 font-semibold': store.isShowingQuickInspect, 'text-blue-500 hover:text-blue-800 font-medium': ! store.isShowingQuickInspect && store.isQuickInspectAvailable, 'text-gray-400 font-medium': ! store.isQuickInspectAvailable }" @click.prevent="store.toggleQuickInspect" v-if="store.resultsToolbar.tags !== false">
                        <ui-icon name="navigation.analysis" class="mr-1"></ui-icon>
                        Inspect
                    </a>

                    <toolbar-analyze :store="store" v-if="store.analyses.length && store.appliedPerspective"></toolbar-analyze>

                    <a href="#" class="inline-flex items-center text-sm text-blue-500 hover:text-blue-800 font-medium" @click.prevent="openExportingModal({streamStore: store})" v-if="store.exportable">
                        <ui-icon name="download" class="text-xl mr-1"></ui-icon>
                        Export
                    </a>

                    <a href="#" class="inline-flex items-center text-sm text-blue-500 hover:text-blue-800 font-medium" @click.prevent="openImportingModal" v-if="store.importable">
                        <ui-icon name="upload" class="text-xl mr-1"></ui-icon>
                        Import
                    </a>

                    <ui-dropdown class="mt-1" :disabled="! store.isBulkTaggingAvailable" width="w-56" v-tooltip="! store.isBulkTaggingAvailable ? 'Bulk Tagging is only available with less than 250 results' : ''" @opened="loadStreamTags(store)" @closed="resetStreamTags(store)" v-if="store.resultsToolbar.tags !== false">
                        <template v-slot:trigger>
                            <a href="#" class="inline-flex items-center text-sm font-medium" :class="{ 'text-blue-500 hover:text-blue-800': store.isBulkTaggingAvailable, 'text-gray-400': ! store.isBulkTaggingAvailable }">
                                <ui-icon name="tags" class="text-xl mr-1"></ui-icon>
                                Tags
                            </a>
                        </template>
                        <template v-slot:content>
                            <tags-dropdown-content :stream="store"></tags-dropdown-content>
                        </template>
                    </ui-dropdown>

                    <slot name="results-actions"></slot>
                </div>

                <div class="flex items-center space-x-4">
                    <results-toolbar-sorting :store="store" v-if="store.sortingOptions.length"></results-toolbar-sorting>
                    <results-toolbar-view :store="store"></results-toolbar-view>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
            </div>
        </div>

        <transition
            enter-active-class="transition ease duration-150 transform"
            enter-class="opacity-0 -translate-y-full"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease duration-150 transform"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-full"
        >
            <stream-content-statistics :store="store" v-if="store.isShowingQuickInspect"></stream-content-statistics>
        </transition>

        <div class="flex justify-center text-sm w-full absolute -top-1 pointer-events-none" v-if="store.itemsNewCount.data">
            <a href="#" class="inline-flex items-center px-6 h-9 leading-4 bg-blue-500 text-white font-medium shadow-md rounded-full bg-opacity-85 hover:bg-blue-600 pointer-events-auto" @click.prevent="showNewItems()">
                {{$number(store.itemsNewCount.data)}} new items
            </a>
        </div>

        <Teleport to="#root">
            <import-options-modal :stream-store="store"></import-options-modal>
        </Teleport>
    </div>
</template>

<script>
import ImportOptionsModal from '@/components/targets/modals/list-import-targets';
import ResultsToolbarSorting from '@/components/ui/cards/toolbar/results-sorting'
import ResultsToolbarView from '@/components/ui/cards/toolbar/results-view'
import StreamContentStatistics from '../statistics/content'
import TagsDropdownContent from '@/components/me/tags/contextual-menu-content'
import ToolbarAnalyze from './toolbar-analyze'

import useTaggingStore from '@/stores/tagging/tagging'
import useModalsExportOptionsStore from '@/stores/modals/export-options'
import useModalsListImportTargetsStore from '@/stores/targets/modals/list-import-targets'

import { scroll } from '@/helpers'
import { mapActions } from 'pinia'

export default {
    components: {
        ImportOptionsModal, ResultsToolbarSorting, ResultsToolbarView, StreamContentStatistics, TagsDropdownContent,
        ToolbarAnalyze
    },

    data: () => ({
        scroll
    }),

    methods: {
        showNewItems() {
            this.store.showNewItems()

            document.querySelector('#app-content').scrollTo(0, 0)
        },

        ...mapActions(useModalsExportOptionsStore, { openExportingModal: 'open' } ),
        ...mapActions(useModalsListImportTargetsStore, { openImportingModal: 'open' } ),
        ...mapActions(useTaggingStore, [ 'loadStreamTags', 'resetStreamTags' ])
    },

    watch: {
        'scroll.position'(val) { if (val > 300) this.showInspect = false }
    }
}
</script>
