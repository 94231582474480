<template>
    <ui-contextual-menu>
        <ui-dropdown-link v-if="! member.disabledAt" icon="eye-off" @click="disableMember(member)">
            Disable
        </ui-dropdown-link>

        <ui-dropdown-link v-else icon="eye" @click="enableMember(member)">
            Enable
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" @click="deleteMember(member)">
            Delete
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useMembersStore from '@/admin/stores/accounts/members'

import { mapActions } from 'pinia'

export default {
    props: [ 'organization', 'member' ],

    methods: {
        ...mapActions(useMembersStore, {
            'disableMember': 'disable',
            'enableMember': 'enable',
            'deleteMember': 'delete'
        })
    }
}
</script>
