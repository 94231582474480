import defineCards from '@/stores/reusable/cards'

import useAccountsOrganizationsOrganizationWorkspacePage from '@/admin/pages/accounts-organizations-organization-workspace'

import {sortAlphabetically, textSearch} from '@/helpers'
import {markRaw} from 'vue'

export const useWorkspaceMembersCardsStore = defineCards({
    id: 'workspaceMembersCards',

    source: null,
    filter: items => textSearch(useAccountsOrganizationsOrganizationWorkspacePage().search, items, i => i.name),

    layoutOptions: [
        {
            id: 'table',
            name: 'Table',
            icon: 'menu',
            available: true,
            component: markRaw(require('@/components/ui/cards/layouts/table').default),
            settings: { showCharts: true }
        }
    ],
    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'desc',
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        }
    ]
})

export default useWorkspaceMembersCardsStore
