<template>
    <div class="border-2 border-gray-50 rounded-lg min-w-0 overflow-hidden flex-1">
        <template v-if="store.selectedTargets[0]">
            <list-card :cards-store="analysisDatasetCardsStore" :list="store.selectedTargets[0]" mode="row" v-if="store.selectedTargets[0].family == 'lists'">
            </list-card>
            <target-card :cards-store="analysisDatasetCardsStore" :target="store.selectedTargets[0]" mode="row" v-else>
            </target-card>
        </template>
    </div>
</template>

<script>
import ListCard from '@/components/targets/stream-cards/list'
import TargetCard from '@/components/targets/stream-cards/target'

import useAnalysisDatasetCardsStore from '@/stores/analysis/dataset-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        ListCard, TargetCard
    },

    computed: {
        ...mapStores(useAnalysisDatasetCardsStore)
    }
}
</script>
