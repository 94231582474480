<template>
    <div class="pb-6 px-8 max-w-11xl mx-auto">
        <ui-cards-toolbar :store="licensesCardsStore"></ui-cards-toolbar>

        <ui-cards :store="licensesCardsStore">
            <template v-slot:item="{ item }">
                <license-card :organization="organization" :license="item" :cards-store="licensesCardsStore"></license-card>
            </template>
        </ui-cards>

        <Teleport to="#header-actions">
            <ui-button icon="plus-square" color="blue" :action="createLicense">
                New License
            </ui-button>

            <form class="w-56 relative" @submit.prevent>
                <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                    <ui-icon name="search" class="text-gray-500"></ui-icon>
                </div>

                <input type="text" ref="input" v-model="$page.search"
                       autocomplete="off" spellcheck="false"
                       class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
            </form>
        </Teleport>
    </div>
</template>

<script>
import LicenseCard from '@/admin/components/accounts/stream-cards/license'

import useOrganizationStore from '@/admin/stores/accounts/organization'
import useLicensesCardsStore from '@/admin/stores/accounts/licenses-cards'
import useLicenseSettingsFormStore from '@/admin/stores/accounts/license-form'

import { useRouter } from '@/admin/helpers'

import { mapState, mapStores } from 'pinia'

export default {
    components: {
        LicenseCard
    },

    computed: {
        ...mapStores(useLicensesCardsStore),

        ...mapState(useOrganizationStore, [
            'organization'
        ])
    },

    methods: {
        createLicense() {
            useRouter().push({ name: 'accounts.organizations.organization.license', params: { licenseId: 'new' } })
        }
    }
}
</script>
