<template>
    <div>
        <div>
            <div class="flex h-screen bg-gray-75">
                <app-navigation></app-navigation>

                <div class="flex-1 h-full overflow-y-auto" id="app-content">
                    <app-warnings></app-warnings>
                    <router-view></router-view>
                </div>

                <notifications-toaster></notifications-toaster>
            </div>
        </div>

        <notifications-overlay notifications-url="/api/me/notifications"></notifications-overlay>
        <help-overlay></help-overlay>
        <quick-search-overlay></quick-search-overlay>
        <support-chat-overlay></support-chat-overlay>

        <auth-session-lost-modal></auth-session-lost-modal>
        <content-detail-modal></content-detail-modal>
        <content-perspective-new-modal></content-perspective-new-modal>
        <content-video-download-modal></content-video-download-modal>
        <debug-modal></debug-modal>
        <delete-confirmation-modal></delete-confirmation-modal>
        <export-options-modal></export-options-modal>
        <my-targets-resource-unavailable></my-targets-resource-unavailable>
        <tags-manage-modal></tags-manage-modal>
        <workspaces-manage-modal></workspaces-manage-modal>
        <targets-list-merge-modal></targets-list-merge-modal>

        <div class="bg-gray-900 rounded-lg shadow-md border flex px-1 py-0.5 absolute right-4 bottom-4 z-40">
            <a href="#" @click.prevent="showQuickSearch" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-400 hover:text-gray-50" v-tooltip.top="'Quick-Search'">
                <ui-icon name="search"></ui-icon>
            </a>

            <a href="#" @click.prevent="toggleSupportChatOverlay" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-400 hover:text-gray-50 relative" v-tooltip.top="'Support Chat'">
                <ui-icon name="message-circle"></ui-icon>
                <span class="absolute -bottom-1 block px-1 h-3 flex items-center justify-center rounded bg-red-500 text-2xs text-white" aria-hidden="true" v-if="unreadSupportMessagesCount">{{unreadSupportMessagesCount > 99 ? '✻' : unreadSupportMessagesCount}}</span>
            </a>

            <a href="#" @click.prevent="toggleNotificationsOverlay" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl rounded-sm transition ease-in-out duration-150 text-gray-400 hover:text-gray-50 relative" v-tooltip.top="'Notifications'">
                <ui-icon name="navigation.notifications"></ui-icon>
                <span class="absolute -bottom-1 block px-1 h-3 flex items-center justify-center rounded bg-red-500 text-2xs text-white" aria-hidden="true" v-if="unreadNotificationsCount">{{ unreadNotificationsCount > 99 ? '✻' : unreadNotificationsCount }}</span>
            </a>
        </div>

        <div class="bg-blue-700 text-white rounded-lg shadow-md border flex px-4 py-0.5 absolute right-40 bottom-4 z-40" v-if="$my.isImpersonating">
            <div class="h-10 flex items-center">
                <p>You're impersonating <span class="font-medium">{{$my.user.email}}</span>. <a class="underline font-medium" href="#" @click.prevent="$my.stopImpersonating">Stop impersonating</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavigation from './AppNavigation'
import AppWarnings from './AppWarnings'
import AuthSessionLostModal from './components/auth/modals/session-lost'
import ContentDetailModal from '@/components/content/modals/content-detail'
import ContentPerspectiveNewModal from '@/components/content/modals/perspective-new'
import ContentVideoDownloadModal from '@/components/content/modals/video-download'
import ExportOptionsModal from '@/components/modals/export-options'
import MyTargetsResourceUnavailable from '@/components/targets/modals/my-targets-resource-unavailable'
import TagsManageModal from '@/components/me/tags/manage-modal'
import WorkspacesManageModal from './components/me/workspaces/modals/manage-workspaces'
import TargetsListMergeModal from '@/components/targets/modals/list-merge'

import { trackScrolling } from '@/helpers/scroll'

export default {
    components: {
        AppNavigation, AppWarnings, AuthSessionLostModal, ContentDetailModal, ContentPerspectiveNewModal, ContentVideoDownloadModal,
        ExportOptionsModal, MyTargetsResourceUnavailable, TagsManageModal, WorkspacesManageModal, TargetsListMergeModal
    },

    mounted() {
        trackScrolling()
    }
}
</script>
