import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useContentPerspectivesPerspectivePage from '@/pages/content-perspectives-perspective'
import useMyPerspectivesStore from '@/stores/me/perspectives'

export const useContentModalsPerspectiveEditStore = defineForm({
    id: 'contentModalsPerspectiveEdit',

    inputs: () => ({
        perspective: {},
        name: null,
        filters: null,
        sortingOption: null,
        sortingDirection: null,
        layout: null,
        topicId: null
    }),

    submitRequest() {
        if (! this.inputs.perspective?.id) {
            trackEvent('content', 'new-perspective-saved')
        }

        let route = this.inputs.perspective && this.inputs.perspective.id
            ? [ 'me perspectives update', { id: this.inputs.perspective.id } ]
            : [ 'me perspectives store' ]

        return api.route(...route)
            .formData({
                _method: this.inputs.perspective && this.inputs.perspective.id ? 'put' : 'post',
                name: this.inputs.name,
                filters: this.inputs.filters,
                'sorting[option]': this.inputs.sortingOption,
                'sorting[direction]': this.inputs.sortingDirection,
                layout: this.inputs.layout,
                topicId: this.inputs.topicId
            })
            .post()
    },

    async onResponse(res) {
        await useMyPerspectivesStore().reload()

        useModal().hide('content-perspective-edit')

        if (! this.inputs.perspective || ! this.inputs.perspective.id) {
            useRouter().push({ name: 'content.perspectives.perspective', params: { perspectiveId: res.data.id } })
        } else {
            useContentPerspectivesPerspectivePage().refreshPerspective()
        }

        this.fulfillPromise()
    },

    actions: {
        open(perspective = {}, stream = null) {
            this.reset()

            this.inputs.perspective = perspective
            this.inputs.name = perspective?.name

            this.inputs.filters = stream?.getContentQueryFilters(false)
            this.inputs.sortingOption = stream?.sorting.id
            this.inputs.sortingDirection = stream?.sorting.direction
            this.inputs.layout = stream?.layout.id
            this.inputs.topicId = stream?.appliedSearchTopic?.id

            useModal().show('content-perspective-edit')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('content-perspective-edit')

            this.fulfillPromise()
        },

        async delete() {
            try {
                await useMyPerspectivesStore().delete({ id: this.inputs.perspective.id })
            } catch (e) {
                return
            }

            useModal().hide('content-perspective-edit')
            useRouter().push({ name: 'content.perspectives' })

            this.fulfillPromise()
        }
    }
})

export default useContentModalsPerspectiveEditStore
