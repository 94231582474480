<template>
    <ui-contextual-menu v-bind="$attrs">
        <lists-section align="right" :target="target"></lists-section>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link @click="toggleMonitoringOption(target, 'imageTextExtraction')">
            {{target.monitoringOptions.includes('imageTextExtraction') ? 'Disable' : 'Enable'}} Image Text Extraction
        </ui-dropdown-link>

        <ui-dropdown-link @click="toggleMonitoringOption(target, 'videoDownload')">
            {{target.monitoringOptions.includes('videoDownload') ? 'Disable' : 'Enable'}} Video Download
        </ui-dropdown-link>

        <ui-dropdown-link @click="toggleMonitoringOption(target, 'videoTranscription')">
            {{target.monitoringOptions.includes('videoTranscription') ? 'Disable' : 'Enable'}} Video Transcription
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link @click="openHistoryDownloadModal(target)">
            Download Full History
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'targets.target.information', params: { type: target.family, modelType: target.type, id: target.id } }).href">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="external-link" :href="target.link" target="_blank" rel="noreferrer noopener" :disabled="! target.link">
            Open original
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(target)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import ListsSection from './target-lists'

import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useTargetsModalsHistoryDownloadStore from '@/stores/targets/modals/history-download'
import useTargetsMyTargetsStatsStore from '@/stores/targets/my-targets/stats'

import { mapActions } from 'pinia'

export default {
    components: { ListsSection },

    props: [ 'target' ],

    methods: {
        ...mapActions(useMyMonitoredTargetsStore, { _toggleMonitoringOption: 'toggleMonitoringOption' }),
        ...mapActions(useTargetsModalsHistoryDownloadStore, { openHistoryDownloadModal: 'open' }),

        async toggleMonitoringOption(target, option) {
            await this._toggleMonitoringOption(target, option)
            useTargetsMyTargetsStatsStore().reload()
        }
    }
}
</script>
