import api from '@/api'

import {defineStore} from 'pinia'
import useAccountSettingsFormStore from "@/stores/settings/account-form";

export const useAccountSettingsStore = defineStore({
    id: 'accountSettings',

    state: () => ({
        loading: false,
        loadingSubscriptions: false,

        currentProfilePhotoUrl: null,
        hasTwoFactorAuth: false,
        hasFacebookLogin: false,

        timezones: [],
        languages: []
    }),

    actions: {
        load() {
            this.loading = true
            this.loadingSubscriptions = true

            Promise.all([
                api.route('settings account').get().json(),
                api.route('settings timezones').get().json(),
                api.route('settings languages').get().json()
            ]).then(values => {
                useAccountSettingsFormStore().inputs.name = values[0].name
                useAccountSettingsFormStore().inputs.email = values[0].email

                this.currentProfilePhotoUrl = values[0].current_profile_photo_url
                this.hasTwoFactorAuth = values[0].has_two_factor_auth
                this.hasFacebookLogin = values[0].has_facebook_login

                this.timezones = values[1].data
                this.languages = values[2].data

                this.loading = false
            })

            api.route('settings subscriptions').get()
                .json(res => {
                    useAccountSettingsFormStore().inputs.newsletter_subscription = res.data.newsletter
                    useAccountSettingsFormStore().inputs.platform_updates_subscription = res.data.platform_updates

                    this.loadingSubscriptions = false
                })
        }
    },
})

export default useAccountSettingsStore
