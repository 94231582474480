<template>
    <div class="absolute inset-0 bg-gray-900 bg-opacity-50 py-16 z-[9999]" @keydown="keyPressed" v-if="isShown" @click.prevent>
        <div class="w-full max-w-2xl mx-auto text-sm text-gray-50 font-semibold px-4 mb-1 text-right">
            Esc to close
        </div>

        <div class="w-full max-w-2xl mx-auto bg-white rounded-md shadow-md max-h-full flex flex-col overflow-hidden" v-click-away="hide">
            <form class="relative p-4" @submit.prevent>
                <div class="absolute top-4 left-7 h-9 flex items-center pointer-events-none text-xl z-40">
                    <ui-icon name="search" class="text-gray-500"></ui-icon>
                </div>

                <input type="text" ref="input" v-model="search" @input="loadDebounced" @keydown.esc="hide"
                    autocomplete="off" spellcheck="false"
                    class="block w-full h-9 pl-10 pr-28 py-3 rounded-t-md placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-500 sm:text-sm border-0 z-30 max-h-48 resize-none rounded-b-md text-gray-800 bg-gray-100" placeholder="Search...">
            </form>

            <div class="text-gray-800 text-center pt-3 pb-6" v-if="inMaintenance">
                Quick-search service is currently under maintenance, please try again in a few moments.
            </div>

            <div class="overflow-y-auto divide-y divide-gray-200" ref="results" v-if="results.length > 0">
                <div :key="index" v-for="result, index in results">
                    <app-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-if="result.resultType == 'app'"></app-result>
                    <analysis-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'analysis'"></analysis-result>
                    <dashboard-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'dashboard'"></dashboard-result>
                    <feature-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'feature'"></feature-result>
                    <perspective-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'perspective'"></perspective-result>
                    <perspective-new-result :query="search" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'perspective-new'"></perspective-new-result>
                    <perspective-new-overlay-result :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'perspective-new-overlay'"></perspective-new-overlay-result>
                    <perspective-search-topic-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'perspective-search-topic'"></perspective-search-topic-result>
                    <perspective-target-list-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'perspective-target-list'"></perspective-target-list-result>
                    <search-topic-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'search-topic'"></search-topic-result>
                    <target-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'target'"></target-result>
                    <target-list-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click="selectResult(result)" v-else-if="result.resultType == 'target-list'"></target-list-result>
                </div>
            </div>
        </div>

        <div class="w-full max-w-2xl mx-auto text-sm text-gray-50 font-semibold px-4 mt-1">
            Press <span class="font-mono text-xs">/</span> or <span class="font-mono text-xs">{{ isMac ? 'CMD+P' : 'CTRL+P' }}</span> to quickly open quick-search from anywhere
        </div>
    </div>
</template>

<script>
import AnalysisResult from './results/analysis-result'
import AppResult from './results/app-result'
import DashboardResult from './results/dashboard-result'
import FeatureResult from './results/feature-result'
import PerspectiveResult from './results/perspective-result'
import PerspectiveNewResult from './results/perspective-new-result'
import PerspectiveNewOverlayResult from './results/perspective-new-overlay-result'
import PerspectiveSearchTopicResult from './results/perspective-search-topic-result'
import PerspectiveTargetListResult from './results/perspective-target-list-result'
import SearchTopicResult from './results/search-topic-result'
import TargetResult from './results/target-result'
import TargetListResult from './results/target-list-result'

import useMyQuickSearchStore from '@/stores/me/quick-search'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        AnalysisResult, AppResult, DashboardResult, FeatureResult, PerspectiveResult, PerspectiveNewResult,
        PerspectiveNewOverlayResult, PerspectiveSearchTopicResult, PerspectiveTargetListResult, SearchTopicResult,
        TargetResult, TargetListResult
    },

    data: () => ({
        focusedResult: null,
        focusedResultRef: null
    }),

    computed: {
        ...mapState(useMyQuickSearchStore, [ 'isShown', 'inMaintenance', 'onSelect', 'results', 'resultsPending' ]),
        ...mapWritableState(useMyQuickSearchStore, [ 'search' ])
    },

    methods: {
        ...mapActions(useMyQuickSearchStore, [ 'hide', 'loadDebounced', 'clear' ]),

        keyPressed(ev) {
            if (ev.key == 'Enter') {
                ev.preventDefault()
                return this.selectResult(this.focusedResult)
            }

            if (ev.key != 'ArrowDown' && ev.key != 'ArrowUp') return

            ev.preventDefault()

            let selectPrevious = ev.key == 'ArrowUp'
            let currentIndex = this.results.indexOf(this.focusedResult)

            let focusIndex = currentIndex === false
                ? (selectPrevious ? this.results.length - 1 : 0)
                : (selectPrevious ? currentIndex - 1 : currentIndex + 1)

            if (focusIndex >= this.results.length) focusIndex = 0
            if (focusIndex < 0) focusIndex = this.results.length - 1

            this.focusedResult = this.results[focusIndex]
            this.focusedResultRef = this.$refs[`result-${focusIndex}`][0]

            this.focusedResultRef.$el.scrollIntoView({ block: 'nearest' })
        },

        selectResult(result) {
            if (this.onSelect && ! result.alwaysOpen) {
                this.onSelect(result)
            } else {
                this.$refs[`result-${this.results.indexOf(result)}`][0].open()
            }
            this.hide()
        }
    },

    watch: {
        isShown(val) {
            if (val) this.$nextTick(() => this.$refs.input.focus())
        }
    }
}
</script>
