import useLicensesStore from '@/admin/stores/accounts/licenses'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationLicensesPage = defineStore({
    id: 'accountsOrganizationsOrganizationLicensesPage',

    state: () => ({
        search: ''
    }),

    actions: {
        beforeEnter(to) {
            useLicensesStore().initialize(to.params.organizationId, true)
        }
    }
})

export default useAccountsOrganizationsOrganizationLicensesPage
