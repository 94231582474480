import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesModalsEditStore from '@/stores/workspaces/modals/manage'
import defineForm from '@/stores/reusable/form'

import api from '@/api'
import { trackEvent } from '@/analytics'

export const useWorkspacesFormsEditStore = defineForm({
    id: 'workspacesFormsEdit',

    inputs: () => ({
        workspace: {},
        name: null,
        description: null,
        avatar: null
    }),

    submitRequest() {
        if (! this.inputs.workspace.id) {
            trackEvent('accounts', 'new-workspace-saved')
        }

        let route = this.inputs.workspace.id
            ? [ 'me workspaces update', { workspaceId: this.inputs.workspace.id } ]
            : [ 'me workspaces store' ]

        return api.route(...route)
            .formData({
                _method: this.inputs.workspace.id ? 'put' : 'post',
                name: this.inputs.name,
                description: this.inputs.description,
                avatar: this.inputs.avatar
            })
            .post()
    },

    onResponse() {
        useMyWorkspacesStore().reload()
        useWorkspacesModalsEditStore().listWorkspaces()
    },

    actions: {
        initialize(workspace) {
            this.reset()

            this.inputs.workspace = workspace || {}
            this.inputs.name = workspace?.name
            this.inputs.description = workspace?.description
            this.inputs.avatar = null
        },

        cancel() {
            useWorkspacesModalsEditStore().listWorkspaces()
        }
    }
})

export default useWorkspacesFormsEditStore
