<template>
    <div>
        <a :class="effectiveClass" :href="href || '#'" :target="target" :rel="rel" @click.stop="click">
            <slot name="before">
                <div class="flex items-center shrink-0 w-6" v-if="isSelectable">
                    <ui-icon name="check" class="text-base" v-if="!checkbox && selected"></ui-icon>
                    <input type="checkbox" class="h-4 w-4 text-blue-600 border-gray-300 pointer-events-none" v-if="checkbox" :checked="selected">
                </div>
            </slot>

            <div :class="effectiveIconClass" v-if="icon">
                <slot name="icon">
                    <ui-icon :name="icon" v-if="icon !== 'none'"></ui-icon>
                </slot>
            </div>
            <div :class="effectiveIconClass" v-else-if="image">
                <img :src="image" class="rounded-full" v-if="image !== 'none'">
            </div>

            <div class="truncate w-full">
                <slot></slot>
            </div>
        </a>

        <div class="absolute z-50 top-0" :class="submenuClasses" v-if="submenu" v-show="effectiveShowSubmenu" @click.stop>
            <div class="rounded py-0.5 drop-shadow-lg bg-white bg-opacity-95 backdrop-blur ring-1 ring-black ring-opacity-5 text-left">
                <slot name="submenu"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import findParentComponentByName from '../../helpers/find-parent-component-by-name'

export default {
    name: 'ui-dropdown-link',

    props: {
        checkbox: {},
        disabled: Boolean,
        href: {},
        icon: {},
        image: {},
        keepOpen: { type: Boolean, default: false },
        selected: {},
        submenu: Boolean,
        submenuAlign: {},
        submenuWidth: {},
        rel: {},
        target: {},
        variant: {}
    },

    data: () => ({
        showSubmenu: false,
        links: []
    }),

    computed: {
        effectiveClass() {
            let classes = 'flex items-center my-0.5 mx-1 px-3 rounded leading-5 focus:outline-none transition duration-150 ease-in-out'

            if (this.variant === 'large') {
                classes += ' h-9 text-base'
            } else {
                classes += ' h-7 text-sm'
            }

            if (this.effectiveShowSubmenu) {
                classes += ' bg-gray-200 text-gray-900';
            } else if (this.disabled) {
                classes += ' text-gray-400'
            } else {
                classes += ' text-gray-900 hover:bg-blue-400 hover:text-white'
            }

            return classes
        },

        effectiveIconClass() {
            let classes = 'flex shrink-0 items-center justify-center'

            if (this.variant === 'large') {
                classes += ' w-6 text-lg mr-2'
            } else {
                classes += ' w-4 text-base mr-2'
            }

            return classes
        },

        effectiveShowSubmenu() {
            return this.showSubmenu && ! this.disabled
        },

        submenuClasses() {
            if (this.submenuAlign == 'left') {
                return `left-full ml-2 ${this.submenuWidth || 'w-48'}`
            } else {
                return `right-full mr-2 ${this.submenuWidth || 'w-48'}`
            }
        },

        isSelectable() {
            return this.selected !== undefined || this.checkbox !== undefined
        }
    },

    methods: {
        click($ev) {
            // if href is set act as a normal link with no special processing
            if (this.href) return

            $ev.preventDefault()

            this.$emit('click')

            if (this.checkbox || this.disabled) {
                return
            }

            if (this.submenu) {
                let isShown = this.showSubmenu

                let dropdown = findParentComponentByName(this, [ 'ui-dropdown', 'ui-dropdown-link' ])
                if (dropdown) dropdown.closeSubmenus()

                this.showSubmenu = ! isShown
                if (! this.showSubmenu) this.closeSubmenus()

                return
            }

            if (! this.keepOpen) {
                findParentComponentByName(this, [ 'ui-dropdown' ]).close()
            }
        },

        closeSubmenus() {
            this.links.forEach(link => {
                link.showSubmenu = false
                link.closeSubmenus()
            })
        }
    },

    created() {
        let dropdown = findParentComponentByName(this, [ 'ui-dropdown', 'ui-dropdown-link' ])
        if (dropdown) dropdown.links.push(this)
    }
}
</script>
