<template>
    <div class="flex-1 min-h-0 px-8 py-6">
        <ui-form :store="workspacesFormsAddUserStore" v-slot="{ inputs, submitting }">
            <div>
                <label class="block text-sm text-gray-700 font-medium mb-1">
                    User
                </label>

                <div>
                    <ui-select :get-option-label="option => option.name" :options="users" v-model="inputs.user">
                        <template v-slot:option="option">
                            <div class="flex items-center py-1">
                                <div class="w-6 h-6 rounded-full shadow-sm mr-3">
                                    <img :src="option.avatarUrl" class="w-full h-full object-cover rounded-full">
                                </div>
                                {{ option.name }}
                            </div>
                        </template>
                    </ui-select>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button :action="cancel">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="blue" :disabled="submitting || ! inputs.user.id">
                    Confirm
                </ui-button>
            </div>
        </ui-form>
    </div>
</template>

<script>
import useMyUsersStore from '@/stores/me/users'
import useWorkspacesFormsAddUserStore from '@/stores/workspaces/forms/add-user'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useWorkspacesFormsAddUserStore),
        ...mapState(useMyUsersStore, { users: 'items' })
    },

    methods: {
        ...mapActions(useWorkspacesFormsAddUserStore, [ 'cancel' ])
    }
}
</script>
