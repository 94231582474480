import useMyNotificationsStore from '@/stores/me/notifications'

import api from '@/api'

export function isLanguageTranslatable(code) {
    return ! ['00', null, 'und', 'en'].includes(code)
}

export default function translate(item, params = {}, beforeFn = null, afterFn = null) {
    item.translation = { isLoading: true, text: undefined }

    if (beforeFn) { beforeFn() }

    return api.route('monitor content translate', { id: params.id ?? item.id })
        .json(params)
        .post()
        .error(422, e => {
            if (e.json.code === 'invalid_translation_language_pair_error') {
                return useMyNotificationsStore().pushToast({
                    type: 'error',
                    title: e.json.title,
                    text: e.json.message
                })
            }

            return e
        })
        .text(text => item.translation.text = text)
        .finally(() => {
            item.translation.isLoading = false

            if (afterFn) { afterFn() }
        })
}
