import { defineContentPerformanceAnalysisStore } from '@/stores/analysis/analyses/content-performance'

import { useRouter } from '@/helpers'

import { add as addTime, parseISO } from 'date-fns'

export const useFeaturesFeatureInformationActivityAnalysisStore = defineContentPerformanceAnalysisStore({
    id: 'featuresFeatureInformationActivityAnalysis',

    name: '',

    date: { type: 'past', date: { past: 1, unit: 'years' } },
    granularity: 'week',

    series: [
        {
            color: '#10b981',
            label: 'Content published',
            datasetType: 'inline-perspective',
            datasetFilters: { 'mentions': [] },
            meta: { aggregator: 'count', metric: 'all' }
        },
        {
            color: '#2172f0',
            label: 'Average content interactions',
            datasetType: 'inline-perspective',
            datasetFilters: { 'mentions': [] },
            meta: { aggregator: 'average', metric: 'interactions' }
        }
    ],

    chainAnalysisOptions: (store, options) => {
        options.chart.spacing = [ 0, 0, 10, 0 ]
        options.plotOptions.series.marker.enabled = true
        options.xAxis.labels.rotation = 0
        options.legend.margin = 15

        return options
    },

    resolvePointUrl: (point, store) => useRouter().resolve({
        name: 'features.feature.stream',
        params: { type: store.feature.type, id: store.feature.id },
        query: { 'filter[date]': `in-range:${point.date}|${addTime(parseISO(point.date), { [store.granularity + 's']: 1 }).toISOString()}` }
    }).fullPath,

    state: () => ({
        feature: null
    }),

    actions: {
        setFeature(feature) {
            this.feature = feature

            this.series[0].datasetFilters['mentions'] = [ { type: feature.type, id: feature.id, name: feature.name, exclude: false } ]
            this.series[1].datasetFilters['mentions'] = [ { type: feature.type, id: feature.id, name: feature.name, exclude: false } ]
        }
    }
})

export default useFeaturesFeatureInformationActivityAnalysisStore
