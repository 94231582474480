import api from '@/api'

import { defineStore } from 'pinia'

export const useTargetsMyTargetsStatsStore = defineStore({
    id: 'targets-myTargets-stats',

    state: () => ({
        stats: {},

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            api.route('me targets stats index').get().json(res => {
                this.stats = res

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        }
    }
})

export default useTargetsMyTargetsStatsStore
