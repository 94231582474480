import useMyRecentFeaturesStore from '@/stores/me/recent-features'
import useFeaturesLibraryTypesStore from '@/stores/features/library-types'
import useFeaturesMediaStreamStore from '@/stores/features/media-stream'
import useFeaturesStreamStore from '@/stores/features/stream'

import { useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const useFeaturesLibraryDetailsPage = defineStore({
    id: 'featuresLibraryDetailsPage',

    state: () => ({
        type: null
    }),

    getters: {
        title: () => 'Library | Features',

        streamStore(store) {
            return [ 'image-cluster', 'video-cluster' ].includes(store.type.type)
                ? useFeaturesMediaStreamStore()
                : useFeaturesStreamStore()
        }
    },

    actions: {
        afterEnter(to) {
            if (! to.params.type) return useRouter().replace({ name: 'features', params: { type: 'image-cluster' } })

            useMyRecentFeaturesStore().initialize()

            this.type = useFeaturesLibraryTypesStore().find(to.params.type)

            if (to.params.type == 'image-cluster') {
                this.streamStore.initialize()

                this.streamStore.apiQueryExtraParameters = {}

                this.streamStore.setLayout('images')

                this.streamStore.navigatedTo(to, {
                    'media': { type: 'contains-image', id: 0 }
                }, {
                    'date': { type: 'past', date: { past: 1, unit: 'months' } }
                })
            } else if (to.params.type == 'video-cluster') {
                this.streamStore.initialize()

                this.streamStore.apiQueryExtraParameters = {}

                this.streamStore.setLayout('videos')

                this.streamStore.navigatedTo(to, {
                    'media': { type: 'contains-video', id: 0 }
                }, {
                    'date': { type: 'past', date: { past: 1, unit: 'months' } }
                })
            } else {
                this.streamStore.initialize()
                this.streamStore.navigatedTo(to, {
                    'type': this.type.type
                })
            }

            this.streamStore.searchContext = this.type.name
        }
    }
})

export default useFeaturesLibraryDetailsPage
