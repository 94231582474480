import useOrganizationsStore from '@/admin/stores/accounts/organizations'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsIndexPage = defineStore({
    id: 'accountsOrganizationsIndexPage',

    state: () => ({
        search: ''
    }),

    actions: {
        beforeEnter() {
            useOrganizationsStore().initialize()
        }
    }
})

export default useAccountsOrganizationsIndexPage
