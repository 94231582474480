import api from '@/admin/api'

import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import { defineStore } from 'pinia'

export const useLicensesStore = defineStore({
    id: 'licenses',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize(organizationId, force = false) {
            return this.load(organizationId, force)
        },

        async load(organizationId, force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('admin accounts organizations licenses', { organizationId: organizationId }).get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadDetails(organizationId, id) {
            return api.route('admin accounts organizations licenses details', { organizationId: organizationId, licenseId: id }).get().json(res => {
                Object.assign(this.find(res.data.id), res.data)
            })
        },

        async all(organizationId) {
            await this.load(organizationId)
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async delete(organizationId, license, force = false) {
            await useDeleteConfirmationModal().open('License')
                .then(async () => {
                    await api.route('admin accounts organizations licenses delete', { organizationId: organizationId, licenseId: license.id })
                        .formData({ _method: 'delete', force: force ? 1 : 0 })
                        .post()
                        .res()
                })

            return this.load(organizationId, true)
        },

        async safeDelete(organizationId, license) {
            try {
                return await this.delete(organizationId, license)
            } catch (e) {}
        }
    }
})

export default useLicensesStore
