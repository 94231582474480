<template>
    <ui-modal name="content-topic-edit" :title="topic.id ? 'Edit a Topic' : 'Create a new Topic'" @opened="opened" @closed="cancel">
        <ui-form :store="contentModalsTopicEditStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div class="mt-8">
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Query
                </label>

                <div>
                    <search-input
                        v-model="inputs.query"
                        :allow-multi-line="true"
                        :allow-highlighting="true"
                        :allow-suggesting-topics="true"
                        :allow-unwrapping-topics="true"
                        classes="overflow-y-hidden rounded-sm shadow-sm border border-gray-300 bg-white px-1.5"
                        focused-classes="h-auto max-h-32 ring-1 ring-indigo-500 border-indigo-500"
                        blurred-classes="h-9"
                    ></search-input>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" size="lg" class="mr-auto" :action="deleteTopic" :disabled="submitting" v-if="inputs.topic.id">
                    Delete
                </ui-button>

                <ui-button type="submit" size="lg" :color="inputs.topic.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.topic.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import SearchInput from '@/components/ui/stream/toolbar/search/input.vue'

import useContentModalsTopicEditStore from '@/stores/content/modals/topic-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    components: {
        SearchInput
    },

    computed: {
        ...mapStores(useContentModalsTopicEditStore),

        topic() { return this.contentModalsTopicEditStore.inputs.topic }
    },

    methods: {
        ...mapActions(useContentModalsTopicEditStore, { 'cancel': 'cancel', 'deleteTopic': 'delete' }),

        opened() {
            this.$refs.nameInput.focus()
        }
    }
}
</script>
