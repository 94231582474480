import useFeaturesFeaturePage from '@/pages/features-feature'
import useFeaturesFeatureInformationActivityAnalysisStore from '@/stores/features/feature/information-activity-analysis'

import { defineStore, mapState } from 'pinia'

export const useFeaturesFeatureInformationPage = defineStore({
    id: 'featuresFeatureInformationPage',

    state: () => ({
        selectedHeatmap: 'Daily'
    }),

    getters: {
        ...mapState(useFeaturesFeaturePage, [ 'type', 'feature', 'model' ])
    },

    actions: {
        afterEnter(to) {
            useFeaturesFeatureInformationActivityAnalysisStore().setFeature(this.feature)
            useFeaturesFeatureInformationActivityAnalysisStore().loadSeries()
        }
    }
})

export default useFeaturesFeatureInformationPage
