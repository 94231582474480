import api from '@/api'

import { defineStore } from 'pinia'

export const defineImageWidget = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,
            type: settings.type,
            undeletable: settings.undeletable ?? false,
            uneditable: settings.uneditable ?? false,

            isUploading: false,
            uploadErrors: null,

            url: settings.url,
            stagedUrl: null,

            editForm: {
                file: '',
            },

            isNew: settings.isNew || false,

            refreshInterval: 30,
            lastRefresh: null
        }),

        getters: {
            isConfigured() {
                return !! this.url
            }
        },

        actions: {
            initialize() {
                this.refresh()
                return this
            },

            load() {
                if (! this.isConfigured) return
            },

            setFile(file) {
                this.editForm.file = file

                const fr = new FileReader()

                fr.addEventListener('load', () => {
                    this.stagedUrl = fr.result
                })

                fr.readAsDataURL(this.editForm.file)
            },

            async upload() {
                this.isUploading = true
                this.uploadErrors = null

                return api.route('me files store', { collection: 'dashboards' }).formData({
                    file: this.editForm.file
                })
                    .post()
                    .error(422, err => {
                        this.uploadErrors = err.json.errors
                        this.isUploading = false
                    })
                    .json(res => {
                        this.url = res.data.url
                        this.isUploading = false
                    })
            },

            edit() {
                this.stagedUrl = null
            },

            async save() {
                return this.upload()
            },

            destroy() {
                this.$dispose()
            },

            refresh() {
                this.load()
                this.lastRefresh = + new Date
            },

            layoutUpdated() {
            },

            serialize() {
                return {
                    url: this.url
                }
            },

            dependencies() {
                return {}
            }
        }
    })
}

export default defineImageWidget
