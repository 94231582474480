<template>
    <div class="bg-white w-full shadow rounded-sm flex flex-col items-center mb-4" :class="{ 'py-52': ! $page.target.metrics.contentCount, 'py-6': $page.target.metrics.contentCount }" v-if="! $page.target.monitored">
        <div class="flex items-center" :class="{ 'flex-col': ! $page.target.metrics.contentCount, 'space-x-4': $page.target.metrics.contentCount }">
            <ui-icon name="eye-off" class="text-gray-400" :class="{ 'text-6xl': ! $page.target.metrics.contentCount, 'text-[26px]': $page.target.metrics.contentCount }"></ui-icon>
            <div :class="{ 'space-y-2 text-center': ! $page.target.metrics.contentCount, 'space-y-1': $page.target.metrics.contentCount }">
                <div class="font-semibold" :class="{ 'mt-6': ! $page.target.metrics.contentCount }">
                    This Target is currently not monitored.
                </div>
                <div v-if="isUnavailable">
                    This Target is no longer available, it might have been banned or removed by the owner.
                </div>
                <div v-else>
                    To start monitoring this Target, click on the button below.
                </div>
            </div>
        </div>
        <div class="flex items-center space-x-4" :class="{ 'mt-6': ! $page.target.metrics.contentCount, 'mt-3': $page.target.metrics.contentCount }" v-if="! isUnavailable">
            <ui-button color="blue" icon="plus" light :action="() => addToMyTargets($page.target)">
                Start monitoring
            </ui-button>
        </div>
    </div>
</template>

<script>
import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useTargetsTargetPage from '@/pages/targets-target'

import { useRouter } from '@/helpers'
import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useMyMonitoredTargetsStore, [ 'isMonitored' ]),

        async addToMyTargets(target) {
            await useMyMonitoredTargetsStore().addToMyTargets(target)
            await useTargetsTargetPage().reload()
            return useRouter().go()
        }
    }
}
</script>
