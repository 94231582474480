import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsMyListsPage = defineStore({
    id: 'targetsMyListsPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'My Lists | Targets'
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize()
            useMyTargetListsStore().initialize()
            useMyRecentTargetsStore().initialize()
        }
    }
})

export default useTargetsMyListsPage
