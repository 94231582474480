<template>
    <div class="relative">
        <div class="px-1 mb-6 mx-auto">
            <div class="flex justify-between items-center">
                <div class="flex items-center space-x-6">
                    <span v-if="store.seriesGeneratedAt">
                        Generated at <span class="font-medium">{{$dateTime(store.seriesGeneratedAt)}}</span>
                    </span>

                    <ui-dropdown :disabled="! store.hasValues" v-if="store.exportable" class="mt-1">
                        <template v-slot:trigger>
                            <a href="#" class="inline-flex items-center text-sm text-blue-500 hover:text-blue-800 font-medium">
                                <ui-icon name="download" class="text-xl mr-1"></ui-icon>
                                Export
                            </a>
                        </template>

                        <template v-slot:content>
                            <ui-dropdown-link @click="store.exportChart({ type: 'image/png' })">Chart - PNG image</ui-dropdown-link>
                            <ui-dropdown-link @click="store.exportChart({ type: 'image/svg+xml' })">Chart - SVG image</ui-dropdown-link>
                            <!-- <ui-dropdown-link @click="store.export({ type: 'application/pdf' })">Chart - PDF document</ui-dropdown-link> -->
                            <ui-dropdown-separator></ui-dropdown-separator>
                            <template v-if="store.exportable.data">
                                <ui-dropdown-link @click="store.export('xlsx')">Table - XLS spreadsheet</ui-dropdown-link>
                                <ui-dropdown-link @click="store.export('csv')">Table - CSV file</ui-dropdown-link>
                                <ui-dropdown-separator></ui-dropdown-separator>
                            </template>
                            <ui-dropdown-link @click="store.exportChart('xls')">Dataset - XLS spreadsheet</ui-dropdown-link>
                            <ui-dropdown-link @click="store.exportChart('csv')">Dataset - CSV file</ui-dropdown-link>
                        </template>
                    </ui-dropdown>

                    <a href="#" class="inline-flex items-center text-sm font-medium" :class="{ 'text-blue-500 hover:text-blue-800': store.hasValues, 'text-gray-600': ! store.hasValues }" @click.prevent="store.print" v-if="store.printable">
                        <ui-icon name="printer" class="text-xl mr-1"></ui-icon>
                        Print
                    </a>
                </div>

                <div class="flex items-center space-x-4">
                    <toolbar-range :store="store" v-if="store.date !== false"></toolbar-range>
                    <toolbar-grouping :store="store" v-if="store.granularity !== false"></toolbar-grouping>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
            </div>
        </div>
    </div>

    <div class="bg-white w-full shadow rounded-lg flex mb-4 p-2 space-x-2">
        <slot name="settings"></slot>
    </div>
</template>

<script>
import ToolbarGrouping from './toolbar-grouping'
import ToolbarRange from './toolbar-range'

export default {
    components: {
        ToolbarGrouping, ToolbarRange
    }
}
</script>
