<template>
    <div>
        <div class="px-8 max-w-4xl mx-auto my-8">
            <div class="bg-white w-full h-64 overflow-hidden shadow rounded-sm mb-6" v-if="$page.feature.type == 'location' && $page.feature.details.lat && $page.feature.details.lng">
                <ui-map :center="[ $page.feature.details.lat, $page.feature.details.lng ]" :marker="true"></ui-map>
            </div>

            <activity-card></activity-card>

            <div class="bg-white w-full overflow-hidden shadow rounded-sm px-5 mb-6" v-if="information.length">
                <div class="px-1 py-4">
                    <h3 class="font-semibold text-gray-900">
                        Details
                    </h3>
                </div>

                <table class="min-w-full divide-y divide-gray-200 text-sm text-gray-900 mb-3">
                    <tr :key="index" v-for="information, index in information">
                        <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium">
                            {{information.label}}
                        </td>
                        <td class="pr-1 pl-3 py-3">
                            {{information.value}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityCard from './information/activity-card'
import UiMap from '@/components/ui/map'

import useFeaturesFeatureInformationActivityAnalysisStore from '@/stores/features/feature/information-activity-analysis'

import filter from 'just-filter'
import { mapStores } from 'pinia'

export default {
    components: {
        ActivityCard, UiMap
    },

    computed: {
        ...mapStores(
            useFeaturesFeatureInformationActivityAnalysisStore
        ),

        information() {
            return filter({
                'First Seen At': this.$page.feature.firstSeenAt ? this.$dateTime(this.$page.feature.firstSeenAt) : null
            }, (k, v) => v)
        }
    }
}
</script>