import { defineContentStreamStore } from '@/stores/reusable/stream'

export const useContentModalsPerspectiveNewStreamStore = defineContentStreamStore({
    id: 'contentModalsPerspectiveNewStream',

    // showsResultsBar: false,
    // supportsSearchTopics: false,
    supportsPerspectives: false,
    updatesQuery: false
})

export default useContentModalsPerspectiveNewStreamStore
