import useSettingsAppsPage from '@/pages/settings-apps'
import useMyAppsStore from '@/stores/me/apps'

import defineCards from '@/stores/reusable/cards'

import { sortAlphabetically, textSearch } from '@/helpers'

export const useAppsCardsStore = defineCards({
    id: 'appsCards',

    source: useMyAppsStore(),
    filter: items => textSearch(useSettingsAppsPage().search, items, i => i.name),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        }
    ]
})

export default useAppsCardsStore
