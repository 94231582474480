<template>
    <div class="flex-1 min-h-0 p-8 pt-4 overflow-y-auto space-y-3 bg-gray-75 rounded-b-md">
        <ui-input placeholder="Search..." icon="search" class="bg-gray-75 border-t-0 border-x-0 border-b border-gray-400 shadow-none placeholder:text-gray-500 focus:ring-0 focus:border-gray-900 mb-4" v-model="search"></ui-input>

        <user-card :user="user" :key="user.id" v-for="user in users"></user-card>

        <div class="text-center text-sm pt-2" v-if="workspace.owned">
            <a href="#" class="text-blue-600 hover:text-blue-800" @click.prevent="addUser(workspace)">
                <ui-icon name="plus-circle"></ui-icon>
                Add a User
            </a>
        </div>
    </div>
</template>

<script>
import UserCard from '@/components/me/workspaces/cards/user'

import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { sortNaturally, textSearch } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        UserCard
    },

    data: () => ({
        search: ''
    }),

    computed: {
        ...mapState(useWorkspacesModalsManage, [ 'workspace' ]),

        users() {
            return sortNaturally(textSearch(this.search, this.workspace.users, v => v.name))
        }
    },

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'addUser' ])
    }
}
</script>
