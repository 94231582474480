<template>
    <l-map :zoom="zoom || 17" :center="latLng(center)" :options="options" class="z-10">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <slot></slot>

        <l-marker :lat-lng="latLng(marker)" :key="index" v-for="marker, index in shownMarkers"></l-marker>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import { latLng } from "leaflet"

import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
})

export default {
    components: { LMap, LTileLayer, LMarker },

    props: [ 'center', 'controls', 'marker', 'markers', 'zoom' ],

    data: () => ({
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }),

    computed: {
        shownMarkers() {
            let markers = this.markers || []

            if (this.marker instanceof Array) markers.push(this.marker)
            if (this.marker) markers.push(this.center)

            return markers
        },

        options() {
            return {
                dragging: this.controls === true,
                zoomControl: this.controls === true
            }
        }
    },

    methods: {
        latLng(val) { return latLng(val[0], val[1]) }
    }
}
</script>