<template>
    <ui-modal name="my-targets-monitoring-requests-modal" title="Monitoring requests" height="80%" max-width="1024px">
        <div>
            <monitoring-requests :requests="requests" v-if="requests.length"></monitoring-requests>
            <div class="py-4">
                <ui-infinite-loading @infinite="loadMore" :identifier="lastReloadTime"></ui-infinite-loading>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import MonitoringRequests from "@/components/targets/my-targets/monitoring-requests.vue"

import useTargetsMyTargetsMonitoringRequestsStore from "@/stores/targets/my-targets/monitoring-requests"
import useTargetsTargetTypesStore from "@/stores/targets/target-types"

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        MonitoringRequests
    },

    computed: {
        ...mapState(useTargetsMyTargetsMonitoringRequestsStore, [ 'lastReloadTime', 'requests' ]),
    },

    methods: {
        ...mapActions(useTargetsMyTargetsMonitoringRequestsStore, { 'loadMore': 'loadMore' }),
        ...mapActions(useTargetsTargetTypesStore, { 'targetTypeToHuman': 'typeToHuman' }),
    }
}
</script>
