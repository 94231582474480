import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetConnectionsStore from '@/stores/targets/target/connections'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetConnectionsPage = defineStore({
    id: 'targetsTargetConnectionsPage',

    state: () => ({
        connectionsType: null
    }),

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            useTargetsTargetConnectionsStore().load(this.target)

            this.connectionsType = to.params.connectionsType || 'upstream'
        }
    }
})

export default useTargetsTargetConnectionsPage
