<template>
    <div class="pb-8 px-8 flex justify-center space-x-4 max-w-7xl mx-auto">
        <div class="w-1/4 space-y-4">
            <div class="bg-white w-full overflow-hidden shadow rounded-sm py-2 mb-4">
                <router-link :to="{ name: 'features.feature.connections', params: { type: $page.feature.type, id: $page.feature.id, connectionsType: type.id } }" class="block font-medium px-6 py-2 hover:text-blue-600 group" :class="{'border-r-2 border-blue-500 text-blue-600': $page.connectionsType == type.id}" :key="type.id" v-for="type in types">
                    <div>{{ type.name }}</div>
                    <div class="text-xs font-normal group-hover:text-blue-500" :class="$page.connectionsType == type.id ? 'text-blue-500' : 'text-gray-800'">{{ type.description }}</div>
                </router-link>
            </div>
        </div>

        <div class="w-3/4">
            <connections-card title="Connected Sources" :connections="connectedSources" :connections-store="featuresFeatureConnectionsStore" v-if="$page.connectionsType == 'connected-sources'"></connections-card>
            <connections-card title="Coexisting Features" :connections="coexistingFeatures" :connections-store="featuresFeatureConnectionsStore" v-if="$page.connectionsType == 'coexisting-features'"></connections-card>
        </div>
    </div>
</template>

<script>
import ConnectionsCard from '@/components/targets/target/connections/connections-card'

import useFeaturesFeatureConnectionsStore from '@/stores/features/feature/connections'

import { mapState, mapStores } from 'pinia'

export default {
    components: { ConnectionsCard },

    data: () => ({
        types: [
            { id: 'connected-sources', name: 'Connected Sources', description: 'Sources connected to this feature directly or via published content.' },
            { id: 'coexisting-features', name: 'Coexisting Features', description: 'Features co-existing on the same content or soruces.' }
        ]
    }),

    computed: {
        ...mapState(useFeaturesFeatureConnectionsStore, [ 'coexistingFeatures', 'connectedSources' ]),

        ...mapStores(useFeaturesFeatureConnectionsStore)
    }
}
</script>
