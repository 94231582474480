import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesFormsAddUserStore from '@/stores/workspaces/forms/add-user'
import useWorkspacesFormsDeleteStore from '@/stores/workspaces/forms/delete'
import useWorkspacesFormsEditStore from '@/stores/workspaces/forms/edit'

import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useWorkspacesModalsManageStore = defineStore({
    id: 'workspacesModalsManage',

    state: () => ({
        workspaceId: null,
        section: 'list-workspaces'
    }),

    getters: {
        workspace() { return useMyWorkspacesStore().find(this.workspaceId) }
    },

    actions: {
        open() {
            this.listWorkspaces()

            useModal().show('workspaces-manage')
        },

        listWorkspaces() {
            this.workspaceId = null
            this.section = 'list-workspaces'
        },

        createWorkspace() {
            this.workspaceId = null
            this.section = 'edit-workspace'

            useWorkspacesFormsEditStore().initialize()
        },

        editWorkspace(workspace) {
            this.workspaceId = workspace.id
            this.section = 'edit-workspace'

            useWorkspacesFormsEditStore().initialize(workspace)
        },

        addUser(workspace) {
            this.workspaceId = workspace.id
            this.section = 'add-user'

            useWorkspacesFormsAddUserStore().initialize(workspace)
        },

        async removeUser(workspace, user) {
            await api.route('me workspaces members delete', { id: workspace.id, userId: user.id })
                .formData({ _method: 'delete' })
                .post()
                .res()

            useMyWorkspacesStore().reload()
        },

        manageUsers(workspace) {
            this.workspaceId = workspace.id
            this.section = 'manage-users'
        },

        deleteWorkspace(workspace) {
            this.workspaceId = workspace.id
            this.section = 'delete-workspace'

            useWorkspacesFormsDeleteStore().initialize(workspace)
        }
    }
})

export default useWorkspacesModalsManageStore
