<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-facebook-user"
        avatar-badge-tooltip="Facebook User"
        avatar-image-class="ring-social-facebook"
        :parent-component="{ 'facebook-post': FacebookPost, 'facebook-post-comment': _.type }"
    >
    </content-card>
</template>

<script>
import ContentCard from './content'
import FacebookPost from './facebook-post'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    data: () => ({
        FacebookPost: markRaw(FacebookPost)
    }),

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
