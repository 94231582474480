<template>
    <div>
        <template v-if="list && ! list.curated">
            <ui-dropdown-link icon="plus" @click="addToList(list, target)" v-if="! isIncludedIn(list, target)">
                Add to List
            </ui-dropdown-link>
            <ui-dropdown-link icon="remove" @click="removeFromList(list, target)" v-else>
                Remove from List
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>

        <ui-dropdown-link icon="navigation.targets" @click="addToMyTargets(target)" v-if="! isMonitored(target)">
            Add to My Targets
        </ui-dropdown-link>
        <ui-dropdown-link icon="navigation.targets" @click="removeFromMyTargets(target)" v-else>
            Remove from My Targets
        </ui-dropdown-link>

        <ui-dropdown-link icon="bookmark" @click="bookmark(target)" v-if="! isBookmarked(target)">
           Bookmark
        </ui-dropdown-link>
        <ui-dropdown-link icon="bookmark" @click="unbookmark(target)" v-if="isBookmarked(target)">
           Unbookmark
        </ui-dropdown-link>

        <ui-dropdown-link icon="folder" :submenu="true" :submenu-align="align" submenu-width="w-56">
            Lists...

            <template v-slot:submenu>
                <div class="px-2 py-1">
                    <div class="relative w-full h-7">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="listsSearch">
                    </div>
                </div>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-async :guards="areListsInitialized">
                    <div class="max-h-80 my-2 overflow-y-auto">
                        <ui-dropdown-link @click="isIncludedIn(list, target) ? removeFromList(list, target) : addToList(list, target)" :icon="true" :selected="isIncludedIn(list, target)" :checkbox="true" keep-open :key="list.id" v-for="list in lists">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar type="target-list" :name="list.name" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                {{list.name}}
                            </template>
                        </ui-dropdown-link>

                        <div class="text-xs text-gray-700 text-center py-1" v-if="! lists.length">
                            No lists have been found.
                        </div>
                    </div>
                </ui-async>
            </template>
        </ui-dropdown-link>
    </div>
</template>

<script>
import { textSearch } from '@/helpers'

import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyMonitoredTargetsStore from '@/stores/me/monitored-targets'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsListStreamStore from '@/stores/targets/list-stream'
import useTargetsListSuggestionsStore from '@/stores/targets/list-suggestions'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'target', 'list', 'align' ],

    data: () => ({
        listsSearch: ''
    }),

    computed: {
        ...mapState(useMyTargetListsStore, {
            areListsInitialized: 'isInitialized',

            lists(store) {
                let lists = this.listsSearch ? textSearch(this.listsSearch, store.items, t => t.name) : store.items

                lists = lists.sort((a, b) => a.name.localeCompare(b.name))

                let applied = lists.filter(list => store.isIncludedIn(list, this.target))
                let notApplied = lists.filter(list => ! store.isIncludedIn(list, this.target))

                return [ ...applied, ...notApplied ]
            }
        })
    },

    methods: {
        ...mapActions(useMyTargetListsStore, {
            _addToList: 'addToList',
            _removeFromList: 'removeFromList',
            isIncludedIn: 'isIncludedIn'
        }),
        ...mapActions(useMyMonitoredTargetsStore, [ 'addToMyTargets', 'isMonitored', 'removeFromMyTargets' ]),
        ...mapActions(useMyBookmarksStore, [ 'bookmark', 'unbookmark', 'isBookmarked' ]),

        async addToList(list, target) {
            await this._addToList(list, target)

            if (this.list) {
                useTargetsListStreamStore().load()
                useTargetsListSuggestionsStore().load(this.list)
            }
        },

        async removeFromList(list, target) {
            await this._removeFromList(list, target)

            if (this.list) {
                useTargetsListStreamStore().load()
                useTargetsListSuggestionsStore().load(this.list)
                useMyTargetListsStore().reload()
            }
        }
    }
}
</script>
