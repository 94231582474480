<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="trash" @click="deleteOrganization(organization)">
            Delete
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useOrganizationsStore from '@/admin/stores/accounts/organizations'

import { mapActions } from 'pinia'

export default {
    props: [ 'organization' ],

    methods: {
        ...mapActions(useOrganizationsStore, {
            'deleteOrganization': 'delete'
        })
    }
}
</script>
