<template>
    <div>
        <section class="fixed inset-y-0 right-4 max-w-full flex z-40" aria-labelledby="slide-over-heading">
            <transition
              enter-active-class="transition ease-in-out duration-200"
              enter-class="translate-x-full"
              enter-to-class="translate-x-0"
              leave-active-class="transition ease-in-out duration-200"
              leave-class="translate-x-0"
              leave-to-class="translate-x-full">
                <div class="w-screen max-w-md pt-4 pb-20" v-show="isOverlayShown">
                    <div class="h-full flex flex-col bg-white border border-gray-100 shadow-lg rounded overflow-y-scroll">
                        <h2 id="slide-over-heading" class="text-xl font-semibold px-4 pt-3 mb-2">
                            Notifications
                        </h2>

                        <ui-spinner v-if="isLoading"></ui-spinner>

                        <div v-if="notifications.length" class="relative flex-1">
                            <ul class="divide-y divide-gray-100 overflow-y-auto">
                                <li class="px-4 py-3 relative" :key="notification.id" v-for="notification in notifications" >
                                    <div class="group flex justify-between items-center">
                                        <a href="#" @click.prevent="notification.onAction ?? null" class="-m-1 p-1 block">
                                            <div class="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"></div>
                                            <div class="flex-1 flex items-center min-w-0 relative">
                                                <span class="shrink-0 inline-block relative">
                                                    <div class="h-8 w-8 rounded-full bg-gray-100 text-xl flex items-center justify-center">
                                                        <ui-icon :name="notification.data.icon || 'alert'"></ui-icon>
                                                    </div>
                                                    <span class="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-blue-400" aria-hidden="true" v-if="! notification.readAt"></span>
                                                </span>
                                                <div class="ml-3">
                                                    <p class="text-sm font-medium truncate">{{notification.data.title}}</p>
                                                    <p class="text-xs text-gray-800 leading-tight">{{notification.data.description}}</p>
                                                    <p class="flex text-2xs text-gray-700 mt-0.5">
                                                        <span class="group flex items-center">
                                                            <ui-icon name="time" class="mr-1"></ui-icon>
                                                            {{ $dateTime(notification.createdAt) }}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <ui-infinite-loading @infinite="scroll" v-if="notifications.length"></ui-infinite-loading>
                        </div>

                        <p v-else class="mx-auto my-auto text-gray-700 text-sm">
                            No notifications just yet. We're sure you'll receive some soon.
                        </p>
                    </div>
                </div>
            </transition>
        </section>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import useMyNotificationsStore from '@/stores/me/notifications'

export default {
    computed: {
        ...mapState(useMyNotificationsStore, [ 'isLoading', 'isOverlayShown', 'notifications' ])
    },

    methods: {
        ...mapActions(useMyNotificationsStore, [ 'scroll' ])
    },
}
</script>
