<template>
    <div
        v-html="text"
        class="w-5/6 rounded-lg py-2 px-4 text-sm font-medium message-text"
        :class="{ 'mr-auto bg-gray-500 text-white': message.from === 'customer', 'ml-auto bg-blue-500 text-white': message.from === 'agent' }"
    >
    </div>
</template>

<script>
import { sanitizeHTML } from '@/helpers'

import { marked } from 'marked'

export default {
    props: {
        message: {
            type: Object,
            required: true,
        }
    },

    computed: {
        text() {
            return sanitizeHTML(marked.parse(this.message.body))
        }
    }
}
</script>

<style>
.message-text h1 { font-size: 24px; line-height: 30px }
.message-text h2 { font-size: 20px; line-height: 26px }
.message-text h3 { font-size: 16px; line-height: 22px }

.message-text p:not(:last-child) { padding-bottom: 16px; }

.message-text p:after {
    content: "";
    display: inline-block;
    width: 0;
}

.message-text ul { list-style-type: disc; padding: 0 1rem }
.message-text ol { list-style-type: decimal; padding: 0 1rem }

.message-text li {
    padding-bottom: 2px;
}

.message-text a {
    font-weight: bold;
    text-decoration: underline;
}
</style>
