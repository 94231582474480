<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-facebook-page"
        avatar-badge-tooltip="Facebook Page"
        :parent-component="{ 'facebook-post': _.type, 'facebook-group-post': FacebookGroupPostCard, 'facebook-user-post': FacebookUserPostCard }"
        :hide-media-section="hideMediaSection"
    >
        <template v-slot:no-content>
            <div class="last:rounded-b-sm text-gray-500" v-if="hasNoContent">
                We weren't able to collect the content of this post. This usually means its privacy settings didn't allow us to see it.
                You can try opening the original <a class="text-blue-700" :href="content.link" target="_blank">post on Facebook</a>.
            </div>
        </template>

        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                <div class="flex items-center" v-if="content.metrics.interactions" >
                    <ui-dropdown align="left" position="top" unstyled>
                        <template v-slot:trigger>
                            <div class="flex justify-center items-center cursor-pointer">
                                <ui-icon name="interactions" class="text-xl shrink-0 text-gray-400"></ui-icon>

                                <div class="leading-none ml-1">
                                    <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions

                                    <span class="text-xs bg-green-50 border border-green-200 text-green-600 pl-1 pr-2 py-0.5 font-medium tracking-tight rounded-full ml-1" v-tooltip="{ content: `This post has <b>${content.benchmarks.reactionsPerformance} times <br>reactions</b> of an average post`, html: true }" v-if="content.labels.includes('overperforming')">
                                        <ui-icon name="arrow-up"></ui-icon>
                                        {{content.benchmarks.reactionsPerformance}}x
                                    </span>
                                    <span class="text-xs bg-red-50 border border-red-200 text-red-600 px-1 py-0.5 font-medium tracking-tight rounded-full ml-1" v-tooltip="{ content: `This post has <b>${content.benchmarks.reactionsPerformance} times <br>reactions</b> of an average post`, html: true }" v-else-if="content.labels.includes('underperforming')">
                                        <ui-icon name="arrow-down"></ui-icon>
                                        {{content.benchmarks.reactionsPerformance}}x
                                    </span>
                                </div>
                            </div>
                        </template>

                        <template v-slot:content>
                            <div class="rounded-sm shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left space-y-3 whitespace-nowrap">
                                <div class="flex items-center" v-if="content.metrics.reactions">
                                    <ui-icon name="thumbs-up" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.reactions) }}</span> reactions
                                    </div>
                                </div>

                                <div class="flex items-center space-x-2 text-sm">
                                    <div class="flex items-center" :key="reaction" v-for="count, reaction in reactions">
                                        <ui-icon :name="`reactions-${reaction}`" class="text-lg" as-image></ui-icon>
                                        <span class="font-medium ml-1">{{ $number(count) }}</span>
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.comments">
                                    <ui-icon name="comment" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.comments) }}</span> comments
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.shares">
                                    <ui-icon name="share" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.shares) }}</span> shares
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                    <ui-icon name="views" class="text-xl shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-1">
                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'
import FacebookGroupPostCard from './facebook-group-post'
import FacebookUserPostCard from './facebook-user-post'

import { markRaw } from 'vue'

export default {
    name: 'facebook-post-card',

    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    data: () => ({
        FacebookGroupPostCard: markRaw(FacebookGroupPostCard),
        FacebookUserPostCard: markRaw(FacebookUserPostCard)
    }),

    computed: {
        isShowingMetrics() {
            return this.content.metrics?.interactions
                || this.content.metrics?.views
        },

        hasNoContent() {
            return !this.content.body
                && !this.content.attachment.url
                && !this.content.parent
                && ! ['added_photos', 'photo', 'added_video', 'video'].includes(this.content.subtype)
        },

        hideMediaSection() {
            return this.content.parent && this.content.parent.images.length
        },

        reactions() {
            if (! this.content.metrics) return []

            let reactions = Object.entries(this.content.metrics.facebook).filter(([ key, value ]) => value > 0)

            reactions.sort(([ keyA, valueA ], [ keyB, valueB ]) => valueB - valueA)

            return Object.fromEntries(reactions)
        },

        significantReactions() {
            if (! this.content.metrics) return []

            let cutoff = this.content.metrics.reactions * 0.1

            return Object.entries(this.reactions)
                .filter(([ key, value ]) => value > cutoff)
                .sort((a, b) => b[1] - a[1])
                .slice(0, 3)
        },

        latestForecast() {
            if (this.content.forecasts && this.content.forecasts.length) {
                return this.content.forecasts[this.content.forecasts.length - 1]
            }

            return null
        }
    }
}
</script>
