import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyStore from '@/stores/me/my'
import useMyTargetListsStore from '@/stores/me/target-lists'

export const useTargetsModalsListDuplicateStore = defineForm({
    id: 'targetsModalsListDuplicate',

    inputs: () => ({
        list: {},
        name: null,
        workspace: {}
    }),

    submitRequest() {
        trackEvent('targets', 'duplicated-target-list-saved')

        return api.route('me lists duplicate', { id: this.inputs.list.id })
            .formData({
                _method: 'post',
                name: this.inputs.name,
                workspaceId: this.inputs.workspace.id
            })
            .post()
    },

    async onResponse(res) {
        await useMyTargetListsStore().reload()

        useModal().hide('targets-list-duplicate')

        useRouter().push({ name: 'targets.my-lists.list', params: { listId: res.data.id, type: 'sources' } })
    },

    actions: {
        open(list = {}) {
            this.reset()

            this.inputs.list = list
            this.inputs.name = `${list.name} (copy)`
            this.inputs.workspace = useMyStore().currentWorkspace

            useModal().show('targets-list-duplicate')
        },

        cancel() {
            useModal().hide('targets-list-duplicate')
        }
    }
})

export default useTargetsModalsListDuplicateStore
