<template>
    <section class="fixed inset-y-0 right-4 max-w-full flex z-40" aria-labelledby="slide-over-heading">
        <transition
              enter-active-class="transition ease-in-out duration-200"
              enter-class="translate-x-full"
              enter-to-class="translate-x-0"
              leave-active-class="transition ease-in-out duration-200"
              leave-class="translate-x-0"
              leave-to-class="translate-x-full"
        >
            <div class="h-full w-screen max-w-lg pt-4 pb-20" v-show="isOverlayOpen">
                <div class="h-full flex flex-col bg-white border border-gray-100 rounded shadow-lg">
                    <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
                        <h1 class="font-semibold">
                            {{ title }}
                        </h1>

                        <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="hideOverlay">
                            <ui-icon name="remove"></ui-icon>
                        </a>
                    </div>

                    <ui-async class="flex-1 min-h-0 px-8 py-6 overflow-y-scroll" :guards="content">
                        <help-content></help-content>
                        <p class="text-xs text-gray-600 mt-6" v-if="updatedAt">Last updated {{ $dateTime(updatedAt) }}</p>
                    </ui-async>

<!--                 <router-link v-if="mode === 'help'" :to="{ name: 'help.page', params: { page: 'home' } }" target="_blank" class="flex items-center justify-between bg-gray-50 border-t border-x border-t-gray-100 border-b border-b-gray-100 rounded-b p-5 shadow-lg">
                    <p class="text-sm font-semibold">Read full documentation</p>
                    <ui-icon name="chevron-right"></ui-icon>
                </router-link>

                <router-link v-if="mode === 'releaseNotes'" :to="{ name: 'release-notes.page', params: { page: currentReleaseNotesPage } }" target="_blank" class="flex items-center justify-between bg-gray-50 border-t border-x border-t-gray-100 border-b border-b-gray-100 rounded-b p-5 shadow-lg">
                    <p class="text-sm font-semibold">Read as full page</p>
                    <ui-icon name="chevron-right"></ui-icon>
                </router-link> -->
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import Content from '@/components/support/help/content'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        'help-content': Content
    },

    computed: {
        currentReleaseNotesPage() {
            return process.env.VUE_APP_RELEASE_NOTES
        },

        ...mapState(useSupportHelpStore, [ 'mode', 'title', 'content', 'updatedAt', 'isOverlayOpen' ])
    },

    methods: {
        ...mapActions(useSupportHelpStore, [ 'click', 'hideOverlay' ])
    }
}
</script>
