import useMyBroadcasterStore from '@/stores/me/broadcaster'
import useMyStore from '@/stores/me/my'

import api from '@/api'
import { downloadUrl, useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const useMyNotificationsStore = defineStore({
    id: 'myNotifications',

    state: () => ({
        broadcaster: null,

        isInitialized: false,
        isSubscribed: false,
        isOverlayShown: false,
        loadingPromise: null,
        isLoading: false,

        toasts: [],
        lastToastId: 0,
        notifications: []
    }),

    actions: {
        async initialize() {
            this.subscribe()
            await this.load()
        },

        toggle() {
            this.isOverlayShown = ! this.isOverlayShown

            if (this.isOverlayShown) {
                this.loadNew()
            }

            if (! this.isOverlayShown) {
                api.route('me notifications mark-as-read')
                    .json({
                        ids: this.notifications.filter(n => n.readAt === null).map(n => n.id)
                    }).post()

                this.notifications.forEach(n => n.readAt = new Date())
            }
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me notifications').get().json(res => {
                this.notifications = []

                res.data.forEach(n => {
                    n.onAction = () => this.resolveAction(n)
                    this.notifications.push(n)
                })

                this.isLoading = false
                this.isInitialized = true
            })
        },

        loadNew() {
            this.isLoading = true

            api.route('me notifications')
                .query({ after: this.notifications[0]?.createdAt })
                .get()
                .json(res => {
                    res.data.forEach(n => {
                        n.onAction = () => this.resolveAction(n)
                        this.notifications.unshift(n)
                    })

                    this.isLoading = false
                })
        },

        scroll($state) {
            let lastNotification = this.notifications[this.notifications.length - 1]

            if (! lastNotification) return $state.complete()

            api.route('me notifications')
                .query({ before: lastNotification.createdAt })
                .get()
                .json(res => {
                    if (! res.data.length) {
                        return $state.complete()
                    }

                    res.data.forEach(n => {
                        n.onAction = () => this.resolveAction(n)
                        this.notifications.push(n)
                    })

                    $state.loaded()
                })
        },

        subscribe() {
            useMyBroadcasterStore().broadcaster.private(`workspaces.${useMyStore().currentWorkspace.id}`)
                .notification((notification) => this.notify(notification))

            useMyBroadcasterStore().broadcaster.private(`users.${useMyStore().user.id}`)
                .notification((notification) => this.notify(notification))
        },

        notify(notification) {
            this.loadNew()

            if (notification.data.toasted) {
                this.pushToast({
                    type: notification.data.level,
                    title: notification.data.title,
                    text: notification.data.description,
                    action: notification.data.action ?? null,
                    onAction: () => this.resolveAction(notification)
                })
            }
        },

        pushToast(toast) {
            if (toast.uniqueId && this.toasts.find(t => t.uniqueId === toast.uniqueId)) { return }

            this.toasts.push(toast = { ...toast, id: ++this.lastToastId })

            let originalOnAction = toast.onAction
            toast.onAction = () => {
                originalOnAction && originalOnAction(toast)
                this.dismissToast(toast)
            }

            if (toast.lifetime) {
                setTimeout(() => this.dismissToast(toast), toast.lifetime * 1000)
            }

            return toast
        },

        dismissToast(toast) {
            if (toast.onDismiss) toast.onDismiss()

            this.toasts = this.toasts.filter(t => t.id != toast.id)
        },

        dismissAllToasts() {
            this.toasts = []
        },

        resolveAction(notification) {
            if (! notification.data.actionMeta) return

            if (notification.data.actionMeta.type == 'link') {
                if (notification.data.actionMeta.url[0] == '/') {
                    // local url, use router to navigate to the url to avoid full app reload
                    useRouter().replace({ path: notification.data.actionMeta.url })
                } else {
                    window.open(notification.data.actionMeta.url, notification.data.actionMeta.target ?? '_self')
                }
            } else if (notification.data.actionMeta.type == 'download') {
                downloadUrl(notification.data.actionMeta.url)
            }
        },

        reportApiError() {
            this.pushToast({
                type: 'error',
                title: 'Unexpected error',
                text: 'You might need to reload the page to recover normal functionality.<br>Please contact us if the problem persists.',
                action: 'Reload',
                onAction: toast => window.location.reload()
            })
        },

        reportForbiddenError() {
            this.pushToast({
                type: 'error',
                title: 'Permission error',
                text: 'You don\'t have permission to perform this action.<br>Please contact us if the problem persists.',
                action: 'Reload',
                onAction: toast => window.location.reload()
            })
        }
    },

    getters: {
        unreadNotificationsCount(state) {
            return state.notifications.filter(n => n.readAt === null).length
        }
    }
})

export default useMyNotificationsStore
