import useMyCuratedTopicsStore from '@/stores/me/curated-topics'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { defineStore } from 'pinia'

export const useContentTopicsPage = defineStore({
    id: 'contentTopicsPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'Topics | Content'
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            useMyCuratedTopicsStore().initialize()
            useMyPerspectivesStore().initialize()
            useMySearchTopicsStore().initialize()
            useMyTargetListsStore().initialize()
        }
    }
})

export default useContentTopicsPage
