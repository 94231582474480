<template>
    <div class="pb-6 px-8 max-w-11xl mx-auto">
        <div v-if="organizationSettingsFormStore.submitted" class="border border-green-300 bg-green-50 text-green-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
            Organization successfully updated.
        </div>

        <ui-form :store="organizationSettingsFormStore" v-slot="{ inputs, submitting, errors }" class="bg-white shadow rounded-sm">
            <div class="px-4 py-5 sm:p-8 space-y-8 divide-y divide-gray-300">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Details</h3>
                        <p class="mt-1 text-sm text-gray-500">
                            Change the basic organization information
                        </p>
                    </div>
                    <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                        <div class="flex-1 space-y-8">
                            <div>
                                <label for="name" class="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <div class="mt-1">
                                    <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                                </div>
                            </div>

                            <div>
                                <label for="note" class="block text-sm font-medium text-gray-700">
                                    Note
                                </label>
                                <div class="mt-1">
                                    <ui-input class="h-36" type="textarea" name="note" id="note" v-model="inputs.note" :errors="errors.note"></ui-input>
                                </div>
                            </div>

                            <div>
                                <label for="timezone" class="block text-sm font-medium text-gray-700">Timezone</label>
                                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="timezones" v-model="inputs.timezone" :reduce="timezone => timezone.key"></ui-select>
                            </div>

                            <div>
                                <label for="preferred_content_language" class="block text-sm font-medium text-gray-700">Preferred content language</label>
                                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="languages" v-model="inputs.preferredContentLanguage" :reduce="language => language.key"></ui-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                    <div class="md:col-span-1">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">API Limits</h3>
                        <p class="mt-1 text-sm text-gray-500">
                            Change the API limits
                        </p>
                    </div>
                    <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                        <div class="flex-1 space-y-8">
                            <table class="table-auto border-separate border-spacing-2 w-full">
                                <thead class="text-left text-sm font-medium text-gray-700">
                                <th>API Type</th>
                                <th>Organization per minute</th>
                                <th>User per minute</th>
                                </thead>

                                <tbody>
                                <tr>
                                    <td class="w-44">Global</td>
                                    <td>
                                        <ui-input type="number" name="globalApiLimitPerMinutePerOrganization" id="globalApiLimitPerMinutePerOrganization" placeholder="5000" v-model="inputs.globalApiLimitPerMinutePerOrganization" :errors="errors.globalApiLimitPerMinutePerOrganization"></ui-input>
                                    </td>
                                    <td>
                                        <ui-input type="number" name="globalApiLimitPerMinutePerUser" id="globalApiLimitPerMinutePerUser" placeholder="1000" v-model="inputs.globalApiLimitPerMinutePerUser" :errors="errors.globalApiLimitPerMinutePerUser"></ui-input>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Content</td>
                                    <td>
                                        <ui-input type="number" name="contentApiLimitPerMinutePerOrganization" id="contentApiLimitPerMinutePerOrganization" placeholder="500" v-model="inputs.contentApiLimitPerMinutePerOrganization" :errors="errors.contentApiLimitPerMinutePerOrganization"></ui-input>
                                    </td>
                                    <td>
                                        <ui-input type="number" name="contentApiLimitPerMinutePerUser" id="contentApiLimitPerMinutePerUser" placeholder="100" v-model="inputs.contentApiLimitPerMinutePerUser" :errors="errors.contentApiLimitPerMinutePerUser"></ui-input>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Features</td>
                                    <td>
                                        <ui-input type="number" name="featuresApiLimitPerMinutePerOrganization" id="featuresApiLimitPerMinutePerOrganization" placeholder="500" v-model="inputs.featuresApiLimitPerMinutePerOrganization" :errors="errors.featuresApiLimitPerMinutePerOrganization"></ui-input>
                                    </td>
                                    <td>
                                        <ui-input type="number" name="featuresApiLimitPerMinutePerUser" id="featuresApiLimitPerMinutePerUser" placeholder="100" v-model="inputs.featuresApiLimitPerMinutePerUser" :errors="errors.featuresApiLimitPerMinutePerUser"></ui-input>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Targets</td>
                                    <td>
                                        <ui-input type="number" name="targetsApiLimitPerMinutePerOrganization" id="targetsApiLimitPerMinutePerOrganization" placeholder="500" v-model="inputs.targetsApiLimitPerMinutePerOrganization" :errors="errors.targetsApiLimitPerMinutePerOrganization"></ui-input>
                                    </td>
                                    <td>
                                        <ui-input type="number" name="targetsApiLimitPerMinutePerUser" id="targetsApiLimitPerMinutePerUser" placeholder="100" v-model="inputs.targetsApiLimitPerMinutePerUser" :errors="errors.targetsApiLimitPerMinutePerUser"></ui-input>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-50 flex px-4 py-5 sm:p-8 rounded-b-lg border-t border-gray-100 flex justify-end">
                <ui-button type="submit" color="blue" :disabled="submitting">
                    Save
                </ui-button>
            </div>
        </ui-form>
    </div>
</template>

<script>
import useOrganizationSettingsFormStore from '@/admin/stores/accounts/organization-form'
import useOrganizationStore from '@/admin/stores/accounts/organization'

import { mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useOrganizationSettingsFormStore),

        ...mapState(useOrganizationStore, [
            'organization',
            'timezones',
            'languages'
        ]),
    }
}
</script>
