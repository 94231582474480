import useOrganizationsStore from '@/admin/stores/accounts/organizations'
import useModalsOrganizationCreateStore from '@/admin/stores/accounts/modals/organization-create'

import api from "@/admin/api"

import { defineForm } from "@/stores/reusable/form"
import { nextTick } from 'vue'

export const useOrganizationSettingsFormStore = defineForm({
    id: 'organizationSettingsForm',

    state: {
        organizationId: null,
    },

    inputs: () => ({
        name: null,
        note: null,
        timezone: null,
        preferredContentLanguage: null,
        ownerName: null,
        ownerEmail: null,
        globalApiLimitPerMinutePerOrganization: null,
        globalApiLimitPerMinutePerUser: null,
        contentApiLimitPerMinutePerOrganization: null,
        contentApiLimitPerMinutePerUser: null,
        featuresApiLimitPerMinutePerOrganization: null,
        featuresApiLimitPerMinutePerUser: null,
        targetsApiLimitPerMinutePerOrganization: null,
        targetsApiLimitPerMinutePerUser: null
    }),

    submitRequest() {
        return api.route(this.organizationId ? 'admin accounts organizations update' : 'admin accounts organizations store', { organizationId: this.organizationId })
            .formData({
                _method: this.organizationId ? 'put' : 'post',
                name: this.inputs.name,
                note: this.inputs.note,
                timezone: this.inputs.timezone,
                preferred_content_language: this.inputs.preferredContentLanguage,
                owner_name: this.inputs.ownerName,
                owner_email: this.inputs.ownerEmail,
                globalApiLimitPerMinutePerOrganization: this.inputs.globalApiLimitPerMinutePerOrganization,
                globalApiLimitPerMinutePerUser: this.inputs.globalApiLimitPerMinutePerUser,
                contentApiLimitPerMinutePerOrganization: this.inputs.contentApiLimitPerMinutePerOrganization,
                contentApiLimitPerMinutePerUser: this.inputs.contentApiLimitPerMinutePerUser,
                featuresApiLimitPerMinutePerOrganization: this.inputs.featuresApiLimitPerMinutePerOrganization,
                featuresApiLimitPerMinutePerUser: this.inputs.featuresApiLimitPerMinutePerUser,
                targetsApiLimitPerMinutePerOrganization: this.inputs.targetsApiLimitPerMinutePerOrganization,
                targetsApiLimitPerMinutePerUser: this.inputs.targetsApiLimitPerMinutePerUser
            })
            .post()
    },

    async onResponse(res) {
        useModalsOrganizationCreateStore().cancel()

        await useOrganizationsStore().load(true)

        nextTick(() => {
            window.scrollTo(0, 0);
        })
    }
})

export default useOrganizationSettingsFormStore
