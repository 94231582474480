import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentYoutubeVideoViewsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentYoutubeVideoViewsAnalysis',
    name: 'content.youtube-video-views',
    title: 'Views',
    color: 'pink-600'
})

export default useTargetsTargetContentYoutubeVideoViewsAnalysisStore
