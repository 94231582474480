import api from '../api'

class ThreeTablesQuery
{
    constructor(endpoint, options = {}) {
        this.endpoint = endpoint

        this.options = {
            types: [],
            filters: {},
            parent: null,
            conversation: null,
            replies: null,
            nodes: null,
            after: null,
            before: null,
            limit: null,
            sorting: null,
            details: null,
            ids: null,
            include: [],
            family: null
        }

        Object.entries(options).forEach(([ option, value ]) => this[option](value))
    }

    types(types) {
        this.options.types = types
        return this
    }

    filters(filters, value) {
        filters instanceof Object
            ? this.options.filters = filters
            : this.options.filters[filters] = value
        return this
    }

    parent(parentId) {
        this.options.parent = parentId
        return this
    }

    conversation(conversationId) {
        this.options.conversation = conversationId
        return this
    }

    replies(replies) {
        this.options.replies = replies
        return this
    }

    nodes(nodes) {
        this.options.nodes = nodes
        return this
    }

    after(after) {
        this.options.after = after
        return this
    }

    before(before) {
        this.options.before = before
        return this
    }

    limit(limit) {
        this.options.limit = limit
        return this
    }

    sorting(sorting) {
        this.options.sorting = sorting
        return this
    }

    details(details = 'extended') {
        this.options.details = details
        return this
    }

    include(include = []) {
        this.options.include = include
        return this
    }

    ids(ids = []) {
        this.options.ids = ids
        return this
    }

    family(family) {
        this.options.family = family
        return this
    }

    signal(abortController) {
        this.options.abortController = abortController
        return this
    }

    when(condition, trueCallback, falseCallback) {
        trueCallback = trueCallback || (() => {})
        falseCallback = falseCallback || (() => {})

        condition ? trueCallback(this) : falseCallback(this)

        return this
    }

    get(callback) {
        return api.route(this.endpoint, { family: this.options.family, id: this.id })
            .query({
                'types[]': this.options.types,
                filters: JSON.stringify(this.options.filters),
                parent: this.options.parent,
                conversation: this.options.conversation,
                replies: this.options.replies,
                nodes: this.options.nodes,
                after: this.options.after,
                before: this.options.before,
                limit: this.options.limit,
                sorting: this.options.sorting,
                details: this.options.details,
                include: this.options.include.join(','),
                'ids[]': this.options.ids,
                family: this.options.family
            })
            .signal(this.options.abortController || new AbortController)
            .get()
            .json(res => {
                if (callback) callback(res.data)
                return res.data
            })
    }

    find(id, callback) {
        return api.route(`${this.endpoint} details`, { family: this.options.family, id: id })
            .query({
                details: this.options.details,
                include: this.options.include.join(','),
                family: this.options.family
            })
            .signal(this.options.abortController || new AbortController)
            .get()
            .json(res => {
                if (callback) callback(res.data)
                return res.data
            })
    }

    export(format, callback) {
        return api.route(`${this.endpoint} export`, { family: this.options.family, id: this.id })
            .query({
                'types[]': this.options.types,
                filters: JSON.stringify(this.options.filters),
                parent: this.options.parent,
                conversation: this.options.conversation,
                replies: this.options.replies,
                nodes: this.options.nodes,
                after: this.options.after,
                before: this.options.before,
                limit: this.options.limit,
                sorting: this.options.sorting,
                details: this.options.details,
                include: this.options.include.join(','),
                'ids[]': this.options.ids,
                family: this.options.family,
                format
            })
            .get()
            .blob(res => callback(res))
    }
}

export const content = options => new ThreeTablesQuery('monitor content', options)
export const features = options => new ThreeTablesQuery('monitor features', options)
export const targets = options => new ThreeTablesQuery('monitor targets', options)
