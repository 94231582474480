<template>
    <router-link :to="to">
        <div class="rounded-lg bg-white shadow hover:shadow-lg cursor-pointer">
            <div class="flex items-center justify-center bg-gray-50 h-32 rounded-t-lg">
                <ui-icon :name="category.icon" class="text-5xl"></ui-icon>
            </div>
            <div class="flex items-center justify-center text-center h-16">
                <div>
                    <div class="font-semibold">{{category.name}}</div>
                    <div class="text-gray-800 text-xs font-medium">{{category.listsRecursiveCount}} {{category.listsRecursiveCount == 1 ? 'list' : 'lists'}}</div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: [ 'category', 'to' ]
}
</script>
