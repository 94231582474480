<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-slot:widget>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <div class="flex items-center text-sm" v-if="store.analysis && store.showTitle">
                    <span class="font-medium truncate">{{store.analysis.name}}</span>
                </div>
                <div class="flex-1 min-h-0 p-2">
                    <daily-heatmap :compact="true" :store="store.analysisStore().heatmapOptions" v-if="store.analysisStore().selectedHeatmapType.id == 'daily'" ref="widgetAnalysis"></daily-heatmap>
                    <hourly-heatmap :compact="true" :store="store.analysisStore().heatmapOptions" v-if="store.analysisStore().selectedHeatmapType.id == 'hourly'" ref="widgetAnalysis"></hourly-heatmap>
                </div>
            </div>
        </template>

        <template v-slot:expanded>
            <div class="h-full flex flex-col p-2 overflow-hidden">
                <div class="flex items-center text-sm" v-if="store.analysis && store.showTitle">
                    <img :src="store.analysis.avatarUrl" class="h-5 w-5 rounded-full mr-1">
                    <span class="truncate">{{store.analysis.name}}</span>
                </div>
                <div class="flex-1 min-h-0 p-4">
                    <daily-heatmap :store="store.analysisStore().heatmapOptions" v-if="store.analysisStore().selectedHeatmapType.id == 'daily'"></daily-heatmap>
                    <hourly-heatmap :store="store.analysisStore().heatmapOptions" v-if="store.analysisStore().selectedHeatmapType.id == 'hourly'"></hourly-heatmap>
                </div>
            </div>
        </template>

        <template v-slot:edit>
            <div class="flex flex-col items-center w-4/5 max-w-xl mx-auto h-full max-h-80 pt-6">
                <div class="relative w-4/5 text-gray-400 focus-within:text-gray-600">
                    <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none text-xl">
                        <ui-icon name="search"></ui-icon>
                    </div>
                    <input id="search_field" placeholder="Search analyses..." type="search" autocomplete="off" class="block border-0 w-full h-8 pl-8 pr-2 py-2 rounded-md bg-gray-100 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm shadow-inner" v-model="store.editForm.search">
                </div>

                <div class="flex-1 overflow-y-auto w-full mt-4 text-sm divide-y divide-gray-100 pb-6">
                    <a href="#" class="flex items-center py-2 px-3 rounded-md hover:bg-blue-50 transition duration-150" @click.prevent="store.editForm.selectedAnalysis = analysis" :key="analysis.id" v-for="analysis in store.editFormAnalyses">
                        <img :src="analysis.avatarUrl" class="h-6 w-6 rounded-full mr-2">
                        <span class="truncate">{{analysis.name}}</span>
                        <div class="w-5 h-5 rounded-full inline-flex justify-center items-center ml-auto" :class="{ 'bg-blue-500 text-white': analysis == store.editForm.selectedAnalysis }">
                            <ui-icon name="check"></ui-icon>
                        </div>
                    </a>
                </div>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'
import DailyHeatmap from '@/components/analysis/analyses/heatmaps/daily'
import HourlyHeatmap from '@/components/analysis/analyses/heatmaps/hourly'

export default {
    components: { DashboardWidget, DailyHeatmap, HourlyHeatmap },

    watch: {
        'store.lastLayoutUpdate'() {
            this.$nextTick(() => {
                if (this.$refs.widgetAnalysis) this.$refs.widgetAnalysis.reflow()
            })
        }
    }
}
</script>
