import api from '@/admin/api'

import useOrganizationSettingsFormStore from '@/admin/stores/accounts/organization-form'

import { defineStore } from 'pinia'

export const useOrganizationStore = defineStore({
    id: 'organization',

    state: () => ({
        organization: null,
        organizationLoaded: false,
        organizationPromise: null,

        timezones: [],
        languages: []
    }),

    actions: {
        async initialize(id, force = false) {
            await this.load(id, force)
        },

        async load(id, force = false) {
            if (this.organizationLoaded && ! force) return Promise.resolve()
            if (this.organizationPromise) return this.organizationPromise

            this.$reset()

            return Promise.all([
                api.route('admin accounts organizations details', { organizationId: id } ).get().json(),
                api.route('settings timezones').get().json(),
                api.route('settings languages').get().json()
            ]).then(values => {
                this.organization = values[0].data

                this.timezones = values[1].data
                this.languages = values[2].data

                this.organizationLoaded = true
                this.organizationPromise = null

                useOrganizationSettingsFormStore().$reset()

                useOrganizationSettingsFormStore().organizationId = this.organization.id

                useOrganizationSettingsFormStore().inputs.name = this.organization.name
                useOrganizationSettingsFormStore().inputs.note = this.organization.note
                useOrganizationSettingsFormStore().inputs.timezone = this.organization.timezone
                useOrganizationSettingsFormStore().inputs.preferredContentLanguage = this.organization.preferredContentLanguage
                useOrganizationSettingsFormStore().inputs.globalApiLimitPerMinutePerOrganization = this.organization.globalApiLimitPerMinutePerOrganization
                useOrganizationSettingsFormStore().inputs.globalApiLimitPerMinutePerUser = this.organization.globalApiLimitPerMinutePerUser
                useOrganizationSettingsFormStore().inputs.contentApiLimitPerMinutePerOrganization = this.organization.contentApiLimitPerMinutePerOrganization
                useOrganizationSettingsFormStore().inputs.contentApiLimitPerMinutePerUser = this.organization.contentApiLimitPerMinutePerUser
                useOrganizationSettingsFormStore().inputs.featuresApiLimitPerMinutePerOrganization = this.organization.featuresApiLimitPerMinutePerOrganization
                useOrganizationSettingsFormStore().inputs.featuresApiLimitPerMinutePerUser = this.organization.featuresApiLimitPerMinutePerUser
                useOrganizationSettingsFormStore().inputs.targetsApiLimitPerMinutePerOrganization = this.organization.targetsApiLimitPerMinutePerOrganization
                useOrganizationSettingsFormStore().inputs.targetsApiLimitPerMinutePerUser = this.organization.targetsApiLimitPerMinutePerUser
            })
        }
    }
})

export default useOrganizationStore
