<template>
    <div class="bg-white w-full shadow rounded-sm flex flex-col items-center mb-4" v-if="$page.target.group || $page.target.source">
        <div class="flex items-center px-6 py-3 w-full" v-if="$page.target.group">
            <div>
                <ui-icon name="user"></ui-icon>
            </div>
            <div class="ml-4">
                <div class="font-semibold">This Source owns "{{$page.target.group.title}}"</div>
                <div class="text-gray-800">Showing only content created by the Group owner.</div>
            </div>
            <div class="ml-auto">
                <router-link :to="{ name: 'targets.target.information', params: { type: $page.target.group.family, modelType: $page.target.group.type, id: $page.target.group.id } }" class="text-blue-500 hover:text-blue-700 hover:underline">
                    Switch to Group view
                    <ui-icon name="arrow-right-circle"></ui-icon>
                </router-link>
            </div>
        </div>
        <div class="flex items-center px-6 py-3 w-full" v-if="$page.target.source">
            <div>
                <ui-icon name="users"></ui-icon>
            </div>
            <div class="ml-4">
                <div class="font-semibold">This Group is owned by "{{$page.target.source.title}}"</div>
                <div class="text-gray-800">Showing content created by all members of the Group.</div>
            </div>
            <div class="ml-auto">
                <router-link :to="{ name: 'targets.target.information', params: { type: $page.target.source.family, modelType: $page.target.source.type, id: $page.target.source.id } }" class="text-blue-500 hover:text-blue-700 hover:underline">
                    Switch to Source view
                    <ui-icon name="arrow-right-circle"></ui-icon>
                </router-link>
            </div>
        </div>
    </div>
</template>
