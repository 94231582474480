import useAnalysisAnalysesContentPerformanceStore from '@/stores/analysis/analyses/content-performance'
import useAnalysisAnalysesContentBreakdownBySourceStore from '@/stores/analysis/analyses/content-breakdown-by-source'
import useAnalysisAnalysesContentBreakdownByGroupStore from '@/stores/analysis/analyses/content-breakdown-by-group'
import useAnalysisAnalysesContentBreakdownByTopicStore from '@/stores/analysis/analyses/content-breakdown-by-topic'
import useAnalysisAnalysesContentPublishTimesStore from "@/stores/analysis/analyses/content-publish-times"
import useAnalysisAnalysesContentPullPushStore from "@/stores/analysis/analyses/content-pull-push"
import useAnalysisAnalysesContentSentimentStore from "@/stores/analysis/analyses/content-sentiment"
import useAnalysisAnalysesContentTopPlatformsStore from '@/stores/analysis/analyses/content-top-platforms'
import useAnalysisAnalysesContentKeywordsStore from "@/stores/analysis/analyses/content-keywords"
import useAnalysisAnalysesTargetPublishTimesStore from "@/stores/analysis/analyses/target-publish-times"
import useAnalysisAnalysesTargetAudienceStore from "@/stores/analysis/analyses/target-audience"
import useAnalysisDatasetCardsStore from '@/stores/analysis/dataset-cards'
import useAnalysisTypes from "@/stores/analysis/analysis-types"
import useMyAnalysesStore from '@/stores/me/analyses'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { defineStore } from 'pinia'

export const useAnalysisMyAnalysesAnalysisPage = defineStore({
    id: 'analysisMyAnalysesAnalysisPage',

    state: () => ({
        type: null,
        analysis: null,
        isNew: false
    }),

    getters: {
        title: store => store.analysis ? `${store.analysis.name} | Analysis` : '',

        store(store) {
            if (! store.type) return

            if (store.type.id == 'content-performance') {
                return useAnalysisAnalysesContentPerformanceStore()
            } else if (store.type.id == 'content-breakdown-by-source') {
                return useAnalysisAnalysesContentBreakdownBySourceStore()
            } else if (store.type.id == 'content-breakdown-by-group') {
                return useAnalysisAnalysesContentBreakdownByGroupStore()
            } else if (store.type.id == 'content-breakdown-by-topic') {
                return useAnalysisAnalysesContentBreakdownByTopicStore()
            } else if (store.type.id == 'content-publish-times') {
                return useAnalysisAnalysesContentPublishTimesStore()
            } else if (store.type.id == 'content-pull-push') {
                return useAnalysisAnalysesContentPullPushStore()
            } else if (store.type.id == 'content-sentiment') {
                return useAnalysisAnalysesContentSentimentStore()
            } else if (store.type.id == 'content-top-platforms') {
                return useAnalysisAnalysesContentTopPlatformsStore()
            } else if (store.type.id == 'content-keywords') {
                return useAnalysisAnalysesContentKeywordsStore()
            } else if (store.type.id == 'target-publish-times') {
                return useAnalysisAnalysesTargetPublishTimesStore()
            } else if (store.type.id == 'target-audience') {
                return useAnalysisAnalysesTargetAudienceStore()
            }
        }
    },

    actions: {
        async beforeEnter(to) {
            this.type = useAnalysisTypes().find(to.params.type)

            this.store.$reset()
            this.store.unserializeUriSeries(to)

            this.isNew = to.params.id == 'new'

            useMyAnalysesStore().initialize().then(() => this.initializeAnalysis(to))
            useMyPerspectivesStore().load()
            useMySearchTopicsStore().load()
            useMyTargetListsStore().load()
        },

        beforeLeave() {
            this.store.abort()
        },

        initializeAnalysis(to) {
            useAnalysisDatasetCardsStore().setAnalysisStore(this.store)

            if (to.params.id == 'new') return

            this.analysis = useMyAnalysesStore().find(to.params.id)

            // we don't really need to load more details here, but this updates the last used date
            useMyAnalysesStore().loadDetails(to.params.id)

            this.store.initialize(this.analysis)
        }
    }
})

export default useAnalysisMyAnalysesAnalysisPage
