import { defineContentStreamStore } from '@/stores/reusable/stream'

export const useFeaturesFeatureStreamStore = defineContentStreamStore({
    id: 'featuresFeatureStream',

    polling: true,

    supportsPerspectives: false
})

export default useFeaturesFeatureStreamStore
