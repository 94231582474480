<template>
    <dashboard-widget v-bind="$attrs" :store="store">
        <template v-slot:widget>
            <div v-if="store.isLoading" class="h-full flex items-center justify-center">
                <ui-spinner></ui-spinner>
            </div>
            <div v-else class="h-full flex flex-col p-2 overflow-hidden">
                <div class="flex items-center text-sm" v-if="store.perspective && store.showPerspectiveName">
                    <img :src="store.perspective.avatarUrl" class="h-5 w-5 rounded-full mr-1">
                    <span class="truncate">{{store.perspective.name}}</span>
                </div>
                <div class="my-auto flex flex-wrap justify-center items-center p-4 text-center">
                    <span class="mr-1" :style="{ fontSize: fontSize(keyword) + 'rem', lineHeight: 2.25 + 'rem' }" :key="index" v-for="keyword, index in store.keywords">
                        {{keyword.keyword}}
                    </span>
                </div>
                <div class="h-5" v-if="store.perspective && store.showPerspectiveName"></div>
            </div>
        </template>

        <template v-slot:expanded>
            <div v-if="store.isLoading" class="h-full flex items-center justify-center">
                <ui-spinner></ui-spinner>
            </div>
            <div v-else class="h-full flex flex-col p-4">
                <div class="flex items-center text-sm" v-if="store.perspective && store.showPerspectiveName">
                    <img :src="store.perspective.avatarUrl" class="h-5 w-5 rounded-full mr-1">
                    <span class="truncate">{{store.perspective.name}}</span>
                </div>
                <div class="my-auto flex flex-wrap justify-center items-center p-8 text-center">
                    <span class="mr-1" :style="{ fontSize: fontSize(keyword) + 'rem', lineHeight: 2.25 + 'rem' }" :key="index" v-for="keyword, index in store.keywords">
                        {{keyword.keyword}}
                    </span>
                </div>
                <div class="h-5" v-if="store.perspective && store.showPerspectiveName"></div>
            </div>
        </template>

        <template v-slot:edit>
            <div class="flex flex-col items-center max-w-xl mx-auto h-full max-h-80 px-3 pt-6">
                <div class="relative w-3/4 text-gray-400 focus-within:text-gray-600">
                    <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none text-xl">
                        <ui-icon name="search"></ui-icon>
                    </div>
                    <input id="search_field" placeholder="Search perspectives..." type="search" autocomplete="off" class="block border-0 w-full h-8 pl-8 pr-2 py-2 rounded-sm bg-gray-100 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm" v-model="store.editForm.search">
                </div>

                <div class="flex-1 overflow-y-auto w-full mt-4 text-sm divide-y divide-gray-100 pb-6">
                    <a href="#" class="flex items-center py-2 px-3 rounded-md hover:bg-blue-50 transition duration-150" @click.prevent="store.editForm.selectedPerspective = perspective" :key="perspective.id" v-for="perspective in store.editFormPerspectives">
                        <img :src="perspective.avatarUrl" class="h-6 w-6 rounded-full mr-2">
                        <span class="truncate">{{perspective.name}}</span>
                        <div class="w-5 h-5 rounded-full inline-flex justify-center items-center ml-auto" :class="{ 'bg-blue-500 text-white': perspective == store.editForm.selectedPerspective }">
                            <ui-icon name="check"></ui-icon>
                        </div>
                    </a>
                </div>
            </div>

            <div class="border-t border-gray-200">
                <div class="max-w-xl mx-auto px-6 py-4">
                    <label class="relative flex items-start">
                        <div class="flex items-center h-5">
                            <input id="comments" type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-full" value="3" v-model="store.editForm.period">
                        </div>
                        <div class="ml-3 text-sm">
                            <span class="font-semibold text-gray-700">Generate from the past 3 days</span>
                        </div>
                    </label>
                    <label class="relative flex items-start mt-1">
                        <div class="flex items-center h-5">
                            <input id="comments" type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-full" value="7" v-model="store.editForm.period">
                        </div>
                        <div class="ml-3 text-sm">
                            <span class="font-semibold text-gray-700">Generate from the past 7 days</span>
                        </div>
                    </label>

                    <label class="relative flex items-start mt-4">
                        <div class="flex items-center h-5">
                            <input id="comments" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-sm" v-model="store.editForm.showPerspectiveName">
                        </div>
                        <div class="ml-3 text-sm">
                            <span class="font-semibold text-gray-700">Show perspective name</span>
                        </div>
                    </label>
                </div>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'

export default {
    components: { DashboardWidget },

    methods: {
        fontSize(keyword) {
            const weights = this.store.keywords.map(i => i.weight)
            const minWeight = Math.min(...weights)
            const maxWeight = Math.max(...weights)

            return ((keyword.weight - minWeight) / (maxWeight - minWeight) + 0.875).toFixed(2)
        }
    }
}
</script>
