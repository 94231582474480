<template>
    <content-card
        v-bind="$props"
        :avatar-badge="content.source.type == 'vkontakte-user' ? 'badges.target-vkontakte-user' : 'badges.target-vkontakte-community'"
        :avatar-badge-tooltip="content.source.type == 'vkontakte-user' ? 'VKontakte User' : 'VKontakte Community'"
        :parent-component="{ 'vkontakte-post': _.type }"
    >
        <!-- Metrics -->
        <template v-slot:metrics>
            <div class="py-3 px-5 flex space-x-5 text-gray-900" v-if="isShowingMetrics">
                <div class="flex items-center" v-if="content.metrics.interactions">
                    <ui-dropdown align="left" position="top" unstyled>
                        <template v-slot:trigger>
                            <div class="flex justify-center items-center cursor-pointer">
                                <ui-icon name="interactions" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                <div class="leading-none ml-2">
                                    <span class="font-bold">{{ $number(content.metrics.interactions) }}</span> interactions
                                </div>
                            </div>
                        </template>

                        <template v-slot:content>
                            <div class="rounded-sm shadow-lg text-white bg-gray-900 bg-opacity-80 border border-gray-700 ring-1 ring-black ring-opacity-5 px-4 py-3 text-left space-y-3 whitespace-nowrap">
                                <div class="flex items-center" v-if="content.metrics.reactions">
                                    <ui-icon name="thumbs-up" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.reactions) }}</span> likes
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.comments">
                                    <ui-icon name="comment" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.comments) }}</span> comments
                                    </div>
                                </div>

                                <div class="flex items-center" v-if="content.metrics.shares">
                                    <ui-icon name="share" class="text-lg shrink-0 text-gray-400"></ui-icon>

                                    <div class="leading-none ml-2">
                                        <span class="font-bold">{{ $number(content.metrics.shares) }}</span> reposts
                                    </div>
                                </div>
                            </div>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="flex justify-center items-center" v-if="content.metrics.views">
                    <ui-icon name="views" class="text-lg shrink-0 text-gray-400"></ui-icon>

                    <div class="leading-none ml-2">
                        <span class="font-bold">{{ $number(content.metrics.views) }}</span> views
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'

export default {
    name: 'vkontakte-post-card',

    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ],

    computed: {
        isShowingMetrics() {
            return this.content.metrics?.interactions
                || this.content.metrics?.views
        }
    }
}
</script>
