<template>
    <div class="rounded-lg bg-white shadow">
        <div class="flex flex-col items-center justify-center pt-8 pb-6">
            <ui-avatar :name="list.name" type="curated-list" class="text-5xl"></ui-avatar>
            <div class="font-semibold mt-4">{{list.name}}</div>
            <div class="text-gray-800 text-xs font-medium">
                {{list.sourcesCount}} {{list.sourcesCount == 1 ? 'source' : 'sources'}}
                ·
                {{list.groupsCount}} {{list.groupsCount == 1 ? 'group' : 'groups'}}
            </div>
            <div class="flex justify-center space-x-3 mt-4">
                <subscribe-button :list="list"></subscribe-button>
                <ui-button light :to="{ name: 'targets.my-lists.list', params: { listId: list.id, type: 'sources' } }">Preview</ui-button>
            </div>
        </div>
    </div>
</template>

<script>
import SubscribeButton from '@/components/targets/curated-lists/subscribe-button'

export default {
    props: [ 'list', 'to' ],

    components: { SubscribeButton }
}
</script>
