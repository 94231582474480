<template>
    <div>
        <div class="rounded bg-white shadow hover:shadow-lg cursor-pointer">
            <div class="flex items-center relative p-4 pb-3">
                <img :src="app.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{app.name}}
                    </h1>
                    <div class="max-w-min text-gray-800 text-xs leading-tight" v-tooltip="'Part of the SHA256 version of your token'">
                        {{app.token}}
                    </div>
                </div>

                <div class="shrink-0">
                    <contextual-menu :app="app"></contextual-menu>
                </div>
            </div>

            <div class="flex items-center relative px-4 py-2 bg-gray-50 border-t border-gray-100 rounded-b">
                <div class="text-sm text-gray-700">
                    {{ $dateTime(app.createdAt) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContextualMenu from '@/components/settings/contextual-menus/app'

export default {
    props: {app: {}, cardsStore: {} },

    components: { ContextualMenu }
}
</script>
