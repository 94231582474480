<template>
    <ui-modal name="dashboard-expanded-widget" unstyled :max-width="maxWidth" :reset="true" v-if="dashboardStore.selectedWidget" @closed="dashboardStore.closeExpandedWidget">
        <div class="py-6 flex flex-col h-full">
            <div class="flex justify-between items-center mb-3">
                <span class="flex items-center text-gray-50 font-semibold">
                    <a href="#" class="inline-flex items-center justify-center bg-gray-300 hover:bg-gray-400 text-gray-900 focus:outline-none focus:text-gray-600 rounded-full transition w-6 h-6 mr-2" @click.prevent="dashboardStore.closeExpandedWidget">
                        <ui-icon name="chevron-left"></ui-icon>
                    </a>

                    <ui-icon :name="dashboardStore.selectedWidget.type.icon" class="mr-1"></ui-icon> <span>{{ dashboardStore.selectedWidget.type.name }}</span>
                </span>

                <div v-if="dashboardStore.isEditing" class="flex space-x-2">
                    <ui-button size="sm" :action="dashboardStore.stopEditingWidget">Cancel</ui-button>
                    <ui-button color="green" size="sm" :action="dashboardStore.saveWidgetBeingEdited">Save</ui-button>
                </div>
            </div>

            <div style="max-height: 90vh;" :style="{ 'width': dashboardStore.selectedWidget.type.expandedEditWidth + 'px' }">
                <analysis-content-performance-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-if="dashboardStore.selectedWidget.type.id == 'analysis-content-performance'"></analysis-content-performance-widget>
                <analysis-content-breakdown-by-source-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-breakdown-by-source'"></analysis-content-breakdown-by-source-widget>
                <analysis-content-breakdown-by-group-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-breakdown-by-group'"></analysis-content-breakdown-by-group-widget>
                <analysis-content-publish-times-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-publish-times'"></analysis-content-publish-times-widget>
                <analysis-content-pull-push-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-pull-push'"></analysis-content-pull-push-widget>
                <analysis-content-sentiment-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-sentiment'"></analysis-content-sentiment-widget>
                <analysis-content-top-platforms-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-top-platforms'"></analysis-content-top-platforms-widget>
                <analysis-content-keywords-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-content-keywords'"></analysis-content-keywords-widget>
                <analysis-target-publish-times-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-target-publish-times'"></analysis-target-publish-times-widget>
                <analysis-target-audience-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'analysis-target-audience'"></analysis-target-audience-widget>
                <keywords-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'keywords'"></keywords-widget>
                <heading-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'heading'"></heading-widget>
                <stream-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'stream'"></stream-widget>
                <image-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'image'"></image-widget>
                <text-widget :store="dashboardStore.selectedWidget" :dashboard-store="dashboardStore" :editable="true" v-else-if="dashboardStore.selectedWidget.type.id == 'text'"></text-widget>
            </div>

            <div class="h-8"></div>
        </div>
    </ui-modal>
</template>

<script>
import AnalysisContentPerformanceWidget from './analysis-content-performance'
import AnalysisContentBreakdownBySourceWidget from './analysis-content-breakdown-by-source'
import AnalysisContentBreakdownByGroupWidget from './analysis-content-breakdown-by-group'
import AnalysisContentPublishTimesWidget from './analysis-content-publish-times'
import AnalysisContentPullPushWidget from './analysis-content-pull-push';
import AnalysisContentSentimentWidget from './analysis-content-sentiment';
import AnalysisContentTopPlatformsWidget from './analysis-content-top-platforms'
import AnalysisContentKeywordsWidget from './analysis-content-keywords';
import AnalysisTargetPublishTimesWidget from './analysis-target-publish-times'
import AnalysisTargetAudienceWidget from './analysis-target-audience'
import KeywordsWidget from './keywords'
import HeadingWidget from './heading'
import StreamWidget from './stream'
import ImageWidget from './image'
import TextWidget from './text'

export default {
    props: [ 'dashboardStore' ],

    components: {
        AnalysisContentPerformanceWidget,
        AnalysisContentBreakdownBySourceWidget,
        AnalysisContentBreakdownByGroupWidget,
        AnalysisContentPublishTimesWidget,
        AnalysisContentPullPushWidget,
        AnalysisContentSentimentWidget,
        AnalysisContentTopPlatformsWidget,
        AnalysisContentKeywordsWidget,
        AnalysisTargetPublishTimesWidget,
        AnalysisTargetAudienceWidget,
        KeywordsWidget,
        HeadingWidget,
        StreamWidget,
        ImageWidget,
        TextWidget
    },

    computed: {
        maxWidth() {
            return this.dashboardStore.selectedWidget?.type.expandedWidth || 520
        }
    }
}
</script>
