import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useTargetsLibraryStreamStore from '@/stores/targets/library-stream'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsLibrarySearchPage = defineStore({
    id: 'targetsLibrarySearchPage',

    state: () => ({
        type: null,
        to: null
    }),

    getters: {
        title: () => 'Library | Targets',

        streamStore(store) {
            return useTargetsLibraryStreamStore()
        }
    },

    actions: {
        afterEnter(to) {
            this.type = to.params.type
            this.to = to

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize()
            useMyTargetListsStore().initialize().then(() => this.initializeStream(to))
            useMyRecentTargetsStore().initialize()
        },

        initializeStream(to) {
            this.streamStore.initialize()
            this.streamStore.navigatedTo(to)
        },

        setType(type) {
            this.type = type
            this.initializeStream(this.to)
        }
    }
})

export default useTargetsLibrarySearchPage
