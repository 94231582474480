<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="edit" @click="editBoard(board)">
            Edit
        </ui-dropdown-link>

        <ui-dropdown-link icon="edit" @click="duplicateBoard(board)">
            Duplicate
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'
import useModalsBoardDuplicateStore from '@/stores/dashboards/modals/board-duplicate'

import { mapActions } from 'pinia'

export default {
    props: [ 'board' ],

    methods: {
        ...mapActions(useModalsBoardEditStore, {
            editBoard: 'open'
        }),

        ...mapActions(useModalsBoardDuplicateStore, {
            duplicateBoard: 'open'
        })
    }
}
</script>
