<template>
    <div class="relative">
        <div class="w-full h-48 p-2 cursor-pointer relative" @click="showMedia">
            <img v-if="thumbnail" :src="thumbnail.src" class="w-full h-full object-contain" style="filter: drop-shadow(0 -8px 0 white) drop-shadow(0 8px 0 white) drop-shadow(-8px 0 0 white) drop-shadow(8px 0 0 white) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));">

            <div v-else class="w-full h-full bg-gray-50 flex items-center justify-center" style="filter: drop-shadow(0 -8px 0 white) drop-shadow(0 8px 0 white) drop-shadow(-8px 0 0 white) drop-shadow(8px 0 0 white) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));">
                <ui-icon name="image" class="text-2xl text-gray-400"></ui-icon>
            </div>

            <div class="rounded-sm absolute inset-0" @click.stop="toggleSelection" v-show="streamStore.isInSelectMode">
            </div>
        </div>

        <div class="absolute -top-4 -right-4" v-show="streamStore.isInSelectMode">
            <div class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-50 bg-white shadow-sm text-white text-xl" :class="{ 'bg-blue-500': isSelected }">
                <ui-icon name="check" v-show="isSelected"></ui-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'video', 'item', 'streamStore' ],

    computed: {
        thumbnail() {
            if (this.item.type == 'facebook-post') {
                return this.item.images.find(i => i.flag == 'full_picture') || this.item.images.find(i => i.flag == 'video_inline');
            } else if (this.item.type == 'instagram-post') {
                return this.item.images.find(i => i.flag == 'ig_post_video_thumb');
            } else if (this.item.type == 'telegram-channel-post' && this.item.type == 'telegram-group-message') {
                return this.item.images.find(i => i.flag == 'telegram_video_thumbnail');
            } else if (this.item.type == 'tiktok-video') {
                return this.item.images.find(i => i.flag == 'tiktok_video_thumbnail');
            } else if (this.item.type == 'vkontakte-post') {
                return this.item.images.find(i => i.flag == 'vk_post_video_thumb');
            } else if (this.item.type == 'youtube-video') {
                return this.item.images.find(i => i.flag == 'yt_video_thumbnail');
            }

            return null;
        },

        isSelected() {
            return this.streamStore.selectedItems.includes(this.item)
        }
    },

    methods: {
        showMedia() {
            this.streamStore.mediaLightbox.open([ { ...this.video, thumbnail: this.thumbnail } ], 0)
        }
    }
}
</script>
