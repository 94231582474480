<template>
    <ui-modal name="targets-history-download" title="History Download" width="320px">
        <template v-if="isDownloaded">
            <div class="text-center">
                Target's history is fully downloaded.
            </div>

            <div class="flex justify-center mt-8">
                <ui-button @click="cancel">
                    Close
                </ui-button>
            </div>
        </template>

        <template v-else-if="! isSupported">
            <div class="text-center">
                Full history download can't be requested for this Target type.
            </div>

            <div class="flex justify-center mt-8">
                <ui-button @click="cancel">
                    Close
                </ui-button>
            </div>
        </template>

        <template v-else>
            <div class="text-center">
                The full history of content published by this Target will be downloaded and archived on our servers.
            </div>

            <div class="text-center mt-8">
                The download process might take up to several hours depending on the number of content published, you will receive a notification once the history is fully downloaded.
            </div>

            <div class="flex justify-center mt-8">
                <ui-button @click="request" color="blue">
                    Confirm
                </ui-button>
            </div>
        </template>
    </ui-modal>
</template>

<script>
import useTargetsModalsHistoryDownloadStore from '@/stores/targets/modals/history-download'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useTargetsModalsHistoryDownloadStore, [ 'isDownloaded', 'isSupported' ])
    },

    methods: {
        ...mapActions(useTargetsModalsHistoryDownloadStore, [ 'cancel', 'request' ])
    }
}
</script>
