import useMyBookmarksStore from '@/stores/me/bookmarks'
import useQuickSearchStore from '@/stores/me/quick-search'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { targets } from '@/api'
import { sortAlphabetically } from '@/helpers'

export default {
    state: {
        selectedTargets: []
    },

    actions: {
        changeDataset(callback) {
            useQuickSearchStore().show({
                families: [ 'targets', 'targetLists' ],
                limit: 50,
                onSelect: result => {
                    if (result) {
                        let fn = callback || this.setDataset

                        fn({
                            datasetFamily: result.resultType == 'target' ? (result.id[0] == 'G' ? 'groups' : 'sources') : 'lists',
                            datasetId: result.resultType == 'target' ? result.id.substring(1) : result.id,
                            datasetName: result.title,
                            label: result.title
                        })
                    }

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...sortAlphabetically(useMyBookmarksStore().items, 'title').map(item => ({
                        id: item.family == 'groups' ? `G${item.id}` : `S${item.id}`,
                        monitorId: item.id,
                        resultType: 'target',
                        type: item.type,
                        title: item.title,
                        image: item.avatarUrl,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    })),
                    ...sortAlphabetically(useMyTargetListsStore().items).map(item => ({
                        id: item.id,
                        resultType: 'target-list',
                        title: item.name
                    }))
                ]
            })
        },

        setDataset(dataset) {
            if (this.series.length) {
                this.updateSeries(this.series[0], dataset)
            } else {
                this.addSeries(dataset)
            }

            this.loadSelectedTargets()
        },

        addTarget() {
            this.changeDataset(dataset => {
                this.addSeries(dataset)
                this.loadSelectedTargets()
            })
        },

        loadSelectedTargets() {
            let groupIds = this.series.filter(s => s.datasetFamily == 'groups').map(s => s.datasetId)
            let sourceIds = this.series.filter(s => s.datasetFamily == 'sources').map(s => s.datasetId)
            let listIds = this.series.filter(s => s.datasetFamily == 'lists').map(s => s.datasetId)

            Promise.all([
                groupIds.length ? targets().family('groups').ids(groupIds).get() : [],
                sourceIds.length ? targets().family('sources').ids(sourceIds).get() : [],
                useMyTargetListsStore().items.filter(list => listIds.includes(list.id))
            ]).then(([ sources, groups, lists ]) => this.selectedTargets = [ ...lists, ...sources, ...groups ])
        }
    }
}
