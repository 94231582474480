<template>
    <div class="bg-gray-50 rounded-lg flex items-center cursor-default shrink-0">
        <a @click.prevent="openTopicSizeModal(store)" href="#" class="px-5 py-3 group">
            <div class="text-blue-700 group-hover:text-blue-800 group-hover:font-medium">
                <span class="capitalize">{{store.topicSize}}</span>
                <ui-icon name="chevron-right" class="ml-1"></ui-icon>
            </div>
            <div class="text-xs text-gray-800 font-semibold">Topic Size</div>
        </a>
    </div>
</template>

<script>
import useAnalysisModalsTopicBreakdownTopicSizeStore from '@/stores/analysis/modals/topic-breakdown-topic-size'

import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useAnalysisModalsTopicBreakdownTopicSizeStore, {
            openTopicSizeModal: 'open'
        })
    }
}
</script>
