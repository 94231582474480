import useMyAnalysesStore from '@/stores/me/analyses'

import defineCards from '@/stores/reusable/cards'

import { sortNaturally } from '@/helpers'

import { defineStore } from 'pinia'

let defineCardsStoreForSection = type => {
    return defineCards({
        id: `analysisIndexTypeCards-${type}`,

        source: useMyAnalysesStore(),
        filter: items => items.filter(item => item.type == type),

        sortingOptions: [
            {
                id: 'default', name: '', icon: '', default: 'asc',
                apply: items => sortNaturally(items)
            }
        ]
    })
}

export const useAnalysisIndexSectionsStore = defineStore({
    id: 'analysisIndexSections',

    state: () => ({
        sections: [
            {
                id: 'content-performance',
                title: 'Content Performance',
                store: defineCardsStoreForSection('content-performance')()
            },
            {
                id: 'content-breakdown-by-source',
                title: 'Top Sources',
                store: defineCardsStoreForSection('content-breakdown-by-source')()
            },
            {
                id: 'content-breakdown-by-group',
                title: 'Top Groups',
                store: defineCardsStoreForSection('content-breakdown-by-group')()
            },
            {
                id: 'content-breakdown-by-topic',
                title: 'Topic Modelling',
                store: defineCardsStoreForSection('content-breakdown-by-topic')()
            },
            {
                id: 'content-publish-times',
                title: 'Content Publish Times',
                store: defineCardsStoreForSection('content-publish-times')()
            },
            {
                id: 'content-pull-push',
                title: 'Content Pull-Push',
                store: defineCardsStoreForSection('content-pull-push')()
            },
            {
                id: 'content-sentiment',
                title: 'Content Sentiment',
                store: defineCardsStoreForSection('content-sentiment')()
            },
            {
                id: 'content-top-platforms',
                title: 'Top Platforms',
                store: defineCardsStoreForSection('content-top-platforms')()
            },
            {
                id: 'content-keywords',
                title: 'Content Keywords',
                store: defineCardsStoreForSection('content-keywords')()
            },
            {
                id: 'target-publish-times',
                title: 'Target Publish Times',
                store: defineCardsStoreForSection('target-publish-times')()
            },
            {
                id: 'target-audience',
                title: 'Target Audience',
                store: defineCardsStoreForSection('target-audience')()
            }
        ]
    }),

    getters: {
        isInitialized() {
            return this.sections.every(section => section.store.isInitialized)
        },

        isEmpty() {
            return this.sections.every(section => ! section.store.items.length)
        }
    }
})

export default useAnalysisIndexSectionsStore
