<template>
    <ui-modal name="new-app" title="Create a new App">
        <ui-form :store="newAppFormStore" v-slot="{ inputs, submitting, errors }">
            <div v-if="isSuccessful">
                <div class="flex flex-col items-center">
                    <ui-icon name="check-circle" class="text-green-500 text-8xl"></ui-icon>

                    <p class="text-center w-72 mt-3">
                        Your app was created!
                    </p>
                </div>

                <div class="flex flex-col items-center mt-8">
                    <p class="text-center">
                        This is your token. Please make sure to save it before closing this window, we're not able to recover it and you'll need a new app if it's lost!
                    </p>

                    <div class="flex flex-col items-center mt-3">
                        <p class="text-center text-red-500 font-medium">Make sure to keep this token to yourself! Providing it to anyone else will give expose access to your account!</p>
                        <p class="mt-8 px-3 py-1 bg-gray-200 rounded-md font-medium text-xl">
                            {{ token }}
                        </p>
                    </div>
                </div>

                <div class="flex justify-end mt-8">
                    <ui-button @click="close">
                        Close
                    </ui-button>
                </div>
            </div>

            <div v-else>
                <label for="name" class="block text-sm font-medium text-gray-700">
                    Pick a name for your app
                </label>
                <div class="mt-2">
                    <ui-input id="name" name="name" :errors="errors.name" v-model="inputs.name"></ui-input>
                </div>

                <div class="flex justify-end space-x-4 mt-8">
                    <ui-button @click="close">
                        Cancel
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Confirm
                    </ui-button>
                </div>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import { mapActions, mapState, mapStores } from 'pinia'

import useNewAppFormStore from "@/stores/settings/new-app-form"

export default {
    computed: {
        ...mapStores(useNewAppFormStore),

        ...mapState(useNewAppFormStore, [
            'isSuccessful',
            'token'
        ])
    },

    methods: {
        ...mapActions(useNewAppFormStore, [
            'close'
        ])
    }
}
</script>
