<template>
    <ui-dropdown align="right" z-index="50" width="w-56">
        <template v-slot:trigger>
            <a href="#" class="flex items-center justify-center focus:outline-none transition w-6 h-6 text-sm rounded-full" :class="{ 'bg-gray-300 hover:bg-gray-400 text-gray-700 hover:text-gray-900': ! store.appliedSearchTopic, 'bg-gray-400 hover:bg-gray-500 text-gray-700 hover:text-gray-900': store.appliedSearchTopic }">
                <ui-icon name="settings"></ui-icon>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                Search In
            </div>

            <ui-dropdown-link icon="type" :selected="! hasSearchType('no-title')" @click="toggleSearchType('no-title')" keep-open>
                Content Title
            </ui-dropdown-link>
            <ui-dropdown-link icon="align-left" :selected="! hasSearchType('no-body')" @click="toggleSearchType('no-body')" keep-open>
                Content Text
            </ui-dropdown-link>
            <ui-dropdown-link icon="image" :selected="hasSearchType('images')" @click="toggleSearchType('images')" keep-open>
                Images Text
            </ui-dropdown-link>
            <ui-dropdown-link icon="video" :selected="hasSearchType('videos')" @click="toggleSearchType('videos')" keep-open>
                Video Transcription
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                Search Type
            </div>

            <ui-dropdown-link :selected="searchType == 'language'" @click="setSearchType('language')" keep-open>
                Language Search
            </ui-dropdown-link>
            <ui-dropdown-link :selected="searchType == 'exact'" @click="setSearchType('exact')" keep-open>
                Exact Search
            </ui-dropdown-link>

            <template v-if="searchType == 'language'">
                <ui-dropdown-separator></ui-dropdown-separator>

                <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                    Input Language
                </div>

                <div class="px-2 py-1">
                    <div class="relative w-full h-7">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                    </div>
                </div>

                <div class="max-h-48 my-0 overflow-y-auto">
                    <template :key="index" v-for="option, index in options">
                        <ui-dropdown-link :icon="option.icon" :selected="store.searchLanguage == option.id" @click="store.applySearchLanguage(option.id)" keep-open v-if="option.id">
                            {{option.name}}
                        </ui-dropdown-link>

                        <ui-dropdown-separator v-else></ui-dropdown-separator>
                    </template>

                    <div class="text-gray-700 text-sm text-center" v-if="! options.length">
                        No results
                    </div>
                </div>
            </template>
            <template v-else>
                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link :selected="exactSearchType == 'case-sensitive'" @click="store.applySearchLanguage('exact-cs')" keep-open>
                    Case-Sensitive
                </ui-dropdown-link>
                <ui-dropdown-link :selected="exactSearchType == 'case-insensitive'" @click="store.applySearchLanguage('exact-ci')" keep-open>
                    Case-Insensitive
                </ui-dropdown-link>
            </template>

            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="time" @click="openSearchHistoryModal(store.table)">
                Search History
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import languageOptions from '@/stores/reusable/stream/language-filter-options'
import useModalsSearchHistoryStore from '@/stores/modals/search-history'

import { textSearch } from '@/helpers'

import { mapActions } from 'pinia'

export default {
    data: () => ({
        search: ''
    }),

    computed: {
        options() {
            return textSearch(this.search, languageOptions.slice(0, -2), o => o.name || true)
        },

        searchType() {
            return [ 'exact-cs', 'exact-ci' ].includes(this.store.searchLanguage) ? 'exact' : 'language'
        },

        exactSearchType() {
            return this.store.searchLanguage == 'exact-cs' ? 'case-sensitive' : 'case-insensitive'
        }
    },

    methods: {
        ...mapActions(useModalsSearchHistoryStore, { openSearchHistoryModal: 'open' }),

        hasSearchType(type) {
            return this.store.searchTypes.includes(type)
        },

        toggleSearchType(type) {
            this.store.toggleSearchType(type)
        },

        setSearchType(type) {
            if (type == this.searchType) return

            this.store.applySearchLanguage(type == 'language' ? this.$my.preferredLanguage : 'exact-cs')
        }
    }
}
</script>
