import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentVKontaktePostViewsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentVKontaktePostViewsAnalysis',
    name: 'content.vkontakte-post-views',
    title: 'Views',
    color: 'pink-600'
})

export default useTargetsTargetContentVKontaktePostViewsAnalysisStore
