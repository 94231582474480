<template>
    <div>
        <div>
            <div class="flex h-screen bg-gray-75">
                <app-navigation></app-navigation>

                <div class="flex-1 h-full overflow-y-auto" id="app-content">
                    <router-view></router-view>
                </div>
            </div>
        </div>

        <delete-confirmation-modal></delete-confirmation-modal>
    </div>
</template>

<script>
import AppNavigation from './AppNavigation'

export default {
    components: {
        AppNavigation
    }
}
</script>
