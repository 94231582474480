import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'

import { defineStore } from 'pinia'

export const useMyDashboardsStore = defineStore({
    id: 'myDashboards',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me dashboards').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadDetails(id) {
            return api.route('me dashboards details', id).get().json(res => {
                Object.assign(this.find(res.data.id), res.data)
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async update(dashboard, data) {
            await api.route('me dashboards update', dashboard.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async delete(dashboard, force = false) {
            await useDeleteConfirmationModal().open('Analysis')
                .then(async () => {
                    await api.route('me dashboards delete', dashboard.id)
                        .formData({ _method: 'delete', force: force ? 1 : 0 })
                        .post()
                        .forbidden(async (res) => {
                            if (res.json.error == 'has-dependents') {
                                return useDeleteConfirmationModal().open('Analysis', res.json.dependents)
                                    .then(() => this.delete(dashboard, true))
                            }
                        })
                        .res()
                })

            return this.reload()
        },

        async safeDelete(dashboard) {
            try {
                return await this.delete(dashboard)
            } catch (e) {}
        }
    }
})

export default useMyDashboardsStore
