<template>
    <router-link :to="{ name: 'features.feature.information', params: { type: this.feature.type, id: this.feature.id } }"
       target="_blank"
       class="flex shrink-0 items-center px-2 py-1 text-gray-800 max-w-2xl overflow-hidden rounded hover:bg-gray-100 transition-all cursor-pointer group"
       v-if="isShown">
        <ui-icon :name="icon" class="text-lg text-gray-400 shrink-0 group-hover:text-gray-600"></ui-icon>
        <span class="ml-1">{{ title }}</span>
    </router-link>
</template>

<script>
let mentionTypes = {
    'email': { icon: 'email' },
    'iban': { icon: 'euro' },
    'location': { icon: 'location' },
    'name': { icon: 'person' },
    'phone-number': { icon: 'phone' }
}

export default {
    props: [ 'feature' ],

    computed: {
        icon() {
            return mentionTypes[this.feature.type].icon
        },

        isShown() {
            return mentionTypes[this.feature.type]
        },

        title() {
            return this.feature.title
        }
    }
}
</script>
