import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const defineStreamMediaLightbox = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            name: settings.name,

            regions: [],
            sidebar: true,

            media: null,
            currentIndex: 0,
            content: null,

            showRawMetadata: false,

            inline: false
        }),

        getters: {
            currentMedia(store) { return store.media ? store.media[store.currentIndex] : {} },
            isFirst(store) { return store.currentIndex == 0 },
            isLast(store) { return store.media && store.currentIndex == store.media.length - 1 }
        },

        actions: {
            open(media, index, content) {
                this.media = media
                this.currentIndex = index
                this.content = content

                useModal().show(this.name)

                this.onMediaSelected()
            },

            showInline(media) {
                this.inline = true
                this.media = media

                this.onMediaSelected()
            },

            onMediaSelected() {
                if (this.currentMedia.family == 'images') {
                    api.route('monitor images details', this.currentMedia.id).get()
                        .json(res => {
                            this.currentMedia.imageCluster = res.data.imageCluster
                        })
                }

                if (this.currentMedia.family == 'videos' && this.currentMedia.id) {
                    api.route('monitor videos details', this.currentMedia.id).get()
                        .json(res => {
                            this.currentMedia.videoCluster = res.data.videoCluster
                        })
                }
            },

            next() {
                if (this.currentIndex + 1 <= this.media.length - 1) {
                    this.currentIndex++
                    this.onMediaSelected()
                }
            },

            previous() {
                if (this.currentIndex - 1 >= 0) {
                    this.currentIndex--
                    this.onMediaSelected()
                }
            }
        }
    })
}

export default defineStreamMediaLightbox
