<template>
    <router-view></router-view>

    <Teleport to="#root">
        <analysis-info-modal></analysis-info-modal>
    </Teleport>
</template>

<script>
import AnalysisInfoModal from "./modals/analysis-info"

export default {
    components: {
        AnalysisInfoModal
    }
}
</script>
