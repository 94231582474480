<template>
    <ui-dropdown align="right" width="w-48" v-if="! inline">
        <template v-slot:trigger>
            <button class="inline-flex items-center justify-center text-blue-400 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full transition w-6 h-6" :class="{ 'text-xl border border-blue-400 hover:border-blue-800 focus:border-blue-800 text-blue-500': ! plain, 'text-2xl': plain }"
                    aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                <ui-icon name="more-horizontal"></ui-icon>
            </button>
        </template>

        <template v-slot:content>
            <slot></slot>
        </template>
    </ui-dropdown>

    <ui-dropdown-link :submenu="true" submenu-align="right" submenu-width="w-48" v-bind="$attrs" v-else>
        {{ name }}

        <template v-slot:submenu>
            <slot></slot>
        </template>
    </ui-dropdown-link>
</template>

<script>
export default {
    props: {
        inline: Boolean,
        name: {},
        plain: Boolean
    }
}
</script>