<template>
    <ui-async :guards="$page.target">
        <ui-header :title="$page.target.title" :avatar="$page.target.avatarUrl"
            :badge="`badges.target-${$page.target.type}`" :badge-grayscale="! $page.target.monitored">
            <template v-slot:subtitle>
                <template v-if="$page.target.type == 'facebook-group'">
                    <div v-if="$page.target.username">@{{$page.target.username}}</div>
                    <div class="px-1" v-if="$page.target.username">·</div>
                    <div>Facebook Group</div>
                </template>
                <template v-else-if="$page.target.type == 'facebook-page'">
                    <div v-if="$page.target.username">@{{$page.target.username}}</div>
                    <div class="px-1" v-if="$page.target.username">·</div>
                    <div>Facebook Page</div>
                </template>
                <template v-else-if="$page.target.type == 'facebook-user'">
                    <div v-if="$page.target.username">@{{$page.target.username}}</div>
                    <div class="px-1" v-if="$page.target.username">·</div>
                    <div>Facebook User</div>
                </template>

                <template v-else-if="$page.target.type == 'telegram-channel'">
                    <div>Telegram Channel</div>
                </template>
                <template v-if="$page.target.type == 'telegram-group'">
                    <div>Telegram Group</div>
                </template>
                <template v-else-if="$page.target.type == 'telegram-user'">
                    <div>Telegram User</div>
                </template>

                <template v-else-if="$page.target.type == 'twitter-user'">
                    <div>Twitter User</div>
                </template>

                <template v-else-if="$page.target.type == 'vkontakte-community'">
                    <div>VKontakte Community</div>
                </template>
                <template v-else-if="$page.target.type == 'vkontakte-user'">
                    <div>VKontakte User</div>
                </template>

                <template v-else-if="$page.target.type == 'web-feed'">
                    <div>Web Feed</div>
                </template>
                <template v-else-if="$page.target.type == 'web-site'">
                    <div>Web Site</div>
                </template>
                <template v-else-if="$page.target.type == 'youtube-channel'">
                    <div>Youtube Channel</div>
                </template>
            </template>

            <template v-slot:actions>
                <contextual-menu :target="$page.target"></contextual-menu>
            </template>

            <template v-slot:navigation>
                <router-link :to="{ name: 'targets.target.information', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="info" class="mr-2"></ui-icon> Information
                </router-link>
                <router-link :to="{ name: 'targets.target.stream', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="stream" class="mr-2"></ui-icon> Stream
                </router-link>
                <router-link :to="{ name: 'targets.target.history', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" exact-active-class="text-blue-600 border-b-blue-500">
                    <ui-icon name="calendar" class="mr-2"></ui-icon> History
                </router-link>
                <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="$page.type == 'sources'">
                    <ui-icon name="share" class="mr-2"></ui-icon> Connections
                </router-link>
                <router-link :to="{ name: 'targets.target.members', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id } }" class="inline-flex items-center font-medium border-b-2 border-t-2 border-transparent pb-2" active-class="text-blue-600 border-b-blue-500" v-if="$page.type == 'groups'">
                    <ui-icon name="users" class="mr-2"></ui-icon> Members
                </router-link>
            </template>

            <template v-slot:subsection>
                <div id="header-bottom"></div>
            </template>
        </ui-header>

        <router-view></router-view>
    </ui-async>
</template>

<script>
import ContextualMenu from '@/components/targets/contextual-menus/target'

export default {
    components: {
        ContextualMenu
    }
}
</script>
