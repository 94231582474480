<template>
    <div class="bg-white w-full overflow-hidden shadow rounded-sm flex flex-col h-96 max-w-3xl mx-auto px-6 py-4 mb-4">
        <div class="flex items-center justify-between pb-4">
            <h3 class="font-semibold text-gray-900">
                Activity
            </h3>
        </div>

        <div class="w-full flex-1 min-h-0 relative">
            <ui-analysis :store="targetsTargetContentYoutubeVideoActivityAnalysisStore"></ui-analysis>
        </div>
    </div>
</template>

<script>
import useTargetsTargetContentYoutubeVideoActivityAnalysisStore from '@/stores/targets/target/content-youtube-video-activity-analysis'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useTargetsTargetContentYoutubeVideoActivityAnalysisStore)
    }
}
</script>
