<template>
    <ui-modal name="targets-list-edit" :title="list.id ? 'Edit a Target List' : 'Create a new Target List'" @opened="opened">
        <ui-form :store="targetsModalsListEditStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" size="lg" class="mr-auto" :action="deleteList" :disabled="submitting" v-if="inputs.list.id">
                    Delete
                </ui-button>

                <ui-button type="submit" size="lg" :color="inputs.list.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.list.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useTargetsModalsListEditStore from '@/stores/targets/modals/list-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useTargetsModalsListEditStore),

        list() { return this.targetsModalsListEditStore.inputs.list }
    },

    methods: {
        ...mapActions(useTargetsModalsListEditStore, { 'deleteList': 'delete' }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
