<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Analysis
    </h1>

    <navigation-section
        :items="topNavigation">
        <template v-slot:actions>
            <ui-button icon="plus-square" light color="blue" :to="{ name: 'analysis.my-analyses.new' }">
                New Analysis
            </ui-button>
        </template>
    </navigation-section>

    <navigation-section title="Pinned &amp; Recent"
        :items="pinnedAndRecent" :wait-until="areAnalysesInitialized" :max-shown="5"
        :to="item => ({ name: 'analysis.analysis.details', params: { type: item.type, id: item.id } })"
        :active="item => false"
        placeholder="Nothing pinned or recently visited yet...">
    </navigation-section>
</template>

<script>
import useMyAnalysesStore from '@/stores/me/analyses'

import { sortNaturally } from '@/helpers'

import { mapState } from 'pinia'

export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.overview', to: { name: 'analysis' } },
            { name: 'My Analyses', icon: 'navigation.sub.analyses', to: { name: 'analysis.my-analyses' } }
        ]
    }),

    computed: {
        ...mapState(useMyAnalysesStore, {
            areAnalysesInitialized: 'isInitialized',
            pinnedAndRecent(store) {
                return sortNaturally(store.items.filter(item => item.pinned || item.recent)).map(i => ({...i, avatarType: 'analysis'}))
            }
        })
    }
}
</script>
