import { defineContentStreamStore } from '@/stores/reusable/stream'

export const useTargetsTargetInformationTopContentStreamStore = defineContentStreamStore({
    id: 'targetsTargetInformationTopContentStream',

    resultsToolbar: false,
    supportsPerspectives: false,
    perPage: 5,
    updatesQuery: false,

    sortingOptions: [
        { name: 'Most Interactions', id: 'interactions', icon: 'zap', value: i => i.metrics.interactions, direction: 'desc', default: true }
    ]
})

export default useTargetsTargetInformationTopContentStreamStore
