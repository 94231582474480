import api from '../../api'
import { route } from '../../helpers'

import {
    endOfMonth, isEqual, startOfToday, startOfTomorrow, startOfYear, subDays, subMonths, subYears
} from 'date-fns'
import { defineStore } from 'pinia'
import {zonedTimeToUtc} from "date-fns-tz";

export const defineAnalysisStore = (settings) => {
    return defineStore({
        id: settings.id,

        state: () => ({
            title: settings.title,
            name: settings.name,
            filterAppends: settings.filterAppends,
            seriesUrl: settings.seriesUrl || route('me analyses series'),
            color: settings.color || 'blue-600',

            series: [],
            seriesLoading: false,
            seriesAbort: null,

            predefinedRanges: settings.predefinedRanges || [
                { name: 'Past Day', range: () => [ subDays(Date.now(), 1), startOfTomorrow() ], granularity: 'hour', ticks: 12, tickFormat: () => "%H:%M" },
                { name: 'Past Week', range: () => [ subDays(startOfToday(), 6), startOfTomorrow() ], granularity: 'day', ticks: 7, tickFormat: () => "%a" },
                { name: 'Past Month', range: () => [ subMonths(startOfToday(), 1), startOfTomorrow() ], granularity: 'day', ticks: 15, tickFormat: () => "%b %d" },
                { name: 'Past 12 Months', range: () => [ endOfMonth(subYears(startOfToday(), 1)), startOfTomorrow() ], granularity: 'month', ticks: 12, tickFormat: t => isEqual(t, startOfYear(t)) ? "%Y" : "%b" },
            ],
            defaultRange: settings.defaultRange !== undefined ? settings.defaultRange : 3,

            selectedRange: null
        }),

        actions: {
            load() {
                if (! this.selectedRange) this.selectedRange = this.predefinedRanges[this.defaultRange]

                if (this.seriesAbort) this.seriesAbort.abort()

                this.seriesLoading = true

                let dateRange = this.selectedRange.range().map(d => zonedTimeToUtc(d).toISOString())

                api.url(this.seriesUrl).json({
                    type: this.name,
                    background: false,
                    fresh: true,
                    configuration: {
                        date: {
                            type: 'in-range',
                            date: { gte: dateRange[0], lte: dateRange[1] }
                        },
                        granularity: this.selectedRange.granularity,
                        ...this.filterAppends
                    },
                }).signal(this.seriesAbort = new AbortController())
                    .post()
                    .json(res => {
                        this.series = res.series
                        this.seriesLoading = false
                    })
            },

            render() {
                this.series = [ ...this.series ]
            },

            selectRange(range) {
                this.selectedRange = range
                this.load()
            }
        }
    })
}

export default defineAnalysisStore
