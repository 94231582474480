<template>
    <dashboard-widget v-bind="$attrs" :store="store" transparent>
        <template v-slot:widget>
            <div class="w-full h-full p-2 overflow-y-auto">
                <div class="flex items-center text-sm" v-if="store.perspective && store.showTitle">
                    <img :src="store.perspective.avatarUrl" class="h-5 w-5 rounded-full mr-1">
                    <span class="truncate">{{store.perspective.name}}</span>
                </div>

                <div class="mt-3">
                    <ui-stream :store="store.widgetStream()"></ui-stream>
                </div>
            </div>
        </template>

        <template v-slot:expanded>
            <div class="w-full h-full p-2 overflow-y-auto">
                <div class="flex items-center text-sm" v-if="store.perspective && store.showTitle">
                    <img :src="store.perspective.avatarUrl" class="h-5 w-5 rounded-full mr-1">
                    <span class="truncate">{{store.perspective.name}}</span>
                </div>

                <div class="mt-3">
                    <ui-stream :store="store.expandedStream()"></ui-stream>
                </div>
            </div>
        </template>

        <template v-slot:edit>
            <div class="flex flex-col items-center max-w-xl mx-auto h-full max-h-80 px-6 pt-6">
                <div class="relative text-gray-400 focus-within:text-gray-600">
                    <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none text-xl">
                        <ui-icon name="search"></ui-icon>
                    </div>
                    <input id="search_field" placeholder="Search sources lists..." type="search" autocomplete="off" class="block border-0 w-full h-8 pl-8 pr-2 py-2 rounded-md bg-gray-100 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm shadow-inner" v-model="store.editForm.search">
                </div>

                <div class="flex-1 overflow-y-auto w-full mt-4 text-sm divide-y divide-gray-100 pb-6">
                    <a href="#" class="flex items-center py-2 px-3 rounded-md hover:bg-blue-50 transition duration-150" @click.prevent="store.editForm.selectedPerspective = perspective" :key="perspective.id" v-for="perspective in store.editFormPerspectives">
                        <img :src="perspective.avatarUrl" class="h-6 w-6 rounded-full mr-2">
                        <span class="truncate">{{perspective.name}}</span>
                        <div class="w-5 h-5 rounded-full inline-flex justify-center items-center ml-auto" :class="{ 'bg-blue-500 text-white': perspective == store.editForm.selectedPerspective }">
                            <ui-icon name="check"></ui-icon>
                        </div>
                    </a>
                </div>
            </div>

            <div class="border-t border-gray-200">
                <div class="max-w-xl mx-auto px-6 py-4">
                    <label class="block text-sm font-semibold text-gray-700 mb-1">
                        Sort by
                    </label>
                    <div class="flex flex-wrap items-center justify-center">
                        <a href="#" class="block m-1" :key="option.id" v-for="option in store.widgetStream().sortingOptions.filter(o => o.id)">
                            <a href="#" @click.prevent="store.editForm.sortingOptionId = option.id; store.editForm.sortingOptionDirection = option.direction" class="inline-flex items-center px-3 h-8 text-sm font-medium leading-4 border rounded-md max-w-full" :class="{ 'bg-blue-100 text-blue-600 border-blue-200': store.editForm.sortingOptionId == option.id, 'bg-gray-50 text-gray-600  border-gray-200': store.editForm.sortingOptionId != option.id }">
                                <ui-icon :name="option.icon" class="mr-2"></ui-icon>
                                <span>{{option.name}}</span>
                            </a>
                        </a>
                    </div>

                    <label class="relative flex items-start mt-4">
                        <div class="flex items-center h-5">
                            <input id="comments" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-sm" v-model="store.editForm.showTitle">
                        </div>
                        <div class="ml-3 text-sm">
                            <span class="font-semibold text-gray-700">Show title</span>
                        </div>
                    </label>
                </div>
            </div>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'

export default {
    components: { DashboardWidget }
}
</script>
