<template>
    <div v-if="! content.stackedChild" class="content-card max-w-4xl w-full overflow-visible relative flex flex-col" :class="{ 'min-h-0': mode == 'modal' }" :data-id="content.id" @click="streamStore.toggleSelection(content)">
        <div class="hover:ring-1 ring-blue-500 rounded absolute inset-0 z-10" v-show="streamStore.isInSelectMode">
        </div>

        <div class="content-card-content flex flex-1 flex-col" :class="{ 'min-h-0': mode == 'modal' }">
            <a v-if="content.stackedParent" @click.prevent="streamStore.expand(content)" href="#" class="flex flex-col items-center">
                <div class="h-2 bg-white border-t border-x border-gray-200" style="width: 90%"></div>
                <div class="h-2 bg-white border-t border-x border-gray-200" style="width: 95%"></div>
            </a>
            <div class="bg-white border-t border-gray-200 shadow rounded flex flex-1 flex-col">
                <a v-if="content.stackedParent" @click.prevent="streamStore.expand(content)" href="#" class="pt-0.5 text-center text-gray-700">
                    <ui-icon name="chevron-down"></ui-icon>
                </a>

                <!-- Content Source & Header -->
                <div class="px-5 py-4 flex items-center justify-between">
                    <div class="flex items-center min-w-0 w-full">
                        <div class="shrink-0 relative">
                            <a :href="$router.resolve({ name: 'targets.target.information', params: { type: content.source.family, modelType: content.source.type, id: content.source.id } }).href" target="_blank">
                                <img class="rounded-full ring-1 ring-gray-300 bg-gray-100" :class="compact ? 'h-7 w-7' : 'h-8 w-8'" :src="content.source.avatarUrl" alt="">
                                <ui-icon :name="avatarBadge" v-tooltip="avatarBadgeTooltip" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full" :class="{ 'grayscale': ! content.source.monitored }"></ui-icon>
                            </a>
                        </div>
                        <div class="ml-3 min-w-0">
                            <div class="inline-flex items-center w-full">
                                <p class="font-semibold truncate min-w-0 leading-tight">
                                    <a :href="$router.resolve({ name: 'targets.target.information', params: { type: content.source.family, modelType: content.source.type, id: content.source.id } }).href" v-tooltip="content.source.title" target="_blank">
                                        {{ content.source.title }}
                                    </a>
                                </p>
                                <span class="text-gray-800 mx-1 whitespace-nowrap leading-tight" v-if="content.group">
                                    in
                                </span>
                                <a :href="$router.resolve({ name: 'targets.target.information', params: { type: content.group.family, modelType: content.group.type, id: content.group.id } }).href" target="_blank" class="inline-flex items-center min-w-0 font-semibold leading-tight" v-tooltip="content.group.title" v-if="content.group">
                                    <img class="h-5 w-5 rounded-full bg-gray-100 shadow mr-1" :src="content.group.avatarUrl" alt="">
                                    <span class="truncate">
                                        {{ content.group.title }}
                                    </span>
                                </a>
                            </div>
                            <div class="flex items-center tracking-tight text-gray-800" :class="compact ? 'text-2xs leading-tight' : 'text-xs leading-tight'">
                                <div class="group flex items-center" v-tooltip="dateTooltip">
                                    <a :href="$router.resolve({ name: 'targets.target.content', params: { type: content.source.family, modelType: content.source.type, id: content.source.id, contentId: content.id } }).href" target="_blank">
                                        {{ $dateTime(content.publishedAt) }}
                                    </a>
                                </div>
                                <div class="ml-2 px-2 py-0.5 leading-none rounded-full bg-red-100 text-red-500 font-medium text-2xs tracking-tight" v-if="content.labels.includes('deleted')">
                                    Deleted
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex shrink-0 items-center text-gray-500 space-x-1">
                        <div class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-100 bg-white shadow text-white text-xl"
                             :class="{ 'bg-blue-500': isSelected }"
                             v-if="streamStore.isInSelectMode">
                            <ui-icon name="check" v-show="isSelected"></ui-icon>
                        </div>

                        <contextual-menu :content="content" :stream-store="streamStore" plain v-else></contextual-menu>
                    </div>
                </div>

                <!-- Content -->
                <div class="mb-4 mx-5 space-y-2" :class="compact ? 'text-sm' : ''">
                    <!-- Conversation root -->
                    <card-root v-if="content.conversationParent" :parent="content" :root="content.conversationParent" :stream-store="streamStore"></card-root>

                    <div class="flex space-x-3" v-if="content.imagesMatch || content.videosMatch">
                        <div class="bg-yellow-100 px-1 text-sm rounded-full border border-yellow-200" v-if="content.imagesMatch">
                            Matching image
                        </div>
                        <div class="bg-yellow-100 px-1 text-sm rounded-full border border-yellow-200" v-if="content.videosMatch">
                            Matching video
                        </div>
                    </div>

                    <h1 class="font-medium" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-if="content.title" v-html="content.title"></h1>

                    <div class="bg-gray-50 rounded-sm px-2 py-1" v-if="content.translation?.isLoading || content.translation?.text">
                        <div class="text-xs text-gray-800 font-semibold italic mb-1">
                            <ui-icon name="translate"></ui-icon>
                            Translated text
                        </div>

                        <div v-if="content.translation?.isLoading" class="flex items-center justify-center py-4">
                            <ui-spinner></ui-spinner>
                        </div>
                        <div class="whitespace-pre-line break-words" v-else v-html="content.translation?.text"></div>
                    </div>

                    <div>
                        <div class="whitespace-pre-line break-words" :class="[isRTL ? 'text-rtl' : 'text-ltr']" :dir="isRTL ? 'rtl' : 'ltr'" v-html="text"></div>
                        <span v-if="! isShowingFullText">
                            <a class="cursor-pointer text-blue-500" @click.prevent="readMore">Read more</a>
                        </span>
                        <span v-if="isShowingFullText && hasVeryLongContent">
                            <a class="cursor-pointer text-blue-500" :href="$router.resolve({ name: 'targets.target.content', params: { type: content.source.family, modelType: content.source.type, id: content.source.id, contentId: content.id } }).href" target="_blank">Show details</a>
                        </span>
                    </div>

                    <!-- Link -->
                    <a :href="content.attachment.url" rel="noreferrer noopener" target="_blank"
                         class="flex items-center block px-3 py-2 text-gray-800 max-w-2xl overflow-hidden shadow-sm rounded-sm border border-gray-200 mt-3 hover:border-blue-500 hover:text-gray-900 group transition-all"
                         v-if="content.attachment.url">
                        <ui-icon name="web" class="text-xl text-blue-500 shrink-0"></ui-icon>
                        <div class="ml-2">
                            <div>
                                {{ content.attachment.url }}
                            </div>
                            <div class="text-gray-700 text-sm group-hover:text-gray-900 line-clamp-2" v-if="content.attachment.description">
                                {{content.attachment.description}}
                            </div>
                        </div>
                    </a>

                    <slot name="no-content"></slot>
                </div>

                <!-- Media -->
                <div class="w-full flex flex-wrap" :class="{ 'mb-4': ! content.parent && mode == 'details' }" v-if="isShowingMedia">
                    <div class="w-full max-h-96 bg-white relative cursor-pointer" @click="showMedia(0)">
                        <img class="w-full h-full object-contain" :src="firstMedia.family == 'videos' ? firstMedia.thumbnailUrl : firstMedia.src" v-if="firstMedia.family == 'videos' ? firstMedia.thumbnailUrl : firstMedia.src">
                        <div class="w-full h-64 bg-gray-500" v-else></div>
                        <div class="absolute inset-0 flex items-center justify-center text-3xl text-white" v-if="firstMedia.family == 'videos'">
                            <div class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full" v-if="firstMedia.src">
                                <ui-icon name="play"></ui-icon>
                            </div>
                            <div class="h-16 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 rounded-2xl px-4" v-else>
                                <div class="flex items-center">
                                    <ui-icon name="warning" class="text-xl"></ui-icon>
                                    <span class="text-lg ml-1">Video unavailable</span>
                                </div>
                                <div class="text-sm mt-1">
                                    Click to learn more
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/4 p-1" :key="key" v-for="media, key in additionalMedia">
                        <div class="h-0 pb-full relative cursor-pointer" @click="showMedia(key + 1)">
                            <img class="w-full h-full absolute object-cover rounded-sm" :src="media.family == 'videos' ? media.thumbnailUrl : media.src" v-if="media.family == 'videos' ? media.thumbnailUrl : media.src">
                            <div class="w-full h-full bg-gray-500 rounded-sm" v-else></div>
                            <div class="absolute inset-0 flex items-center justify-center text-white bg-gray-800 bg-opacity-75 font-medium rounded-sm"
                                 v-if="hiddenMediaCount && key == additionalMedia.length - 1">
                                <span class="text-center">
                                    <span class="text-4xl">+{{ hiddenMediaCount }}</span>
                                </span>
                            </div>
                            <div class="absolute inset-0 flex items-center justify-center text-3xl text-white" v-else-if="media.family == 'videos'">
                                <div class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full">
                                    <ui-icon :name="media.src ? 'play' : 'warning'"></ui-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Parent post -->
                <div class="mb-4 mx-3 last:rounded-b" v-if="content.parent">
                    <component :is="parentComponent[content.parent.type]" :content="content.parent" :stream-store="streamStore" :compact="true" class="border border-gray-100"></component>
                </div>

                <div class="mb-2 mx-5 text-xs text-gray-600" v-if="mode == 'details'">
                    <a href="#" @click.prevent="showHash(content)" v-tooltip="'Content Hash'">
                        <ui-icon name="shield"></ui-icon> {{content.hash}}
                    </a>
                </div>

                <div class="mt-auto divide-y divide-gray-100 bg-gray-50 border-t border-gray-100 rounded-b" :class="compact ? 'text-sm' : ''">
                    <!-- Mentions -->
                    <card-mentions v-if="$hasFeatureTo(['access-features'])" :mentions="content.features" :stream-store="streamStore"></card-mentions>

                    <!-- Metrics -->
                    <slot name="metrics"></slot>

                    <!-- Tags & Labels -->
                    <div class="py-1 px-3 flex flex-wrap gap-x-1 gap-y-1" v-if="sentiment || (content.tags.length > 0)">

                        <!-- Sentiment Label -->
                        <ui-dropdown>
                            <template v-slot:trigger>
                                <div class="flex items-center text-lg font-medium cursor-default hover:bg-gray-100 py-1 px-2 rounded" v-tooltip="'Content sentiment'" v-if="sentiment">
                                    <ui-icon :name="sentiment.icon" :class="sentiment.color"></ui-icon>
                                    <div class="ml-1 text-xs" :class="sentiment.color">{{ sentiment.title }}</div>
                                </div>
                            </template>
                            <template v-slot:content>
                                <ui-dropdown-link icon="flag" @click="editSentiment(content)">
                                    Report wrong value
                                </ui-dropdown-link>
                            </template>
                        </ui-dropdown>

                        <!-- User Tags -->
                        <div class="flex items-center text-lg font-medium text-gray-800 cursor-default py-1 px-2 rounded" :key="tag.id" v-for="tag in content.tags">
                            <ui-icon name="user-tag" :class="`text-${tag.color}`"></ui-icon>
                            <div class="ml-1 text-xs">{{ tag.name }}</div>
                        </div>
                    </div>

                    <!-- Replies -->
                    <card-replies v-if="content.conversationChildren && content.conversationChildren.length && mode != 'modal'" :parent="content" :replies="content.conversationChildren" :stream-store="streamStore"></card-replies>
                </div>

                <slot name="details"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import CardMentions from './partials/mentions'
import CardReplies from './partials/replies'
import CardRoot from './partials/root'
import CardVideo from './partials/video'
import ContextualMenu from '@/components/content/contextual-menus/content'

import useContentHashStore from '@/stores/content/modals/content-hash'
import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'
import useContentModalsSentimentEditStore from '@/stores/content/modals/sentiment-edit'

import { mapActions } from 'pinia'

// Make sure keywords highlighting classes are generated: bg-yellow-100 bg-orange-100 bg-red-100
export default {
    components: { CardMentions, CardReplies, CardRoot, CardVideo, ContextualMenu },

    props: [
        'content', 'streamStore',
        'avatarBadge', 'avatarBadgeTooltip',
        'parentComponent',
        'compact', 'mode', 'isExpanded',
        'hideMediaSection'
    ],

    data: () => ({
        showFullText: false
    }),

    computed: {
        isSelected() {
            return this.streamStore.selectedItems.includes(this.content)
        },

        isShowingMedia() {
            return this.media?.length && ! this.hideMediaSection
        },

        isShowingFullText() {
            return this.showFullText || (this.content.body || '').length <= 300
        },

        isShowingTags() {
            return this.content.tags && this.content.tags.length
        },

        isRTL() {
            return ['ar', 'arc', 'ha', 'khw', 'ks', 'ps', 'yi', 'az', 'dv', 'he', 'ku', 'ckb', 'fa', 'ur'].includes(this.content.language)
        },

        hasVeryLongContent() {
            return this.content.body.length > 8000 && this.mode != 'details'
        },

        labels() {
            let appendPerformanceMultiplier = (text, performance) => {
                return performance < 2 ? text : text + ` x${Math.floor(performance)}`
            }

            let labels = [
                {
                    id: 'deleted',
                    title: () => 'Deleted',
                    tooltip: 'The original post was deleted',
                    style: 'bg-red-100 text-red-500'
                },
                {
                    id: 'overperforming',
                    title: () => appendPerformanceMultiplier('Overperforming', this.content.benchmarks.reactionsPerformance),
                    tooltip: `This post has <b>${this.content.benchmarks.reactionsPerformance} times more<br>reactions</b> than an average post`,
                    style: 'bg-yellow-100 text-yellow-600',
                    icon: 'zap'
                },
                {
                    id: 'heated',
                    title: () => appendPerformanceMultiplier('Heated', this.content.benchmarks.commentsPerformance),
                    tooltip: `This post has <b>${this.content.benchmarks.commentsPerformance} times more<br>comments</b> than an average post`,
                    style: 'bg-indigo-100 text-indigo-500'
                },
                {
                    id: 'underperforming',
                    title: () => appendPerformanceMultiplier('Underperforming', this.content.benchmarks.reactionsPerformance),
                    tooltip: `This post has <b>${this.content.benchmarks.reactionsPerformance} times the <br>reactions</b> of an average post`,
                    style: 'bg-pink-100 text-pink-500',
                    icon: 'zap'
                },
                {
                    id: 'viral',
                    title: () => appendPerformanceMultiplier('Viral', this.content.benchmarks.sharesPerformance),
                    tooltip: `This post was <b>shared ${this.content.benchmarks.sharesPerformance} times more</b><br>than an average post`,
                    style: 'bg-purple-100 text-purple-500'
                }
            ]

            return labels.filter(l => this.content.labels.includes(l.id)).map(l => ({ ...l, title: l.title() }))
        },

        sentiment() {
            if (this.content.labels.includes('sentiment:positive')) {
                return {title: 'Positive', color: 'text-emerald-500', icon: 'face-smile'}
            } else if (this.content.labels.includes('sentiment:negative')) {
                return {title: 'Negative', color: 'text-rose-500', icon: 'face-frown'}
            } else if (this.content.labels.includes('sentiment:neutral')) {
                return {title: 'Neutral', color: 'text-indigo-500', icon: 'face-neutral'}
            } else {
                return null
            }
        },

        dateTooltip() {
            return {
                html: true,
                content: `Published <b>${this.$dateTime(this.content.publishedAt)}</b><br>Detected <b>${this.$dateTime(this.content.firstSeenAt)}</b><br>(${this.content.firstSeenDifference})`
            }
        },

        text() {
            return this.isShowingFullText
                ? (this.hasVeryLongContent ? this.content.body.substr(0, 8000) + '...' : this.content.body)
                : this.content.body.replace(/\n\s*\n/g, "\n").substr(0, 300) + '...'
        },

        firstMedia() {
            return this.media?.[0] || {}
        },

        additionalMedia() {
            return this.media?.slice(1, 5) || {}
        },

        hiddenMediaCount() {
            return this.media?.length > 5 ? this.media.length - 5 : 0
        },

        media() {
            let hiddenImageFlags = [
                'ig_post_video_thumb', 'telegram_video_thumbnail', 'telegram_video_thumbnail',
                'tiktok-ingester_video_thumbnail', 'yt_video_thumbnail', 'IG_video_thumbnail', 'vk_post_video_thumb'
            ]

            if (this.content.videos.length) hiddenImageFlags.push('full_picture', 'video_inline')

            return [
                ...this.content.images.filter(i => ! i.flag.startsWith('video_thumbnail_') && ! hiddenImageFlags.includes(i.flag)),
                ...this.content.videos.map(v => ({ ...v, thumbnailUrl: this.videoThumbnailUrl(v) }))
            ]
        }
    },

    methods: {
        ...mapActions(useContentModalsContentDetailStore, {
            openDetails: 'open'
        }),
        ...mapActions(useContentHashStore, { showHash: 'show' }),
        ...mapActions(useContentModalsSentimentEditStore, { editSentiment: 'open' }),

        readMore() {
            this.showFullText = true
            this.streamStore.triggerLayoutUpdate([ this.content ])
        },

        showMedia(currentIndex) {
            this.streamStore.mediaLightbox.open(this.media, currentIndex, this.content)
        },

        videoThumbnailUrl(video) {
            return this.content.images.find(i => i.flag == `video_thumbnail_${video.id}`)?.src
                || this.content.images[0]?.src
        }
    },

    watch: {
        'content.tags'() { this.streamStore.triggerLayoutUpdate([ this.content ]) },
        'content.isTranslating'() { this.streamStore.triggerLayoutUpdate([ this.content ]) }
    }
}
</script>
