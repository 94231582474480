import { defineContentKeywordsAnalysisStore } from '@/stores/analysis/analyses/content-keywords'
import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export const defineAnalysisContentKeywordsWidget = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,
            type: settings.type,
            undeletable: settings.undeletable ?? false,
            uneditable: settings.uneditable ?? false,

            analysisId: settings.analysisId,
            analysisStore: null,

            showTitle: true,

            editForm: {
                search: '',
                selectedAnalysis: null,
                showTitle: null
            },

            isNew: settings.isNew || false,
            lastLayoutUpdate: +new Date(),

            refreshInterval: 15,
            lastRefresh: null
        }),

        getters: {
            analysis(store) {
                let myAnalyses = useMyAnalysesStore()

                return myAnalyses.items.find(l => l.id == store.analysisId)
            },

            editFormAnalyses(store) {
                let myAnalyses = useMyAnalysesStore()

                let analyses = myAnalyses.items.filter(ch => ch.type == 'content-keywords')

                return store.editForm.search
                    ? analyses.filter(ch => ch.name.toLowerCase().includes(store.editForm.search.toLowerCase()))
                    : analyses
            },

            isConfigured() {
                return this.analysis
            }
        },

        actions: {
            initialize() {
                this.load(true)
                this.lastRefresh = + new Date

                return this
            },

            load(fresh = false) {
                if (! this.isConfigured) return

                this.analysisStore = defineContentKeywordsAnalysisStore({
                    id: `contentKeywordsAnalysis${this.analysis.id}`
                })

                if (fresh) {
                    this.analysisStore().initialize(this.analysis)
                } else {
                    this.analysisStore().loadSeries()
                }
            },

            edit() {
                this.editForm.search = ''
                this.editForm.selectedAnalysis = this.analysis
                this.editForm.showTitle = this.showTitle
            },

            save() {
                this.analysisId = this.editForm.selectedAnalysis?.id
                this.showTitle = this.editForm.showTitle

                this.load(true)
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                if (this.analysisStore) this.analysisStore().$dispose()

                this.$dispose()
            },

            refresh() {
                this.load()
                this.analysisStore().passive = true

                this.lastRefresh = + new Date
            },

            layoutUpdated() {
                this.lastLayoutUpdate = +new Date()
            },

            serialize() {
                return {
                    analysisId: this.analysis?.id,
                    showTitle: this.showTitle
                }
            },

            dependencies() {
                return {
                    'analyses': [ this.analysis?.id ]
                }
            }
        }
    })
}

export default defineAnalysisContentKeywordsWidget
