import useMyAnalysesStore from '../stores/me/analyses'
import useReportStore from '../stores/dashboards/report'
import useMyPerspectivesStore from '../stores/me/perspectives'
import useTargetListsStore from '../stores/me/target-lists'

import { defineStore } from 'pinia'

export const useDashboardsReportsPage = defineStore({
    id: 'dashboardsReportPage',

    getters: {
        title: () => useReportStore()?.report.name ? `${useReportStore().report.name} | Dashboards` : ''
    },

    actions: {
        async beforeEnter(to) {
            await Promise.all([
                useMyAnalysesStore().initialize(),
                useMyPerspectivesStore().initialize(),
                useTargetListsStore().initialize()
            ])

            useReportStore().initialize(to.params.report)
        },

        async beforeLeave() {
            useReportStore().stopUpdating()
        }
    }
})

export default useDashboardsReportsPage
