import { defineContentStreamStore } from '@/stores/reusable/stream'

export const useFeaturesMediaStreamStore = defineContentStreamStore({
    id: 'featuresMediaStream',

    perPage: 50,
    supportsPerspectives: false,
    supportsSearchTopics: false
})

export default useFeaturesMediaStreamStore
