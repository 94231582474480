import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const defineCards = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            source: settings.source || { items: [] },
            filter: settings.filter || (items => items),

            chooseCallback: settings.choose || false,

            layoutOptions: settings.layoutOptions || [
                {
                    id: 'grid',
                    name: 'Grid',
                    icon: 'view-grid',
                    available: true,
                    component: markRaw(require('@/components/ui/cards/layouts/grid').default),
                    settings: { showCharts: true }
                },
                {
                    id: 'table',
                    name: 'Table',
                    icon: 'menu',
                    available: true,
                    component: markRaw(require('@/components/ui/cards/layouts/table').default),
                    settings: { showCharts: true }
                }
            ],
            sortingOptions: settings.sortingOptions || [
                { id: 'default', name: 'Default', icon: '', direction: 'asc', default: true, apply: items => items }
            ],

            selectedSortingOption: null,
            selectedLayoutOption: null,

            rows: settings.rows,

            ...(settings.state || [])
        }),

        getters: {
            availableSortingOptions(store) {
                return store.sortingOptions.filter(option => {
                    return option.available === undefined || option.available === true || option.available(store)
                })
            },

            items(store) {
                let items = [ ...this.source.items ]

                items = this.filter(items)

                items = this.sorting.apply(items)
                items = this.sorting.direction == 'desc' ? items.reverse() : items

                return items
            },

            sorting(store) {
                let option = store.availableSortingOptions.find(o => o.id && o.id == store.selectedSortingOption)

                if (! option) option = store.availableSortingOptions.find(o => o.default) ?? store.availableSortingOptions[0]

                return option
            },

            layout(store) {
                let layout = store.layoutOptions.find(o => o.id == store.selectedLayoutOption)

                return layout && layout.available ? layout : store.layoutOptions.find(o => o.available !== false)
            },

            layoutSettings() {
                return this.layout.settings || {}
            },

            isInitialized() {
                return this.source.isInitialized
            }
        },

        actions: {
            setSorting(option) {
                if (! this.sortingOptions.find(o => o.id == option)) return

                this.selectedSortingOption = option
            },

            setLayout(layout) {
                this.selectedLayoutOption = layout
            },

            refreshLayouts(width) {
                this.layoutOptions.forEach(layout => {
                    layout.available = ! layout.minWidth || width >= layout.minWidth
                })
            },

            choose(item) {
                this.chooseCallback(item, this)
            },

            ...(settings.actions || [])
        }
    })
}

export default defineCards

export const useDefaultCardsStore = defineCards({
    id: 'default-cards'
})
