<template>
    <div>
        <div class="px-8 py-3.5 mx-auto" :class="{ 'flex items-center': store.compactToolbar }">
            <slot name="before-input"></slot>

            <div class="flex flex-1 items-center space-x-4">
                <toolbar-search :store="store"></toolbar-search>

                <ui-button color="blue" icon="check-square" :action="savePerspective" v-if="store.supportsPerspectives && store.hasUnsavedFilters && ! $page.perspective">
                    Save
                </ui-button>
            </div>

            <div id="top-filters"></div>

            <toolbar-filters :store="store"></toolbar-filters>
        </div>
    </div>
</template>

<script>
import ToolbarFilters from './toolbar-filters'
import ToolbarSearch from './toolbar-search.vue'

import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'

import { mapActions } from 'pinia'

export default {
    components: {
        ToolbarFilters, ToolbarSearch
    },

    methods: {
        ...mapActions(useContentModalsPerspectiveEditStore, { _openEditModal: 'open' }),

        async savePerspective() {
            this.store.applySearchQuery()

            if (this.store.appliedPerspective && this.store.appliedPerspective.id) {
                await this.store.savePerspective()
            } else {
                await this._openEditModal(this.store.appliedPerspective, this.store)
            }
        }
    }
}
</script>
