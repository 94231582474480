<template>
    <ui-modal name="thread-info" title="Thread" width="100%" :max-width="600" :max-height="500" @opened="opened" @closed="closed">
        <div>
            <label for="internalName" class="block text-sm text-gray-700 font-medium mb-1">
                Internal Subject
            </label>

            <ui-input type="text" name="internalName" id="internalName" placeholder="Internal Subject" v-model="inputs.internalName"></ui-input>
        </div>

        <div>
            <label for="agent" class="block text-sm font-medium text-gray-700 mt-4">
                Agent
            </label>
            <ui-select v-if="agentsLoaded" class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="agents" v-model="inputs.agentId" :reduce="agent => agent.id" label="name"></ui-select>
            <div v-else class="mt-1 flex items-center">
                <ui-spinner type="clip"></ui-spinner>
                <span class="ml-3">Loading agents...</span>
            </div>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="blue" :action="() => updateThread(inputs)">
                Save
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useAgentsStore from '@/admin/stores/support/agents'
import useThreadStore from '@/admin/stores/support/thread'

import { mapActions, mapState } from 'pinia'

export default {
    data() {
        return {
            inputs: {
                internalName: null,
                agentId: null
            }
        }
    },

    computed: {
        ...mapState(useAgentsStore, [
            'agents',
            'agentsLoaded'
        ]),

        ...mapState(useThreadStore, [
            'thread'
        ])
    },

    methods: {
        opened() {
            this.inputs.internalName = this.thread.internalName
            this.inputs.agentId = this.thread.agent.id
        },

        closed() {
            this.inputs.internalName = null
        },

        ...mapActions(useThreadStore, [
            'updateThread'
        ])
    }
}
</script>
