<template>
    <div class="content-card bg-white max-w-3xl w-full overflow-visible shadow rounded-sm relative flex flex-col">
        <div class="content-card-content shrink-0 px-4 py-5">
        <svg
            role="img"
            :width="large ? '400' : '400'"
            :height="large ? '364' : '110'"
            aria-labelledby="loading-aria"
            :viewBox="large ? '0 0 400 364' : '0 0 400 110'"
            preserveAspectRatio="none"
        >
            <title id="loading-aria">Loading...</title>
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                clip-path="url(#clip-path)"
                style='fill: url("#fill");'
            ></rect>
            <defs>
                <clipPath id="clip-path">
                    <circle cx="24" cy="24" r="22" />
                    <rect x="60" y="10" rx="4" ry="4" width="160" height="8" />
                    <rect x="60" y="28" rx="4" ry="4" width="80" height="8" />
                    <rect x="1" y="67" rx="3" ry="3" width="420" height="6" />
                    <rect x="1" y="83" rx="3" ry="3" width="380" height="6" />
                    <rect x="1" y="99" rx="3" ry="3" width="180" height="6" />
                    <rect x="1" y="124" rx="4" ry="4" width="508" height="240" />
                </clipPath>
                <linearGradient id="fill">
                    <stop
                        offset="0.599964"
                        stop-color="#f3f3f3"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="-2; -2; 1"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                    <stop
                        offset="1.59996"
                        stop-color="rgb(59, 130, 246)"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="-1; -1; 2"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                    <stop
                        offset="2.59996"
                        stop-color="#f3f3f3"
                        stop-opacity="1"
                    >
                        <animate
                            attributeName="offset"
                            values="0; 0; 3"
                            keyTimes="0; 0.25; 1"
                            dur="2s"
                            repeatCount="indefinite"
                        ></animate>
                    </stop>
                </linearGradient>
            </defs>
        </svg>
    </div>
    </div>
</template>

<script>
export default {
    props: { large: Boolean }
}
</script>
