import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import api from '@/api'
import useTargetsMyListsListPage from '@/pages/targets-my-lists-list'

export const useModalsImportOptionsStore = defineStore({
    id: 'modalsImportOptions',

    state: () => ({
        streamStore: null,
        isImporting: false,

        file: null
    }),

    actions: {
        reset() {
            this.$reset()
        },

        open() {
            this.reset()

            useModal().show('targets-list-import')
        },

        cancel() {
            useModal().hide('targets-list-import')
        },

        doImport(streamStore) {
            this.streamStore = streamStore

            this.isImporting = true

            api.route('me lists import-targets', [ useTargetsMyListsListPage().listId ]).formData({
                file: this.file
            }).post().res(() => {
                streamStore.load()

                this.cancel()
            })
        }
    }
})

export default useModalsImportOptionsStore
