̦<template>
    <div class="h-full min-h-[9rem] relative">
        <div v-if="store.isLoading" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-100 bg-opacity-10 backdrop-blur-sm rounded-md">
            <div class="bg-gray-200 bg-opacity-80 text-gray-900 font-medium px-6 py-4 rounded-lg shadow-sm flex items-center">
                <ui-spinner type="clip" class="mr-2"></ui-spinner>
                Updating heatmap content...
            </div>
        </div>

        <div class="relative min-h-[9rem]" ref="contentContainer" v-if="store.series.length">
            <div class="w-full h-full" ref="content"></div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
                <div class="absolute mb-3 h-12 w-32" :style="`left:${tooltip.position.x}px;top:${tooltip.position.y}px;`" v-show="tooltip.show">
                    <div class="bg-gray-900 bg-opacity-80 border border-gray-700 text-gray-50 shadow rounded-sm text-xs px-3 py-1 text-center -translate-x-1/2">
                        <div class="text-2xs font-medium mb-1">{{dayFormatter(tooltip.value.weekday)}} between {{tooltip.value.hour}}:00 and {{tooltip.value.hour}}:59</div>
                        <div class="font-bold">{{tooltip.value.value}}</div>
                    </div>
                </div>
            </transition>
        </div>

        <div v-else-if="! store.isLoading" class="h-full flex flex-col items-center justify-center text-gray-700">
            <ui-icon name="frown" class="mb-2 text-3xl text-gray-600"></ui-icon>
            <p class="font-medium">Nothing to show yet.</p>
            <p>Please check back later or try a different time range.</p>
        </div>
    </div>
</template>

<script>
import { select } from 'd3-selection'
import { group, max, range } from 'd3-array'
import { scaleQuantize } from 'd3-scale'

export default {
    props: ['cellWidth', 'cellHeight', 'compact'],

    watch: {
        'store.series'(val) {
            if (val.length) this.$nextTick(() => this.render())
        },
    },

    mounted() {
        this.store.series && this.store.series.length && this.render()
    },

    data: () => ({
        tooltip: {
            show: false,
            value: {},
            position: {}
        }
    }),

    methods: {
        dayOfWeek(day) {
            return (day + 6) % 7
        },

        dayFormatter(day) {
            if (this.compact) {
                return ['M', 'T', 'W', 'T', 'F', 'S', 'S'][(day + 6) % 7]
            }

            return ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'][(day + 6) % 7]
        },

        render() {
            if (! this.$refs.content) return

            this.$refs.content.innerHTML = ''

            let data = [ ...this.store.series ].sort((a, b) => a.weekday - b.weekday)

            if (!data.length) return

            let weekdayHourValues = data.map(dv => ({
                weekday: Number(dv.weekday),
                hour: Number(dv.hour),
                value: Number(dv.value)
            }))

            // Fill missing weekday hours with 0
            let m = group(weekdayHourValues, d => `${d.weekday}:${d.hour}`)
            let weekdaysHours = range(7).map(w => range(24))

            weekdaysHours.forEach(function (weekday, weekdayIndex) {
                weekday.forEach(function (hour) {
                    m.get(`${weekdayIndex}:${hour}`) || weekdayHourValues.push({weekday: weekdayIndex, hour: hour, value: 0})
                })
            })

            const width = this.$refs.content.offsetWidth

            const svg = select(this.$refs.content).append('svg')

            const cellWidth = this.cellWidth ?? width / 24 - (30 / 24)
            const cellHeight = this.cellHeight ?? cellWidth

            svg.attr('viewBox', `0 0 ${width} ${cellHeight * 7 + cellHeight}`)

            const wrapper = svg.append('g')

            const colorFn = scaleQuantize()
                .domain([1, Math.ceil(max(weekdayHourValues.map(c => c.value)))])
                .range([ '#c5dafb', '#9fc2f9', '#79a9f6', '#5d96f4', '#3f84f2', '#2172f0', '#0e62e4', '#0f55c8', '#0d49ab' ])

            // Days text on the left
            wrapper
                .selectAll()
                .data(range(7))
                .enter()
                .append('text')
                .attr('x', 8)
                .attr('y', d => (d + 0.6) * cellHeight)
                .attr('class', (this.compact ? 'text-2xs' : 'text-xs') + ' fill-current font-medium text-gray-700')
                .text(d => this.dayFormatter(d + 1))

            // Points
            wrapper
                .selectAll()
                .data(weekdayHourValues, (d) => d.weekday + ':' + d.hour)
                .enter()
                .append('rect')
                .attr('width', cellWidth - 2.5)
                .attr('height', cellHeight - 2.5)
                .attr('x', (d) => d.hour * cellWidth + 30)
                .attr('y', (d) => this.dayOfWeek(d.weekday) * cellHeight + 0.5)
                .attr('fill', d => d.value === 0 ? '#f0f3f5' : colorFn(d.value))
                .on('mouseover', (ev, d) => this.showTooltip({weekday: d.weekday, hour: d.hour, value: d.value}, { x: ev.clientX, y: ev.clientY }))
                .on('mouseout', () => this.hideTooltip())

            // Hours text at the bottom
            wrapper
                .selectAll()
                .data(range(24))
                .enter()
                .append('text')
                .attr('x', d => d * cellWidth + 30)
                .attr('y', 7 * cellHeight + 12)
                .attr('class', (this.compact ? 'text-2xs' : 'text-xs') + ' fill-current text-gray-700')
                .text(d => d)
        },

        showTooltip(value, position) {
            if (! this.$refs.contentContainer) return

            this.tooltip.value = value

            this.tooltip.position = {
                x: position.x - this.$refs.contentContainer.getBoundingClientRect().left,
                y: position.y + 20 - this.$refs.contentContainer.getBoundingClientRect().top
            }

            this.tooltip.show = true
        },

        hideTooltip() {
            this.tooltip.show = false
        },

        handleEvent(callback) {
            return function(event, d) { callback(this, d, event) }
        },

        reflow() {
            this.render()
        }
    }
}
</script>
