<template>
    <div class="mb-16" v-if="! store.isInitialized || store.items.length">
        <div class="flex items-center justify-between mb-6" v-if="title">
            <h1 class="px-0.5 text-2xl text-gray-900 font-semibold">
                {{title}}
            </h1>

            <div class="flex items-center space-x-3">
                <results-view :store="store"></results-view>
                <results-view-settings :store="store"></results-view-settings>
            </div>
        </div>

        <ui-cards :store="store">
            <template v-slot:item="{ item, cardsStore }">
                <slot name="item" :item="item" :cards-store="cardsStore"></slot>
            </template>
        </ui-cards>
    </div>
</template>

<script>
import ResultsView from '@/components/ui/cards/toolbar/results-view'
import ResultsViewSettings from '@/components/ui/cards/toolbar/results-view-settings'

export default {
    components: {
        ResultsView, ResultsViewSettings
    },

    props: [ 'title' ]
}
</script>
