import { defineStore } from 'pinia'
import { hasFeatureTo } from '@/helpers/licenses'

export const useContentLibraryTypesStore = defineStore({
    id: 'content-library-types',

    getters: {
        content: state => ([
            {
                type: 'facebook-post',
                name: 'Facebook Posts',
                icon: 'badges.target-facebook-page',
                filter: 'facebook-post'
            },
            {
                type: 'facebook-group-post',
                name: 'Facebook Group Posts',
                icon: 'badges.target-facebook-group',
                filter: 'facebook-group-post'
            },
            {
                type: 'instagram-post',
                name: 'Instagram Posts',
                icon: 'badges.target-instagram-profile',
                filter: 'instagram-post'
            },
            {
                type: 'telegram-channel-post',
                name: 'Telegram Channel Posts',
                icon: 'badges.target-telegram-channel',
                filter: 'telegram-channel-post'
            },
            {
                type: 'telegram-group-message',
                name: 'Telegram Group Messages',
                icon: 'badges.target-telegram-group',
                filter: 'telegram-group-message'
            },
            {
                type: 'tiktok-video',
                name: 'TikTok Videos',
                icon: 'badges.target-tiktok-user',
                filter: 'tiktok-video'
            },
            {
                type: 'twitter-tweet',
                name: 'Twitter Tweets',
                icon: 'badges.target-twitter-user',
                filter: 'twitter-tweet'
            },
            {
                type: 'vkontakte-post',
                name: 'VKontakte Posts',
                icon: 'badges.target-vkontakte-community',
                filter: 'vkontakte-post'
            },
            {
                type: 'web-article',
                name: 'Web Articles',
                icon: 'badges.target-web-feed',
                filter: 'web-article'
            },
            {
                type: 'web-resource',
                name: 'Web Resources',
                icon: 'badges.target-web-site',
                filter: 'web-resource'
            },
            {
                type: 'youtube-video',
                name: 'Youtube Videos',
                icon: 'badges.target-youtube-channel',
                filter: 'youtube-video'
            }
        ].filter(t => hasFeatureTo([`access-content-${t.type}`])))
    },

    actions: {
        async all() {
            return this.content
        },

        find(type) {
            return this.content.find(f => f.type == type)
        }
    }
})

export default useContentLibraryTypesStore
