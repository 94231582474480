<template>
    <component :is="cardComponent" :content="content" mode="modal" :stream-store="streamStore" :key="content.id" v-if="inline && content">
        <template v-slot:details>
            <content-detail-replies :replies="replies"></content-detail-replies>
        </template>
    </component>

    <ui-modal name="content-detail" title="Conversation" width="100%" height="auto" :max-width="760" :max-height="800" unstyled @opened="opened" v-else>
        <div class="h-full overflow-y-auto rounded-md" style="max-height:800px;" ref="scrollContainer">
            <component :is="cardComponent" :content="content" mode="modal" :stream-store="streamStore" :key="content.id" v-if="content">
                <template v-slot:details>
                    <content-detail-replies :replies="replies"></content-detail-replies>
                </template>
            </component>
        </div>
    </ui-modal>
</template>

<script>
import ContentDetailReplies from './partials/content-detail-replies'

import ContentFacebookGroupPostCard from '@/components/content/stream-cards/facebook-group-post'
import ContentFacebookPostCard from '@/components/content/stream-cards/facebook-post'
import ContentFacebookUserPostCard from '@/components/content/stream-cards/facebook-user-post'
import ContentInstagramPostCard from '@/components/content/stream-cards/instagram-post'
import ContentTelegramChannelPostCard from '@/components/content/stream-cards/telegram-channel-post'
import ContentTelegramGroupMessageCard from '@/components/content/stream-cards/telegram-group-message'
import ContentTiktokVideoCard from '@/components/content/stream-cards/tiktok-video'
import ContentTwitterTweetCard from '@/components/content/stream-cards/twitter-tweet'
import ContentVkontaktePostCard from '@/components/content/stream-cards/vkontakte-post'
import ContentWebArticleCard from '@/components/content/stream-cards/web-article'
import ContentWebResourceCard from '@/components/content/stream-cards/web-resource'
import ContentYoutubeVideoCard from '@/components/content/stream-cards/youtube-video'

import { mapState } from 'pinia'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

export default {
    props: [ 'inline' ],

    components: {
        ContentDetailReplies,
        ContentFacebookGroupPostCard, ContentFacebookPostCard, ContentFacebookUserPostCard, ContentInstagramPostCard,
        ContentTelegramChannelPostCard, ContentTelegramGroupMessageCard, ContentTiktokVideoCard,
        ContentVkontaktePostCard, ContentWebArticleCard, ContentWebResourceCard, ContentYoutubeVideoCard,
        ContentTwitterTweetCard
    },

    computed: {
        ...mapState(useContentModalsContentDetailStore, [ 'loading', 'content', 'replies', 'streamStore' ]),

        cardComponent() {
            return {
                'facebook-group-post': ContentFacebookGroupPostCard,
                'facebook-post': ContentFacebookPostCard,
                'facebook-user-post': ContentFacebookUserPostCard,
                'instagram-post': ContentInstagramPostCard,
                'telegram-channel-post': ContentTelegramChannelPostCard,
                'telegram-group-message': ContentTelegramGroupMessageCard,
                'tiktok-video': ContentTiktokVideoCard,
                'twitter-tweet': ContentTwitterTweetCard,
                'vkontakte-post': ContentVkontaktePostCard,
                'web-article': ContentWebArticleCard,
                'web-resource': ContentWebResourceCard,
                'youtube-video': ContentYoutubeVideoCard
            }[this.content.type]
        }
    },

    methods: {
        opened() {
            this.$refs.scrollContainer?.scrollTo({ top: 0 })
        }
    }
}
</script>
