import defineAnalysisContentPerformanceWidget from './widgets/analysis-content-performance'
import defineAnalysisContentBreakdownBySourceWidget from './widgets/analysis-content-breakdown-by-source'
import defineAnalysisContentBreakdownByGroupWidget from './widgets/analysis-content-breakdown-by-group'
import defineAnalysisContentPublishTimesWidget from './widgets/analysis-content-publish-times'
import defineAnalysisContentPullPushWidget from "./widgets/analysis-content-pull-push"
import defineAnalysisContentSentimentWidget from "./widgets/analysis-content-sentiment"
import defineAnalysisContentTopPlatformsWidget from './widgets/analysis-content-top-platforms'
import defineAnalysisContentKeywordsWidget from '@/stores/dashboards/widgets/analysis-content-keywords'
import defineAnalysisTargetPublishTimesWidget from './widgets/analysis-target-publish-times'
import defineAnalysisTargetAudienceWidget from '@/stores/dashboards/widgets/analysis-target-audience'
import defineKeywordsWidget from './widgets/keywords'
import defineImageWidget from './widgets/image'
import defineTextWidget from './widgets/text'

import useModalsReportEditStore from '@/stores/dashboards/modals/report-edit'
import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '@/api'
import { useModal } from '@/helpers'
import { date } from '@/helpers/datetime'

import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export const useReportStore = defineStore({
    id: 'report',

    state: () => ({
        report: {},
        reportLoaded: false,
        reportPromise: null,

        configuration: null,
        contents: null,

        exports: [],

        isBeingSaved: false,
        isBeingExported: false,
        isEditing: false,

        activePage: 1,

        selectedWidget: null,
        selectedWidgetId: null,

        widgetBeingDeleted: null,
        widgetBeingDeletedId: null,

        lastWidgetId: 0,

        widgetTypes: [
            {
                id: 'analysis-content-performance',
                name: 'Content Performance Analysis',
                icon: 'analysis',
                minW: 6, minH: 12, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineAnalysisContentPerformanceWidget
            },
            {
                id: 'analysis-content-breakdown-by-source',
                name: 'Top Sources Analysis',
                icon: 'analysis',
                minW: 12, minH: 38, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentBreakdownBySourceWidget
            },
            {
                id: 'analysis-content-breakdown-by-group',
                name: 'Top Groups Analysis',
                icon: 'analysis',
                minW: 12, minH: 38, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentBreakdownByGroupWidget
            },
            {
                id: 'analysis-content-publish-times',
                name: 'Content Publish Times Analysis',
                icon: 'analysis',
                minW: 9, minH: 8, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentPublishTimesWidget
            },
            {
                id: 'analysis-content-pull-push',
                name: 'Content Pull/Push Analysis',
                icon: 'analysis',
                minW: 9, minH: 12, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentPullPushWidget
            },
            {
                id: 'analysis-content-sentiment',
                name: 'Content Sentiment',
                icon: 'analysis',
                showBackground: true,
                minW: 12, minH: 18, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentSentimentWidget
            },
            {
                id: 'analysis-content-top-platforms',
                name: 'Top Platforms Analysis',
                icon: 'analysis',
                minW: 12, minH: 38, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentTopPlatformsWidget
            },
            {
                id: 'analysis-content-keywords',
                name: 'Content Keywords Analysis',
                icon: 'analysis',
                minW: 9, minH: 12, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisContentKeywordsWidget
            },
            {
                id: 'analysis-target-publish-times',
                name: 'Target Publish Times Analysis',
                icon: 'analysis',
                minW: 9, minH: 8, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisTargetPublishTimesWidget
            },
            {
                id: 'analysis-target-audience',
                name: 'Target Audience Analysis',
                icon: 'analysis',
                minW: 9, minH: 12, startingW: 12,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineAnalysisTargetAudienceWidget
            },
            {
                id: 'keywords',
                name: 'Keywords',
                icon: 'align-center',
                minW: 3, minH: 5,
                expandedWidth: 1120, expandedEditWidth: 480,
                defineStore: defineKeywordsWidget
            },
            {
                id: 'image',
                name: 'Image',
                icon: 'image',
                minW: 3, minH: 5,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineImageWidget
            },
            {
                id: 'text',
                name: 'Text',
                icon: 'text-word',
                inline: true,
                minW: 3, minH: 2, startingW: 12, startingH: 14,
                expandedWidth: 1120, expandedEditWidth: 960,
                defineStore: defineTextWidget
            }
        ]
    }),

    actions: {
        async initialize(id) {
            this.$reset()

            await this.load(id)

            this.loadExports()

            this.createMetaWidgets()

            this.startUpdating()
        },

        async load(id, force = false) {
            if (this.reportLoaded && ! force) return Promise.resolve()
            if (this.reportPromise) return this.reportPromise

            return this.reportPromise = api.route('me dashboards details', { id }).get().json(res => {
                this.report = res.data
                this.reportLoaded = true
                this.reportPromise = null

                this.contents = this.report.contents.map(page => page.map(w => this.loadWidget(w)))
                this.configuration = this.report.configuration
            })
        },

        async loadExports() {
            api.route('me dashboards exports', { id: this.report.id }).get().json(res => {
                this.exports = res.data
            })
        },

        async reload() {
            if (! this.report.id) return

            return this.load(this.report.id, true)
        },

        edit() {
            useModalsReportEditStore().cancel()

            this.isEditing = true
        },

        save() {
            this.isBeingSaved = true

            this.contents.forEach(p => p.forEach(w => w.isNew = false))

            if (this.selectedWidget) {
                this.saveWidgetBeingEdited()
            }

            let contents = JSON.stringify(this.contents.map(p => p.map(w => ({
                x: w.x, y: w.y,
                w: w.w, h: w.h,
                type: w.type.id,
                state: w.serialize()
            }))))

            let dependencies = JSON.stringify(this.contents.map(p => p.flat())
                .flat()
                .map(w => w.dependencies())
                .reduce((allDeps, deps) => {
                    Object.entries(deps).forEach(([ relation, ids ]) => {
                        allDeps[relation] = [ ...(allDeps[relation] || []), ...ids.filter(v => v) ]
                    })
                    return allDeps
                }, {}))

            return api.route('me dashboards update', { id: this.report.id }).formData({
                _method: 'put',
                name: this.report.name,
                description: this.report.description,
                configuration: JSON.stringify(this.configuration),
                contents,
                dependencies
            }).post().json(res => {
                this.selectedWidgetId = null
                this.selectedWidget = null
                this.isBeingSaved = false
                this.isEditing = false
            })
        },

        startExportingReport() {
            useModal().show('report-export')
        },

        exportReport() {
            this.isBeingExported = true

            api.route('me dashboards export', { id: this.report.id }).get().res(res => {
                useModal().hide('report-export')

                this.isBeingExported = false
            })
        },

        setActivePage(page) {
            this.activePage = page
        },

        addPage() {
            this.contents.push([])
        },

        removePage(index) {
            this.contents[index].forEach(w => w.destroy())

            this.contents.splice(index, 1)
        },

        createMetaWidgets() {
            if (! this.contents.length) this.contents.unshift([])

            if (! this.contents[0].length) {
                this.contents[0].push(defineImageWidget({
                    id: 'reportHeaderImageWidget',
                    type: this.widgetTypes.find(w => w.id === 'image'),
                    minH: 20, minW: 5,
                    h: 16, w: 4, x: 0, y: 0, i: 0,
                    undeletable: true,
                    url: '/g.png'
                })())

                this.contents[0].push(defineTextWidget({
                    id: 'reportHeaderTitleWidget',
                    type: this.widgetTypes.find(w => w.id === 'text'),
                    minH: 20, minW: 18,
                    h: 14, w: 18, x: 5, y: 1, i: 1,
                    undeletable: true,
                    text: '<h1>Your new report</h1><p><span style="color: #74777b">Your new subtitle</span></p>'
                })())

                this.contents[0].push(defineTextWidget({
                    id: 'reportHeaderSideTextWidget',
                    type: this.widgetTypes.find(w => w.id === 'text'),
                    minH: 20, minW: 3,
                    h: 16, w: 6, x: 30, y: 0, i: 2,
                    undeletable: true,
                    text: `<p>${date(new Date(), 'MMMM do, y')}</p>`
                })())
            }

            this.contents[0].forEach(w => w.undeletable = true)
        },

        addWidget(type, edit = true) {
            let lastNonEmptyRow = Math.max(...this.contents[this.activePage].map(w => w.y + w.h - 1))

            let widget = this.createWidget(type, {
                x: 0, y: lastNonEmptyRow > 0 ? lastNonEmptyRow + 1 : 0,
                w: type.startingW ?? type.minW, h: type.startingH ?? type.minH,
                isNew: true
            }, this.activePage)

            this.contents[this.activePage].push(widget)

            if (edit) {
                this.editWidget(widget)
                this.edit()
            }

            return widget
        },

        loadWidget(widget) {
            let type = this.widgetTypes.find(t => t.id == widget.type)

            return this.createWidget(type, { ...widget, ...widget.state })
        },

        createWidget(type, settings) {
            let index = this.lastWidgetId++

            let store = type.defineStore({
                id: `dashboard${this.report.id}Widgets${index}`,
                i: `report-${this.report.id}-${type.id}-${index}`,
                ...settings,
                type
            })

            return store().initialize()
        },

        async expandWidget(widget) {
            this.selectedWidgetId = widget.$id
            this.selectedWidget = widget

            await nextTick()

            useModal().show('dashboard-expanded-widget')
        },

        closeExpandedWidget() {
            this.selectedWidgetId = null
            this.selectedWidget = null

            useModal().hide('dashboard-expanded-widget')
        },

        async editWidget(widget) {
            if (! widget.type.inline) {
                await this.expandWidget(widget)
            }

            this.selectedWidgetId = widget.$id
            this.selectedWidget = widget
            this.selectedWidget.edit()
        },

        stopEditingWidget() {
            this.closeExpandedWidget()

            this.selectedWidgetId = null
            this.selectedWidget = null

            useModal().hide('dashboard-expanded-widget')
        },

        async saveWidgetBeingEdited() {
            await this.selectedWidget.save()

            this.selectedWidgetId = null
            this.selectedWidget = null
        },

        async deleteWidget(widget) {
            this.widgetBeingDeleted = widget

            await useDeleteConfirmationModal().open('Dashboard widget')
                .then(() => {
                    this.contents = this.contents.map(p => p.filter(w => w !== this.widgetBeingDeleted))
                    this.widgetBeingDeleted.destroy()
                })

            this.widgetBeingDeleted = null
        },

        stopDeletingWidget() {
            this.widgetBeingDeleted = null
        },

        startUpdating() {
            this.refreshInterval = setInterval(() => {
                this.contents.forEach(page => page.forEach(widget => {
                    if (widget.lastRefresh + widget.refreshInterval * 60000 < + new Date) {
                        widget.refresh()
                    }
                }))
            }, 60000)
        },

        stopUpdating() {
            clearInterval(this.refreshInterval)
        },

        layoutUpdated() {
            this.contents.forEach(p => p.forEach(w => w.layoutUpdated()))
        }
    }
})

export default useReportStore
