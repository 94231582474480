<template>
    <ui-modal name="topic-breakdown-topic-size" title="Topic size">
        <div class="w-full">
            <div class="w-2/3 flex justify-between mx-auto">
                <p>Small</p>
                <p>Medium</p>
                <p>Large</p>
            </div>

            <div class="w-2/3 mx-auto">
                <input type="range" min="0" max="2" step="1" class="w-full h-1" v-model="topicSize">
            </div>

            <div class="text-gray-700 text-sm mt-16 h-10">
                <p v-if="topicSize == 0"><strong>Small topic</strong> size is default option. It forms a topic when there's around 30 sentences with a similar narrative.</p>
                <p v-else-if="topicSize == 1"><strong>Medium topic</strong> size reduces the amount of formed topics by ~30% by increasing the required amount of sentences required to form a topic, which creates a more high-level overview.</p>
                <p v-else-if="topicSize == 2"><strong>Large topic</strong> size reduces the amount of formed topics by further ~30% by increasing the required amount of sentences required to form a topic. This results in an even higher-level overview.</p>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button @click="close">
                    Close
                </ui-button>

                <ui-button @click.prevent="apply" color="blue" type="submit">
                    Apply
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import useAnalysisModalsTopicBreakdownTopicSizeStore from '@/stores/analysis/modals/topic-breakdown-topic-size'

import { mapActions, mapWritableState } from 'pinia'

export default {
    computed: {
        ...mapWritableState(useAnalysisModalsTopicBreakdownTopicSizeStore, [
            'topicSize'
        ])
    },

    methods: {
        ...mapActions(useAnalysisModalsTopicBreakdownTopicSizeStore, [ 'apply', 'close' ]),
    }
}
</script>
