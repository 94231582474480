import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesModalsEditStore from '@/stores/workspaces/modals/manage'
import defineForm from '@/stores/reusable/form'

import api from '@/api'

export const useWorkspacesFormsDeleteStore = defineForm({
    id: 'workspacesFormsDelete',

    inputs: () => ({
        workspace: {}
    }),

    submitRequest() {
        return api.route('me workspaces delete', { id: this.inputs.workspace.id })
            .formData({ _method: 'delete' })
            .post()
    },

    onResponse() {
        useMyWorkspacesStore().reload()
        useWorkspacesModalsEditStore().listWorkspaces()
    },

    actions: {
        initialize(workspace) {
            this.reset()

            this.inputs.workspace = workspace
        },

        cancel() {
            useWorkspacesModalsEditStore().editWorkspace(this.inputs.workspace)
        }
    }
})

export default useWorkspacesFormsDeleteStore
