<template>
    <div class="pb-6 px-8 max-w-11xl mx-auto">
        <ui-cards-toolbar :store="workspacesCardsStore"></ui-cards-toolbar>

        <ui-cards :store="workspacesCardsStore">
            <template v-slot:item="{ item }">
                <workspace-card :organization="organization" :workspace="item" :cards-store="workspacesCardsStore"></workspace-card>
            </template>
        </ui-cards>

        <Teleport to="#header-actions">
            <ui-button icon="plus-square" color="blue" :action="openWorkspaceCreateModal">
                New Workspace
            </ui-button>

            <form class="w-56 relative" @submit.prevent>
                <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                    <ui-icon name="search" class="text-gray-500"></ui-icon>
                </div>

                <input type="text" ref="input" v-model="$page.search"
                       autocomplete="off" spellcheck="false"
                       class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
            </form>
        </Teleport>

        <Teleport to="#root">
            <workspace-create-modal></workspace-create-modal>
        </Teleport>
    </div>
</template>

<script>
import WorkspaceCard from '@/admin/components/accounts/stream-cards/workspace'
import WorkspaceCreateModal from '@/admin/components/accounts/modals/workspace-create'

import useOrganizationStore from '@/admin/stores/accounts/organization'
import useWorkspacesCardsStore from '@/admin/stores/accounts/workspaces-cards'
import useModalsWorkspaceCreateStore from '@/admin/stores/accounts/modals/workspace-create'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        WorkspaceCard,
        WorkspaceCreateModal
    },

    computed: {
        ...mapStores(useWorkspacesCardsStore),

        ...mapState(useOrganizationStore, [
            'organization'
        ])
    },

    methods: {
        ...mapActions(useModalsWorkspaceCreateStore, { openWorkspaceCreateModal: 'open' })
    }
}
</script>
