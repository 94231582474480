<template>
    <div class="py-1 px-5 bg-gray-50 border-t border-gray-100 rounded-b-md flex-1 flex flex-col min-h-0">
        <div class="flex items-center justify-between my-1">
            <span class="text-gray-800 text-sm">
                Showing <span class="font-medium">{{replies.length}}</span> replies
            </span>

            <ui-dropdown align="right" v-if="! parent">
                <template v-slot:trigger>
                    <div class="flex items-center space-x-2">
                        <span class="text-gray-700 text-sm font-medium">Order by</span>
                        <div class="inline-flex items-center px-3 pr-2 h-7 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                            <ui-icon :name="sortingOption.icon" class="mr-1"></ui-icon>
                            <span class="font-medium">{{sortingOption.name}}</span>
                            <ui-icon name="select" class="ml-2"></ui-icon>
                        </div>
                    </div>
                </template>

                <template v-slot:content>
                    <template v-for="option, index in sortingOptions">
                        <ui-dropdown-link :icon="option.icon" @click="setSorting(option.id)" :key="`ordering-${option.id}`" v-if="option.id">
                            {{option.name}}
                        </ui-dropdown-link>

                        <ui-dropdown-separator :key="`ordering-separator-${index}`" v-else></ui-dropdown-separator>
                    </template>
                </template>
            </ui-dropdown>
        </div>

        <div class="flex-1 min-h-0">
            <div class="divide-y divide-gray-100">
                <content-detail-reply :reply="reply" v-for="reply in replies" :replies-component="_.type" :key="reply.id"></content-detail-reply>
            </div>

            <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadAdditionalReplies(parent, $event)"></ui-infinite-loading>
        </div>
    </div>
</template>

<script>
import ContentDetailReply from './content-detail-reply'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'parent', 'replies' ],

    components: { ContentDetailReply },

    computed: {
        ...mapState(useContentModalsContentDetailStore, [
            'lastReloadTime', 'sortingOption', 'sortingOptions'
        ])
    },

    methods: {
        ...mapActions(useContentModalsContentDetailStore, [ 'loadAdditionalReplies', 'setSorting' ])
    }
}
</script>
