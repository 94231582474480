import useMyStore from '@/stores/me/my'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsCuratedListsPage = defineStore({
    id: 'targetsCuratedListsPage',

    state: () => ({
        search: '',

        rootCategory: null,
        category: null
    }),

    getters: {
        title: () => 'Curated Lists | Targets'
    },

    actions: {
        afterEnter(to) {
            this.search = ''

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize().then(() => this.initializeCategories())
            useMyTargetListsStore().initialize()
            useMyRecentTargetsStore().initialize()
        },

        initializeCategories() {
            if (this.rootCategory) return

            let categories = [ ...useMyCuratedListsStore().categories ]
                .sort((a, b) => a.name.localeCompare(b.name))

            this.rootCategory = this.category = categories.find(c => c.language === useMyStore().preferredLanguage)
                ?? categories.find(c => c.language === 'en')
                ?? categories[0]
        }
    }
})

export default useTargetsCuratedListsPage
