import api from '@/api'

import { defineStore } from 'pinia'
import useMyStore from '@/stores/me/my'

export const useMyWarningsStore = defineStore({
    id: 'myWarnings',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    getters: {
        activeItems(state) {
            return state.items.filter(warning => ! useMyStore().user.preferences.seenWarnings?.includes(warning.id))
        }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me warnings').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async close(warning) {
            if (! useMyStore().user.preferences.seenWarnings) {
                useMyStore().user.preferences.seenWarnings = []
            }

            useMyStore().user.preferences.seenWarnings.push(warning.id)

            useMyStore().updatePreferences({
                'seenWarnings': [... new Set((useMyStore().user.preferences.seenWarnings ?? []).concat([warning.id]))]
            })
        },

        async all() {
            await this.load()
            return this.items
        }
    }
})

export default useMyWarningsStore
