import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyAnalysesStore from '@/stores/me/analyses'

export const useAnalysisModalsAnalysisInfoStore = defineForm({
    id: 'analysisModalsAnalysisInfo',

    state: {
        analysis: {},
        analysisStore: null,

        notify: false
    },

    inputs: () => ({
        name: null
    }),

    submitRequest() {
        if (! this.analysis) {
            trackEvent('analyses', 'new-analysis-saved', this.analysisStore.type)
        }

        let route = this.analysis && this.analysis.id
            ? [ 'me analyses update', { id: this.analysis.id } ]
            : [ 'me analyses store' ]

        let payload = this.analysis && this.analysis.id
            ? {
                _method: 'put',
                name: this.inputs.name,
                notify: this.notify
            }
            : {
                type: this.analysisStore.type,
                name: this.inputs.name,
                configuration: this.analysisStore.analysisConfiguration,
                dependencies: this.analysisStore.resolveDependencies(),
                notify: this.notify
            }

        return api.route(...route)
            .json(payload)
            .post()
    },

    async onResponse(res) {
        await useMyAnalysesStore().reload()

        useModal().hide('analysis-analysis-info')

        if (this.analysis && this.analysis.id) {
            this.analysisStore.name = res.data.name
            this.analysisStore.isSaving = this.analysisStore.isDirty = false
        } else {
            this.analysisStore.id = res.data.id

            useRouter().replace({
                name: 'analysis.analysis.details',
                params: { type: this.analysisStore.type, id: this.analysisStore.id }
            })
        }

        this.fulfillPromise()
    },

    actions: {
        open(analysis = {}, analysisStore = null, notify = false) {
            this.reset()

            this.analysis = analysis
            this.inputs.name = analysis?.name

            this.analysisStore = analysisStore

            this.notify = notify

            useModal().show('analysis-analysis-info')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('analysis-analysis-info')

            this.fulfillPromise()
        },

        async delete() {
            try {
                await useMyAnalysesStore().delete({ id: this.analysis.id })
            } catch (e) {
                return
            }

            useModal().hide('analysis-analysis-info')
            useRouter().push({ name: 'analysis' })

            this.fulfillPromise()
        }
    }
})

export default useAnalysisModalsAnalysisInfoStore
