import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetHistoryStore from '@/stores/targets/target/history'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetHistoryPage = defineStore({
    id: 'targetsTargetHistoryPage',

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            useTargetsTargetHistoryStore().load(this.target)
        }
    }
})

export default useTargetsTargetHistoryPage
