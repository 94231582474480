import { asyncResource } from '@/api'
import { hasFeatureTo } from '@/helpers/licenses'
import languageFilterOptions from './language-filter-options'
import { defineContentPerspectiveActivityByDayAnalysisStore } from '@/stores/content/perspective-activity-by-day-analysis'
import { defineContentPerspectivePrevalenceAnalysisStore } from '@/stores/content/perspective-prevalence-analysis'
import { defineContentTopPlatformsAnalysisStore } from '@/stores/analysis/analyses/content-top-platforms'

export default () => ({
    table: 'content',

    supportsSearchLanguage: true,
    multiLineSearchInput: true,
    exactCount: false,

    sortingOptions: [
        { name: 'Latest', id: 'latest', icon: 'calendar', value: i => ({ value: i.publishedAt, id: i.id }), apiId: 'date', type: 'date', direction: 'desc', default: true },
        { name: 'Oldest', id: 'oldest', icon: 'calendar', value: i => ({ value: i.publishedAt, id: i.id }), apiId: 'date', type: 'date' },
        { name: 'Most Relevant', id: 'ids', icon: 'star', value: (i, store) => ({ value: store.items.data.indexOf(i), id: i.id }), available: store => store.filter('semantic').value },
        {},
        { name: 'Most Interactions', id: 'interactions', icon: 'zap', value: i => ({ value: i.metrics.interactions, id: i.id }), direction: 'desc' },
        { name: 'Trending', id: 'trending', icon: 'fire', value: i => ({ value: i.benchmarks.reactionsPerformance, id: i.id }), direction: 'desc' },
        {},
        { name: 'Most Reactions', id: 'reactions', icon: 'thumbs-up', value: i => ({ value: i.metrics.reactions, id: i.id }), direction: 'desc' },
        { name: 'Most Comments', id: 'comments', icon: 'comment', value: i => ({ value: i.metrics.comments, id: i.id }), direction: 'desc' },
        { name: 'Most Shares', id: 'shares', icon: 'share', value: i => ({ value: i.metrics.shares, id: i.id }), direction: 'desc' },
        { name: 'Most Views', id: 'views', icon: 'eye', value: i => ({ value: i.metrics.views, id: i.id }), direction: 'desc' }
    ],

    stackingOptions: [
        { name: 'Text', id: 'text', value: i => i.hash }
    ],

    filters: [
        { name: 'Text', id: 'text', type: 'search-query', hidden: true, search: true },
        { name: 'Notification', id: 'notification', type: 'notification', icon: 'bell', ephemeral: true },
        { name: 'Date', id: 'date', type: 'date', icon: 'calendar' },
        { name: 'Targets', id: 'targets', type: 'targets', icon: 'navigation.targets', multiple: true },
        {
            name: 'Types', id: 'types', type: 'types', icon: 'stream',
            filters: [
                {
                    name: 'Type', id: 'type', type: 'type', icon: 'stream', multiple: true,
                    options: [
                        { name: 'Facebook Post', id: 'facebook-post', icon: 'badges.target-facebook-page' },
                        { name: 'Facebook Group Post', id: 'facebook-group-post', icon: 'badges.target-facebook-group' },
                        { name: 'Instagram Post', id: 'instagram-post', icon: 'badges.target-instagram-profile' },
                        { name: 'Telegram Channel Posts', id: 'telegram-channel-post', icon: 'badges.target-telegram-channel' },
                        { name: 'Telegram Group Messages', id: 'telegram-group-message', icon: 'badges.target-telegram-group' },
                        { name: 'TikTok Video', id: 'tiktok-video', icon: 'badges.target-tiktok-user' },
                        { name: 'Twitter Tweet', id: 'twitter-tweet', icon: 'badges.target-twitter-user' },
                        { name: 'VKontakte Posts', id: 'vkontakte-post', icon: 'badges.target-vkontakte-community' },
                        { name: 'Web Article', id: 'web-article', icon: 'badges.target-web-feed' },
                        { name: 'Web Resource', id: 'web-resource', icon: 'badges.target-web-site' },
                        { name: 'Youtube Video', id: 'youtube-video', icon: 'badges.target-youtube-channel' },
                    ],
                    filter: t => hasFeatureTo([`access-content-${t.id}`])
                },
                { name: 'Replies', id: 'replies', type: 'replies', icon: 'crosshair', multiple: false }
            ]

        },
        { name: 'Type', id: 'type', type: 'options', icon: 'stream', multiple: true, hidden: true,
            options: [
                { name: 'Facebook Post', id: 'facebook-post', icon: 'badges.target-facebook-page' },
                { name: 'Facebook Group Post', id: 'facebook-group-post', icon: 'badges.target-facebook-group' },
                { name: 'Instagram Post', id: 'instagram-post', icon: 'badges.target-instagram-profile' },
                { name: 'Telegram Channel Posts', id: 'telegram-channel-post', icon: 'badges.target-telegram-channel' },
                { name: 'Telegram Group Messages', id: 'telegram-group-message', icon: 'badges.target-telegram-group' },
                { name: 'TikTok Video', id: 'tiktok-video', icon: 'badges.target-tiktok-user' },
                { name: 'Twitter Tweet', id: 'twitter-tweet', icon: 'badges.target-twitter-user' },
                { name: 'VKontakte Posts', id: 'vkontakte-post', icon: 'badges.target-vkontakte-community' },
                { name: 'Web Article', id: 'web-article', icon: 'badges.target-web-feed' },
                { name: 'Web Resource', id: 'web-resource', icon: 'badges.target-web-site' },
                { name: 'Youtube Video', id: 'youtube-video', icon: 'badges.target-youtube-channel' },
            ],
            filter: t => hasFeatureTo([`access-content-${t.id}`])
        },
        {
            name: 'Replies', id: 'replies', type: 'replies', icon: 'crosshair', hidden: true,
        },
        { name: 'Tags', id: 'tags', type: 'tags', icon: 'tags',  multiple: true },
        {
            name: 'Performance', id: 'performance', type: 'performance', icon: 'play',
            filters: [
                {
                    name: 'Metrics', id: 'metrics', type: 'metrics', icon: 'flag', multiple: false,
                    metrics: [
                        { name: 'Total Interactions', icon: 'zap', id: 'interactions' },
                        {},
                        { name: 'Reactions', icon: 'thumbs-up', id: 'reactions' },
                        { name: 'Comments', icon: 'comment', id: 'comments' },
                        { name: 'Shares', icon: 'share', id: 'shares' },
                        { name: 'Views', icon: 'stream', id: 'views' }
                    ]
                },
                { name: 'Labels', id: 'labels', type: 'labels', icon: 'award', multiple: true },
                {
                    name: 'Sentiments', id: 'sentiments', type: 'sentiments', icon: 'smile', multiple: true,
                    sentiments: [
                        { name: 'Positive', id: 'positive', icon: 'face-smile', color: 'text-emerald-500' },
                        { name: 'Negative', id: 'negative', icon: 'face-frown', color: 'text-rose-500' },
                        { name: 'Neutral', id: 'neutral', icon: 'face-neutral', color: 'text-indigo-500' }
                    ]
                }
            ]
        },
        {
            name: 'Features', id: 'features', type: 'features', icon: 'play',
            filters: [
                { name: 'Mentions', id: 'mentions', type: 'mentions', icon: 'at-sign', multiple: true },
                { name: 'Media', id: 'media', type: 'media', icon: 'image', multiple: false }
            ]
        },
        { name: 'Deleted', id: 'deleted', type: 'deleted' },
        { name: 'Mentions', id: 'mentions', type: 'mentions', icon: 'at-sign', hidden: true },
        { name: 'Media', id: 'media', type: 'media', icon: 'image', hidden: true },
        { name: 'Labels', id: 'labels', type: 'labels', icon: 'award', multiple: true, hidden: true,  },
        {
            name: 'Metrics', id: 'metrics', type: 'metrics', icon: 'flag', hidden: true,
            metrics: [
                { name: 'Interactions', icon: 'zap', id: 'interactions' },
                {},
                { name: 'Reactions', icon: 'thumbs-up', id: 'reactions' },
                { name: 'Comments', icon: 'comment', id: 'comments' },
                { name: 'Shares', icon: 'share', id: 'shares' },
                { name: 'Views', icon: 'stream', id: 'views' }
            ]
        },
        { name: 'Semantic', id: 'semantic', type: 'semantic-query', icon: 'ai' },
        {
            name: 'Language', id: 'language', type: 'options', icon: 'translate', multiple: true, searchable: true,
            options: languageFilterOptions
        },
        {
            name: 'Sentiments', id: 'sentiments', type: 'sentiments', icon: 'smile', multiple: true, hidden: true,
            sentiments: [
                { name: 'Positive', id: 'positive', icon: 'face-smile', color: 'text-emerald-500' },
                { name: 'Negative', id: 'negative', icon: 'face-frown', color: 'text-rose-500' },
                { name: 'Neutral', id: 'neutral', icon: 'face-neutral', color: 'text-indigo-500' }
            ]
        }
    ],

    statistics: {
        values: {
            aggregates: asyncResource({
                collection: false,

                request(api, store) {
                    return api.route(store.apiEndpoint)
                        .query({ ...store.apiQuery, aggregates: 'results:count,sources:count,groups:count,interactions:sum,reactions:sum,comments:sum,shares:sum,contentSentiment:sum,repliesSentiment:sum,platformMetrics:sum' })
                }
            })
        },

        analyses: {
            contentActivityByDayAnalysis: {
                store: defineContentPerspectiveActivityByDayAnalysisStore({ id: 'contentActivityByDayAnalysis' }),
                load: (store, filters) => store.loadSeries(filters),
                reset: store => store.resetSeries()
            },
            contentPerspectivePrevalenceAnalysis: {
                store: defineContentPerspectivePrevalenceAnalysisStore({ id: 'contentPerspectivePrevalenceAnalysis' }),
                load: (store, filters) => store.loadSeries(filters),
                reset: store => store.resetSeries()
            },
            contentPerspectiveTopPlatformsAnalysis: {
                store: defineContentTopPlatformsAnalysisStore({ id: 'contentPerspectiveTopPlatformsAnalysis' }),
                load: (store, filters) => {
                    let filtersObject = JSON.parse(filters)
                    store.series = [{
                        datasetType: 'inline-perspective',
                        datasetFilters: filters,
                        meta: { metric: 'interactions' },
                        values: []
                    }]
                    if (filtersObject['date']) store.date = filtersObject['date']
                    store.granularity = ! filtersObject['date'] || (filtersObject['date'].type == 'past' && filtersObject['date'].date.unit == 'years')
                        ? 'week' : 'day'
                    store.loadSeries()
                }
            }
        },

        keywords: null
    }
})
