import useWorkspaceStore from '@/admin/stores/accounts/workspace'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationWorkspacePage = defineStore({
    id: 'accountsOrganizationsOrganizationWorkspacePage',

    state: () => ({
        search: ''
    }),

    actions: {
        async beforeEnter(to) {
            if (to.params.workspaceId != useWorkspaceStore().workspace?.id) {
                useWorkspaceStore().initialize(to.params.organizationId, to.params.workspaceId, true)
            }
        }
    }
})

export default useAccountsOrganizationsOrganizationWorkspacePage
