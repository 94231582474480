import useMyUsersStore from '@/stores/me/users'
import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesModalsEditStore from '@/stores/workspaces/modals/manage'
import defineForm from '@/stores/reusable/form'

import api from '@/api'

export const useWorkspacesFormsAddUserStore = defineForm({
    id: 'workspacesFormsAddUser',

    inputs: () => ({
        workspace: {},
        user: {}
    }),

    submitRequest() {
        return api.route('me workspaces members store', { id: this.inputs.workspace.id, userId: this.inputs.user.id })
            .post()
    },

    onResponse() {
        useMyWorkspacesStore().reload()
        useWorkspacesModalsEditStore().manageUsers(this.inputs.workspace)
    },

    actions: {
        initialize(workspace) {
            this.reset()

            this.inputs.workspace = workspace || {}

            useMyUsersStore().reload()
        },

        cancel() {
            useWorkspacesModalsEditStore().manageUsers(this.inputs.workspace)
        }
    }
})

export default useWorkspacesFormsAddUserStore
