import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export const useAnalysisMyAnalysesPage = defineStore({
    id: 'analysisMyAnalysesPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'My Analyses | Analysis'
    },

    actions: {
        afterEnter() {
            this.$reset()

            useMyAnalysesStore().initialize()
        }
    }
})

export default useAnalysisMyAnalysesPage
