<template>
    <ui-modal name="two-factor-auth" title="Manage two-factor authentication" @closed="refresh">
        <ui-form :store="twoFactorAuthFormStore" v-slot="{ inputs, submitting, errors }">
            <div class="border border-red-300 bg-red-50 text-red-900 w-full px-4 py-2 text-sm mb-6 rounded" v-if="Object.keys(errors).length">
                <p :key="error" v-for="error in errors">
                    {{ error[0] }}
                </p>
            </div>

            <template v-if="! hasTwoFactorAuth || isSuccessful">
                <ui-spinner v-if="isGenerating"></ui-spinner>

                <div v-else>
                    <div v-if="isSuccessful">
                        <div class="flex flex-col items-center">
                            <ui-icon name="check-circle" class="text-green-500 text-8xl"></ui-icon>

                            <p class="text-center w-72 mt-3">
                                Thank you for setting up two-factor authentication!
                            </p>
                        </div>

                        <div class="flex justify-end mt-8">
                            <ui-button @click="close">
                                Close
                            </ui-button>
                        </div>
                    </div>

                    <div v-else-if="! isScannedOrCopied">
                        <div class="flex flex-col items-center w-full">
                            <p class="text-center">
                                Use your favorite two-factor authentication app to scan the code below.
                                We recommend trying <a href="https://authy.com/features/setup/" target="_blank" class="text-blue-500 italic">Authy</a>
                                or <a href="https://googleauthenticator.net/" target="_blank" class="text-blue-500 italic">Google Authenticator</a>.
                            </p>

                            <img class="w-2/3" :src="secretDataUri" alt="QR Code">

                            <div class="flex flex-col items-center">
                                <p>Alternatively, enter the following code manually:</p>
                                <p class="w-min mt-3 px-3 py-1 bg-gray-200 rounded-md font-medium text-xl">
                                    {{ secret }}
                                </p>
                            </div>
                        </div>

                        <div class="flex justify-end space-x-4 mt-12">
                            <ui-button @click="close">
                                Cancel
                            </ui-button>

                            <ui-button color="blue" :action="confirmScannedOrCopied">
                                Next step
                            </ui-button>
                        </div>
                    </div>

                    <div v-else-if="isScannedOrCopied && ! isRecoveryPhraseBackedUp">
                        <div class="flex flex-col items-center">
                            <p class="text-center">
                                This is your backup phrase. Please make sure you've have a copy or a printed versions. Storing it in your password manager is also a good option.
                            </p>

                            <p class="text-center mt-3">
                                This phrase can be used to recover your account if you ever lose your two-factor device, or if it gets stolen or damaged.
                            </p>

                            <div class="flex flex-col items-center mt-3">
                                <p class="text-red-500 font-medium">Please make sure this phrase is safely copied and stored before you continue!</p>
                                <p class="mt-8 px-3 py-1 bg-gray-200 rounded-md font-medium text-xl">
                                    {{ recoveryPhrase }}
                                </p>
                            </div>
                        </div>

                        <div class="flex justify-between mt-12">
                            <ui-button @click="cancelScannedOrCopied">
                                Back
                            </ui-button>

                            <div class="flex space-x-4">
                                <ui-button @click="close">
                                    Cancel
                                </ui-button>

                                <ui-button color="blue" :action="confirmRecoveryPhraseBackedUp">
                                    Next step
                                </ui-button>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="isScannedOrCopied && isRecoveryPhraseBackedUp">
                        <div class="flex flex-col items-center">
                            <p class="text-center w-72">
                                Just one more thing. Verify the configuration by <strong>entering the 6 digit code</strong> from your app into the field below:
                            </p>

                            <ui-input class="mt-8" v-model="inputs.code" ref="code"></ui-input>
                        </div>

                        <div class="flex justify-between mt-12">
                            <ui-button @click="cancelRecoveryPhraseBackedUp">
                                Back
                            </ui-button>

                            <div class="flex space-x-4">
                                <ui-button @click="close">
                                    Cancel
                                </ui-button>

                                <ui-button type="submit" color="blue" :disabled="submitting">
                                    Confirm
                                </ui-button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <label for="currentPassword" class="block text-sm text-gray-600 mt-3 mb-2">
                    Please enter your current password to confirm the changes:
                </label>

                <ui-input type="password" name="currentPassword" id="twoFactorAuthCurrentPassword" v-model="inputs.currentPassword"></ui-input>

                <div class="flex justify-end space-x-4 mt-8">
                    <ui-button @click="close">
                        Cancel
                    </ui-button>

                    <ui-button color="red" :action="remove">
                        Remove
                    </ui-button>
                </div>
            </template>
        </ui-form>
    </ui-modal>
</template>

<script>
import { mapActions, mapState, mapStores } from 'pinia'

import useTwoFactorAuthFormStore from "@/stores/settings/two-factor-auth-form"
import useAccountSettingsStore from '@/stores/settings/account'

export default {
    computed: {
        ...mapState(useAccountSettingsStore, [
            'hasTwoFactorAuth'
        ]),
        ...mapState(useTwoFactorAuthFormStore, [
            'isGenerating',
            'isScannedOrCopied',
            'isRecoveryPhraseBackedUp',
            'isSuccessful',

            'secret',
            'secretDataUri',
            'recoveryPhrase'
        ]),
        ...mapStores(useTwoFactorAuthFormStore)
    },

    methods: {
        ...mapActions(useTwoFactorAuthFormStore, [
            'confirmScannedOrCopied',
            'cancelScannedOrCopied',
            'confirmRecoveryPhraseBackedUp',
            'cancelRecoveryPhraseBackedUp',

            'remove',
            'refresh',
            'close'
        ])
    },

    watch: {
        isRecoveryPhraseBackedUp(newVal) {
            if (newVal === true) { this.$nextTick(() => { this.$refs.code.focus() }) }
        }
    }
}
</script>
