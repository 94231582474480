export default {
    data: function () {
        return {
            safeColors: [
                '#3b82f6', // blue-500
                '#22c55e', // green-500
                '#ef4444', // red-500
                '#a855f7', // purple-500
                '#14b8a6', // teal-500
                '#f59e0b', // amber-500
                '#ec4899', // pink-500
                '#06b6d4', // cyan-500
                '#f97316', // orange-500
                '#d946ef', // fuchsia-500
                '#6366f1', // indigo-500
                '#84cc16', // lime-500

                '#93c5fd', // blue-300
                '#86efac', // green-300
                '#fca5a5', // red-300
                '#d8b4fe', // purple-300
                '#5eead4', // teal-300
                '#fcd34d', // amber-300
                '#f9a8d4', // pink-300
                '#67e8f9', // cyan-300
                '#fb923c', // orange-300
                '#f0abfc', // fuchsia-300
                '#a5b4fc', // indigo-300
                '#bef264', // lime-300

                '#1d4ed8', // blue-700
                '#15803d', // green-700
                '#b91c1c', // red-700
                '#7e22ce', // purple-700
                '#0f766e', // teal-700
                '#b45309', // amber-700
                '#be185d', // pink-700
                '#0e7490', // cyan-700
                '#b45309', // orange-700
                '#a21caf', // fuchsia-700
                '#4338ca', // indigo-700
                '#4d7c0f', // lime-700
            ]
        }
    },

    computed: {
        defaultSafeColor() {
            return this.safeColors[0]
        }
    }
}
