<template>
    <Toggle v-model="model" :classes="{
      container: 'inline-block rounded-full outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-30',
      toggle: `flex ${size == 'sm' ? 'w-7 h-3' : 'w-10 h-4'} rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none`,
      toggleOn: 'bg-blue-500 border-blue-500 justify-start text-white',
      toggleOff: 'bg-gray-200 border-gray-200 justify-end text-gray-700',
      toggleOnDisabled: 'bg-gray-300 border-gray-300 justify-start text-gray-400 cursor-not-allowed',
      toggleOffDisabled: 'bg-gray-200 border-gray-200 justify-end text-gray-400 cursor-not-allowed',
      handle: `inline-block bg-white ${size == 'sm' ? 'w-3 h-3' : 'w-4 h-4'} top-0 rounded-full absolute transition-all`,
      handleOn: 'left-full transform -translate-x-full',
      handleOff: 'left-0',
      handleOnDisabled: 'bg-gray-100 left-full transform -translate-x-full',
      handleOffDisabled: 'bg-gray-100 left-0',
      label: 'text-center w-6 border-box whitespace-nowrap select-none',
    }"></Toggle>
</template>

<script>
import Toggle from '@vueform/toggle'

export default {
    props: [ 'modelValue', 'size' ],

    components: { Toggle },

    computed: {
        model: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        }
    }
}
</script>
