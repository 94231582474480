<template>
    <ui-modal name="content-video-download" title="Video Download" width="320px">
        <div class="text-center">
            The video will be downloaded and archived on our servers.
        </div>

        <div class="text-center mt-8">
            The download process might take some time, you will receive a notification with a downloading link once it's ready.
        </div>

        <div class="flex justify-center mt-8">
            <ui-button @click="confirm" color="blue">
                Confirm
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useContentModalsVideoDownloadStore from '@/stores/content/modals/video-download'

import { mapActions } from 'pinia'

export default {
    methods: {
        ...mapActions(useContentModalsVideoDownloadStore, [ 'confirm' ])
    }
}
</script>
