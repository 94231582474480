<template>
    <div class="flex items-center justify-center w-full h-full">
        <div :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }">
            <a href="#" class="block bg-gray-400 rounded-sm" @click.prevent="play" v-if="! isPlaying">
                <img :src="thumbnailSrc" class="object-contain rounded-sm" :class="{ 'aspect-[16/9] w-full': orientation == 'landscape', 'aspect-[9/16] h-full': orientation == 'portrait' }" v-if="thumbnailSrc">

                <div class="absolute inset-0 flex items-center justify-center text-3xl text-white">
                    <div class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full" v-if="src">
                        <ui-icon name="play"></ui-icon>
                    </div>

                    <div class="flex flex-col items-center justify-center text-base w-96 text-center" v-else>
                        <div class="flex items-center">
                            <ui-icon name="warning" class="text-xl"></ui-icon>
                            <span class="text-xl ml-1">Video unavailable</span>
                        </div>
                        <div class="mt-6">
                            To download this video, please click on the "Download video" action in the sidebar.
                        </div>
                        <div class="mt-4">
                            To automatically download all videos for this Target in the future, you can enable the "Video download" feature in the My Targets section.
                        </div>
                    </div>
                </div>
            </a>

            <template v-if="isPlaying">
                <iframe :src="src" class="rounded-sm h-full w-full" scrolling="no" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true" v-if="type == 'iframe'">
                </iframe>

                <video class="rounded-sm h-full w-full" controls autoplay v-if="type == 'video'">
                    <source :src="src" type="video/mp4">
                </video>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'thumbnail', 'video' ],

    data: () => ({
        isPlaying: false
    }),

    computed: {
        type() {
            return this.video.key ? 'video' : 'iframe'
        },

        orientation() {
            return this.video.height
                ? (this.video.height > this.video.width ? 'portrait' : 'landscape')
                : (this.video.thumbnail?.height > this.video.thumbnail?.width ? 'portrait' : 'landscape')
        },

        src() {
            return this.video.src ? (this.video.src + (this.video.src.includes('?') ? '&autoplay=true' : '?autoplay=true')) : null
        },

        thumbnailSrc() {
            return this.thumbnail || this.video.thumbnail?.src || this.video.thumbnailUrl
        }
    },

    methods: {
        play() {
            this.isPlaying = true
        }
    }
}
</script>
