import api from "@/api";
import { useModal } from "@/helpers";
import { defineForm } from "@/stores/reusable/form";

import useMyAppsStore from '@/stores/me/apps'

export const useNewAppFormStore = defineForm({
    id: 'newAppForm',

    state: {
        isSuccessful: false,

        token: null
    },

    inputs: () => ({
        name: null
    }),

    submitRequest() {
        return api.route('me apps store')
            .formData({
                name: this.inputs.name
            })
            .post()
            .error(403, err => {
                this.submitting = false
                this.errors = { other: [ err.json.message ] }
            })
    },

    async onResponse(res) {
        useMyAppsStore().reload()

        this.token = res.data.token
        this.isSuccessful = true
    },

    actions: {
        open() {
            this.reset()

            useModal().show('new-app');
        },

        close() {
            useModal().hide('new-app')
        }
    }
})

export default useNewAppFormStore
