<template>
    <vue-final-modal :name="name" :classes="containerClass" :content-class="contentClass" :content-style="contentStyle" @opened="$emit('opened')" @closed="$emit('closed')" @keyup.esc="$modal.hide(name)" v-model="show">
        <template v-if="unstyled">
            <slot></slot>
        </template>

        <template v-else>
            <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
                <h1 class="font-semibold text-gray-900">
                    {{title}}
                </h1>

                <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="$modal.hide(name)">
                    <ui-icon name="remove"></ui-icon>
                </a>
            </div>
            <div class="flex-1 min-h-0 px-8 py-6" :class="{ 'overflow-y-auto': scrollable }">
                <slot></slot>
            </div>
        </template>
    </vue-final-modal>
</template>

<script>
export default {
    props: {
        name: {},
        height: {},
        maxHeight: {},
        maxWidth: {},
        title: {},
        width: {},
        unstyled: Boolean,
        scrollable: { type: Boolean, default: true }
    },

    data: () => ({
        show: false
    }),

    computed: {
        containerClass() {
            return 'flex items-center justify-center'
        },

        contentClass() {
            return ! this.unstyled ? 'bg-white flex flex-col h-auto w-full rounded-lg' : ''
        },

        contentStyle() {
            return [
                this.maxHeight ? `max-height: ${this.processLength(this.maxHeight)};` : 'max-height: 90vh;',
                this.maxWidth !== false ? `max-width: ${this.processLength(this.maxWidth || 600)};` : '',
                this.height ? `height: ${this.processLength(this.height)};` : '',
                this.width ? `width: ${this.processLength(this.width)};` : ''
            ]
        }
    },

    methods: {
        processLength(length) {
            return length.toString().match(/^[0-9.]+$/) ? `${length}px` : length
        }
    }
}
</script>

<style>
.vfm--overlay {
    @apply bg-gray-900 bg-opacity-50 !important;
}
</style>
