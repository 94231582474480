<template>
    <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:px-6 lg:px-8">
        <template v-if="store.items.data && store.items.data.length">
            <template v-for="item in store.items.data">
                <template v-for="media in item.images">
                    <feature-image-card :image="media" :item="item" :stream-store="store" :key="media.id" v-if="filterMedia(media)"></feature-image-card>
                </template>
            </template>
        </template>

        <template v-else-if="store.items.pending">
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
        </template>
    </div>
</template>

<script>
import FeatureImageCard from '@/components/features/stream-cards/image'

import StreamPendingPlaceholder from '../cards/pending-media-placeholder'

export default {
    components: { FeatureImageCard, StreamPendingPlaceholder },

    methods: {
        filterMedia(media) {
            return ! this.store.layout.mediaFilter || this.store.layout.mediaFilter(media)
        }
    }
}
</script>
