<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="star" v-if="member.role !== 'owner'" @click="setAsOwner(member)">
            Set as Owner
        </ui-dropdown-link>

        <ui-dropdown-link icon="zap" v-if="member.role === 'team-member'" @click="setAsAdmin(member)">
            Set as Admin
        </ui-dropdown-link>

        <ui-dropdown-link icon="user" v-if="member.role === 'team-admin'" @click="setAsMember(member)">
            Set as Member
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" :disabled="member.role === 'owner'" @click="removeMember(member)">
            Remove
        </ui-dropdown-link>
    </ui-contextual-menu>
</template>

<script>
import useWorkspaceStore from '@/admin/stores/accounts/workspace'

import { mapActions } from 'pinia'

export default {
    props: [ 'workspace', 'member' ],

    methods: {
        ...mapActions(useWorkspaceStore, [ 'setAsOwner', 'setAsAdmin', 'setAsMember', 'removeMember' ])
    }
}
</script>
