<template>
    <div class="flex items-center justify-between px-4 pt-3">
        <h1 class="text-xl font-semibold">Help</h1>
    </div>

    <div class="mt-2">
        <navigation-items :items="navigation"></navigation-items>
    </div>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import Items from './items'

import { mapState } from 'pinia'

export default {
    components: {
        'navigation-items': Items
    },

    computed: {
        ...mapState(useSupportHelpStore, [ 'navigation' ])
    }
}
</script>
