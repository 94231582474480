import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentFacebookPostCommentsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentFacebookPostCommentsAnalysis',
    name: 'content.facebook-post-comments',
    title: 'Comments',
    color: 'indigo-600'
})

export default useTargetsTargetContentFacebookPostCommentsAnalysisStore
