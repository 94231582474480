<template>
    <div class="flex flex-1 flex-wrap relative gap-2 max-w-full">
        <a @click.prevent="store.deleteSeries(target.series)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg cursor-default shrink-0 py-2 px-4" :key="target.id" v-for="target in targets">
            <div class="flex items-center space-x-2 h-full mb-1">
                <template v-if="target.family == 'lists'">
                    <ui-avatar :name="target.name" type="target-list" class="h-7 w-7"></ui-avatar>
                    <div>
                        <p class="max-w-xs truncate font-semibold text-lg leading-tight">
                            {{target.name}}
                        </p>
                        <div class="max-w-xs text-gray-800 text-xs leading-tight truncate">
                            List
                        </div>
                    </div>
                </template>
                <template v-else>
                    <ui-avatar :image="target.avatarUrl" class="h-7 w-7"></ui-avatar>
                    <div>
                        <p class="max-w-xs truncate font-semibold text-lg leading-tight">
                            {{target.title}}
                        </p>
                        <div class="max-w-xs text-gray-800 text-xs leading-tight truncate">
                            {{typeToHuman(target.type)}}
                        </div>
                    </div>
                </template>
            </div>
        </a>

        <a v-if="store.series.length < store.seriesOptions.limit" @click.prevent="store.addTarget(store)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-default shrink-0 py-2 px-4 text-gray-800">
            <div class="leading-tight">
                <ui-icon name="plus" class="text-xl"></ui-icon>
            </div>
            <div class="leading-tight">
                Add target
            </div>
        </a>
    </div>
</template>

<script>
import targetTypesStore from '@/stores/targets/target-types'

import { mapActions } from 'pinia'

export default {
    computed: {
        targets() {
            return this.store.series.map(series => ({
                ...this.targetForSeries(series), series
            })).filter(t => t.id)
        }
    },

    methods: {
        ...mapActions(targetTypesStore, [ 'typeToHuman' ]),

        targetForSeries(series) {
            return this.store.selectedTargets.find(t => t.family == series.datasetFamily && t.id == series.datasetId) || {}
        }
    }
}
</script>
