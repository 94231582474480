import api from '@/admin/api'
import { useModal, useRouter } from '@/admin/helpers'

import { defineStore } from 'pinia'

export const useMyStore = defineStore({
    id: 'my',

    state: () => ({
        isAuthenticated: false,
        isInMaintenance: false,

        isInitialized: false,
        loadingPromise: false,

        showSidebar: true,

        user: {},

        preferences: {},

        intendedPath: null,

        currentWorkspace: null
    }),

    actions: {
        async initialize() {
            if (this.isInitialized) return
            if (this.loadingPromise) return this.loadingPromise

            return this.loadingPromise = api.route('me').get()
                .error(401, () => {
                    this.isAuthenticated = false
                    this.isInitialized = true
                })
                .json(res => {
                    this.isAuthenticated = true
                    this.isInitialized = true

                    this.user = res.data
                    this.preferences = res.data.preferences
                    this.currentWorkspace = res.data.currentWorkspace

                    this.showSidebar = this.preferences.showSidebar !== false
                })
        },

        async reinitialize() {
            this.isInitialized = false
            this.loadingPromise = null

            return this.initialize()
        },

        async loggedIn() {
            await this.reinitialize()

            useRouter().push(this.intendedPath || { name: 'home' })

            this.intendedPath = null
        },

        async logOut() {
            await api.route('auth logout').post().res()

            window.location.reload()
        },

        sessionLost() {
            useModal().show('auth-session-lost')
        },

        inMaintenance() {
            this.isInMaintenance = true
        },

        updatePreferences(preferences) {
            return api.route('me preferences update')
                .formData({ _method: 'put', preferences: JSON.stringify(preferences) }, true)
                .post()
                .json(res => this.preferences = res.data)
        },

        toggleSidebar() {
            this.showSidebar = ! this.showSidebar

            this.updatePreferences({ 'showSidebar': this.showSidebar })
        }
    }
})

export default useMyStore
