export default (items, field = 'name') => {
    items = [ ...items ].sort((a, b) => a[field].localeCompare(b[field]))

    let pinned = items.filter(item => item.pinned)
    let other = items.filter(item => ! item.pinned)

    let recent = other.filter(item => item.recent)
    other = other.filter(item => ! item.recent)

    return [ ...pinned, ...recent, ...other ]
}
