<template>
    <router-link :to="{ name: 'features.feature.information', params: { type: feature.type, id: feature.id } }" custom v-slot="{ navigate }">
        <tr class="border-b border-gray-200 last:border-none cursor-pointer">
            <td class="py-4 px-6" @click="action ? action() : navigate($event)">
                <div class="flex items-center">
                    <div class="shrink-0 relative">
                        <img class="rounded-full w-8 h-8 shadow" :src="feature.avatarUrl" alt="">
                        <ui-icon :name="`badge-feature-${feature.type}`" class="absolute -right-px -bottom-px text-lg" style="filter: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);"></ui-icon>
                    </div>
                    <div class="ml-3">
                        <div class="font-semibold text-xl leading-tight">
                            {{ feature.title }}
                        </div>
                        <div class="text-gray-700 text-xs leading-tight font-medium">
                            {{featureTypeLabel(feature.type)}}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </router-link>
</template>

<script>
export default {
    name: 'features-card',

    props: [ 'action', 'feature', 'streamStore' ],

    computed: {
        isSelected() {
            return this.streamStore.selectedItems.includes(this.feature)
        }
    },

    methods: {
        featureTypeLabel(type) {
            return {
                'domain': 'Domain',
                'email': 'Email Address',
                'facebook-media': 'Facebook Media',
                'hashtag': 'Hashtag',
                'iban': 'IBAN',
                'image': 'Image',
                'video': 'Video',
                'ip-address': 'IP Address',
                'location': 'Location',
                'name': 'Person Name',
                'phone-number': 'Phone Number',
                'url': 'URL'
            }[type]
        }
    }
}
</script>
