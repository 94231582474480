import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentVKontaktePostCommentsAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentVKontaktePostCommentsAnalysis',
    name: 'content.vkontakte-post-comments',
    title: 'Comments',
    color: 'indigo-600'
})

export default useTargetsTargetContentVKontaktePostCommentsAnalysisStore
