import api from '@/api'

import { defineStore } from 'pinia'

const defineAnalysis = settings => defineStore({
    id: settings.id,

    state: () => ({
        title: settings.title,
        type: settings.type,

        series: settings.series || [],

        date: settings.date || { type: 'past', date: { past: 1, unit: 'years' }, },
        granularity: settings.granularity || 'day',

        isLoading: false,
        isLoadingFresh: false,
        loadingPromise: null,
        abortLoading: null,

        analysisSeries: [],
        seriesGeneratedAt: null,

        exportable: true,
        printable: true,

        chainAnalysisOptions: settings.chainAnalysisOptions || ((store, options) => options),
        chainConfiguration: settings.chainConfiguration || ((store, options) => options),
        passive: settings.passive || false,

        processValues: settings.processValues || (values => values.map(point => ({
            x: Date.parse(point.x),
            y: Math.round(parseFloat(point.y) * 10000) / 10000, // rounding to 2 decimals
            url: point.url
        }))),

        analysisRef: null,

        ...(settings.state || (() => {}))()
    }),

    getters: {
        analysisOptions: store => (store.chainAnalysisOptions(store, {
            boost: { useGPUTranslations: true },
            chart: {
                type: 'line',
                resetZoomButton: { position: { x: 0, y: 0 } },
                spacing: [0, 0, 5, 0],
                zoomType: 'x'
            },
            credits: { enabled: false },
            exporting: {
                chartOptions: {
                    chart: {
                        spacing: [40, 40, 40, 40]
                    }
                },
                buttons: {
                    contextButton: { enabled: false }
                }
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: 'rgb(44, 64, 76)', cursor: 'pointer', fontFamily: 'Roboto, sans-serif', fontSize: '12px', fontWeight: '500', textOverflow: 'ellipsis'
                },
                labelFormatter: function () { return `<span style="color:${this.color}">${this.name}</span>` },
                margin: 15,
                padding: 0
            },
            plotOptions: {
                column: {
                    marker: { enabled: false },
                    fillOpacity: 1
                },
                series: {
                    animation: ! settings.passive || true,
                    connectNulls: true,
                    dataLabels: { enabled: false },
                    enableMouseTracking: true,
                    marker: {
                        enabledThreshold: 4,
                        fillColor: '#fff',
                        lineColor: null,
                        lineWidth: 2,
                        radius: 4,
                        symbol: 'circle'
                    },
                    states: {
                        hover: { lineWidthPlus: 0 }
                    },
                    turboThreshold: 2500
                }
            },
            series: store.analysisSeries.map(series => ({
                ...series, name: series.label, data: series.values
            })),
            title: {
                style: {
                    color: 'rgb(44, 64, 76)', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500'
                },
                text: store.title
            },
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () { return `${this.series.name}<br><strong>${this.y}</strong>` },
                shadow: false,
                style: { color: '#fff', textAlign: 'center' },
                xDateFormat: '%Y-%m-%d'
            },
            xAxis: {
                title: 'Time',
                type: 'datetime',
                labels: {
                    align: 'center',
                    enabled: true,
                    rotation: 45,
                    style: { color: '#b8c3c9' }
                }
            },
            yAxis: [ ...(new Set(store.analysisSeries.map(s => s.yAxis))) ].map((id, index) => ({
                gridLineColor: '#e0e5e8',
                id,
                labels: {
                    align: index == 0 ? 'left' : 'right',
                    enabled: true,
                    padding: 0,
                    style: { color: '#b8c3c9' },
                    x: 1,
                    y: 14,
                    zIndex: 1
                },
                opposite: index == 1,
                tickPixelInterval: 50,
                title: { text: '' },
                visible: index < 2
            }))
        })),

        analysisConfiguration: store => (store.chainConfiguration(store, {
            series: store.series,
            date: store.date,
            granularity: store.granularity
        })),

        hasValues: store => store.analysisSeries.some(series => series.values && series.values.length)
    },

    actions: {
        async loadSeries(fresh = false) {
            if (this.loadingPromise) this.abortLoading.abort()

            this.isLoading = true
            this.isLoadingFresh = fresh

            return this.loadingPromise = api.route('me analyses series')
                .json({
                    type: this.type,
                    configuration: this.analysisConfiguration,
                    fresh: fresh
                })
                .signal(this.abortLoading = new AbortController())
                .post()
                .json(response => {
                    this.analysisSeries = response.series.map(series => ({
                        ...series,
                        values: this.processValues(series.values)
                    }))
                    this.seriesGeneratedAt = new Date(response.cachedAt)

                    if (! fresh && Math.abs(this.seriesGeneratedAt - new Date()) > 15 * 60 * 1000) {
                        this.loadSeries(true)
                    }
                })
                .finally(() => {
                    this.isLoading = this.isLoadingFresh = false
                })
        },

        setDate(date) {
            this.date = date
            this.loadSeries()
        },

        setGranularity(granularity) {
            this.granularity = granularity
            this.loadSeries()
        },

        setAnalysisRef(analysis) {
            this.analysisRef = analysis
        },

        print() {
            this.analysisRef.print()
        },

        export(options) {
            this.analysisRef.export(options)
        },

        abort() {
            if (this.abortLoading) this.abortLoading.abort()
        },

        ...(settings.actions || [])
    }
})

export default defineAnalysis
