<template>
    <div>
        <ui-header title="Settings" subtitle="Apps">
            <template v-slot:actions>
                <ui-button icon="plus-square" color="blue" :action="createApp">
                    New App
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search analyses..."
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="key" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to the Apps section!</h1>
                <p class="text-lg my-1">In this section you can <span class="font-medium">create your apps to interact with our API</span>.</p>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">App</h2>
                        <p class="text-justify my-2">Just pick a name and start creating! Whether you want to aggregate data for your own analytic tool, create a new GUI or a phone app, we've got you covered.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Tokens</h2>
                        <p class="text-justify my-2">Creating an app will generate a token for you. This can then be used in the <span class="font-medium">Authorization</span> HTTP header to interact with our API as if you were logged in using your account.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can <span class="font-medium">create your first app</span>.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button icon="plus-square" color="blue" size="lg" :action="createApp">
                        New App
                    </ui-button>
                </div>
            </div>

            <ui-cards-toolbar :store="appsCardsStore"></ui-cards-toolbar>

            <ui-cards :store="appsCardsStore">
                <template v-slot:item="{ item }">
                    <app-card :app="item" :cards-store="appsCardsStore"></app-card>
                </template>
            </ui-cards>
        </div>

        <Teleport to="#root">
            <new-app-modal></new-app-modal>
        </Teleport>
    </div>
</template>

<script>
import useMyAppsStore from '@/stores/me/apps'
import useAppsCardsStore from '@/stores/settings/apps-cards'
import useNewAppFormStore from '@/stores/settings/new-app-form'

import AppCard from '@/components/settings/stream-cards/app-card'
import NewAppModal from '@/components/settings/modals/new-app'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    components: {
        AppCard,
        NewAppModal
    },

    computed: {
        ...mapStores(useAppsCardsStore),

        ...mapState(useMyAppsStore, {
            apps: 'items',
            areAppsInitialized: 'isInitialized'
        }),

        isShowingWelcomeMessage() {
            return this.areAppsInitialized && ! this.apps.length
        }
    },

    methods: {
        ...mapActions(useNewAppFormStore, {
            createApp: 'open'
        })
    }
}
</script>
