import api from '@/admin/api'

import { defineStore } from 'pinia'

export const useThreadsStore = defineStore({
    id: 'threads',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,
        loadingPromise: null,

        timezones: [],
        languages: []
    }),

    actions: {
        async initialize(force = false) {
            return this.load(force)
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('admin support threads').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async markThreadAsResolved(id) {
            return api.route('admin support threads resolve', { threadId: id } ).post().res()
        },
    }
})

export default useThreadsStore
