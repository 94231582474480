import defineAnalysis from '@/stores/reusable/analysis'

export const useTargetsTargetContentVKontaktePostActivityAnalysisStore = defineAnalysis({
    id: 'targetsTargetContentVKontaktePostActivityAnalysis',

    type: 'content.vkontakte-post-activity',

    granularity: 'hour',

    series: [
        {
            color: '#2170f0',
            label: 'Likes',
            dataset: 'likes'
        },
        {
            color: '#4e46e5',
            label: 'Comments',
            dataset: 'comments'
        },
        {
            color: '#9233ea',
            label: 'Reposts',
            dataset: 'reposts'
        },
        {
            color: '#db2777',
            label: 'Views',
            dataset: 'views',
            yAxis: 'views'
        }
    ],

    chainAnalysisOptions: (store, options) => {
        options.chart.spacing = [ 0, 0, 5, 0 ]
        options.plotOptions.series.marker.enabled = true
        options.xAxis.labels.rotation = 0
        options.legend.margin = 15

        return options
    },

    chainConfiguration: (store, configuration) => {
        configuration.modelId = store.content?.modelId

        if (! store.content?.metrics?.views) {
            configuration.series = configuration.series.slice(0, 3)
        }

        return configuration
    },

    state: () => ({
        content: null
    }),

    actions: {
        setContent(content) {
            this.content = content
        }
    }
})

export default useTargetsTargetContentVKontaktePostActivityAnalysisStore
