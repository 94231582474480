<template>
    <router-link :to="{ name: 'accounts.organizations.organization.workspace', params: { organizationId: organization.id, workspaceId: workspace.id } }" class="block">
        <div class="bg-white cursor-pointer flex h-16 hover:shadow-inner group relative">
            <div class="pl-6 flex items-center flex-1 min-w-0">
                <img :src="workspace.avatarUrl" class="rounded-full w-8 h-8 shadow-sm shrink-0">

                <div class="flex-1 min-w-0 px-3">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{ workspace.name }}
                    </h1>
                </div>
            </div>

            <div class="flex items-center pl-4 pr-6">
                <slot name="actions">
                    <workspace-contextual-menu :organization="organization" :workspace="workspace" plain></workspace-contextual-menu>
                </slot>
            </div>

            <div class="flex items-center space-x-5 px-4 w-1/3 bg-gray-50 border-l border-gray-100 group-hover:shadow-inner">
                <div class="w-36">
                    <div class="text-2xs text-gray-700 leading-tight">
                        Created on
                    </div>
                    <div class="font-semibold text-sm">
                        {{ $dateTime(workspace.createdAt) }}
                    </div>
                </div>

                <div>
                    <span class="font-semibold text-sm">{{ workspace.users.length }}</span> <span class="text-2xs text-gray-700 leading-tight">Members</span>
                </div>

                <div>
                    <span class="font-semibold text-sm">{{ workspace.dashboardsCount }}</span> <span class="text-2xs text-gray-700 leading-tight">Dashboards</span>
                </div>

                <div>
                    <span class="font-semibold text-sm">{{ workspace.perspectivesCount }}</span> <span class="text-2xs text-gray-700 leading-tight">Perspectives</span>
                </div>

                <div>
                    <span class="font-semibold text-sm">{{ workspace.analysesCount }}</span> <span class="text-2xs text-gray-700 leading-tight">Analyses</span>
                </div>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(workspace)" v-if="cardsStore.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </router-link>
</template>

<script>
import WorkspaceContextualMenu from '@/admin/components/accounts/contextual-menus/workspace'

export default {
    props: { organization: {}, workspace: {}, cardsStore: {} },

    components: {
        WorkspaceContextualMenu
    }
}
</script>
