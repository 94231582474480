import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export const useAnalysisIndexPage = defineStore({
    id: 'analysisIndexPage',

    getters: {
        title: () => 'Analysis'
    },

    actions: {
        afterEnter() {
            useMyAnalysesStore().initialize()
        }
    }
})

export default useAnalysisIndexPage
