<template>
    <div>
        <ui-header title="Curated Lists">
            <template v-slot:actions>
                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search curated lists..."
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <ui-async :guards="areCuratedListsInitialized">
                <div class="flex justify-center my-8">
                    <ui-dropdown>
                        <template v-slot:trigger>
                            <div class="relative text-3xl font-bold cursor-pointer" v-if="$page.rootCategory">
                                <ui-icon :name="$page.rootCategory.icon"></ui-icon>
                                <span class="mx-1">{{$page.rootCategory.name}}</span>
                                <ui-icon name="chevron-down" class="text-lg text-gray-700"></ui-icon>
                            </div>
                        </template>

                        <template v-slot:content>
                            <ui-dropdown-link :icon="category.icon" variant="large" @click="$page.rootCategory = $page.category = category" :key="category.id" v-for="category in rootCategories">
                                {{category.name}}
                            </ui-dropdown-link>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="grid grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-6" v-if="subcategories.length">
                    <template v-for="category in subcategories">
                        <category-card :category="category" :to="{ name: 'targets.curated-lists.category', params: { categoryId: category.id } }" :key="category.id" v-if="category.leaf"></category-card>
                        <category-card :category="category" @click.prevent="$page.category = category" :key="category.id" v-else></category-card>
                    </template>
                </div>

                <div class="h-120 flex flex-col items-center justify-center text-gray-600" v-if="noSearchResults">
                    <ui-icon name="wind" class="text-6xl text-gray-500"></ui-icon>
                    <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                    <div class="text-center text-sm">Check your spelling or try a different query.</div>
                </div>
            </ui-async>
        </div>
    </div>
</template>

<script>
import CategoryCard from './stream-cards/curated-category'

import useMyCuratedListsStore from '@/stores/me/curated-lists'

import breakpoint from '@/helpers/breakpoint'
import { textSearch } from '@/helpers'

import { mapState } from 'pinia'

export default {
    components: {
        CategoryCard
    },

    computed: {
        ...mapState(useMyCuratedListsStore, {
            areCuratedListsInitialized: 'isInitialized',
            rootCategories(store) {
                return [ ...store.categories ].sort((a, b) => a.name.localeCompare(b.name))
            }
        }),

        subcategories() {
            return textSearch(this.$page.search, this.$page.category?.children || [], i => i.name)
        },

        perRow() {
            return { xs: 2, sm: 2, md: 2, lg: 3, xl: 3, '2xl': 3, '3xl': 4 }[breakpoint.is]
        },

        noSearchResults() {
            return this.areCuratedListsInitialized && ! this.subcategories.length
        }
    }
}
</script>
