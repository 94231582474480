<template>
    <div class="bg-white w-full overflow-hidden shadow rounded-sm mb-4 divide-y divide-gray-200 px-6">
        <div>
            <div class="py-4 flex items-center justify-between">
                <h3 class="font-semibold">
                    <ui-icon name="arrow-up"></ui-icon>
                    Upstream Sources
                </h3>

                <ui-help-button size="smaller" v-tooltip="'Sources this target shares content from.'"></ui-help-button>
            </div>

            <ui-async :guards="sourcesUpstream.data || sourcesUpstream.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3" v-if="shownUpstreamSources.length">
                    <tr :key="index" v-for="connection, index in shownUpstreamSources">
                        <td class="py-2 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <img class="rounded-full w-6 h-6 shadow" :src="connection.source.avatarUrl" alt="">
                                <ui-icon :name="`badges.target-${connection.source.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm" :class="{ 'grayscale': false && ! connection.source.monitored }"></ui-icon>
                            </div>

                            <div class="truncate px-2">
                                <router-link :to="connectionUrl(connection)" v-tooltip="connection.source.title">{{ connection.source.title }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 py-2 font-semibold text-blue-500 text-right">
                            {{ connection.strength }}
                        </td>
                    </tr>
                </table>

                <div class="py-4 text-center space-y-2 text-sm text-gray-800" v-else-if="sourcesUpstream.error">
                    <p>We were unable to load connections for this Target.</p>
                    <p>
                        <a href="#" @click="reload()" class="text-blue-500 hover:text-blue-700">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No sources found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showUpstreamSourcesShowMoreLink">
                    <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id, connectionsType: 'upstream' } }" class="text-blue-500 hover:text-blue-700">
                        Show More
                    </router-link>
                </div>
            </ui-async>
        </div>

        <div>
            <div class="py-4 flex items-center justify-between">
                <h3 class="font-semibold">
                    <ui-icon name="arrow-down"></ui-icon>
                    Downstream Sources
                </h3>

                <ui-help-button size="smaller" v-tooltip="'Sources sharing content from this target.'"></ui-help-button>
            </div>

            <ui-async :guards="sourcesDownstream.data || sourcesDownstream.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3" v-if="shownDownstreamSources.length">
                    <tr :key="index" v-for="connection, index in shownDownstreamSources">
                        <td class="py-2 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <img class="rounded-full w-6 h-6 shadow" :src="connection.source.avatarUrl" alt="">
                                <ui-icon :name="`badges.target-${connection.source.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm" :class="{ 'grayscale': false && ! connection.source.monitored }"></ui-icon>
                            </div>

                            <div class="truncate px-2">
                                <router-link :to="connectionUrl(connection)" v-tooltip="connection.source.title">{{ connection.source.title }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 py-2 font-semibold text-blue-500 text-right">
                            {{ connection.strength }}
                        </td>
                    </tr>
                </table>

                <div class="py-4 text-center space-y-2 text-sm text-gray-800" v-else-if="sourcesDownstream.error">
                    <p>We were unable to load connections for this Target.</p>
                    <p>
                        <a href="#" @click="reload()" class="text-blue-500 hover:text-blue-700">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No sources found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showDownstreamSourcesShowMoreLink">
                    <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id, connectionsType: 'downstream' } }" class="text-blue-500 hover:text-blue-700">
                        Show More
                    </router-link>
                </div>
            </ui-async>
        </div>

        <div v-if="$hasFeatureTo(['access-features'])">
            <div class="py-4 flex items-center justify-between">
                <h3 class="font-semibold">
                    <ui-icon name="feature"></ui-icon>
                    Connected Features
                </h3>

                <ui-help-button size="smaller" v-tooltip="'Features with strongest connections to this target.'"></ui-help-button>
            </div>

            <ui-async :guards="sourcesConnectedFeatures.data || sourcesConnectedFeatures.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3" v-if="shownConnectedFeatures.length">
                    <tr :key="index" v-for="connection, index in shownConnectedFeatures">
                        <td class="py-2 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <img class="rounded-full w-6 h-6 shadow" :src="connection.feature.avatarUrl" alt="">
                                <ui-icon :name="`badge-feature-${connection.feature.type}`" class="absolute -right-px -bottom-px text-sm" style="filter: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);"></ui-icon>
                            </div>

                            <div class="truncate px-2">
                                <router-link :to="connectionUrl(connection)" v-tooltip="connection.feature.title">{{ connection.feature.title }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 py-2 font-semibold text-blue-500 text-right">
                            {{ connection.strength }}
                        </td>
                    </tr>
                </table>

                <div class="py-4 text-center space-y-2 text-sm text-gray-800" v-else-if="sourcesConnectedFeatures.error">
                    <p>We were unable to load connections for this Target.</p>
                    <p>
                        <a href="#" @click="reload()" class="text-blue-500 hover:text-blue-700">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No features found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showConnectedFeaturesShowMoreLink">
                    <router-link :to="{ name: 'targets.target.connections', params: { type: $page.target.family, modelType: $page.target.type, id: $page.target.id, connectionsType: 'connected-features' } }" class="text-blue-500 hover:text-blue-700">
                        Show More
                    </router-link>
                </div>
            </ui-async>
        </div>
    </div>
</template>

<script>
import useTargetsTargetConnectionsStore from '@/stores/targets/target/connections'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useTargetsTargetConnectionsStore, [
            'sourcesUpstream', 'sourcesDownstream', 'sourcesConnectedFeatures'
        ]),

        shownUpstreamSources() {
            return this.sourcesUpstream.data ? [ ...this.sourcesUpstream.data ].slice(0, 5) : []
        },

        showUpstreamSourcesShowMoreLink() {
            return this.sourcesUpstream.data?.length > 3
        },

        shownDownstreamSources() {
            return this.sourcesDownstream.data ? [ ...this.sourcesDownstream.data ].slice(0, 5) : []
        },

        showDownstreamSourcesShowMoreLink() {
            return this.sourcesDownstream.data?.length > 3
        },

        shownConnectedFeatures() {
            return this.sourcesConnectedFeatures.data ? [ ...this.sourcesConnectedFeatures.data ].slice(0, 5) : []
        },

        showConnectedFeaturesShowMoreLink() {
            return this.sourcesConnectedFeatures.data?.length > 3
        }
    },

    methods: {
        ...mapActions(useTargetsTargetConnectionsStore, [ 'reload' ]),

        connectionUrl(connection) {
            if (connection.source) {
                return { name: 'targets.target.information', params: { type: 'source', modelType: connection.source.type, id: connection.source.id } }
            } else if (connection.group) {
                return { name: 'targets.target.information', params: { type: 'group', modelType: connection.group.type, id: connection.group.id } }
            } else if (connection.feature) {
                return { name: 'features.feature.information', params: { type: connection.feature.type, id: connection.feature.id } }
            }
        }
    }
}
</script>
