<template>
    <ui-modal name="workspaces-manage" width="600" max-height="80%" unstyled content-class="bg-white rounded-md flex flex-col h-auto w-full">
        <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
            <h1 class="font-semibold text-gray-900 flex items-center space-x-2" v-if="workspace">
                <a href="#" @click.prevent="listWorkspaces">
                    <ui-icon name="arrow-left"></ui-icon>
                </a>
                <img class="rounded-full w-6 h-6 shadow" :src="workspace.avatarUrl">
                <span>{{workspace.name}}</span>
            </h1>
            <h1 class="font-semibold text-gray-900" v-else>
                My Workspaces
            </h1>

            <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('workspaces-manage')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <add-user-section v-if="section == 'add-user'"></add-user-section>
        <delete-section v-if="section == 'delete-workspace'"></delete-section>
        <list-section v-if="section == 'list-workspaces'"></list-section>
        <edit-section v-if="section == 'edit-workspace'"></edit-section>
        <manage-users-section v-if="section == 'manage-users'"></manage-users-section>
    </ui-modal>
</template>

<script>
import AddUserSection from './sections/add-user'
import DeleteSection from './sections/delete'
import EditSection from './sections/edit'
import ListSection from './sections/list'
import ManageUsersSection from './sections/manage-users'

import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        AddUserSection, DeleteSection, EditSection, ListSection, ManageUsersSection
    },

    computed: {
        ...mapState(useWorkspacesModalsManage, [ 'section', 'workspace' ])
    },

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'listWorkspaces' ])
    }
}
</script>
