import { defineStore } from 'pinia'

import useMyStore from '@/stores/me/my'
import useMyBroadcasterStore from '@/stores/me/broadcaster'

export const useAnalysesChannel = defineStore({
    id: 'analysesChannel',

    state: () => ({
        channel: null
    }),

    actions: {
        async initialize() {
            this.join()
        },

        join() {
            this.channel = useMyBroadcasterStore().broadcaster.private('users.' + useMyStore().user.id + '.analyses')
        }
    }
})

export default useAnalysesChannel
