<template>
    <div>
        <ui-header title="Analysis">
            <template v-slot:actions>
                <ui-button color="blue" icon="plus-square" :to="{ name: 'analysis.my-analyses.new' }">
                    New Analysis
                </ui-button>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="isShowingWelcomeMessage">
                <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
                <h1 class="text-2xl font-semibold my-6">Welcome to the Analysis section!</h1>
                <p class="text-lg my-1">In this section you can <span class="font-medium">analyze and visualize the collected data</span> from your perspectives, lists or targets in general.</p>
                <div class="flex space-x-10 my-10">
                    <div class="flex-1">
                        <h2 class="font-semibold">Analysis Types</h2>
                        <p class="text-justify my-2">We provide a <span class="font-medium">variety of analysis types</span> for many use-cases. Some of our analyses will help you visualize the published content itself, other will help you see patterns in a target's behavior.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Data Series</h2>
                        <p class="text-justify my-2">Many analyses will allow you to <span class="font-medium">visualize multiple series of data</span>. Depending on the type of the analysis this will often take a form of a perspective, targets list or a particular target to analyze.</p>
                    </div>
                    <div class="flex-1">
                        <h2 class="font-semibold">Analyses & Export</h2>
                        <p class="text-justify my-2">Most analyses include a visualization in a form of a line analysis, bar analysis or a heatmap. You can <span class="font-medium">export and save these visualizations</span> to use in your own documents or presentations.</p>
                    </div>
                </div>
                <p class="text-lg my-1">
                    To start you can <span class="font-medium">create your first analysis</span>, click on the New Analysis button to see all available analysis types.
                </p>
                <div class="flex items-center mt-8">
                    <ui-button color="blue" icon="plus-square" size="lg" :to="{ name: 'analysis.my-analyses.new' }">
                        New Analysis
                    </ui-button>
                </div>
            </div>

            <ui-cards-section title="Pinned & Recent" :store="analysisIndexCardsStore" key="indexCards">
            </ui-cards-section>

            <ui-cards-section :title="section.title" :store="section.store" :key="section.id" v-for="section in sections">
            </ui-cards-section>
        </div>
    </div>
</template>

<script>
import UiCardsSection from '@/components/ui/cards/cards-section'

import useAnalysisIndexCardsStore from '@/stores/analysis/index-cards'
import useAnalysisIndexSectionsStore from '@/stores/analysis/index-sections'

import { mapState, mapStores } from 'pinia'

export default {
    components: {
        UiCardsSection
    },

    computed: {
        ...mapStores(useAnalysisIndexCardsStore, useAnalysisIndexSectionsStore),
        ...mapState(useAnalysisIndexSectionsStore, [ 'sections' ]),

        isShowingWelcomeMessage() {
            return this.analysisIndexCardsStore.isInitialized && ! this.analysisIndexCardsStore.items.length
                && this.analysisIndexSectionsStore.isInitialized && this.analysisIndexSectionsStore.isEmpty
        }
    }
}
</script>
