<template>
    <div class="h-full overflow-y-auto" style="print-color-adjust: exact;" ref="report">
        <ui-async :guards="configuration && contents && headerImageMetaWidget && headerTitleMetaWidget">
                <div class="report-page relative flex flex-col p-4 bg-white" :style="{ height: configuration.height + 'px', width: configuration.width + 'px' }" v-for="content, i in contents.slice(1)" :key="i">
                    <div class="h-28" v-if="configuration.displayHeader">
                        <grid-layout
                            v-model:layout="contents[0]"
                            :col-num="36"
                            :row-height="5"
                            :max-rows="20"
                            :prevent-collision="true"
                            :auto-size="false"
                            :is-draggable="false"
                            :is-resizable="false"
                            :is-mirrored="false"
                            :vertical-compact="false"
                            :margin="[0, 0]"
                            :use-css-transforms="true"
                            @layout-ready="layoutUpdated"
                            @layout-updated="layoutUpdated"
                            ref="gridLayout"
                            v-if="contents && contents.length"
                        >
                            <grid-item v-if="configuration.displayHeaderImage" :x="contents[0][0].x" :y="contents[0][0].y" :w="contents[0][0].w" :h="contents[0][0].h" :min-w="contents[0][0].minW" :min-h="contents[0][0].minH" :i="contents[0][0].i" :static="true">
                                <image-widget :store="contents[0][0]" :dashboard-store="reportStore"></image-widget>
                            </grid-item>

                            <grid-item v-if="configuration.displayHeaderTitle" :x="contents[0][1].x" :y="contents[0][1].y" :w="contents[0][1].w" :h="contents[0][1].h" :min-w="contents[0][1].minW" :min-h="contents[0][1].minH" :i="contents[0][1].i" :static="true">
                                <text-widget :store="contents[0][1]" :dashboard-store="reportStore" :editable="true"></text-widget>
                            </grid-item>

                            <grid-item v-if="configuration.displayHeaderSideText" :x="contents[0][2].x" :y="contents[0][2].y" :w="contents[0][2].w" :h="contents[0][2].h" :min-w="contents[0][2].minW" :min-h="contents[0][2].minH" :i="contents[0][2].i" :static="true">
                                <text-widget :store="contents[0][2]" :dashboard-store="reportStore" :editable="true"></text-widget>
                            </grid-item>
                        </grid-layout>
                    </div>

                    <div class="h-full max-h-full overflow-none">
                        <grid-layout
                            v-model:layout="contents[i + 1]"
                            :col-num="12"
                            :row-height="20"
                            :is-draggable="false"
                            :is-resizable="false"
                            :is-mirrored="false"
                            :vertical-compact="false"
                            :margin="[0, 0]"
                            :use-css-transforms="true"
                            @layout-ready="layoutUpdated"
                            @layout-updated="layoutUpdated"
                            ref="gridLayout"
                            v-if="content && content.length"
                        >
                            <grid-item
                                v-for="widget in contents[i + 1]" :x="widget.x" :y="widget.y" :w="widget.w" :h="widget.h" :min-w="widget.type.minW" :min-h="widget.type.minH" :i="widget.i" :static="true" :key="widget.i">

                                <analysis-content-performance-widget :store="widget" :dashboard-store="reportStore" v-if="widget.type.id == 'analysis-content-performance'"></analysis-content-performance-widget>
                                <analysis-content-breakdown-by-source-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-source'"></analysis-content-breakdown-by-source-widget>
                                <analysis-content-breakdown-by-group-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-breakdown-by-group'"></analysis-content-breakdown-by-group-widget>
                                <analysis-content-publish-times-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-publish-times'"></analysis-content-publish-times-widget>
                                <analysis-content-pull-push-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-pull-push'"></analysis-content-pull-push-widget>
                                <analysis-content-top-platforms-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-top-platforms'"></analysis-content-top-platforms-widget>
                                <analysis-content-keywords-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-content-keywords'"></analysis-content-keywords-widget>
                                <analysis-target-publish-times-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-target-publish-times'"></analysis-target-publish-times-widget>
                                <analysis-target-audience-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'analysis-target-audience'"></analysis-target-audience-widget>
                                <keywords-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'keywords'"></keywords-widget>
                                <stream-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'stream'"></stream-widget>
                                <image-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'image'"></image-widget>
                                <text-widget :store="widget" :dashboard-store="reportStore" v-else-if="widget.type.id == 'text'"></text-widget>

                            </grid-item>
                        </grid-layout>
                    </div>

                    <div class="flex items-end justify-between">
                        <p v-if="configuration.displayFooter && configuration.displayFooterPagination">
                            Page {{ i + 1 }} of {{ contents.length - 1 }}
                        </p>

                        <div class="ml-auto">
                            <img class="h-10" src="/gerulata.png" alt="Gerulata">
                        </div>
                    </div>
                </div>
        </ui-async>
    </div>
</template>

<script>
import AnalysisContentPerformanceWidget from './widgets/analysis-content-performance'
import AnalysisContentBreakdownBySourceWidget from './widgets/analysis-content-breakdown-by-source'
import AnalysisContentBreakdownByGroupWidget from './widgets/analysis-content-breakdown-by-group'
import AnalysisContentPublishTimesWidget from './widgets/analysis-content-publish-times'
import AnalysisContentPullPushWidget from './widgets/analysis-content-pull-push'
import AnalysisContentTopPlatformsWidget from './widgets/analysis-content-top-platforms'
import AnalysisContentKeywordsWidget from './widgets/analysis-content-keywords'
import AnalysisTargetPublishTimesWidget from './widgets/analysis-target-publish-times'
import AnalysisTargetAudienceWidget from './widgets/analysis-target-audience'
import KeywordsWidget from './widgets/keywords'
import StreamWidget from './widgets/stream'
import ImageWidget from './widgets/image'
import TextWidget from './widgets/text'

import installVueGrid from 'vue-grid-layout'
let vueGrid = { component(name, component) { this[name] = component } }
installVueGrid(vueGrid)

import useReportStore from '@/stores/dashboards/report'

import {mapActions, mapState, mapStores, mapWritableState} from 'pinia'

export default {
    components: {
        GridLayout: vueGrid['grid-layout'],
        GridItem: vueGrid['grid-item'],
        AnalysisContentPerformanceWidget,
        AnalysisContentBreakdownBySourceWidget,
        AnalysisContentBreakdownByGroupWidget,
        AnalysisContentPublishTimesWidget,
        AnalysisContentPullPushWidget,
        AnalysisContentTopPlatformsWidget,
        AnalysisContentKeywordsWidget,
        AnalysisTargetPublishTimesWidget,
        AnalysisTargetAudienceWidget,
        KeywordsWidget,
        StreamWidget,
        ImageWidget,
        TextWidget
    },

    computed: {
        ...mapWritableState(useReportStore, [
            'contents'
        ]),

        ...mapState(useReportStore, [
            'report',
            'configuration',
            'activePage',
            'isEditing',
            'headerImageMetaWidget',
            'headerTitleMetaWidget'
        ]),

        ...mapStores(useReportStore),

        isShowingWelcomeMessage() {
            return this.contents && ! this.contents.length
        }
    },

    methods: {
        ...mapActions(useReportStore, [ 'edit', 'setActivePage', 'addPage', 'removePage' ]),

        contentScrolled() {
            const activePage = Math.floor(this.$refs.report.scrollTop / ( this.configuration.height - this.configuration.height / 3 ) )

            activePage <= this.contents.length - 1 ? this.setActivePage(activePage) : this.setActivePage(this.contents.length - 1)
        },

        layoutUpdated() { useReportStore().layoutUpdated() }
    }
}
</script>
