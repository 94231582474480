import { content as contentApi } from '@/helpers/api-three-tables'

import { defineStore } from 'pinia'

export const useTargetsTargetContentFacebookPostSharesStore = defineStore({
    id: 'targetsTargetContentFacebookPostSharesStore',

    state: () => ({
        contentId: null,
        shares: null,

        totalSharesCount: null,
        unavailableSharesCount: null
    }),

    getters: {
        lastShare(store) {
            return store.shares[store.shares.length - 1]
        }
    },

    actions: {
        load(content) {
            this.contentId = content.id
            this.totalSharesCount = content.metrics.shares

            contentApi()
                .parent(this.contentId)
                .sorting('date-asc')
                .limit(25)
                .get(content => this.appendShares(content))
        },

        loadMore(infiniteScroll) {
            if (! this.lastShare) return

            contentApi()
                .parent(this.contentId)
                .before({ value: this.lastShare.publishedAt, id: this.lastShare.id })
                .sorting('date-asc')
                .limit(25)
                .get(content => {
                    content = this.filterDuplicates(content)

                    this.appendShares(content)

                    if (! content.length) {
                        infiniteScroll.complete()
                    } else {
                        infiniteScroll.loaded()
                    }
                })
        },

        appendShares(shares) {
            this.shares = [ ...(this.shares || []), ...shares ]

            if (! shares.length) {
                this.unavailableSharesCount = this.totalSharesCount - this.shares?.length
            }
        },

        filterDuplicates(items) {
            let loadedIds = (this.shares || []).map(i => i.id)

            return items.filter(i => ! loadedIds.includes(i.id))
        }
    }
})

export default useTargetsTargetContentFacebookPostSharesStore
