import useWorkspaceSettingsFormStore from '@/admin/stores/accounts/workspace-form'
import useOrganizationStore from '@/admin/stores/accounts/organization'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsWorkspaceCreateStore = defineStore({
    id: 'modalsWorkspaceCreate',

    actions: {
        open() {
            useWorkspaceSettingsFormStore().$reset()
            useWorkspaceSettingsFormStore().organizationId = useOrganizationStore().organization.id

            useModal().show('workspace-create')
        },

        cancel() {
            useModal().hide('workspace-create')
        }
    }
})

export default useModalsWorkspaceCreateStore
