<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Dashboards
    </h1>

    <navigation-section :items="topNavigation"></navigation-section>

    <navigation-section v-if="formattedExports.length" title="Exports" :items="formattedExports" as-links max-shown="5"></navigation-section>
</template>

<script>
import useReportStore from '@/stores/dashboards/report'
import {mapState} from 'pinia'

export default {
    data: () => ({
        topNavigation: [
            { name: 'All dashboards', icon: 'navigation.sub.analyses', to: { name: 'dashboards' } },
            { name: 'Boards', icon: 'navigation.sub.analyses', to: { name: 'dashboards', params: { type: 'boards' } } },
            { name: 'Reports', icon: 'navigation.sub.analyses', to: { name: 'dashboards', params: { type: 'reports' } } }
        ]
    }),

    computed: {
        ...mapState(useReportStore, [ 'exports' ]),

        formattedExports() {
            return this.exports.map(e => ({
                name: this.$dateTime(e.createdAt),
                icon: 'file-text',
                url: e.url
            }))
        }
    }
}
</script>
