<template>
    <div :class="{ 'bg-white w-full overflow-hidden shadow rounded-sm': ! inline }">
        <div class="px-5">
            <div class="flex items-center space-x-3 mt-4 mb-2">
                <img :src="feature.avatarUrl" class="w-12 h-12 rounded-full shadow ring-2 ring-white">
                <h1 class="flex-1 text-xl font-bold leading-none line-clamp-2">{{feature.title}}</h1>
            </div>

            <table class="w-full table-fixed divide-y divide-gray-200 text-sm text-gray-900 mb-4" v-if="details">
                <tr :key="label" v-for="value, label in details">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium" :class="{'w-1/4': feature.type !== 'domain', 'w-1/3': feature.type === 'domain'}">{{label}}</td>
                    <td class="pr-1 pl-3 py-3 whitespace-pre-line break-words">{{value}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import filter from 'just-filter'

export default {
    props: [ 'feature', 'inline' ],

    computed: {
        details() {
            return filter({
                'First Seen At': this.feature.firstSeenAt ? this.$dateTime(this.feature.firstSeenAt) : null
            }, (k, v) => v)
        }
    }
}
</script>
