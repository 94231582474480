import useMyAnalysesStore from '@/stores/me/analyses'

import { defineStore } from 'pinia'

export const useAnalysisMyAnalysesNewPage = defineStore({
    id: 'analysisMyAnalysesNewPage',

    state: () => ({
        search: ''
    }),

    getters: {
        title: () => 'New Analysis | Analysis'
    },

    actions: {
        beforeEnter() {
            this.$reset()

            useMyAnalysesStore().initialize()
        }
    }
})

export default useAnalysisMyAnalysesNewPage
