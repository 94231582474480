import useTargetsIndexStreamStore from '@/stores/targets/index-stream'
import useMyStore from '@/stores/me/my'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useMyRecentTargetsStore from '@/stores/me/recent-targets'

import { defineStore } from 'pinia'

export const useTargetsIndexPage = defineStore({
    id: 'targetsIndexPage',

    state: () => ({
        curatedRootCategory: null,
        curatedCategory: null,
        type: 'sources'
    }),

    getters: {
        title: () => 'Targets',

        streamStore(store) {
            return useTargetsIndexStreamStore()
        }
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            this.streamStore.clear()
            this.streamStore.removeAllFilters()

            useMyBookmarksStore().initialize()
            useMyCuratedListsStore().initialize().then(() => this.initializeCuratedCategories())
            useMyTargetListsStore().initialize()
            useMyRecentTargetsStore().initialize()
        },

        initializeCuratedCategories() {
            if (this.curatedRootCategory) return

            let categories = [ ...useMyCuratedListsStore().categories ]
                .sort((a, b) => a.name.localeCompare(b.name))

            this.curatedRootCategory = this.curatedCategory = categories.find(c => c.language === useMyStore().preferredLanguage)
                ?? categories.find(c => c.language === 'en')
                ?? categories[0]
        }
    }
})

export default useTargetsIndexPage
