import useMyStore from '@/stores/me/my'

import api from '@/api'
import { dateTime, downloadBlob, useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsExportOptionsStore = defineStore({
    id: 'modalsExportOptions',

    state: () => ({
        streamStore: null,
        query: null,
        isExporting: false,

        limit: 1000,
        filename: '',
        format: 'xlsx'
    }),

    actions: {
        reset() {
            this.$reset()
        },

        open({ streamStore, query }) {
            this.reset()

            this.filename = `export_${dateTime(new Date(), 'd_MMM_yyyy_H_mm')}`

            if (streamStore) this.setStreamStore(streamStore)
            if (query) this.query = query

            useModal().show('export-options')
        },

        cancel() {
            useModal().hide('export-options')
        },

        setStreamStore(streamStore) {
            this.streamStore = streamStore

            const name = this.streamStore.appliedPerspective?.name || this.streamStore.table
            const slug = name.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '_')
                .replace(/^-+|-+$/g, '')
                .slice(0, 40)

            this.filename = `${slug}_export_${dateTime(new Date(), 'd_MMM_yyyy_H_mm')}`
        },

        doExport() {
            this.isExporting = true

            let callback = res => {
                downloadBlob(res, this.filename, this.format)

                this.cancel()

                this.isExporting = false
            }

            this.query ? this.query.export(this.format, callback) : this.makeRequest().blob(callback)
        },

        makeRequest() {
            return api.route(this.streamStore.exportEndpoint, {
                ...this.streamStore.apiQuery, ...{
                    include: '',
                    limit: this.limit,
                    format: this.format
                }, 'workspace': useMyStore().currentWorkspace.id
            }).get()
        }
    }
})

export default useModalsExportOptionsStore
