import useWorkspaceStore from '@/admin/stores/accounts/workspace'
import useModalsWorkspaceMemberCreateStore from '@/admin/stores/accounts/modals/workspace-member-create'

import api from "@/admin/api"

import { defineForm } from "@/stores/reusable/form"
import { nextTick } from 'vue'
import useMemberStore from '@/admin/stores/accounts/member'

export const useMemberSettingsFormStore = defineForm({
    id: 'memberSettingsForm',

    state: {
        organizationId: null,
        workspaceId: null,
        memberId: null
    },

    inputs: () => ({
        name: null,
        password: null
    }),

    submitRequest() {
        return api.route(this.memberId
                ? 'admin accounts organizations members update'
                : 'admin accounts organizations members store',
            { organizationId: this.organizationId, memberId: this.memberId }
        ).formData({
            _method: this.memberId ? 'put' : 'post',
            name: this.inputs.name,
            email: this.inputs.email,
            password: this.inputs.password,
            workspaceId: this.workspaceId
        }).post()
    },

    async onResponse(res) {
        useModalsWorkspaceMemberCreateStore().cancel()

        if (this.workspaceId) {
            await useWorkspaceStore().load(this.organizationId, this.workspaceId, true)
        }

        if (this.memberId) {
            await useMemberStore().load(this.organizationId, this.memberId, true)
        }

        nextTick(() => {
            window.scrollTo(0, 0);
        })
    }
})

export default useMemberSettingsFormStore
