<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Release notes
    </h1>

    <navigation-section
        title="Releases"
        :items="navigation"
        :wait-until="isNavigationInitialized"
        :max-shown="10" :to="item => ({ name: 'release-notes.page', params: { page: item.uri } })"
        :active="item => false"
    >
    </navigation-section>
</template>

<script>
import useSupportReleaseNotesPage from '@/stores/support/release-notes'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useSupportReleaseNotesPage, {
            isNavigationInitialized: 'isNavigationInitialized',
            navigation(store) {
                return store.items.map(i => {
                    i.icon = 'tag'

                    return i
                })
            }
        })
    },
}
</script>
