<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset">
        <template v-slot:applied>
            <ui-icon :name="value[0].icon" class="mr-1" :class="value[0].iconClass" v-if="value.length === 1 && value[0].icon"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} {{filter.name}}s
            </span>
            <span class="font-medium" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <template v-if="filter.searchable">
                    <div class="px-4">
                        <div class="relative w-full h-8">
                            <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                                <ui-icon name="search" class="text-sm"></ui-icon>
                            </div>
                            <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                        </div>
                    </div>

                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>
                </template>

                <template v-for="option, index in options">
                    <ui-dropdown-link :icon="option.icon" :checkbox="filter.multiple" :selected="filter.multiple ? localItems.includes(option) : value && (value.length ? value : [ value ]).some(v => v.id === option.id)" @click="filter.multiple ? toggleLocalItem(option) : store.addFilter(filter.id, option)" :key="`link-${index}`" v-if="option.id">
                        {{option.name}}
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2" :key="`separator-${index}`" v-else></ui-dropdown-separator>
                </template>

                <div class="text-gray-700 text-sm text-center" v-if='filter.searchable && ! options.length'>
                    No results
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button v-if="filter.multiple" @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import { textSearch } from '@/helpers'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        localItems: [],
        search: ''
    }),

    computed: {
        options() {
            return textSearch(
                this.search,
                this.filter.options.filter(this.filter.filter || (t => true)),
                o => o.name || true
            )
        },

        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    },

    methods: {
        toggleLocalItem(item) {
            this.localItems.some(v => v.id === item.id) ? this.localItems.splice(this.localItems.findIndex(v => v.id === item.id), 1) : this.localItems.push(item)
        },

        reset() {
            this.localItems = this.store.getFilterValue(this.filter.id) ? [...this.store.getFilterValue(this.filter.id)] : []
            this.search = ''
        },

        apply() {
            this.localItems.length ? this.store.addFilter(this.filter.id, this.localItems) : this.store.removeFilter(this.filter.id)
        }
    }
}
</script>
