<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="refresh" :keep-open="keepOpen">
        <template v-slot:applied>
            <div class="flex items-center space-x-1">
                <div :key="index" v-for="filter, index in filter.filters" class="flex items-center">
                    <!-- Mentions -->
                    <div v-if="filter.type === 'mentions' && store.getFilterValue(filter.id)" class="inline-flex items-center">
                        <div v-if="store.getFilterValue(filter.id).length > 1" class="inline-flex items-center leading-none space-x-1">
                            <ui-icon name="info"></ui-icon>
                            <span class="font-medium">{{ store.getFilterValue(filter.id).length }} Mentions</span>
                        </div>

                        <div v-else class="inline-flex items-center leading-none space-x-1 max-w-sm">
                            <ui-icon class="shrink-0" :name="store.getFilterValue(filter.id)[0].type.icon"></ui-icon>
                            <span class="font-medium truncate">{{store.getFilterValue(filter.id)[0].name}}</span>
                        </div>
                    </div>

                    <!-- Media -->
                    <div v-if="filter.type === 'media' && store.getFilterValue(filter.id)" class="inline-flex space-x-1">
                        <template v-if="store.getFilterValue(filter.id).type == 'contains-image'">
                            <ui-icon name="image"></ui-icon>
                            <span>Contains Image</span>
                        </template>

                        <template v-else-if="store.getFilterValue(filter.id).type == 'contains-similar-image'">
                            <ui-icon name="image"></ui-icon>
                            <span>Contains similar Image</span>
                        </template>

                        <template v-else-if="store.getFilterValue(filter.id).type == 'contains-video'">
                            <ui-icon name="video"></ui-icon>
                            <span>Contains Video</span>
                        </template>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <ui-dropdown-link
                    icon="image"
                    :selected="containsMedia == 'contains-image'"
                    @click="setContainsMedia('contains-image')"
                    keep-open
                >
                    Contains Image
                </ui-dropdown-link>

                <ui-dropdown-link
                    icon="video"
                    :selected="containsMedia == 'contains-video'"
                    @click="setContainsMedia('contains-video')"
                    keep-open
                >
                    Contains Video
                </ui-dropdown-link>

                <div class="max-h-80 overflow-y-auto">
                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <template v-if="! selectedMentionsToInclude.length && ! selectedMentionsToExclude.length">
                        <p class="text-xs text-center my-3">Showing content with all Features.</p>

                        <ui-dropdown-separator class="my-2"></ui-dropdown-separator>
                    </template>

                    <div v-if="selectedMentionsToInclude.length" class="text-xs font-medium text-gray-700 px-4 mb-2">
                        Show only content with
                    </div>

                    <ui-dropdown-link @click="deselectFeature(feature)" keep-open :icon="`badges.feature-${feature.type}`" :key="`${feature.family}-${feature.id}`" v-for="feature in selectedMentionsToInclude">
                        <template v-slot:default>
                            <div class="flex items-center group">
                                {{feature.name}}
                                <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                            </div>
                        </template>
                    </ui-dropdown-link>

                    <ui-dropdown-link v-if="selectedMentionsToInclude.length" icon="plus-circle" @click="selectFeatureToInclude" keep-open>
                        Add Feature...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectFeatureToInclude" keep-open>
                        Show only content with...
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <div v-if="selectedMentionsToExclude.length" class="text-xs font-medium text-gray-700 px-4 mb-2">
                        Exclude content with
                    </div>

                    <div class="max-h-80 overflow-y-auto">
                        <ui-dropdown-link @click="deselectFeature(feature)" keep-open :icon="`badges.feature-${feature.type}`" :key="`${feature.family}-${feature.id}`" v-for="feature in selectedMentionsToExclude">
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{feature.name}}
                                    <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>
                    </div>

                    <ui-dropdown-link v-if="selectedMentionsToExclude.length" icon="plus-circle" @click="selectFeatureToExclude" keep-open>
                        Add Feature...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectFeatureToExclude" keep-open>
                        Exclude content with...
                    </ui-dropdown-link>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="reset(); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">
                    Apply
                </ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        containsMedia: null,
        selectedMentions: [],

        keepOpen: false
    }),

    computed: {
        selectedMentionsToInclude() {
            return sortAlphabetically(this.selectedMentions.filter(val => ! val.exclude))
        },

        selectedMentionsToExclude() {
            return sortAlphabetically(this.selectedMentions.filter(val => val.exclude))
        }
    },

    methods: {
        selectFeatureToInclude() {
            this.selectFeature()
        },

        selectFeatureToExclude() {
            this.selectFeature(true)
        },

        selectFeature(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'features' ],
                onSelect: result => {
                    this.selectedMentions.push({
                        id: result.id,
                        type: result.type,
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                }
            })
        },

        deselectFeature(feature) {
            this.selectedMentions = this.selectedMentions.filter(f => f.id != feature.id || f.type != feature.type)
        },

        setContainsMedia(value) {
            this.containsMedia = this.containsMedia == value ? null : value
        },

        reset() {
            this.store.removeFilters(this.filter.filters)
        },

        refresh() {
            this.selectedMentions = (this.store.filter('mentions').value || []).map(mention => ({
                ...mention, type: mention.type.id
            }))

            this.containsMedia = this.store.filter('media').value?.type
        },

        apply() {
            if (this.selectedMentions.length) {
                this.store.filter('mentions').set(this.selectedMentions.map(mention => ({
                    ...mention, type: this.store.mentionFilterTypes.find(t => t.id == mention.type)
                })))
            } else {
                this.store.filter('mentions').remove()
            }

            if (this.containsMedia) {
                this.store.filter('media').set({ type: this.containsMedia, id: 0 })
            } else {
                this.store.filter('media').remove()
            }
        }
    }
}
</script>
