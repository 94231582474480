import defineAnalysisStore from '@/stores/reusable/spark-analysis'

export const useTargetsTargetContentFacebookPostSharesAnalysisStore = defineAnalysisStore({
    id: 'targetsTargetContentFacebookPostSharesAnalysis',
    name: 'content.facebook-post-shares',
    title: 'Shares',
    color: 'purple-600'
})

export default useTargetsTargetContentFacebookPostSharesAnalysisStore
