<template>
    <div>
        <ui-header title="Organizations">
            <template v-slot:actions>
                <ui-button icon="plus-square" color="blue" :action="openOrganizationCreateModal">
                    New Organization
                </ui-button>

                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off" spellcheck="false"
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
                </form>
            </template>
        </ui-header>

        <div class="pb-6 px-8 max-w-11xl mx-auto">
            <ui-cards-toolbar :store="organizationsCardsStore"></ui-cards-toolbar>

            <ui-cards :store="organizationsCardsStore">
                <template v-slot:item="{ item }">
                    <organization-card :organization="item" :cards-store="organizationsCardsStore"></organization-card>
                </template>
            </ui-cards>
        </div>

        <Teleport to="#root">
            <organization-create-modal></organization-create-modal>
        </Teleport>
    </div>
</template>

<script>
import OrganizationCard from '@/admin/components/accounts/stream-cards/organization'
import OrganizationCreateModal from '@/admin/components/accounts/modals/organization-create'

import useOrganizationsCardsStore from '@/admin/stores/accounts/organizations-cards'
import useModalsOrganizationCreateStore from '@/admin/stores/accounts/modals/organization-create'

import { mapActions, mapStores } from 'pinia'

export default {
    components: {
        OrganizationCard,
        OrganizationCreateModal
    },

    computed: {
        ...mapStores(useOrganizationsCardsStore)
    },

    methods: {
        ...mapActions(useModalsOrganizationCreateStore, { openOrganizationCreateModal: 'open' })
    }
}
</script>
