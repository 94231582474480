<template>
    <div v-for="item, i in items" :key="i" class="mx-2 py-0.5 font-medium leading-7 cursor-pointer">
        <a href="#" @click.prevent="navigate(item)"
           class="flex items-center px-2 py-0.5 transition ease-in-out duration-150 hover:bg-gray-75 rounded-sm"
           :class="{ 'pl-4 pr-2': child }">
            <span
                class="inline-flex justify-center items-center mr-1 w-5 h-5 leading-4 text-blue-600 transition duration-150 ease-in-out shrink-0">
                <ui-icon name="hash" class="text-xl"></ui-icon>
            </span>

            <p class="tracking-tight truncate">{{ item.title }}</p>
        </a>

        <items v-if="item.children" :child="true" :items="item.children"></items>
    </div>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import { mapActions } from 'pinia'
import { useRoute, useRouter } from '@/helpers'

export default {
    props: {
        'items': {
            required: true
        },
        'child': {
            required: false,
            default: false
        }
    },

    methods: {
        ...mapActions(useSupportHelpStore, [ 'click', 'parseLink', 'setSection', 'scrollTop' ]),

        navigate(item) {
            const [page, section] = this.parseLink(item.uri)

            if (useRoute().params.page === page) {
                if (section) {
                    return this.setSection(section)
                }

                this.scrollTop()
            }

            useRouter().push({ name: 'help.page', params: { page, section } })
        }
    }
}
</script>
