import useMyAnalysesStore from '@/stores/me/analyses'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useAnalysesChannel from '@/stores/analysis/analyses-channel'

import hasDatasetConcern from './concerns/has-dataset-concern'

import api from '@/api'
import { distanceTime } from '@/helpers/datetime'

import { defineStore } from 'pinia'
import { shallowRef } from 'vue'

export const defineContentBreakdownByTopicAnalysisStore = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            analysis: null,

            id: null,
            name: settings.name ?? 'Untitled analysis',
            type: 'content-breakdown-by-topic',
            fingerprint: null,

            series: settings.series || [],
            styles: settings.styles || {},

            date: false,
            granularity: 'week',
            topicSize: 'small',

            status: 'new',

            isLoading: false,
            isLoadingFresh: false,
            loadingPromise: null,
            abortLoading: null,

            elapsedBuildingTime: 0,
            estimatedBuildingTime: null,

            isDirty: false,
            isSaving: false,

            analysisSeries: {},
            seriesGeneratedAt: null,

            seriesOptions: {
                limit: 10,
                hasColors: false,
                hasLabels: true
            },

            styleOptions: [
                { name: 'Legend', id: 'analysis.legend', type: 'toggle' }
            ],

            otherOptions: true,

            styleDefaults: {
                'analysis.legend': 'enabled'
            },

            exportable: false,
            printable: false,

            chainTimelineAnalysisOptions: settings.chainTimelineAnalysisOptions || ((store, options) => options),
            chainBreakdownAnalysisOptions: settings.chainBreakdownAnalysisOptions || ((store, options) => options),

            passive: settings.passive || false,

            analysisRef: null
        }),

        getters: {
            breakdownAnalysisOptions: store => store.status === 'done' ? store.analysisSeries[store.topicSize]?.map(topic => (store.chainBreakdownAnalysisOptions(store, {
                boost: { useGPUTranslations: true },
                chart: {
                    type: 'bar',
                    resetZoomButton: { position: { x: 0, y: 0 } },
                    spacing: [6, 12, 6, 12],
                    zoomType: 'x'
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        useHTML: true,
                        style: {
                            width: '100px',
                            whiteSpace: 'normal'
                        },
                        step: 1,
                        formatter: function () {
                            return '<div align="center" style="width:100px">' + this.value + '</div>';
                        }
                    },
                },
                yAxis: {
                    gridLineColor: 'transparent',
                    title: {
                        text: null
                    }
                },
                credits: { enabled: false },
                exporting: {
                    chartOptions: {
                        chart: {
                            spacing: [40, 40, 40, 40]
                        }
                    },
                    buttons: {
                        contextButton: { enabled: false }
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        marker: { enabled: false },
                        fillOpacity: 1
                    },
                    series: {
                        animation: ! settings.passive || true,
                        connectNulls: true,
                        dataLabels: { enabled: false },
                        enableMouseTracking: true,
                        marker: {
                            enabled: store.effectiveStyles['analysis.markers'] == 'show',
                            enabledThreshold: store.effectiveStyles['analysis.markers'] == 'auto' ? 4 : 0,
                            fillColor: '#fff',
                            lineColor: null,
                            lineWidth: 2,
                            radius: 4,
                            symbol: 'circle'
                        },
                        states: {
                            hover: { lineWidthPlus: 0 }
                        },
                        turboThreshold: 2500
                    }
                },
                series: [{
                    data: Object.entries(topic).map(v => ({
                        y: v[1],
                        name: v[0],
                        color: '#3f84f2'
                    }))
                }],
                title: {
                    style: {
                        color: 'rgb(44, 64, 76)', fontFamily: 'Roboto, sans-serif', fontSize: '18px', fontWeight: '500'
                    },
                    text: Object.keys(topic).slice(0, 3).join(', ') + '...'
                },
                tooltip: {
                    backgroundColor: 'rgba(107, 114, 128, 0.8)',
                    borderColor: 'rgb(156, 163, 175)',
                    borderRadius: 7,
                    hideDelay: 100,
                    padding: 8,
                    pointFormatter: function () { return `${this.series.name}<br><strong>${this.y}</strong>` },
                    shadow: false,
                    style: { color: '#fff', textAlign: 'center' },
                    xDateFormat: '%Y-%m-%d'
                }
            }))) : null,

            effectiveStyles: store => ({
                ...store.styleDefaults,
                ...store.styles
            }),

            analysisConfiguration: store => ({
                series: store.series.map(series => ({
                    color: series.color,
                    datasetId: series.datasetId,
                    datasetType: series.datasetType,
                    datasetFilters: series.datasetFilters,
                    label: series.label,
                    meta: series.meta
                })),
                styles: store.effectiveStyles
            }),

            remainingBuildingTime(store) {
                if (! store.estimatedBuildingTime || ! store.elapsedBuildingTime) { return null }

                let endTime = new Date()
                endTime.setSeconds(endTime.getSeconds() + (store.estimatedBuildingTime - store.elapsedBuildingTime))

                return distanceTime(endTime, new Date())
            },

            hasValues: store => Object.keys(store.analysisSeries).length && store.analysisSeries[store.topicSize].length
        },

        actions: {
            initialize(analysis) {
                this.$reset()

                this.analysis = analysis

                this.id = analysis.id
                this.name = analysis.name

                this.series = analysis.configuration.series || this.series
                this.styles = analysis.configuration.styles || this.styles

                this.loadSeries(false, false)
            },

            async loadSeries(build = false, fresh = false) {
                this.status = 'unknown'

                if (this.loadingPromise) this.abortLoading.abort()

                this.isLoading = true
                this.isLoadingFresh = fresh

                if (build) { this.status = 'building' }

                if (! build) {
                    await api.route('me analyses cached-series')
                        .json({
                            type: this.type,
                            configuration: this.analysisConfiguration
                        })
                        .post()
                        .error(422, () => {
                            this.isLoading = false
                            this.fingerprint = null
                        })
                        .json(response => {
                            if (response.series) {
                                this.fingerprint = null

                                this.analysisSeries = response.series
                                this.seriesGeneratedAt = new Date(response.cachedAt)

                                this.status = 'done'

                                this.isLoading = this.isLoadingFresh = false
                            }
                        })

                    if (this.status !== 'done') {
                        await api.route('me analyses fingerprint')
                            .json({
                                type: this.type,
                                configuration: this.analysisConfiguration
                            })
                            .post()
                            .error(422, () => {
                                this.isLoading = false
                                this.fingerprint = null
                            })
                            .json(response => {
                                this.fingerprint = response.fingerprint
                            })

                        await api.route('me analyses locked')
                            .json({
                                type: this.type,
                                configuration: this.analysisConfiguration
                            })
                            .post()
                            .error(422, () => {
                                this.isLoading = false
                                this.fingerprint = null
                            })
                            .json(response => {
                                if (response.locked) {
                                    this.status = 'building'
                                }
                            })

                        if (this.status === 'unknown') {
                            if (this.series.length) { this.status = 'ready' } else { this.status = 'new' }
                        }
                    }

                    return
                }

                return this.loadingPromise = api.route('me analyses series')
                    .json({
                        type: this.type,
                        configuration: this.analysisConfiguration,
                        background: true,
                        fresh: fresh
                    })
                    .signal(this.abortLoading = new AbortController())
                    .post()
                    .error(422, () => {
                        this.isLoading = false
                        this.fingerprint = null
                    })
                    .json(response => {
                        if (response.series) {
                            this.fingerprint = null

                            this.analysisSeries = response.series
                            this.seriesGeneratedAt = new Date(response.cachedAt)

                            this.status = 'done'

                            this.isLoading = this.isLoadingFresh = false
                        }

                        if (response.fingerprint) {
                            this.fingerprint = response.fingerprint

                            useAnalysesChannel().channel.listen('SeriesFinishedBuilding', (payload) => {
                                if (payload.fingerprint === this.fingerprint) {
                                    this.analysisSeries = payload.data

                                    this.status = 'done'

                                    this.isLoading = this.isLoadingFresh = false
                                }
                            })

                            useAnalysesChannel().channel.listen('SeriesBuildingProgress', (payload) => {
                                if (payload.fingerprint === this.fingerprint) {
                                    this.status = 'building'

                                    if (payload.elapsed < payload.estimated) {
                                        this.elapsedBuildingTime = payload.elapsed
                                    }

                                    if (payload.elapsed >= payload.estimated) {
                                        this.elapsedBuildingTime = payload.estimated
                                    }

                                    this.estimatedBuildingTime = payload.estimated
                                }
                            })

                            setTimeout(() => {
                                if (this.status === 'unknown') {
                                    if (this.series.length) { this.status = 'ready' } else { this.status = 'new' }
                                }
                            }, 5000)
                        }
                    })
            },

            stopBuilding() {
                this.status = 'ready'
            },

            addSeries(data) {
                this.series.push({
                    label: data.label,
                    color: data.color,
                    datasetType: data.datasetType,
                    datasetId: data.datasetId,
                    meta: data.meta || {},
                    values: data.values || []
                })

                this.isDirty = true
                this.status = 'ready'
            },

            updateSeries(series, data) {
                Object.assign(series, {
                    label: data.label || series.label,
                    color: data.color || series.color,
                    datasetType: data.datasetType || series.datasetType,
                    datasetId: data.datasetId || series.datasetId,
                    meta: data.meta || series.meta,
                    values: data.values || series.values
                })

                this.isDirty = true
                this.status = 'ready'
            },

            deleteSeries(series) {
                const index = this.series.indexOf(series)

                if (index >= 0) {
                    this.abort()

                    this.analysisSeries = {}
                    this.isDirty = true
                    return this.series.splice(index, 1)
                }

                if (! this.series.length) {
                    this.status = 'new'
                }
            },

            isValidSeries(data) {
                return data.label && data.datasetType && data.datasetId
            },

            async unserializeUriSeries(to) {
                await useMyPerspectivesStore().initialize()

                let perspective = useMyPerspectivesStore().find(to.query['perspective-id'])

                if (perspective) {
                    this.addSeries({
                        label: perspective.name,
                        datasetType: 'perspective',
                        datasetId: perspective.id,
                        meta: { metric: 'all', aggregator: 'count' }
                    })
                }
            },

            setGranularity(granularity) {
                this.granularity = granularity
            },

            setStyle(name, value) {
                this.styles[name] = value
                this.isDirty = true
            },

            setAnalysisRef(analysis) {
                this.analysisRef = shallowRef(analysis)
            },

            save(notify = false) {
                this.isSaving = true

                return api.route(this.id ? 'me analyses update' : 'me analyses store', { id: this.id })
                    .json({
                        _method: this.id ? 'put' : 'post',
                        type: this.type,
                        name: this.name,
                        configuration: this.analysisConfiguration,
                        dependencies: this.resolveDependencies(),
                        notify: notify
                    })
                    .post()
                    .json(response => {
                        this.id = response.data.id
                    })
                    .finally(() => {
                        return useMyAnalysesStore().reload().then(() => {
                            this.isSaving = this.isDirty = false
                        })
                    })
            },

            resolveDependencies() {
                return {
                    'perspectives': this.series.filter(s => s.datasetType == 'perspective').map(s => s.datasetId),
                    'target-lists': this.series.filter(s => s.datasetType == 'target-list').map(s => s.datasetId),
                    'topics': this.series.filter(s => s.datasetType == 'search-topic').map(s => s.datasetId)
                }
            },

            abort() {
                if (this.abortLoading) this.abortLoading.abort()
            },

            ...hasDatasetConcern.actions,
            ...(settings.actions || [])
        }
    })
}

const useAnalysisAnalysesContentBreakdownByTopicStore = defineContentBreakdownByTopicAnalysisStore({ id: 'analysisAnalysesContentBreakdownByTopic' })

export default useAnalysisAnalysesContentBreakdownByTopicStore
