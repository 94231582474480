<template>
    <div class="flex shrink-0 h-full bg-white shadow transition-all" :class="{ 'w-80': $my.showSidebar, 'w-14': ! $my.showSidebar }">
        <div class="flex flex-col w-14 bg-gray-900 px-2 py-4 relative z-50">
            <div class="flex flex-col divide-y divide-gray-800 space-y-4">
                <nav class="space-y-2">
                    <router-link :to="{ name: 'home' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Home'">
                        <ui-icon name="navigation.sub.overview"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'accounts' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Accounts'">
                        <ui-icon name="users"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'support' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Support'">
                        <ui-icon name="message-square"></ui-icon>
                    </router-link>
                </nav>
            </div>

            <nav class="mt-auto">
                <a href="#" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" :class="{'text-gray-50': $my.showSidebar}" v-tooltip.right="'Toggle Sidebar'" @click.prevent="$my.toggleSidebar">
                    <ui-icon name="sidebar"></ui-icon>
                </a>

                <div class="flex items-center justify-center mt-4">
                    <ui-icon name="g" class="text-gray-50 text-4xl"></ui-icon>
                </div>
            </nav>
        </div>

        <div class="flex-1 overflow-y-auto">
            <router-view name="navigation"></router-view>
        </div>
    </div>
</template>