<template>
    <div class="flex-1 min-h-0 p-8 pt-4 overflow-y-auto space-y-3 bg-gray-75 rounded-b-md">
        <ui-input placeholder="Search..." icon="search" class="bg-gray-75 border-t-0 border-x-0 border-b border-gray-400 shadow-none placeholder:text-gray-500 focus:ring-0 focus:border-gray-900 mb-4" v-model="search"></ui-input>

        <workspace-card :workspace="workspace" :key="workspace.id" v-for="workspace in workspaces"></workspace-card>

        <div class="text-center text-sm pt-2">
            <a href="#" class="text-blue-600 hover:text-blue-800" @click.prevent="createWorkspace">
                <ui-icon name="plus-circle"></ui-icon>
                Create a New Workspace
            </a>
        </div>
    </div>
</template>

<script>
import WorkspaceCard from '@/components/me/workspaces/cards/workspace'

import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspacesModalsManage from '@/stores/workspaces/modals/manage'

import { sortNaturally, textSearch } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        WorkspaceCard
    },

    data: () => ({
        search: ''
    }),

    computed: {
        ...mapState(useMyWorkspacesStore, {
            workspaces(store) {
                return sortNaturally(textSearch(this.search, store.items.filter(item => ! item.personal), v => v.name))
            }
        })
    },

    methods: {
        ...mapActions(useWorkspacesModalsManage, [ 'createWorkspace' ])
    }
}
</script>
