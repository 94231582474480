import { defineTargetsStreamStore } from '../reusable/stream'
import { useRoute, useRouter } from '@/helpers'

import filter from 'just-filter'

export const useTargetsIndexStreamStore = defineTargetsStreamStore({
    id: 'targetsIndexStream',

    showsResultsBar: false,

    searchContext: 'All Targets',

    actions: {
        triggerQueryChange() {
            if (! this.appliedFilters.length) return

            let query = filter(useRoute().query, key => {
                return ! (key.match(/filter\[.+?\]/) || [ 'sorting' ].includes(key))
            })

            useRouter().push(
                {
                    name: 'targets.library.search',
                    query: { ...query, ...this.toQuery() }
                },
                null,
                error => { if (error.name != 'NavigationDuplicated') throw error }
            )
        }
    }
})

export default useTargetsIndexStreamStore
