<template>
    <search-filter :align="filter.alignment || 'left'" :filter="filter" :store="store" @opened="reset">
        <template v-slot:applied>
            <div class="inline-flex space-x-1">
                <div class="inline-flex items-center space-x-1 leading-4" :key="index" v-for="current, index in value">
                    <ui-icon :name="current.metric.icon"></ui-icon>

                    <span class="font-medium" v-if="! current.gte">&lt; {{current.lte}}</span>
                    <span class="font-medium" v-else-if="! current.lte">&gt; {{current.gte}}</span>
                    <span class="font-medium" v-else>{{current.gte}} - {{current.lte}}</span>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64 p-2 pt-0" v-if="selectedMetric">
                <label class="block mb-2 px-4 text-gray-400 text-xs font-semibold uppercase">
                    {{selectedMetric.name}}
                </label>

                <div class="flex items-stretch px-4">
                    <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-sm placeholder-gray-400 border-gray-300 border-r-0 rounded-r-none text-center" placeholder="Min" v-model="min">
                    <div class="flex items-center border-t border-b border-gray-300 text-gray-400 px-1">&ndash;</div>
                    <input type="number" min="0" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-sm placeholder-gray-400 border-gray-300 border-l-0 rounded-l-none text-center" placeholder="Max" v-model="max">
                </div>
            </div>

            <div class="w-48" v-else>
                <template v-for="metric, index in filter.metrics">
                    <ui-dropdown-link :checkbox="filter.multiple" :selected="value && value.some(v => v.metric.id === metric.id)" :icon="metric.icon" @click="selectedMetric = metric" :key="`metric-${index}`" v-if="metric.id">
                        {{metric.name}}
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2" :key="`separator-${index}`" v-else></ui-dropdown-separator>
                </template>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click="store.removeFilter(filter.id); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button v-if="selectedMetric" @click="store.addFilter(filter.id, [{ metric: selectedMetric, gte: min, lte: max }]); close()" color="blue" size="sm">
                    Apply
                </ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

export default {
    components: { SearchFilter },

    props: [ 'filter' ],

    data: () => ({
        selectedMetric: null,
        min: '',
        max: ''
    }),

    computed: {
        value() {
            return this.store.getFilterValue(this.filter.id)
        }
    },

    methods: {
        reset() {
            this.selectedMetric = null
            this.min = ''
            this.max = ''
        }
    }
}
</script>
