<template>
    <ui-dropdown :align="align || 'left'" z-index="50" width="w-auto" v-if="! filter.hidden && (applied.length || ! filter.ephemeral)" :keep-open="keepOpen" @opened="$emit('opened')" @closed="$emit('closed')">
        <template v-slot:trigger>
            <a href="#" class="block" :class="{ 'cursor-default': filter.readOnly }" @click="click">
                <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-none bg-blue-50 text-blue-600 rounded-sm max-w-full" v-if="applied.length && applied.some(f => f.value)">
                    <slot name="applied"></slot>
                </div>

                <div class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-50 rounded-sm max-w-full" v-else>
                    {{filter.name}}
                </div>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div @click.stop>
                <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-900">
                    <p>{{filter.name}}</p>
                    <a href="#" @click.prevent="close">
                        <ui-icon name="x"></ui-icon>
                    </a>
                </div>

                <slot name="options"></slot>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div class="pt-1">
                    <slot name="controls" :close="close"></slot>
                </div>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>

export default {
    props: { 'align': { type: String }, 'filter' : { type: Object }, 'keepOpen': { type: Boolean, default: false }, 'isOpen': { type: Boolean } },

    computed: {
        applied() {
            return this.filter.filters
                ? this.store.appliedFilters.filter(af => this.filter.filters.some(fs => fs.id == af.filter.id))
                : this.store.appliedFilters.filter(f => f.filter == this.filter)
        }
    },

    methods: {
        click($ev) {
            if (this.filter.readOnly) {
                $ev.preventDefault()
                $ev.stopPropagation()
            }
        }
    }
}
</script>
