<template>
    <component :is="to ? 'router-link' : 'button'" :type="to ? '' : 'button'" :class="effectiveClass" :to="to" :disabled="isDisabled" @click="click">
        <ui-icon :name="icon" :class="{ 'mr-2': $slots.default }" v-if="icon"></ui-icon>
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'ui-button',

    props: {
        'action': {}, 'color': {}, 'icon': {}, 'disabled': Boolean, 'light': Boolean, 'route': {}, 'size': {}, 'to': {}, 'unstyled': Boolean
    },

    data: () => ({
        executing: false
    }),

    computed: {
        effectiveClass() {
            if (this.unstyled) return

            let colorClass = {
                solid: {
                    blue: 'bg-blue-500 hover:bg-blue-600 text-white shadow-sm',
                    gray: 'bg-gray-200 hover:bg-gray-300 text-gray-900 shadow-sm',
                    green: 'bg-green-500 hover:bg-green-600 text-white shadow-sm',
                    red: 'bg-red-500 hover:bg-red-600 text-white shadow-sm'
                },
                light: {
                    blue: 'border border-blue-500 hover:border-blue-600 text-blue-500 hover:text-blue-700 shadow-sm',
                    // gray: 'border border-gray-300 hover:border-gray-400 text-gray-900 shadow-sm',
                    gray: 'border border-gray-400 hover:border-gray-700 text-gray-700 hover:text-gray-900',
                    green: 'border border-green-500 hover:border-green-600 text-green-500 hover:text-green-700 shadow-sm',
                    red: 'border border-red-500 hover:border-red-600 text-red-500 hover:text-red-700 shadow-sm'
                }
            }[this.light ? 'light' : 'solid'][this.color || 'gray']

            let sizeClass = {
                xs: 'h-7 text-sm font-medium px-2',
                sm: 'h-8 text-sm font-medium px-3',
                base: 'h-8 text-sm font-medium px-6',
                lg: 'h-10 font-medium px-6'
            }[this.size || 'base']

            return `inline-flex justify-center items-center ${sizeClass} rounded ${colorClass} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-vivid disabled:opacity-50 transition ease-in-out duration-150`
        },

        isDisabled() {
            return this.disabled || this.executing
        }
    },

    methods: {
        async click(ev) {
            if (this.route) return this.$router.push(this.route)

            if (! this.action) return

            ev.preventDefault()

            let instance, method

            if (this.action instanceof Array) {
                [ instance, method ] = this.action
            } else {
                method = this.action
            }

            this.executing = true
            if (instance) {
                await instance[method]()
            } else {
                await method()
            }
            this.executing = false
        }
    }
}
</script>