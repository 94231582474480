import useTargetsTargetPage from '@/pages/targets-target'
import useTargetsTargetContentStore from '@/stores/targets/target/content'

import { defineStore, mapState } from 'pinia'

export const useTargetsTargetContentPage = defineStore({
    id: 'targetsTargetContentPage',

    getters: {
        ...mapState(useTargetsTargetPage, [ 'type', 'target', 'model' ])
    },

    actions: {
        afterEnter(to) {
            useTargetsTargetContentStore().load(to.params.contentId)
        }
    }
})

export default useTargetsTargetContentPage
