<template>
    <div class="flex items-center justify-between px-4 pt-3">
        <h1 class="text-xl font-semibold">Content</h1>
<!--        <ui-help-button v-tooltip="'Content'" page="content"></ui-help-button>-->
    </div>

    <navigation-section
        :items="topNavigation">
    </navigation-section>

    <navigation-section title="Pinned & Recent"
        :items="pinnedAndRecent" :wait-until="arePerspectivesInitialized && areTargetListsInitialized && areTopicsInitialized" :max-shown="5"
        :active="item => false"
        placeholder="Nothing pinned or recently visited yet..."
    >
    </navigation-section>

    <navigation-section title="Library"
        :items="libraryContent"
        :to="item => ({ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[type]': item.type } })"
        :active="item => false">
    </navigation-section>
</template>

<script>
import useContentLibraryTypesStore from '@/stores/content/library-types'
import useContentStreamStore from '@/stores/content/stream'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { sortNaturally } from '@/helpers'

import { mapState, mapStores } from 'pinia'

export default {
    data: () => ({
        topNavigation: [
            { name: 'Overview', icon: 'navigation.sub.overview', to: { name: 'content' } },
            { name: 'Perspectives', icon: 'navigation.sub.perspectives', to: { name: 'content.perspectives' } },
            { name: 'Topics', icon: 'navigation.sub.topics', to: { name: 'content.topics' } }
        ]
    }),

    computed: {
        pinnedAndRecent() {
            let items = [
                ...this.perspectives.map(item => ({
                    ...item, avatarType: 'perspective', to: { name: 'content.perspectives.perspective', params: { perspectiveId: item.id } }
                })),
                ...this.lists.map(item => ({
                    ...item, avatarType: 'target-list', to: { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[targets]': `list|${item.id}|${item.name}`, 'filter[date]': 'past:1|months' } }
                })),
                ...this.topics.map(item => ({
                    ...item, avatarType: 'topic', to: { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${item.query}|${item.language}|`, 'filter[date]': 'past:1|months', 'topic': item.id } }
                }))
            ]

            return sortNaturally(items.filter(item => item.pinned || item.recent))
        },

        ...mapState(useMyPerspectivesStore, {
            arePerspectivesInitialized: 'isInitialized',
            perspectives: 'items'
        }),

        ...mapState(useMyTargetListsStore, {
            areTargetListsInitialized: 'isInitialized',
            lists: 'items'
        }),

        ...mapState(useMySearchTopicsStore, {
            areTopicsInitialized: 'isInitialized',
            topics: 'items'
        }),

        ...mapState(useContentLibraryTypesStore, {
            libraryContent: 'content'
        }),

        ...mapStores(useContentStreamStore)
    }
}
</script>
