<template>
    <div class="sticky mx-auto bottom-4 border z-50 h-9 text-sm shadow-md w-min flex items-center font-medium">
        <a href="#" @click.prevent="() => editReport(report)" class="flex items-center shrink-0 bg-gray-800 transition ease-in-out duration-150 text-gray-400 hover:text-gray-50 h-10 h-full rounded-l-lg px-3 py-0.5">
            <ui-icon class="mr-2" name="settings"></ui-icon>
            <span>Document settings</span>
        </a>

        <div class="flex items-center bg-gray-900 text-gray-400 h-10 h-full divide-x divide-gray-400 rounded-r-lg py-0.5">
            <a href="#" class="flex items-center px-3 transition ease-in-out duration-150 hover:text-gray-50" @click.prevent="addAnalysis()">
                <ui-icon class="mr-2" name="plus-circle"></ui-icon>
                <span>Analysis</span>
            </a>

<!--            <a href="#" class="flex items-center px-3 transition ease-in-out duration-150 hover:text-gray-50" @click.prevent="addWidget(widgetTypes.find(w => w.id === 'keywords'))">-->
<!--                <ui-icon class="mr-2" name="align-center"></ui-icon>-->
<!--                <span>Keywords</span>-->
<!--            </a>-->

            <a href="#" class="flex items-center px-3 transition ease-in-out duration-150 hover:text-gray-50" @click.prevent="addWidget(widgetTypes.find(w => w.id === 'image'))">
                <ui-icon class="mr-2" name="image"></ui-icon>
                <span>Image</span>
            </a>

            <a href="#" class="flex items-center px-3 transition ease-in-out duration-150 hover:text-gray-50" @click.prevent="addWidget(widgetTypes.find(w => w.id === 'text'))">
                <ui-icon class="mr-2" name="text-word"></ui-icon>
                <span>Text</span>
            </a>
        </div>
    </div>
</template>

<script>
import useReportStore from '@/stores/dashboards/report'
import useModalsReportEditStore from '@/stores/dashboards/modals/report-edit'
import useModalsAddAnalysisStore from '@/stores/dashboards/modals/add-analysis'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'size' ],

    computed: {
        ...mapState(useReportStore, [ 'report', 'widgetTypes' ])
    },

    methods: {
        ...mapActions(useReportStore, [ 'addWidget' ]),
        ...mapActions(useModalsReportEditStore, { editReport: 'open' }),
        ...mapActions(useModalsAddAnalysisStore, { addAnalysis: 'open' })
    }
}
</script>
