<template>
    <ui-modal name="targets-list-import" title="Import targets">
        <label for="file" class="block text-sm font-medium text-gray-700">
            Import file
        </label>
        <div class="mt-2">
            <input id="file" name="file" type="file" @change="change" />
        </div>

        <div class="mt-6 text-sm text-gray-700">
            <p>Imports a previously generated CSV or Excel export into the current list.</p>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="blue" icon="download" @click.prevent="doImport(streamStore)" :disabled="isImporting">
                {{ isImporting ? 'Importing...' : 'Import' }}
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import useModalsImportOptionsStore from '@/stores/targets/modals/list-import-targets'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    props: {
        streamStore: {
            required: true
        }
    },

    computed: {
        ...mapState(useModalsImportOptionsStore, [
            'isImporting'
        ]),

        ...mapWritableState(useModalsImportOptionsStore, [
            'file'
        ])
    },

    methods: {
        ...mapActions(useModalsImportOptionsStore, [
            'doImport'
        ]),

        change(event) {
            this.file = event.target.files[0]
        }
    }
}
</script>
