<template>
    <a href="#" class="block px-6 py-3 hover:bg-gray-100 hover:ring-1 ring-gray-300 ring-inset" :class="{ 'bg-gray-100 ring-1': focused }" @click.prevent="open">
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon name="search" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-700">
                    <div class="truncate">Create a new Perspective</div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
import useContentModalsPerspectiveNewStore from '@/stores/content/modals/perspective-new'
import useMyQuickSearchStore from '@/stores/me/quick-search'

export default {
    props: [ 'focused', 'query' ],

    methods: {
        async open() {
            useMyQuickSearchStore().hide()
            useMyQuickSearchStore().onSelect(await useContentModalsPerspectiveNewStore().open())
        }
    }
}
</script>
