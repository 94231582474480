import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal, useRouter } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyStore from '@/stores/me/my'
import useMyPerspectivesStore from '@/stores/me/perspectives'

export const useContentModalsPerspectiveDuplicateStore = defineForm({
    id: 'contentModalsPerspectiveDuplicate',

    inputs: () => ({
        perspective: {},
        name: null,
        workspace: {}
    }),

    submitRequest() {
        trackEvent('content', 'duplicated-perspective-saved')

        return api.route('me perspectives duplicate', { id: this.inputs.perspective.id })
            .formData({
                _method: 'post',
                name: this.inputs.name,
                workspaceId: this.inputs.workspace.id
            })
            .post()
    },

    async onResponse(res) {
        await useMyPerspectivesStore().reload()

        useModal().hide('content-perspective-duplicate')

        useRouter().push({ name: 'content.perspectives.perspective', params: { perspectiveId: res.data.id } })
    },

    actions: {
        open(perspective = {}) {
            this.reset()

            this.inputs.perspective = perspective
            this.inputs.name = `${perspective.name} (copy)`
            this.inputs.workspace = useMyStore().currentWorkspace

            useModal().show('content-perspective-duplicate')
        },

        cancel() {
            useModal().hide('content-perspective-duplicate')
        }
    }
})

export default useContentModalsPerspectiveDuplicateStore
