export default [
    // Root redirect to default section (home)
    { path: '', redirect: { name: 'home' } },

    // Public routes
    {
        component: require('./Main.vue').default,
        meta: { guestOnly: true },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: { page: require('@/pages/auth-login').default },
                component: require('@/components/auth/login').default
            },
            {
                path: '/login/activate/:token',
                name: 'login.activate',
                meta: { page: require('@/pages/auth-activate').default },
                component: require('@/components/auth/activate').default
            },
            {
                path: '/login/social',
                name: 'login.social',
                meta: { page: require('@/pages/auth-social').default },
                component: require('@/components/ui/screen').default
            },
            {
                path: '/password-recovery/request',
                name: 'password-recovery.request',
                meta: { page: require('@/pages/auth-password-recovery-request').default },
                component: require('@/components/auth/password-recovery-request').default
            },
            {
                path: '/password-recovery/reset/:token',
                name: 'password-recovery.reset',
                meta: { page: require('@/pages/auth-password-recovery-reset').default },
                component: require('@/components/auth/password-recovery-reset').default
            }
        ]
    },

    // Application routes (requires authentication)
    {
        component: require('./App').default,
        meta: { authenticatedOnly: true },
        children: [
            {
                path: '/home',
                components: {
                    default: require('./components/home/screen').default,
                    navigation: require('./components/home/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'home',
                        component: require('./components/home/index').default,
                        meta: { page: require('./pages/home-index').default }
                    }
                ]
            },
            {
                path: '/accounts',
                components: {
                    default: require('./components/accounts/screen').default,
                    navigation: require('./components/accounts/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'accounts',
                        component: require('./components/accounts/index').default,
                        meta: { page: require('./pages/accounts-index').default }
                    },
                    {
                        path: 'organizations',
                        name: 'accounts.organizations',
                        component: require('./components/accounts/organizations').default,
                        meta: { page: require('./pages/accounts-organizations-index').default }
                    },
                    {
                        path: 'organizations/:organizationId',
                        component: require('./components/accounts/organization').default,
                        meta: { page: require('./pages/accounts-organizations-organization').default },
                        children: [
                            {
                                path: '',
                                name: 'accounts.organizations.organization.information',
                                component: require('./components/accounts/organization/information').default,
                                meta: { page: require('./pages/accounts-organizations-organization-information').default }
                            },
                            {
                                path: 'licenses',
                                name: 'accounts.organizations.organization.licenses',
                                component: require('./components/accounts/organization/licenses').default,
                                meta: { page: require('./pages/accounts-organizations-organization-licenses').default }
                            },
                            {
                                path: 'licenses/:licenseId',
                                name: 'accounts.organizations.organization.license',
                                component: require('./components/accounts/license').default,
                                meta: { page: require('./pages/accounts-organizations-organization-license').default }
                            },
                            {
                                path: 'workspaces',
                                name: 'accounts.organizations.organization.workspaces',
                                component: require('./components/accounts/organization/workspaces').default,
                                meta: { page: require('./pages/accounts-organizations-organization-workspaces').default }
                            },
                            {
                                path: 'workspaces/:workspaceId',
                                name: 'accounts.organizations.organization.workspace',
                                component: require('./components/accounts/workspace').default,
                                meta: { page: require('./pages/accounts-organizations-organization-workspace').default }
                            },
                            {
                                path: 'members',
                                name: 'accounts.organizations.organization.members',
                                component: require('./components/accounts/organization/members').default,
                                meta: { page: require('./pages/accounts-organizations-organization-members').default }
                            },
                            {
                                path: 'members/:memberId',
                                name: 'accounts.organizations.organization.member',
                                component: require('./components/accounts/member').default,
                                meta: { page: require('./pages/accounts-organizations-organization-member').default }
                            }
                        ]
                    }
                ]
            },
            {
                path: '/support',
                components: {
                    default: require('./components/support/screen').default,
                    navigation: require('./components/support/navigation').default
                },
                children: [
                    {
                        path: '',
                        name: 'support',
                        component: require('./components/support/index').default,
                        meta: { page: require('./pages/support-index').default }
                    },
                    {
                        path: 'threads',
                        name: 'support.threads',
                        component: require('./components/support/threads').default,
                        meta: { page: require('./pages/support-threads-index').default }
                    },
                    {
                        path: 'threads/:threadId',
                        name: 'support.threads.thread',
                        component: require('./components/support/thread').default,
                        meta: { page: require('./pages/support-threads-thread').default }
                    }
                ]
            }
        ]
    },

    // Catch-all route for "not found" pages
    { path: '/:pathMatch(.*)*', redirect: { name: 'home' } }
]
