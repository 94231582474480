import useLicenseStore from '@/admin/stores/accounts/license'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationLicensePage = defineStore({
    id: 'accountsOrganizationsOrganizationLicensePage',

    state: () => ({
        search: ''
    }),

    actions: {
        async beforeEnter(to) {
            if (to.params.licenseId != useLicenseStore().license?.id) {
                useLicenseStore().initialize(to.params.organizationId, to.params.licenseId, true)
            }
        }
    }
})

export default useAccountsOrganizationsOrganizationLicensePage
