<template>
    <h1 class="text-xl font-semibold px-4 pt-3">
        Settings
    </h1>

    <navigation-section :items="topNavigation">
    </navigation-section>
</template>

<script>
import useSettingsStore from '@/stores/settings/settings'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useSettingsStore, [ 'topNavigation' ])
    }
}
</script>
