import useMyStore from '@/stores/me/my'

import api from '@/api'
import { useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const useAuthSocialLoginStore = defineStore({
    id: 'authSocialLogin',

    state: () => ({
        error: null
    }),

    actions: {
        async initiate(activationToken) {
            api.route('auth social login').query({ activationToken }).get().json(res => {
                window.location = res.redirectUrl
            })
        },

        finish(to) {
            let unknownError = () => {
                this.error = 'Unexpected error, please try again later and contact us if the problem persists.'
                useRouter().push({ name: 'login' })
            }

            api.route('auth social login callback')
                .formData({
                    code: to.query.code,
                    error: to.query.error,
                    state: to.query.state,
                    activationToken: to.query.state?.split('|')[1]
                })
                .post()
                .error(400, unknownError)
                .error(403, err => {
                    this.error = err.json.message
                    useRouter().push({ name: 'login' })
                })
                .error(404, unknownError)
                .error(500, unknownError)
                .res(() => useMyStore().loggedIn())
                .catch(unknownError)
        }
    }
})

export default useAuthSocialLoginStore
