<template>
    <ui-modal name="organization-create" title="Create a new Organization" :max-width="520" @opened="opened">
        <ui-form :store="organizationSettingsFormStore" v-slot="{ inputs, submitting, errors }">
            <label for="name" class="block text-sm font-medium text-gray-700">
                Name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
            </div>

            <div>
                <label for="timezone" class="block text-sm font-medium text-gray-700 mt-4">
                    Timezone
                </label>
                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="timezones" v-model="inputs.timezone" :reduce="timezone => timezone.key"></ui-select>
            </div>

            <div>
                <label for="preferred_content_language" class="block text-sm font-medium text-gray-700 mt-4">
                    Preferred content language
                </label>
                <ui-select class="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :options="languages" v-model="inputs.preferredContentLanguage" :reduce="language => language.key"></ui-select>
            </div>

            <div>
                <label for="owner_name" class="block text-sm font-medium text-gray-700 mt-4">
                    Owner name
                </label>
                <ui-input type="text" id="name" v-model="inputs.ownerName" :errors="errors.owner_name" ref="owner_name"></ui-input>
            </div>

            <div>
                <label for="owner_email" class="block text-sm font-medium text-gray-700 mt-4">
                    Owner e-mail
                </label>
                <ui-input type="text" id="name" v-model="inputs.ownerEmail" :errors="errors.owner_email" ref="owner_email"></ui-input>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="green" :disabled="submitting">
                    Create
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useOrganizationSettingsFormStore from '@/admin/stores/accounts/organization-form'
import useOrganizationsStore from '@/admin/stores/accounts/organizations'

import { mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useOrganizationSettingsFormStore),

        ...mapState(useOrganizationsStore, [
            'timezones',
            'languages'
        ])
    },

    methods: {
        opened() { this.$refs.name.focus() }
    }
}
</script>
