<template>
    <ui-modal name="content-perspective-new" title="New Perspective" unstyled height="96%" max-width="1200px" width="100%" @closed="cancel">
        <div class="bg-white rounded-md flex flex-col h-full w-full">
            <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
                <h1 class="font-semibold text-gray-900">
                    New Perspective
                </h1>

                <div class="space-x-3" v-if="contentModalsPerspectiveNewStreamStore.appliedFilters.length">
                    <ui-button :action="cancel">Cancel</ui-button>
                    <ui-button color="blue" :action="save">Save</ui-button>
                </div>

                <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('analyses-series-perspectives-dataset')" v-else>
                    <ui-icon name="remove"></ui-icon>
                </a>
            </div>
            <div class="flex-1 min-h-0 pt-1 overflow-y-auto rounded-b-md">
                <ui-stream-toolbar :store="contentModalsPerspectiveNewStreamStore"></ui-stream-toolbar>

                <div class="bg-gray-75 p-8">
                    <ui-stream :store="contentModalsPerspectiveNewStreamStore" v-if="contentModalsPerspectiveNewStreamStore.appliedFilters.length"></ui-stream>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import UiStreamToolbar from '@/components/ui/stream/toolbar/toolbar'

import useContentModalsPerspectiveNewStore from '@/stores/content/modals/perspective-new'
import useContentModalsPerspectiveNewStreamStore from '@/stores/content/modals/perspective-new-stream'

import { mapActions, mapStores } from 'pinia'

export default {
    components: {
        UiStreamToolbar
    },

    computed: {
        ...mapStores(useContentModalsPerspectiveNewStreamStore)
    },

    methods: {
        ...mapActions(useContentModalsPerspectiveNewStore, [ 'cancel', 'save' ])
    }
}
</script>
