<template>
    <div class="h-full flex flex-col">
        <div v-if="! thread" class="flex-1"></div>
        <div v-else class="flex-1 overflow-y-auto" ref="messages" @scroll="handleScroll">
            <ui-header :title="thread.name" :avatar="thread.avatarUrl" :back="{ name: 'support.threads' }">
                <template v-slot:subtitle>
                    <div class="flex items-center">
                        <div>
                            By <span class="font-medium">
                            <router-link :to="{ name: 'accounts.organizations.organization.member', params: { organizationId: thread.customer.organization.id, memberId: thread.customer.id } }" class="font-medium">
                                {{ thread.customer.name }}
                            </router-link>
                        </span>
                        </div>
                        <div v-if="thread.agent">
                            <span class="px-1">&middot;</span>
                            <span>Assigned to </span>
                            <router-link :to="{ name: 'accounts.organizations.organization.member', params: { organizationId: thread.agent.organization.id, memberId: thread.agent.id } }" class="font-medium">
                                {{ thread.agent.name }}
                            </router-link>
                        </div>
                        <div>
                            <span class="px-1">&middot;</span>
                            <span>Started on </span>
                            <span class="font-medium">{{ $dateTime(thread.createdAt) }}</span>
                        </div>
                        <div v-if="thread.resolvedAt">
                            <span class="px-1">&middot;</span>
                            <span>Resolved at </span>
                            <span class="font-medium">{{ $dateTime(thread.resolvedAt) }}</span>
                        </div>
                    </div>
                </template>
                <template v-slot:actions>
                    <ui-button v-if="! thread?.resolvedAt" icon="check" color="blue" :action="markThreadAsResolvedWrapper">
                        Resolve Thread
                    </ui-button>
                    <ui-button v-else icon="check" color="blue" :action="markThreadAsUnresolved">
                        Unresolve Thread
                    </ui-button>

                    <ui-button v-if="! thread?.agent" icon="user" color="blue" :action="assignThreadToSelf">
                        Assign to me
                    </ui-button>

                    <a href="#" @click.prevent="openThreadInfoModal" class="inline-flex items-center justify-center text-blue-500 hover:text-blue-800 focus:outline-none focus:text-blue-800 rounded-full border border-blue-400 hover:border-blue-800 focus:border-blue-800 transition w-8 h-8 text-xl">
                        <ui-icon name="settings"></ui-icon>
                    </a>
                </template>
            </ui-header>

            <div class="mx-auto px-32 space-y-2 pb-6">
                <message v-for="message, i in thread.messages" :key="message.id" @mouseover="hover(message)" :previous-message="thread.messages[i - 1]" :message="message"></message>

                <div v-if="sendingMessage" class="flex justify-end mr-64">
                    <div class="px-3 py-2.5 rounded-md shadow-sm cursor-pointer text-white bg-blue-300 rounded-br-none">
                        <ui-spinner type="spiral" color="#d6dde0"></ui-spinner>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasNewMessages && ! isScrolledToBottom" @click="scrollToBottom" class="sticky bottom-32 flex items-center mx-auto px-3 py-1.5 rounded-md bg-blue-500 hover:bg-blue-600 text-white shadow-sm cursor-pointer">
            <span>New messages</span>
            <ui-icon name="chevron-down" class="w-4 h-4 ml-1"></ui-icon>
        </div>

        <div class="sticky bottom-0 p-2 flex items-center bg-white shadow-sm border-t border-l border-gray-200 w-full">
            <textarea
                rows="3"
                :disabled="sendingMessage || thread?.resolvedAt"
                v-model="newMessage"
                @keydown.enter.shift.exact.prevent="sendMessageWrapper"
                @keydown.enter.alt.exact.prevent="sendMessageWrapper"
                class="flex-1 rounded-md disabled:bg-gray-100 border border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            ></textarea>
            <div class="flex flex-col items-center mx-1.5">
                <ui-button :action="sendMessageWrapper" :disabled="sendingMessage || thread?.resolvedAt" color="blue">Send</ui-button>
                <p class="text-sm text-gray-700 mt-3">Shift/Alt + Enter to send</p>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <thread-info></thread-info>
        <message-reminder-create></message-reminder-create>
    </Teleport>
</template>

<script>
import Message from '@/admin/components/support/message'
import ThreadInfo from '@/admin/components/support/modals/thread-info'
import MessageReminderCreate from '@/admin/components/support/modals/message-reminder-create'

import useThreadStore from '@/admin/stores/support/thread'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    data() {
        return {
            initialScrollCompleted: false,
            isScrolledToBottom: false,
            hasNewMessages: false,
        }
    },

    components: { Message, ThreadInfo, MessageReminderCreate },

    computed: {
        ...mapState(useThreadStore, [
            'thread'
        ]),

        ...mapWritableState(useThreadStore, [
            'newMessage',
            'activeMessage',
            'sendingMessage',
        ])
    },

    methods: {
        handleScroll(e) {
            this.isScrolledToBottom = e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight - 200

            if (this.isScrolledToBottom) {
                this.hasNewMessages = false
            }
        },

        scrollToBottom() {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight + this.$refs.messages.clientHeight

            this.hasNewMessages = false
        },

        async markThreadAsResolvedWrapper() {
            this.markThreadAsResolved().then(() => {
                this.$router.push({ name: 'support.threads' })
            })
        },

        async sendMessageWrapper() {
            this.sendMessage().then(() => {
                this.scrollToBottom()
            })
        },

        hover(message) {
            this.activeMessage = message.id
        },

        ...mapActions(useThreadStore, [
            'sendMessage',
            'assignThreadToSelf',
            'openThreadInfoModal',
            'markThreadAsResolved',
            'markThreadAsUnresolved'
        ])
    },

    watch: {
        'thread.messages.length': {
            deep: true,
            handler(newValue, oldValue) {
                this.$nextTick(() => {
                    if (! this.initialScrollCompleted) {
                        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
                    }

                    if (newValue > oldValue && ! this.isScrolledToBottom && this.initialScrollCompleted) {
                        this.hasNewMessages = true
                    }

                    this.initialScrollCompleted = true
                })
            }
        }
    }
}
</script>
