import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'
import useOrganizationStore from '@/admin/stores/accounts/organization'
import useWorkspaceStore from '@/admin/stores/accounts/workspace'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsWorkspaceMemberCreateStore = defineStore({
    id: 'modalsWorkspaceMemberCreate',

    actions: {
        open() {
            useMemberSettingsFormStore().$reset()
            useMemberSettingsFormStore().organizationId = useOrganizationStore().organization.id
            useMemberSettingsFormStore().workspaceId = useWorkspaceStore().workspace.id

            useModal().show('workspace-member-create')
        },

        cancel() {
            useModal().hide('workspace-member-create')
        }
    }
})

export default useModalsWorkspaceMemberCreateStore
