import useContentIndexStreamStore from '@/stores/content/index-stream'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyCuratedTopicsStore from '@/stores/me/curated-topics'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useTaggingStore from '@/stores/tagging/tagging'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { defineStore } from 'pinia'

export const useContentIndexPage = defineStore({
    id: 'contentIndexPage',

    getters: {
        title: () => 'Content',

        streamStore() {
            return useContentIndexStreamStore()
        },

        perspective() {
            return useMyPerspectivesStore().new()
        }
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            this.streamStore.initialize()
            this.streamStore.clear()
            this.streamStore.removeAllFilters()

            useMyBookmarksStore().initialize()
            useMyCuratedTopicsStore().initialize()
            useMyPerspectivesStore().initialize()
            useMySearchTopicsStore().initialize()
            useTaggingStore().initialize()
            useMyTargetListsStore().initialize()
        }
    }
})

export default useContentIndexPage
