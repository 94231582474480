<template>
    <div>
        <ui-header :title="$page.type.name || 'Library'" :icon="$page.type.icon">
            <template v-slot:subsection>
                <stream-toolbar :store="$page.streamStore"></stream-toolbar>
            </template>
        </ui-header>

        <div class="pb-8 px-8 max-w-11xl mx-auto">
            <ui-stream :store="$page.streamStore" key="featuresMediaStream" v-if="[ 'image-cluster', 'video-cluster' ].includes($page.type.type)">
            </ui-stream>

            <ui-stream :store="$page.streamStore" key="featuresStream" v-else>
                <template v-slot:item="{ item, layout }">
                    <feature-card :feature="item" v-if="layout.id == 'grid'"></feature-card>
                    <feature-row :feature="item" v-if="layout.id == 'table'"></feature-row>
                </template>

                <template v-slot:placeholder="{ layout }">
                    <feature-placeholder-card v-if="layout.id == 'grid'"></feature-placeholder-card>
                    <feature-placeholder-row v-if="layout.id == 'table'"></feature-placeholder-row>
                </template>
            </ui-stream>
        </div>
    </div>
</template>

<script>
import FeatureCard from './stream-cards/feature'
import FeaturePlaceholderCard from './stream-cards/feature-placeholder'
import FeaturePlaceholderRow from './stream-cards/feature-placeholder-row'
import FeatureRow from './stream-cards/feature-row'
import StreamToolbar from '@/components/ui/stream/toolbar/toolbar'
import UiStream from '@/components/ui/stream/stream'

export default {
    components: {
        FeatureCard, FeaturePlaceholderCard, FeaturePlaceholderRow, FeatureRow, StreamToolbar, UiStream
    }
}
</script>