import { useModal } from '@/helpers'
import {defineStore} from 'pinia'

import useMyAnalysesStore from '@/stores/me/analyses'

export const useModalsAddAnalysisStore = defineStore({
    id: 'modalsAddAnalysis',

    state: () => ({
        search: '',
        selectedAnalysis: null,
    }),

    getters: {
        analyses(state) {
            let myAnalyses = useMyAnalysesStore().items

            myAnalyses = myAnalyses.filter(a => [
                'content-breakdown-by-group',
                'content-breakdown-by-source',
                'content-keywords',
                'content-performance',
                'content-publish-times',
                'content-pull-push',
                'content-sentiment',
                'content-top-platforms',
                'target-audience',
                'target-publish-times'
            ].includes(a.type) )

            return state.search
                ? myAnalyses.filter(a => a.name.toLowerCase().includes(state.search.toLowerCase()))
                : myAnalyses
        }
    },

    actions: {
        open() {
            this.$reset()

            useModal().show('add-analysis')
        },

        close() {
            useModal().hide('add-analysis')
        },

        setAnalysis(analysis) {
            this.selectedAnalysis = analysis
        },

        addAnalysis(dashboardStore) {
            let widget = dashboardStore.addWidget(dashboardStore.widgetTypes.find(t => t.id === `analysis-${this.selectedAnalysis.type}`), false)

            widget.analysisId = this.selectedAnalysis.id
            widget.initialize()

            this.close()
        }
    }
})

export default useModalsAddAnalysisStore
