import api from '@/admin/api'

import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'
import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'

import { defineStore } from 'pinia'

export const useMembersStore = defineStore({
    id: 'members',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    actions: {
        async initialize(organizationId, force = false) {
            return this.load(organizationId, force)
        },

        async load(organizationId, force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('admin accounts organizations members', { organizationId: organizationId }).get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null

                useMemberSettingsFormStore().workspaceId = null
            })
        },

        async loadDetails(organizationId, id) {
            return api.route('admin accounts organizations members details', { organizationId: organizationId, workspaceId: id }).get().json(res => {
                Object.assign(this.find(res.data.id), res.data)
            })
        },

        async all(organizationId) {
            await this.load(organizationId)
            return this.items
        },

        find(id) {
            return this.items.find(i => i.id == id)
        },

        async disable(member, force = false) {
            api.route('admin accounts users disable', { userId: member.id })
                .formData({ force: force ? 1 : 0 })
                .post()
                .res()
                .then(() => {
                    this.load(member.organization.id, true)
                })
        },

        async enable(member, force = false) {
            api.route('admin accounts users enable', { userId: member.id })
                .formData({ force: force ? 1 : 0 })
                .post()
                .res()
                .then(() => {
                    this.load(member.organization.id, true)
                })
        },

        async delete(member, force = false) {
            await useDeleteConfirmationModal().open('Member')
                .then(async () => {
                    await api.route('admin accounts users delete', { userId: member.id })
                        .formData({ _method: 'delete', force: force ? 1 : 0 })
                        .post()
                        .res()
                })

            return this.load(member.organization.id, true)
        }
    }
})

export default useMembersStore
