export default () => ({
    table: 'features',
    perPage: 50,

    resultsToolbar: { tags: false },

    supportsPerspectives: false,
    supportsSearchTopics: false,

    layoutOptions: [
        { name: 'Grid', id: 'grid', icon: 'view-grid', available: true },
        { name: 'Table', id: 'table', icon: 'menu', available: true }
    ],

    sortingOptions: [
        { name: 'Name', id: 'name', icon: 'text-case', value: i => ({ value: i.title, id: i.id }), default: true }
    ],

    filters: [
        { name: 'Name', id: 'name', type: 'search-query', hidden: true, search: true },
        { name: 'Type', id: 'type', type: 'text', hidden: true }
    ]
})
