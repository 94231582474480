<template>
    <div class="rounded bg-white shadow w-full divide-y divide-gray-200">
        <template v-if="store.source.isLoading">
            <template v-for="v, i in Array.from({ length: rows || 25 })">
                <slot name="placeholder" :index="i" :cards-store="store"></slot>
            </template>
        </template>

        <template v-else-if="store.source.isInitialized">
            <template v-for="item in items">
                <slot name="item" :item="item" :cards-store="store"></slot>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    computed: {
        items() {
            if (this.rows) {
                return this.store.items.slice(0, this.rows)
            }

            return this.store.items
        },

        rows() {
            return this.store.layout?.settings?.rows
        }
    }
}
</script>
