<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-web-feed"
        avatar-badge-tooltip="Web Feed"
        :parent-component="{ 'web-article': _.type }"
    >
        <template v-slot:details>
            <slot name="details"></slot>
        </template>
    </content-card>
</template>

<script>
import ContentCard from './content'

export default {
    components: { ContentCard },

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
