import useMyAnalysesStore from '@/stores/me/analyses'
import useMyDashboardsStore from '@/stores/me/dashboards'

import { defineStore } from 'pinia'

export const useDashboardsManagePage = defineStore({
    id: 'dashboardsManagePage',

    state: () => ({
        type: '',
        search: ''
    }),

    getters: {
        title: () => 'Dashboards'
    },

    actions: {
        beforeEnter(to) {
            this.$reset()

            useMyAnalysesStore().initialize()
            useMyDashboardsStore().initialize()

            this.type = to.params.type
        }
    }
})

export default useDashboardsManagePage
