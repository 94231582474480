<template>
    <ui-modal name="analyses-content-performance-series" :title="series ? series.label : 'Add series'" @opened="opened" @closed="cancel">
        <ui-form :store="analysisModalsContentPerformanceSeriesStore" v-slot="{ inputs, submitting, errors }">
            <div class="mb-6">
                <label class="block text-sm font-semibold text-gray-700 mb-1">
                    Label
                </label>
                <div class="relative">
                    <ui-input v-model="inputs.label" type="text"></ui-input>

                    <div class="absolute flex items-center right-1 inset-y-0">
                        <ui-dropdown width="w-auto" align="right">
                            <template v-slot:trigger>
                                <div class="w-8 h-8 p-0.5">
                                    <div class="w-full h-full rounded-md" :style="`background: ${inputs.color}`"></div>
                                </div>
                            </template>>

                            <template v-slot:content>
                                <div class="py-1">
                                    <div class="flex flex-wrap justify-center w-36">
                                        <div class="w-8 h-8 p-0.5" @click="inputs.color = color" :key="index" v-for="color, index in safeColors">
                                            <div class="w-full h-full rounded-md" :style="`background: ${color}`"></div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </ui-dropdown>
                    </div>
                </div>
            </div>

            <label class="block text-sm font-semibold text-gray-700 mb-1">
                Data-set
            </label>
            <div>
                <div v-if="inputs.datasetId">
                    <ui-async :guards="datasetList || datasetPerspective || datasetTopic">
                        <component :is="datasetComponent" :cards-store="analysisModalsContentPerformanceSeriesCardsStore" :list="datasetList" :perspective="datasetPerspective" :topic="datasetTopic" class="bg-white rounded-md border border-gray-200">
                        </component>
                    </ui-async>
                </div>

                <div class="rounded-sm bg-white shadow hover:shadow-lg cursor-pointer border border-gray-200 flex items-center justify-between" v-else>
                    <div class="flex items-center py-4 pl-6">
                        <ui-avatar type="perspective" name="?" class="w-9 h-9 drop-shadow-sm shrink-0"></ui-avatar>

                        <div class="ml-3">
                            <h1 class="text-xl font-semibold truncate leading-tight">
                                No data-set selected
                            </h1>
                            <div class="text-gray-800 text-xs leading-tight flex items-center mt-0.5">
                                Please choose a data-set
                            </div>
                        </div>
                    </div>
                    <div class="py-4 pl-4 pr-6">
                        <ui-button light color="blue" :action="changeDataset">Change</ui-button>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <label class="block text-sm font-semibold text-gray-700 mb-1">
                    Metric
                </label>
                <div>
                    <div class="border border-blue-200 divide-y divide-blue-200 text-gray-800 rounded w-full">
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer rounded-t" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.aggregator == 'count' }" @click.prevent="inputs.meta.aggregator = 'count'; inputs.meta.metric = 'all';">
                            <ui-icon name="hash" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Published Content</div>
                                <div class="text-xs text-gray-700 tracking-tight" :class="{ 'text-blue-500': inputs.meta.aggregator == 'count' }">Absolute count of the content published in the data set.</div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.aggregator == 'prevalence' }" @click.prevent="inputs.meta.aggregator = 'prevalence'; inputs.meta.metric = 'all';">
                            <ui-icon name="flag" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Published Content Prevalence</div>
                                <div class="text-xs text-gray-700 tracking-tight" :class="{ 'text-blue-500': inputs.meta.aggregator == 'prevalence' }">Count of the content published in the data set relative to all published content.</div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.metric == 'interactions' }" @click.prevent="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'interactions';">
                            <ui-icon name="zap" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Interactions</div>
                                <div class="text-xs text-gray-700 tracking-tight" :class="{ 'text-blue-500': inputs.meta.metric == 'interactions' }">Sum or average of interactions (reactions, comments and shares) in the data set.</div>
                            </div>
                            <div class="flex items-center ml-auto text-sm">
                                <div class="rounded-l bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'summary' && inputs.meta.metric == 'interactions' }" @click.stop="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'interactions';">
                                    Sum
                                </div>
                                <div class="rounded-r bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'average' && inputs.meta.metric == 'interactions' }" @click.stop="inputs.meta.aggregator = 'average'; inputs.meta.metric = 'interactions';">
                                    Average
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.metric == 'reactions' }" @click.prevent="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'reactions';">
                            <ui-icon name="thumbs-up" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Reactions</div>
                            </div>
                            <div class="flex items-center ml-auto text-sm">
                                <div class="rounded-l bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'summary' && inputs.meta.metric == 'reactions' }" @click.stop="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'reactions';">
                                    Sum
                                </div>
                                <div class="rounded-r bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'average' && inputs.meta.metric == 'reactions' }" @click.stop="inputs.meta.aggregator = 'average'; inputs.meta.metric = 'reactions';">
                                    Average
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.metric == 'comments' }" @click.prevent="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'comments';">
                            <ui-icon name="comment" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Comments</div>
                            </div>
                            <div class="flex items-center ml-auto text-sm">
                                <div class="rounded-l bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'summary' && inputs.meta.metric == 'comments' }" @click.stop="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'comments';">
                                    Sum
                                </div>
                                <div class="rounded-r bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'average' && inputs.meta.metric == 'comments' }" @click.stop="inputs.meta.aggregator = 'average'; inputs.meta.metric = 'comments';">
                                    Average
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer rounded-b" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.metric == 'shares' }" @click.prevent="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'shares';">
                            <ui-icon name="share" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Shares</div>
                            </div>
                            <div class="flex items-center ml-auto text-sm">
                                <div class="rounded-l bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'summary' && inputs.meta.metric == 'shares' }" @click.stop="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'shares';">
                                    Sum
                                </div>
                                <div class="rounded-r bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'average' && inputs.meta.metric == 'shares' }" @click.stop="inputs.meta.aggregator = 'average'; inputs.meta.metric = 'shares';">
                                    Average
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center w-full px-4 py-2 cursor-pointer rounded-b" :class="{ 'bg-blue-50 text-blue-600': inputs.meta.metric == 'views' }" @click.prevent="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'views';">
                            <ui-icon name="eye" class="shrink-0 text-xl"></ui-icon>
                            <div class="px-3">
                                <div class="font-medium">Views</div>
                            </div>
                            <div class="flex items-center ml-auto text-sm">
                                <div class="rounded-l bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'summary' && inputs.meta.metric == 'views' }" @click.stop="inputs.meta.aggregator = 'summary'; inputs.meta.metric = 'views';">
                                    Sum
                                </div>
                                <div class="rounded-r bg-gray-50 p-1 px-2" :class="{ 'bg-blue-600 text-white': inputs.meta.aggregator == 'average' && inputs.meta.metric == 'views' }" @click.stop="inputs.meta.aggregator = 'average'; inputs.meta.metric = 'views';">
                                    Average
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-center mt-8">
                <ui-button @click="deleteSeries" color="red" v-if="series">
                    Delete
                </ui-button>

                <ui-button @click="cancel" class="ml-auto">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="blue" class="ml-3" :disabled="! isValid">
                    Save
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import ListCard from '@/components/targets/stream-cards/list'
import PerspectiveCard from '@/components/content/stream-cards/perspective'
import TopicCard from '@/components/content/stream-cards/topic'

import useAnalysisModalsContentPerformanceSeriesStore from '@/stores/analysis/modals/content-performance/series'
import useAnalysisModalsContentPerformanceSeriesCardsStore from '@/stores/analysis/modals/content-performance/series-cards'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMySearchTopicsStore from '@/stores/me/search-topics'
import useMyTargetListsStore from '@/stores/me/target-lists'

import { mapActions, mapState, mapStores } from 'pinia'
import safeColors from '@/helpers/safe-colors'

export default {
    components: {
        ListCard, PerspectiveCard, TopicCard
    },

    mixins: [ safeColors ],

    computed: {
        ...mapStores(useAnalysisModalsContentPerformanceSeriesStore, useAnalysisModalsContentPerformanceSeriesCardsStore),
        ...mapState(useAnalysisModalsContentPerformanceSeriesStore, [ 'isValid' ]),

        analysisStore() {
            return this.analysisModalsContentPerformanceSeriesStore.inputs.analysisStore
        },

        series() {
            return this.analysisModalsContentPerformanceSeriesStore.inputs.series
        },

        datasetComponent() {
            return {
                'perspective': PerspectiveCard,
                'search-topic': TopicCard,
                'target-list': ListCard
            }[this.analysisModalsContentPerformanceSeriesStore.inputs.datasetType]
        },

        datasetList() {
            return this.analysisModalsContentPerformanceSeriesStore.inputs.datasetType == 'target-list'
                ? useMyTargetListsStore().find(this.analysisModalsContentPerformanceSeriesStore.inputs.datasetId) : null
        },

        datasetPerspective() {
            return this.analysisModalsContentPerformanceSeriesStore.inputs.datasetType == 'perspective'
                ? useMyPerspectivesStore().find(this.analysisModalsContentPerformanceSeriesStore.inputs.datasetId) : null
        },

        datasetTopic() {
            return this.analysisModalsContentPerformanceSeriesStore.inputs.datasetType == 'search-topic'
                ? useMySearchTopicsStore().find(this.analysisModalsContentPerformanceSeriesStore.inputs.datasetId) : null
        },

        nextColor() {
            return this.safeColors.filter(color => {
                return ! this.analysisStore.series.find(series => {
                    return series.color === color
                })
            })[0]
        }
    },

    methods: {
        ...mapActions(useAnalysisModalsContentPerformanceSeriesStore, {
            cancel: 'cancel', changeDataset: 'changeDataset', deleteSeries: 'delete'
        }),

        opened() {
            if (! this.series) {
                this.analysisModalsContentPerformanceSeriesStore.inputs.color = this.nextColor
            }
        }
    }
}
</script>
