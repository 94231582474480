<template>
    <div class="bg-gray-50 rounded-lg flex items-center cursor-default shrink-0">
        <ui-dropdown align="right">
            <template v-slot:trigger>
                <div class="px-5 py-3 group">
                    <div class="text-blue-700 group-hover:text-blue-800 group-hover:font-medium">
                        {{store.sumLists ? 'As Single Series' : 'As Multiple Series'}}
                        <ui-icon name="select" class="ml-1"></ui-icon>
                    </div>
                    <div class="text-xs text-gray-800 font-semibold">Lists Display</div>
                </div>
            </template>
            <template v-slot:content>
                <ui-dropdown-link @click="store.setSumLists(true)">As Single Series</ui-dropdown-link>
                <ui-dropdown-link @click="store.setSumLists(false)">As Multiple Series</ui-dropdown-link>
            </template>
        </ui-dropdown>
    </div>
</template>
