import useFeaturesFeaturePage from '@/pages/features-feature'
import useFeaturesFeatureStreamStore from '@/stores/features/feature/stream'

import { defineStore, mapState } from 'pinia'

export const useFeaturesFeatureStreamPage = defineStore({
    id: 'featuresFeatureStreamPage',

    getters: {
        ...mapState(useFeaturesFeaturePage, [ 'type', 'feature', 'model' ])
    },

    actions: {
        afterEnter(to) {
            let featuresDetailsStream = useFeaturesFeatureStreamStore()

            let forcedFilters = { 'mentions': [ {
                type: featuresDetailsStream.mentionFilterTypes.find(t => t.id == this.feature.type),
                id: this.feature.id,
                name: this.feature.title,
                exclude: false
            } ] }

            featuresDetailsStream.initialize()
            featuresDetailsStream.navigatedTo(to, forcedFilters)

            featuresDetailsStream.searchContext = this.feature.title
        }
    }
})

export default useFeaturesFeatureStreamPage
