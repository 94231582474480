<template>
    <ui-header :title="report.name || 'Dashboard'"
        :avatar="report.avatarUrl"
               :back="{ name: 'dashboards', params: { type: 'reports' } }">
        <template v-slot:actions>
            <ui-button color="blue" icon="file-text" v-if="! isEditing" :action="startExportingReport">
                Export
            </ui-button>

            <ui-button color="blue" icon="check-square" v-if="isEditing" :action="save">
                Save
            </ui-button>

            <ui-button color="blue" @click.native.prevent v-if="! isEditing && isBeingSaved">
                <ui-spinner color="white" type="clip" class="text-lg mr-1"></ui-spinner>
                Saving...
            </ui-button>

            <ui-button color="blue" icon="edit" v-if="! isEditing" :action="edit">
                Edit
            </ui-button>
        </template>
    </ui-header>
</template>

<script>
import useReportStore from '@/stores/dashboards/report'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useReportStore, [ 'report', 'isBeingSaved', 'isEditing' ])
    },

    methods: {
        ...mapActions(useReportStore, [ 'edit', 'save', 'startExportingReport' ])
    }
}
</script>
