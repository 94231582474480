import useTargetsBookmarksPage from '@/pages/targets-bookmarks'
import useMyBookmarksStore from '@/stores/me/bookmarks'

import defineCards from '@/stores/reusable/cards'

import { textSearch } from '@/helpers'

export const useTargetsBookmarksCardsStore = defineCards({
    id: 'targetsBookmarksCards',

    source: useMyBookmarksStore(),
    filter: items => textSearch(useTargetsBookmarksPage().search, items, i => i.name),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: 'desc',
            apply: items => items.sort((a, b) => b.title.localeCompare(a.title))
        }
    ]
})

export default useTargetsBookmarksCardsStore
