import useMyStore from '@/stores/me/my'

import api from '@/api'

import debounce from 'just-debounce-it'
import { defineStore } from 'pinia'

export const useMyQuickSearchStore = defineStore({
    id: 'myQuickSearch',

    state: () => ({
        isShown: false,
        inMaintenance: false,

        search: '',

        results: [],
        resultsPending: false,
        resultsPromise: null,

        families: [],
        limit: 3,
        perspectiveStarters: true,
        onSelect: null,
        onHide: null,
        initialResults: []
    }),

    actions: {
        initialize() {
        },

        show(options) {
            this.$reset()

            this.isShown = true

            this.families = options?.families || []
            this.limit = options?.limit || 3
            this.perspectiveStarters = options?.perspectiveStarters ?? true
            this.onSelect = options?.onSelect
            this.onHide = options?.onHide
            this.results = this.initialResults = options?.initialResults || []
        },

        hide() {
            if (this.onHide) this.onHide()

            this.isShown = false
        },

        loadDebounced: debounce(function () { this.load() }, 100),

        async load() {
            if (this.search.length == 0) this.results = this.initialResults
            if (this.search.length < 3) return

            if (this.resultsPromise) {
                this.abortGeneralLoad?.abort()
                this.abortWorkspaceLoad?.abort()
            }

            this.resultsPending = true
            this.inMaintenance = false

            let generalSearch = Promise.resolve()
            let workspaceSearch = Promise.resolve()

            if (this.shouldSearch([ 'app', 'targets', 'features' ])) {
                generalSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortGeneralLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchGeneral })
                    .options({ credentials: 'same-origin' })
                    .json({
                        searches: [
                            this.shouldSearch('app') ? {
                                collection: 'app',
                                q: this.search,
                                query_by: 'title',
                                per_page: this.limit
                            } : null,
                            this.shouldSearch('targets') ? {
                                collection: 'targets',
                                q: this.search,
                                query_by: 'title,username',
                                sort_by: 'followers:desc,_text_match:desc',
                                group_by: 'type',
                                group_limit: this.limit,
                                per_page: Math.min(this.limit * 10, 250)
                            } : null,
                            this.shouldSearch('features') ? {
                                collection: 'features',
                                q: this.search,
                                query_by: 'title',
                                group_by: 'type',
                                group_limit: this.limit,
                                per_page: Math.min(this.limit * 10, 250)
                            } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            if (this.shouldSearch([ 'analyses', 'dashboards', 'perspectives', 'searchTopics', 'targetLists' ])) {
                workspaceSearch = api.url(`${process.env.VUE_APP_QUICK_SEARCH_URL}/multi_search`, true)
                    .signal(this.abortWorkspaceLoad = new AbortController())
                    .headers({ 'X-TYPESENSE-API-KEY': useMyStore().currentWorkspace.keys.quickSearchWorkspace })
                    .options({ credentials: 'same-origin' })
                    .query({ 'query_by': 'title' })
                    .json({
                        searches: [
                            this.shouldSearch('analyses') ? { collection: 'analyses', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('dashboards') ? { collection: 'dashboards', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('perspectives') ? { collection: 'perspectives', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('searchTopics') ? { collection: 'searchTopics', q: this.search, per_page: this.limit } : null,
                            this.shouldSearch('targetLists') ? { collection: 'targetLists', q: this.search, per_page: this.limit } : null
                        ].filter(v => v)
                    })
                    .post()
                    .error(503, () => this.inMaintenance = true)
                    .json()
            }

            return this.resultsPromise = Promise.all([ generalSearch, workspaceSearch ])
                .then(([ generalData, workspaceData ]) => {
                    if (this.inMaintenance) return

                    this.results = [
                        ...(! this.families.length ? [{ resultType: 'perspective-new' }] : []),
                        ...this.familyResults(workspaceData, 'analyses').hits.map(result => ({ ...result.document, resultType: 'analysis' })),
                        ...this.familyResults(generalData, 'app').hits.map(result => ({ ...result.document, resultType: 'app' })),
                        ...this.familyResults(workspaceData, 'dashboards').hits.map(result => ({ ...result.document, resultType: 'dashboard' })),
                        ...this.familyResults(workspaceData, 'perspectives').hits.map(result => ({ ...result.document, resultType: 'perspective' })),
                        ...(this.perspectiveStarters ? this.familyResults(workspaceData, 'targetLists').hits.map(result => ({ ...result.document, resultType: 'perspective-target-list' })) : []),
                        ...(this.perspectiveStarters ? this.familyResults(workspaceData, 'searchTopics').hits.map(result => ({ ...result.document, resultType: 'perspective-search-topic' })) : []),
                        ...this.familyResults(workspaceData, 'targetLists').hits.map(result => ({ ...result.document, resultType: 'target-list' })),
                        ...this.familyResults(generalData, 'targets').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'target' }))),
                        ...this.familyResults(generalData, 'features').grouped_hits.flatMap(group => group.hits.map(result => ({ ...result.document, resultType: 'feature' })))
                    ]

                    this.resultsPending = false
                    this.resultsPromise = null
                    this.abortGeneralLoad = this.abortWorkspaceLoad = null
                })
        },

        clear() {
            this.$reset()
        },

        shouldSearch(family) {
            if (family instanceof Array) return family.some(f => this.shouldSearch(f))
            return ! this.families.length || this.families.includes(family)
        },

        familyResults(data, family) {
            if (! data) return { hits: [], grouped_hits: [] }
            return data.results.find(r => r.request_params.collection_name == family) || { hits: [], grouped_hits: [] }
        }
    }
})

export default useMyQuickSearchStore
