import useSettingsAccountSocialLogin from '@/stores/settings/account-social-login'

import { defineStore } from 'pinia'

export const useSettingsAccountLoginSocialPage = defineStore({
    id: 'settingsAccountLoginSocialPage',

    actions: {
        beforeEnter(to) {
            useSettingsAccountSocialLogin().finishConnecting(to)
        }
    }
})

export default useSettingsAccountLoginSocialPage
