<template>
    <tr class="border-b border-gray-200 last:border-none">
        <td class="py-4 px-6">
            <div class="flex items-center">
                <ui-avatar type="curated-list" class="text-4xl text-gray-100"></ui-avatar>
                <div class="ml-3">
                    <div class="bg-gray-100 rounded-full h-5 w-32"></div>
                    <div class="bg-gray-100 rounded-full h-3 w-24 mt-0.5"></div>
                </div>
            </div>
        </td>
        <td class="py-4 px-6">
            <div class="bg-gray-100 rounded-full h-4 w-48"></div>
        </td>
        <td class="py-4 px-6 w-32">
        </td>
    </tr>
</template>
