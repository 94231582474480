<template>
    <div>
        <div class="bg-white w-full shadow rounded-sm mb-16 flex flex-col items-center justify-center text-center text-gray-900 p-10 max-w-4xl mx-auto" v-if="! store.series.length">
            <ui-icon name="navigation.analysis" class="text-6xl text-blue-600"></ui-icon>
            <h1 class="text-2xl font-semibold my-6">Welcome to the "Content Pull/Push" analysis!</h1>
            <p class="text-lg my-1">This analysis <span class="font-medium">visualizes the interaction between prevalence and interactions</span> of content in the dataset.</p>
            <div class="flex space-x-10 my-10">
                <div class="flex-1">
                    <h2 class="font-semibold">Data-set</h2>
                    <p class="text-justify my-2">Select a Perspective, Topic or Target List as a data-set, or define a new perspective inline.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Analysis Styles</h2>
                    <p class="text-justify my-2">Customize how the analysis looks by choosing form multiple analysis types like line or bar analysis and other options.</p>
                </div>
                <div class="flex-1">
                    <h2 class="font-semibold">Range & Grouping</h2>
                    <p class="text-justify my-2">Use the range & grouping controls to specify the shown date range and granularity. You can also drag select a part of the analysis to zoom in.</p>
                </div>
            </div>
            <p class="text-lg my-1">
                To start let's <span class="font-medium">add some data</span> to the analysis by clicking on the Select Data-set button.
            </p>
            <div class="flex items-center mt-8">
                <ui-button color="blue" icon="plus-square" size="lg" :action="() => store.changeDataset()">
                    Select Data-set
                </ui-button>
            </div>
        </div>

        <div v-else>
            <analysis-toolbar :store="store">
                <template v-slot:settings>
                    <settings-dataset :store="store"></settings-dataset>
                </template>
            </analysis-toolbar>

            <div class="px-4 py-8 h-128 bg-white rounded-sm shadow relative">
                <div class="absolute top-4 right-4 z-20">
                    <chart-styles-contextual-menu :store="store"></chart-styles-contextual-menu>
                </div>

                <ui-analysis :store="store" ref="analysis"></ui-analysis>
            </div>
        </div>
    </div>
</template>

<script>
import AnalysisToolbar from './toolbar/toolbar'
import ChartStylesContextualMenu from '@/components/analysis/contextual-menus/chart-styles'
import SettingsDataset from './settings/dataset'

export default {
    components: {
        AnalysisToolbar, ChartStylesContextualMenu, SettingsDataset
    },

    watch: {
        'store.series.length'() {
            this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
        }
    },

    mounted() {
        this.$nextTick(() => this.store.setAnalysisRef(this.$refs.analysis))
    }
}
</script>
