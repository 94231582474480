import useListSuggestionsStore from '@/stores/targets/list-suggestions'
import useMyTargetListsStore from '@/stores/me/target-lists'
import useQuickSearchStore from '@/stores/me/quick-search'
import useTargetsMyListsListPage from '@/pages/targets-my-lists-list'

import api from '@/api'
import { trackEvent } from '@/analytics'

import { defineStore } from 'pinia'

export const useTargetsModalsListAddTargetStore = defineStore({
    id: 'targetsModalsListAddTarget',

    state: () => ({
        list: {}
    }),

    actions: {
        open(list) {
            this.$reset()

            this.list = list

            useQuickSearchStore().show({
                families: [ 'targets' ],
                limit: 50,
                onSelect: result => {
                    this.add(result)
                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...useListSuggestionsStore().suggestedSources.map(item => ({
                        id: item.family == 'groups' ? `G${item.id}` : `S${item.id}`,
                        monitorId: item.id,
                        resultType: 'target',
                        type: item.type,
                        title: item.title,
                        image: item.avatarUrl,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    })),
                    ...useListSuggestionsStore().suggestedGroups.map(item => ({
                        id: item.family == 'groups' ? `G${item.id}` : `S${item.id}`,
                        monitorId: item.id,
                        resultType: 'target',
                        type: item.type,
                        title: item.title,
                        image: item.avatarUrl,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    }))
                ]
            })
        },

        add(result) {
            trackEvent('targets', 'target-added-to-target-list', result.type)

            let route = result.id[0] == 'G' ? 'me lists groups store' : 'me lists sources store'

            api.route(route, [ this.list.id, result.id.substring(1) ]).post().res(() => {
                useMyTargetListsStore().reload()
                useTargetsMyListsListPage().streamStore.load()
                useListSuggestionsStore().load(this.list)
            })
        },
    }
})

export default useTargetsModalsListAddTargetStore
