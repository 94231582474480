<template>
    <ui-async :guards="license">
        <div class="pb-6 px-8 max-w-11xl mx-auto">
            <div v-if="licenseSettingsFormStore.submitted" class="border border-green-300 bg-green-50 text-green-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
                License successfully updated.
            </div>

            <ui-form :store="licenseSettingsFormStore" v-slot="{ inputs, submitting, errors }" class="bg-white shadow rounded-sm">
                <div class="px-4 py-5 sm:p-8 space-y-8 divide-y divide-gray-300">
                    <div class="md:grid md:grid-cols-3 md:gap-6">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Details</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change the basic license information
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="name" class="block text-sm font-medium text-gray-700">
                                        Name <span class="text-red-500">*</span>
                                    </label>
                                    <div class="mt-1">
                                        <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                                    </div>
                                </div>

                                <div class="grid grid-cols-2 space-x-3">
                                    <div>
                                        <label for="validFrom" class="block text-sm font-medium text-gray-700">
                                            Valid from <span class="text-red-500">*</span>
                                        </label>
                                        <div class="mt-1">
                                            <ui-dropdown width="w-auto" keepOpen>
                                                <template v-slot:trigger>
                                                    <ui-input type="text" name="validFrom" id="validFrom" disabled v-model="validFrom" :errors="errors.validFrom"></ui-input>
                                                    <p class="mt-0.5 text-xs text-gray-700">(from 00:00)</p>
                                                </template>

                                                <template v-slot:content="{ close }">
                                                    <div v-click-away="close">
                                                        <ui-date-picker :show-second="false" type="date" v-model="inputs.validFrom"></ui-date-picker>
                                                    </div>
                                                </template>
                                            </ui-dropdown>
                                        </div>
                                    </div>
                                    <div>
                                        <label for="validTo" class="block text-sm font-medium text-gray-700">
                                            Valid to <span class="text-red-500">*</span>
                                        </label>
                                        <div class="mt-1">
                                            <ui-dropdown width="w-64" keepOpen>
                                                <template v-slot:trigger>
                                                    <ui-input type="text" name="validTo" id="validTo" disabled v-model="validTo" :errors="errors.validTo"></ui-input>
                                                    <p class="mt-0.5 text-xs text-gray-700">(until 23:59)</p>
                                                </template>

                                                <template v-slot:content="{ close }">
                                                    <div v-click-away="close">
                                                        <ui-date-picker :show-second="false" type="date" v-model="inputs.validTo"></ui-date-picker>
                                                    </div>
                                                </template>
                                            </ui-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Billing</h3>
                            <div class="mt-1 text-sm text-gray-500">
                                <p>
                                    Change the billing options for this license
                                </p>
                            </div>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="billingType" class="block text-sm font-medium text-gray-700">
                                        Type <span class="text-red-500">*</span>
                                    </label>
                                    <ui-select class="mt-1" :options="[ { id: 'free', name: 'Free' }, { id: 'paid', name: 'Paid' } ]" :reduce="billingType => billingType.id" label="name" v-model="inputs.billingType" :disabled="license.id"></ui-select>
                                </div>

                                <template v-if="inputs.billingType === 'paid'">
                                    <div>
                                        <label for="paid_customer" class="block text-sm font-medium text-gray-700">
                                            Customer <span class="text-red-500">*</span>
                                        </label>
                                        <div>
                                            <ui-select class="mt-1" :options="billingCustomers" :reduce="o => o.id" label="name" v-model="inputs.billing.paid.customer"></ui-select>
                                        </div>
                                    </div>
                                    <div class="space-y-1.5">
                                        <div class="flex items-center" v-for="item, i in inputs.billing.paid.items" :key="i">
                                            <div class="w-3/4 mr-3">
                                                <label for="paid_itemItem" class="block text-sm font-medium text-gray-700">
                                                    Item <span class="text-red-500">*</span>
                                                </label>
                                                <div>
                                                    <ui-select class="mt-1" :options="billingItems" :reduce="o => o.id" label="name" v-model="inputs.billing.paid.items[i].item"></ui-select>
                                                </div>
                                            </div>
                                            <div class="w-1/4">
                                                <label for="paid_itemQuantity" class="block text-sm font-medium text-gray-700">
                                                    Quantity <span class="text-red-500">*</span>
                                                </label>
                                                <div class="mt-1">
                                                    <ui-input type="number" name="paid_itemQuantity" id="paid_itemQuantity" v-model="inputs.billing.paid.items[i].quantity" :errors="errors.billing?.paid?.items[i]?.quantity"></ui-input>
                                                </div>
                                            </div>
                                            <ui-button @click.prevent="removeItem(i)" class="mt-6 ml-3 h-10" icon="x" color="red"></ui-button>
                                        </div>
                                        <div v-if="inputs.billing.paid.items.length < 15" class="mt-1.5 text-sm text-blue-500 font-medium">
                                            <a href="#" @click.prevent="addNewItem">
                                                <ui-icon name="plus"></ui-icon>
                                                Add item
                                            </a>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2 space-x-3">
                                        <div>
                                            <label for="paid_period" class="block text-sm font-medium text-gray-700">
                                                Period <span class="text-red-500">*</span>
                                            </label>
                                            <div>
                                                <ui-select class="mt-1" :options="[ { id: 'once', name: 'Once' }, { id: 'monthly', name: 'Monthly'}, { id: 'yearly', name: 'Yearly' } ]" :reduce="o => o.id" label="name" v-model="inputs.billing.paid.period"></ui-select>
                                            </div>
                                        </div>
                                        <div>
                                            <label for="paid_taxType" class="block text-sm font-medium text-gray-700">
                                                Tax Type <span class="text-red-500">*</span>
                                            </label>
                                            <div>
                                                <ui-select class="mt-1" :options="billingTaxTypes" :reduce="o => o.id" label="name" v-model="inputs.billing.paid.taxType">
                                                    <template v-slot:selected-option="option">
                                                        {{ option.name }} ({{ option.percent }}%)
                                                    </template>
                                                    <template v-slot:option="option">
                                                        {{ option.name }} ({{ option.percent }}%)
                                                    </template>
                                                </ui-select>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Features</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change the features for this license
                            </p>
                        </div>
                        <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                            <div class="flex-1 space-y-8">
                                <div>
                                    <label for="enabledFeatures" class="block text-sm font-medium text-gray-700">
                                        Enabled Features
                                    </label>
                                    <div class="mt-3 columns-3">
                                        <div class="flex items-center" v-for="feature in availableFeatures" :key="feature">
                                            <input class="mr-1.5" type="checkbox" id="features" :checked="inputs.features.includes(feature)" @input="toggleFeature(feature)">
                                            <a href="#" @click.prevent="toggleFeature(feature)">{{ feature }}</a>
                                        </div>
                                    </div>
                                    <div class="mt-3 text-sm font-medium space-x-3">
                                        <a class="text-blue-500" href="#" @click.prevent="enableAllFeatures">
                                            <ui-icon name="plus"></ui-icon>
                                            Enable all features
                                        </a>
                                        <a class="text-red-500" href="#" @click.prevent="disableAllFeatures">
                                            <ui-icon name="minus"></ui-icon>
                                            Disable all features
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md:grid md:grid-cols-3 md:gap-6 pt-8">
                        <div class="md:col-span-1">
                            <h3 class="text-lg font-medium leading-6 text-gray-900">Budgets</h3>
                            <p class="mt-1 text-sm text-gray-500">
                                Change the budgets for this license
                            </p>
                        </div>
                        <div class="mt-5 md:mt-0 md:col-span-2">
                            <div class="flex-1">
                                <table class="table-auto border-separate w-1/2">
                                    <thead class="text-left text-sm font-medium text-gray-700">
                                        <th>Budget Type</th>
                                        <th>Value</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="w-44">Monitored Targets</td>
                                            <td>
                                                <ui-input type="number" name="monitoredTargetsBudget" id="monitoredTargetsBudget" placeholder="100" v-model="inputs.budgets.monitoredTargets" :errors="errors?.budgets?.monitoredTargetsBudget"></ui-input>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Video Download</td>
                                            <td>
                                                <ui-input type="number" name="videoDownloadBudget" id="videoDownloadBudget" placeholder="10" v-model="inputs.budgets.videoDownload" :errors="errors?.budgets?.videoDownload"></ui-input>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Video Transcription</td>
                                            <td>
                                                <ui-input type="number" name="videoTranscriptionBudget" id="videoTranscriptionBudget" placeholder="10" v-model="inputs.budgets.videoTranscription" :errors="errors?.budgets?.videoTranscription"></ui-input>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Image Text Extraction</td>
                                            <td>
                                                <ui-input type="number" name="imageTextExtractionBudget" id="imageTextExtractionBudget" placeholder="10" v-model="inputs.budgets.imageTextExtraction" :errors="errors?.budgets?.imageTextExtraction"></ui-input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p class="mt-3 text-sm text-gray-700">Use -1 to disable a type if needed</p>
                        </div>
                    </div>
                </div>

                <div class="bg-gray-50 flex px-4 py-5 sm:p-8 rounded-b-lg border-t border-gray-100 flex justify-end">
                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Save
                    </ui-button>
                </div>
            </ui-form>

            <Teleport to="#header-actions">
                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off" spellcheck="false"
                           class="block w-full h-8 pl-8 pr-2 rounded-sm placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm" placeholder="Search...">
                </form>
            </Teleport>
        </div>
    </ui-async>
</template>

<script>
import useLicenseSettingsFormStore from '@/admin/stores/accounts/license-form'
import useOrganizationStore from '@/admin/stores/accounts/organization'
import useLicenseStore from '@/admin/stores/accounts/license'

import { date } from '@/helpers/datetime'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useLicenseSettingsFormStore),

        ...mapState(useLicenseSettingsFormStore, [ 'availablePricingTypes', 'availableFeaturesTemplates', 'availableFeatures' ]),

        ...mapState(useOrganizationStore, [ 'organization' ]),

        ...mapState(useLicenseStore, [ 'license', 'billingCustomers', 'billingItems', 'billingTaxTypes' ]),

        validFrom() {
            return useLicenseSettingsFormStore().inputs.validFrom ? date(useLicenseSettingsFormStore().inputs.validFrom) : null
        },

        validTo() {
            return useLicenseSettingsFormStore().inputs.validTo ? date(useLicenseSettingsFormStore().inputs.validTo) : null
        }
    },

    methods: {
        ...mapActions(useLicenseSettingsFormStore, [ 'addNewItem', 'removeItem', 'toggleFeature', 'enableAllFeatures', 'disableAllFeatures' ])
    }
}
</script>
