import useOrganizationStore from '@/admin/stores/accounts/organization'

import { defineStore } from 'pinia'

export const useAccountsOrganizationsOrganizationPage = defineStore({
    id: 'accountsOrganizationsOrganizationPage',

    actions: {
        async beforeEnter(to) {
            if (to.params.organizationId != useOrganizationStore().organization?.id) {
                useOrganizationStore().initialize(to.params.organizationId, true)
            }
        }
    }
})

export default useAccountsOrganizationsOrganizationPage
