import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useAnalysisModalsTopicBreakdownTopicSizeStore = defineStore({
    id: 'analysisModalsTopicBreakdownTopicSize',

    state: () => ({
        isOpen: false,

        analysisStore: null,

        topicSize: 0
    }),

    actions: {
        open(analysisStore) {
            this.analysisStore = analysisStore

            useModal().show('topic-breakdown-topic-size')
        },

        close() {
            useModal().hide('topic-breakdown-topic-size')
        },

        apply() {
            this.analysisStore.topicSize = [
                'small', 'medium', 'large'
            ][this.topicSize] ?? 0

            this.close()
        }
    }
})

export default useAnalysisModalsTopicBreakdownTopicSizeStore
