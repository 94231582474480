import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useTargetsMyTargetsMonitoringRequestsStore = defineStore({
    id: 'targets-myTargets-monitoringRequests',

    state: () => ({
        requests: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date(),

        page: 1
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            api.route('me targets monitoring-requests index').get().json(res => {
                this.requests = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadMore(infiniteScroll) {
            return api.route('me targets monitoring-requests index', { page: this.page + 1 }).get().json(res => {
                if (res.data.length) {
                    this.page++
                    this.requests = [ ...this.requests,  ...(res.data || []) ]
                    infiniteScroll.loaded()
                } else {
                    infiniteScroll.complete()
                }
            })
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        open() {
            useModal().show('my-targets-monitoring-requests-modal')
        }
    }
})

export default useTargetsMyTargetsMonitoringRequestsStore
