import useAnalysisModalsContentPerformanceSeriesStore from './series'

import defineCards from '@/stores/reusable/cards'

// Used to render the selected dataset card in the series modal
export const useAnalysisModalsContentPerformanceSeriesCardsStore = defineCards({
    id: 'analysisModalsContentPerformanceSeriesCards',

    choose: () => useAnalysisModalsContentPerformanceSeriesStore().changeDataset()
})

export default useAnalysisModalsContentPerformanceSeriesCardsStore
