import useContentModalsNotificationsManageFormStore from './notifications-manage-form'

import api from '@/api'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import { defineStore } from 'pinia'

export const useContentModalsNotificationsManageStore = defineStore({
    id: 'contentModalsNotificationsManage',

    state: () => ({
        perspective: null,
        selectedSubscription: null,
        mode: 'index',

        subscriptionTypes: [
            { id: 'content-alert', defaultName: 'Untitled Content Alert' },
            { id: 'digest', defaultName: 'Untitled Digest', defaultConfiguration: { frequency: 'weekly', metric: 'interactions', count: 10 } },
            { id: 'leaderboard', defaultName: 'Untitled Leaderboard', defaultConfiguration: { frequency: 'weekly', metric: 'interactions', count: 10 } }
        ]
    }),

    getters: {
        subscriptions: store => store.perspective?.subscriptions || []
    },

    actions: {
        open(perspective) {
            this.$reset()

            this.perspective = perspective

            this.editSubscription([ ...this.subscriptions ].sort((a, b) => a.name.localeCompare(b.name))[0])

            if (! this.selectedSubscription) this.createSubscription()

            useModal().show('content-notifications-manage')
        },

        refresh() {
            this.editSubscription([ ...this.subscriptions ].sort((a, b) => a.name.localeCompare(b.name))[0])

            if (! this.subscriptions.length) this.mode = 'create-subscription'
        },

        createSubscription(type) {
            if (! type) {
                return this.mode = 'create-subscription'
            }

            let subscription = {
                type: type,
                name: this.subscriptionTypes.find(t => t.id == type).defaultName,
                enabled: true,
                configuration: { ...this.subscriptionTypes.find(t => t.id == type).defaultConfiguration },
                channels: { inApp: [ '*' ] }
            }

            this.editSubscription(subscription)

            useContentModalsNotificationsManageFormStore().submit()

            trackEvent('content', 'new-subscription-saved', type)
        },

        editSubscription(subscription) {
            this.mode = 'index'
            this.selectedSubscription = subscription

            useContentModalsNotificationsManageFormStore().edit(this.perspective, subscription)
        },

        reloadSubscriptions() {
            return api.route('me perspectives subscriptions index', { id: this.perspective.id })
                .get()
                .json(res => this.perspective.subscriptions = res.data)
        }
    }
})

export default useContentModalsNotificationsManageStore
