<template>
    <div class="h-full" v-if="editor">
        <div class="absolute left-0 -top-12 z-50 h-10 flex items-center divide-x divide-gray-400 bg-gray-800 text-gray-400 rounded-md px-1 shadow-sm">
            <div class="flex items-center px-1">
                <div @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 1 }) }">
                    H1
                </div>

                <div @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 2 }) }">
                    H2
                </div>

                <div @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('heading', { level: 3 }) }">
                    H3
                </div>
            </div>

            <div class="flex items-center px-1">
                <div @click="editor.chain().focus().toggleBold().run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('bold') }">
                    <ui-icon name="bold"></ui-icon>
                </div>

                <div @click="editor.chain().focus().toggleItalic().run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('italic') }">
                    <ui-icon name="italic"></ui-icon>
                </div>

                <div @click="editor.chain().focus().toggleUnderline().run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('underline') }">
                    <ui-icon name="underline"></ui-icon>
                </div>
            </div>

            <div class="flex items-center px-1">
                <div @click="editor.chain().focus().setTextAlign('left').run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'left' }) }">
                    <ui-icon name="align-left"></ui-icon>
                </div>

                <div @click="editor.chain().focus().setTextAlign('center').run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'center' }) }">
                    <ui-icon name="align-center"></ui-icon>
                </div>

                <div @click="editor.chain().focus().setTextAlign('right').run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'right' }) }">
                    <ui-icon name="align-right"></ui-icon>
                </div>

                <div @click="editor.chain().focus().setTextAlign('justify').run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive({ textAlign: 'justify' }) }">
                    <ui-icon name="align-justify"></ui-icon>
                </div>
            </div>

            <div class="flex items-center px-1">
                <div @click="editor.chain().focus().toggleBulletList().run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('bulletList') }">
                    <ui-icon name="list-unordered"></ui-icon>
                </div>

                <div @click="editor.chain().focus().toggleOrderedList().run()" class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full" :class="{ 'bg-gray-900 text-gray-50': editor.isActive('orderedList') }">
                    <ui-icon name="list-ordered"></ui-icon>
                </div>
            </div>

            <div class="flex items-center px-1">
                <ui-dropdown width="w-auto" align="right">
                    <template v-slot:trigger>
                        <div class="inline-flex items-center px-3 h-7 text-sm font-medium hover:bg-gray-900 rounded-sm max-w-full">
                            <ui-icon v-if="! editor.getAttributes('textStyle').color" name="palette"></ui-icon>
                            <div v-else class="h-4 w-4" :style="{ 'background-color': editor.getAttributes('textStyle').color }"></div>
                        </div>
                    </template>>

                    <template v-slot:content>
                        <div class="py-1">
                            <div class="flex flex-wrap justify-center w-36">
                                <div class="w-8 h-8 p-0.5" @click="editor.chain().focus().unsetColor().run()">
                                    <div class="w-full h-full rounded-md" :class="{ 'border border-2 border-gray-400': ! editor.getAttributes('textStyle').color }" style="background: #000"></div>
                                </div>

                                <div class="w-8 h-8 p-0.5" @click="editor.chain().focus().setColor(color).run()" :key="index" v-for="color, index in safeColors">
                                    <div class="w-full h-full rounded-md" :class="{ 'border border-2 border-gray-400': editor.getAttributes('textStyle').color === color }" :style="`background: ${color}`"></div>
                                </div>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>
            </div>
        </div>

        <editor-content class="h-full overflow-y-scroll" :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Color from '@tiptap/extension-color'
import safeColors from "@/helpers/safe-colors"

export default {
    mixins: [safeColors],

    components: {
        EditorContent
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            editor: null,
        }
    },

    watch: {
        modelValue(modelValue) {
            const isSame = this.editor.getHTML() === modelValue

            if (isSame) {
                return
            }

            this.editor.commands.setContent(modelValue, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                Underline,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                TextStyle,
                ListItem,
                BulletList,
                OrderedList,
                Color
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
            },
        })

        this.editor.commands.setContent(this.modelValue, false)
    },

    beforeUnmount() {
        this.editor.destroy()
    }
}
</script>

<style>
.ProseMirror {
    outline: none;
    padding: 8px;
    min-height: 100vh;
    overflow-y: scroll;
    white-space: break-spaces;
}

.ProseMirror p { padding-bottom: 0.33rem }

.ProseMirror h1 { font-size: 24px; line-height: 30px; padding-bottom: 0.4rem }
.ProseMirror h2 { font-size: 20px; line-height: 26px; padding-bottom: 0.4rem }
.ProseMirror h3 { font-size: 16px; line-height: 22px; padding-bottom: 0.4rem }

.ProseMirror ul { list-style-type: disc; padding: 0 1rem }
.ProseMirror ol { list-style-type: decimal; padding: 0 1rem }

.ProseMirror li {
    padding-bottom: 2px;
}
</style>
