<template>
    <content-card
        v-bind="$props"
        avatar-badge="badges.target-youtube-channel"
        avatar-badge-tooltip="Youtube Channel"
        avatar-image-class="ring-social-youtube"
        :parent-component="{ 'youtube-video': YoutubeVideo, 'youtube-video-comment': _.type }"
    >
    </content-card>
</template>

<script>
import ContentCard from './content'
import YoutubeVideo from './youtube-video'

import { markRaw } from 'vue'

export default {
    components: { ContentCard },

    data: () => ({
        YoutubeVideo: markRaw(YoutubeVideo)
    }),

    props: [ 'content', 'mode', 'streamStore' ]
}
</script>
