import api from '@/admin/api'

import useMemberSettingsFormStore from '@/admin/stores/accounts/member-form'
import useWorkspaceSettingsFormStore from '@/admin/stores/accounts/workspace-form'
import useWorkspaceMembersCardsStore from '@/admin/stores/accounts/workspace-members-cards'

import { defineStore } from 'pinia'

export const useWorkspaceStore = defineStore({
    id: 'workspace',

    state: () => ({
        workspace: null,
        workspaceLoaded: false,
        workspacePromise: null
    }),

    actions : {
        async initialize(organizationId, workspaceId, force = false) {
            await this.load(organizationId, workspaceId, force)
        },

        async load(organizationId, workspaceId, force = false) {
            if (this.workspaceLoaded && ! force) return Promise.resolve()
            if (this.workspacePromise) return this.workspacePromise

            this.$reset()

            useWorkspaceSettingsFormStore().$reset()
            useMemberSettingsFormStore().$reset()

            return this.workspacePromise = api.route('admin accounts organizations workspaces details', { organizationId: organizationId, workspaceId: workspaceId } ).get().json(res => {
                this.workspace = res.data
                this.workspaceLoaded = true
                this.workspacePromise = null

                const owner = useWorkspaceStore().workspace.owner
                owner.role = 'owner'

                useWorkspaceMembersCardsStore().source = {
                    items: useWorkspaceStore().workspace.users.filter(u => u.id !== owner.id).concat([owner]),
                    isInitialized: true
                }

                useMemberSettingsFormStore().workspaceId = workspaceId

                useWorkspaceSettingsFormStore().organizationId = organizationId
                useWorkspaceSettingsFormStore().workspaceId = workspaceId

                useWorkspaceSettingsFormStore().inputs.name = this.workspace.name
            })
        },

        setAsOwner(member) {
            api.route('admin accounts workspaces members set-as-owner', { workspaceId: this.workspace.id, memberId: member.id })
                .post()
                .res(() => {
                    this.load(this.workspace.organization.id, this.workspace.id, true)
                })
        },

        setAsAdmin(member) {
            api.route('admin accounts workspaces members set-as-admin', { workspaceId: this.workspace.id, memberId: member.id })
                .post()
                .res(() => {
                    this.load(this.workspace.organization.id, this.workspace.id, true)
                })
        },

        setAsMember(member) {
            api.route('admin accounts workspaces members set-as-member', { workspaceId: this.workspace.id, memberId: member.id })
                .post()
                .res(() => {
                    this.load(this.workspace.organization.id, this.workspace.id, true)
                })
        },

        removeMember(member, force = false) {
            api.route('admin accounts workspaces members remove-member', { workspaceId: this.workspace.id, memberId: member.id })
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .res(() => {
                    this.load(this.workspace.organization.id, this.workspace.id, true)
                })
        }
    }
})

export default useWorkspaceStore
