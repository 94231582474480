<template>
    <router-view></router-view>

    <Teleport to="#root">
        <content-lightbox :store="contentDetailMediaLightbox"></content-lightbox>

        <notifications-manage-modal></notifications-manage-modal>
        <perspective-edit-modal></perspective-edit-modal>
        <topic-edit-modal></topic-edit-modal>
        <perspective-duplicate-modal></perspective-duplicate-modal>
        <search-history-modal :stream-store="$page.streamStore"></search-history-modal>
        <sentiment-edit-modal></sentiment-edit-modal>
    </Teleport>
</template>

<script>
import ContentLightbox from '@/components/ui/stream/lightbox/lightbox'
import NotificationsManageModal from './modals/notifications-manage'
import PerspectiveEditModal from './modals/perspective-edit.vue'
import TopicEditModal from './modals/topic-edit'
import PerspectiveDuplicateModal from './modals/perspective-duplicate'
import SearchHistoryModal from '@/components/modals/search-history.vue'
import SentimentEditModal from '@/components/content/modals/sentiment-edit.vue'

import useContentModalsContentDetailStore from '@/stores/content/modals/content-detail'

import { mapState } from 'pinia/dist/pinia'

export default {
    components: {
        ContentLightbox, NotificationsManageModal, PerspectiveEditModal,
        TopicEditModal, PerspectiveDuplicateModal, SearchHistoryModal,
        SentimentEditModal
    },

    computed: {
        ...mapState(useContentModalsContentDetailStore, { contentDetailMediaLightbox: 'mediaLightbox' })
    }
}
</script>
