<template>
    <ui-modal name="search-history" title="Search history" :max-height="800" width="600" unstyled content-class="w-full max-w-2xl mx-auto bg-white rounded shadow-md max-h-full flex flex-col overflow-hidden" @keydown="keyPressed">
        <div class="flex items-center justify-between px-8 py-6 border-b border-gray-200">
            <h1 class="font-semibold text-gray-900">
                Search history
            </h1>

            <a href="#" class="text-xl text-gray-800 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('search-history')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <ui-async class="flex min-h-0" :guards="! isLoading">
            <div class="text-gray-800 text-center pt-3 pb-6" v-if="! items.length">
                No history just yet. Start searching or applying filters to see your search history here.
            </div>

            <div class="overflow-y-auto divide-y divide-gray-200 min-h-0 w-full" ref="items" v-else>
                <search-history-item :key="search.id" v-for="search, index in items" :stream-store="streamStore" :search="search" :next-search="items[index + 1]" :focused="search == focusedItem" :ref="`item-${index}`"></search-history-item>
            </div>
        </ui-async>
    </ui-modal>
</template>

<script>
import SearchHistoryItem from '@/components/modals/partials/search-history-item.vue'

import useMySearchHistoryStore from '@/stores/me/search-history'

import { mapState } from 'pinia'

export default {
    components: {
        SearchHistoryItem
    },

    props: {
        streamStore: {
            required: true
        }
    },

    data: () => ({
        focusedItem: null,
        focusedItemRef: null
    }),

    computed: {
        ...mapState(useMySearchHistoryStore, [ 'isLoading', 'items' ])
    },

    methods: {
        keyPressed(ev) {
            if (ev.key == 'Enter') {
                ev.preventDefault()
                return this.focusedItemRef?.open()
            }

            if (ev.key != 'ArrowDown' && ev.key != 'ArrowUp') return

            ev.preventDefault()

            let selectPrevious = ev.key == 'ArrowUp'
            let currentIndex = this.items.indexOf(this.focusedItem)

            let focusIndex = currentIndex === false
                ? (selectPrevious ? this.items.length - 1 : 0)
                : (selectPrevious ? currentIndex - 1 : currentIndex + 1)

            if (focusIndex >= this.items.length) focusIndex = 0
            if (focusIndex < 0) focusIndex = this.items.length - 1

            this.focusedItem = this.items[focusIndex]
            this.focusedItemRef = this.$refs[`item-${focusIndex}`][0]

            this.focusedItemRef.$el.scrollIntoView({ block: 'nearest' })
        }
    }
}
</script>
