<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="pin-off" @click="updateAnalysis(analysis, { favorite: 1 })" v-if="! analysis.pinned">
           Pin
        </ui-dropdown-link>
        <ui-dropdown-link icon="pin-off" @click="updateAnalysis(analysis, { favorite: 0 })" v-if="analysis.pinned">
           Un-pin
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" @click="editAnalysis(analysis)">
           Info
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="trash" @click="deleteAnalysis(analysis)">
           Delete
        </ui-dropdown-link>

        <template v-if="can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="showDebug(analysis)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useAnalysesModalsAnalysisInfoStore from '@/stores/analysis/modals/analysis-info'
import useMyAnalysesStore from '@/stores/me/analyses'

import { mapActions } from 'pinia'

export default {
    props: [ 'analysis' ],

    methods: {
        ...mapActions(useMyAnalysesStore, {
            'deleteAnalysis': 'delete',
            'updateAnalysis': 'update'
        }),
        ...mapActions(useAnalysesModalsAnalysisInfoStore, { 'editAnalysis': 'open' })
    }
}
</script>
