 <template>
    <div v-if="store.items.length">
        <div class="px-1 mb-8 mx-auto">
            <div class="flex justify-between items-center">
                <div class="text-gray-800 text-sm">
                    Showing
                    <span class="font-medium">{{ $number(store.items.length) }}</span>
                    results
                </div>

                <div class="flex items-center space-x-4">
                    <results-sorting :store="store"></results-sorting>
                    <results-view :store="store"></results-view>
                    <results-view-settings :store="store"></results-view-settings>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
                <div class="border-b border-gray-200 w-1/4 mt-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
import ResultsSorting from './results-sorting'
import ResultsView from './results-view'
import ResultsViewSettings from './results-view-settings'

export default {
    components: {
        ResultsSorting, ResultsView, ResultsViewSettings
    }
}
</script>
