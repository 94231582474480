<template>
    <a :href="$router.resolve(to)" class="block px-6 py-3 hover:bg-gray-100 hover:ring-1 ring-gray-300 ring-inset" :class="{ 'bg-gray-100 ring-1': focused }" @click.prevent>
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <img class="h-8 w-8 rounded-full bg-gray-100 shadow" :src="result.image" alt="">
                <ui-icon :name="`badges.target-${result.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-base" :class="{ 'grayscale': ! result.flags.includes('monitored') }"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                    <div class="h-3 w-3 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xs inline-flex ml-1"
                         v-if="result.flags.includes('verified')">
                        <ui-icon name="check"></ui-icon>
                    </div>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    {{typeName}} · <span class="capitalize">{{family}}</span> · {{result.description.length ? result.description.join(' · ') + ' · ' : ''}} {{$number(result.followers)}} {{ family == 'group' ? 'members' : 'followers' }}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        family() {
            return this.result.id.charAt(0) === 'G' ? 'groups' : 'sources'
        },

        to() {
            return { name: 'targets.target.information', params: { type: this.family, modelType: this.result.type, id: this.result.monitorId } }
        },

        typeName() {
            return {
                'facebook-group': 'Facebook Group',
                'facebook-page': 'Facebook Page',
                'facebook-user': 'Facebook User',
                'telegram-channel': 'Telegram Channel',
                'telegram-group': 'Telegram Group',
                'telegram-user': 'Telegram User',
                'twitter-user': 'Twitter User',
                'vkontakte-community': 'VKontakte Community',
                'vkontakte-user': 'VKontakte User',
                'web-feed': 'Web Feed',
                'web-site': 'Web Site',
                'youtube-channel': 'Youtube Channel'
            }[this.result.type] || 'Unknown type'
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
