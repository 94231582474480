import useContentPerspectivesPage from '@/pages/content-perspectives'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import defineCards from '@/stores/reusable/cards'

import { sortAlphabetically, textSearch } from '@/helpers'

export const useContentPerspectivesCardsStore = defineCards({
    id: 'contentPerspectivesCards',

    source: useMyPerspectivesStore(),
    filter: items => textSearch(useContentPerspectivesPage().search, items.filter(item => ! item.virtual), i => i.name),

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => items.sort((a, b) => new Date(a.lastUsedAt) - new Date(b.lastUsedAt))
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => items.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
        }
    ]
})

export default useContentPerspectivesCardsStore
