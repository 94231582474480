<template>
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-6" :class="{ '2xl:grid-cols-4': ! $my.showSidebar, 'xl:grid-cols-4 2xl:grid-cols-4': ! $my.showSidebar }">
        <template v-if="store.source.isLoading">
            <template v-for="v, i in Array.from({ length: rows * perRow || 25 })">
                <slot name="placeholder" :index="i"></slot>
            </template>
        </template>

        <template v-else-if="store.source.isInitialized">
            <template v-for="item in items">
                <slot name="item" :item="item"></slot>
            </template>
        </template>
    </div>
</template>

<script>
import breakpoint from '@/helpers/breakpoint'

export default {
    computed: {
        items() {
            if (this.rows) {
                return this.store.items.slice(0, this.rows * this.perRow)
            }

            return this.store.items
        },

        rows() {
            return this.store.layout?.settings?.rows
        },

        perRow() {
            return { 'xs': 2, 'sm': 2, 'md': 2, 'lg': 3, 'xl': this.$my.showSidebar ? 3 : 4, '2xl': 4, '3xl': 4 }[breakpoint.is]
        }
    }
}
</script>
