<template>
    <div>
        <transition-group
            enter-active-class="transition duration-100 ease-out"
            enter-class="scale-95 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <div v-for="item in activeItems" :key="item.id"
                 class="flex items-center justify-between border-b px-6 py-3 font-medium" :class="warningClasses(item)">
                <p>{{ item.body }}</p>
                <a href="#" @click.prevent="close(item)" class="shrink-0 ml-3">
                    <ui-icon name="x"></ui-icon>
                </a>
            </div>
        </transition-group>
    </div>
</template>

<script>
import useMyWarningsStore from '@/stores/me/warnings'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useMyWarningsStore, [ 'activeItems' ])
    },

    methods: {
        ...mapActions(useMyWarningsStore, [ 'close' ]),

        warningClasses(warning) {
            return {
                'bg-green-300 text-green-900': warning.type == 'success',
                'bg-blue-100 text-blue-900': warning.type == 'notice',
                'bg-amber-200 text-amber-900': warning.type == 'warning',
                'bg-red-200 text-red-900': warning.type == 'danger'
            }
        }
    }
}
</script>