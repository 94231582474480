import useMyStore from '@/stores/me/my'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useAuthPasswordRecoveryResetFormStore = defineForm({
    id: 'authPasswordRecoveryResetForm',

    inputs: () => ({
        email: null,
        password: null,
        passwordConfirmation: null,
        resetToken: null
    }),

    async submitRequest() {
        await api.url('/sanctum/csrf-cookie').get().res()

        return api.route('auth password-recovery reset')
            .formData({
                email: this.inputs.email,
                password: this.inputs.password,
                passwordConfirmation: this.inputs.passwordConfirmation,
                resetToken: this.inputs.resetToken
            })
            .post()
            .error(400, err => {
                this.errors = { other: [ err.json.message ] }
                this.submitting = false
            })
    },

    async onResponse(res) {
        useMyStore().loggedIn()
    },

    actions: {
        setResetToken(token, email) {
            this.inputs.resetToken = token
            this.inputs.email = email
        }
    }
})

export default useAuthPasswordRecoveryResetFormStore
