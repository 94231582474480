<template>
    <div class="bg-white w-full overflow-hidden shadow rounded-sm px-6 mb-4">
        <ui-async :guards="connections.data || connections.error">
            <div class="bg-white py-4 pb-4 flex items-center justify-between">
                <h3 class="font-semibold text-gray-900">
                    {{title}}
                </h3>
            </div>

            <table class="min-w-full divide-y divide-gray-200 text-sm text-gray-900 mb-3" v-if="connections.data && connections.data.length">
                <tr :key="index" v-for="connection, index in connections.data">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium break-all">
                        <router-link :to="connectionUrl(connection)" class="flex items-center">
                            <div class="shrink-0 relative" v-if="connection.group">
                                <img class="rounded-full w-8 h-8 shadow" :src="connection.group.avatarUrl" alt="">
                                <ui-icon :name="`badges.target-${connection.group.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm" :class="{ 'grayscale': ! connection.group.monitored }"></ui-icon>
                            </div>
                            <div class="shrink-0 relative" v-else-if="connection.source">
                                <img class="rounded-full w-8 h-8 shadow" :src="connection.source.avatarUrl" alt="">
                                <ui-icon :name="`badges.target-${connection.source.type}`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm" :class="{ 'grayscale': ! connection.source.monitored }"></ui-icon>
                            </div>
                            <div class="shrink-0 relative" v-else-if="connection.feature">
                                <img class="rounded-full w-8 h-8 shadow" :src="connection.feature.avatarUrl" alt="">
                                <ui-icon :name="`badge-feature-${connection.feature.type}`" class="absolute -right-px -bottom-px text-sm" style="filter: drop-shadow(0 -1px 0 white) drop-shadow(0 1px 0 white) drop-shadow(-1px 0 0 white) drop-shadow(1px 0 0 white);"></ui-icon>
                            </div>

                            <div class="truncate px-3">
                                {{ connectionTitle(connection) }}
                            </div>
                        </router-link>
                    </td>
                    <td class="py-2 font-semibold text-blue-500 text-right">
                        {{ connection.strength }}
                    </td>
                </tr>
            </table>

            <div class="py-8 text-center space-y-4 text-gray-800" v-else-if="connections.error">
                <p>We were unable to load connections for this Target.</p>
                <p>
                    <a href="#" @click.prevent="connectionsStore.reload()" class="text-blue-500 hover:text-blue-700">Retry</a>
                </p>
            </div>

            <div class="py-20 text-gray-700 text-center text-sm" v-else>
                <p>No connections found.</p>
            </div>
        </ui-async>
    </div>
</template>

<script>
export default {
    props: [ 'connections', 'connectionsStore', 'title' ],

    methods: {
        connectionTitle(connection) {
            return connection.source?.title || connection.group?.title || connection.feature?.title
        },

        connectionUrl(connection) {
            if (connection.source) {
                return { name: 'targets.target.information', params: { type: 'source', modelType: connection.source.type, id: connection.source.id } }
            } else if (connection.group) {
                return { name: 'targets.target.information', params: { type: 'group', modelType: connection.group.type, id: connection.group.id } }
            } else if (connection.feature) {
                return { name: 'features.feature.information', params: { type: connection.feature.type, id: connection.feature.id } }
            }
        }
    }
}
</script>
