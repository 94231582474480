<template>
    <ui-modal name="analysis-analysis-info" title="Analysis" @opened="opened" @closed="cancel">
        <ui-form :store="analysisModalsAnalysisInfoStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups Activity" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteAnalysis" :disabled="submitting" v-if="analysis && analysis.id">
                    Delete
                </ui-button>

                <ui-button type="submit" color="blue" :disabled="submitting">
                    Save
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useAnalysisModalsAnalysisInfoStore from '@/stores/analysis/modals/analysis-info'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAnalysisModalsAnalysisInfoStore),

        analysis() { return this.analysisModalsAnalysisInfoStore.inputs?.analysis }
    },

    methods: {
        ...mapActions(useAnalysisModalsAnalysisInfoStore, {
            'cancel': 'cancel', 'deleteAnalysis': 'delete', 'submit': 'submit'
        }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
