import api from '@/admin/api'

import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import { defineStore } from 'pinia'

export const useOrganizationsStore = defineStore({
    id: 'organizations',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,
        loadingPromise: null,

        timezones: [],
        languages: []
    }),

    actions: {
        async initialize() {
            return this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return Promise.all([
                api.route('admin accounts organizations').get().json(),
                api.route('settings timezones').get().json(),
                api.route('settings languages').get().json()
            ]).then(values => {
                this.items = values[0].data

                this.timezones = values[1].data
                this.languages = values[2].data

                this.isLoading = false
                this.isInitialized = true
                this.loadingPromise = null
            })
        },

        async delete(organization, force = false) {
            await useDeleteConfirmationModal().open('Organization')
                .then(async () => {
                    await api.route('admin accounts organizations delete', { organizationId: organization.id })
                        .formData({ _method: 'delete', force: force ? 1 : 0 })
                        .post()
                        .res()
                })

            return this.load(true)
        }
    }
})

export default useOrganizationsStore
