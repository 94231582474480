<template>
    <div class="relative">
        <div class="w-full h-48 p-2 cursor-pointer relative" @click="showMedia">
            <img :src="image.src" class="w-full h-full object-contain" style="filter: drop-shadow(0 -8px 0 white) drop-shadow(0 8px 0 white) drop-shadow(-8px 0 0 white) drop-shadow(8px 0 0 white) drop-shadow(0 8px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0 4px 4px rgba(0, 0, 0, 0.05));">
            <div class="rounded-sm absolute inset-0" @click.stop="toggleSelection" v-show="streamStore.isInSelectMode">
            </div>
        </div>

        <div class="absolute -top-4 -right-4" v-show="streamStore.isInSelectMode">
            <div class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-50 bg-white shadow-sm text-white text-xl" :class="{ 'bg-blue-500': isSelected }">
                <ui-icon name="check" v-show="isSelected"></ui-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'image', 'item', 'streamStore' ],

    computed: {
        isSelected() {
            return this.streamStore.selectedItems.includes(this.item)
        }
    },

    methods: {
        showMedia() {
            this.streamStore.mediaLightbox.open([ this.image ], 0)
        }
    }
}
</script>
