<template>
    <div class="bg-white w-full overflow-hidden shadow rounded-sm flex flex-col mb-4" v-if="$page.target.monitored || $page.target.metrics.contentCount">
        <div class="px-6 py-4 flex items-center justify-between">
            <h3 class="font-semibold text-gray-900">
                Statistics
            </h3>
        </div>
        <div class="px-6 pb-6 pt-2 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <target-metric title="Content" :value="$page.target.metrics.contentCount" :analysis-store="targetsTargetInformationSourcePublishedContentAnalysisStore" v-if="$page.type == 'sources'"></target-metric>
            <target-metric title="Content" :value="$page.target.metrics.contentCount" :analysis-store="targetsTargetInformationGroupPublishedContentAnalysisStore" v-if="$page.type == 'groups'"></target-metric>

            <template v-if="! [ 'telegram-group', 'vkontakte-community' ].includes($page.target.type)">
                <target-metric title="Members" :value="$page.target.metrics.membersCount" v-if="$page.type == 'groups'"></target-metric>
            </template>

            <template v-if="$page.target.type == 'facebook-page'">
                <target-metric title="Followers" :value="$page.target.metrics.followers" :analysis-store="targetsTargetInformationFacebookPageFollowersAnalysisStore"></target-metric>
            </template>
            <template v-if="$page.target.type == 'telegram-channel'">
                <target-metric title="Subscribers" :value="$page.target.metrics.followers" :analysis-store="targetsTargetInformationTelegramChannelSubscribersAnalysisStore"></target-metric>
            </template>
            <template v-if="$page.target.type == 'telegram-group'">
                <target-metric title="Members" :value="$page.target.metrics.members" :analysis-store="targetsTargetInformationTelegramGroupMembersAnalysisStore"></target-metric>
            </template>
            <template v-if="$page.target.type == 'vkontakte-community'">
                <target-metric title="Members" :value="$page.target.metrics.members" :analysis-store="targetsTargetInformationVKontakteCommunityMembersAnalysisStore"></target-metric>
            </template>
            <template v-if="$page.target.type == 'vkontakte-user'">
                <target-metric title="Followers" :value="$page.target.metrics.followers" :analysis-store="targetsTargetInformationVKontakteUserFollowersAnalysisStore"></target-metric>
                <target-metric title="Friends" :value="$page.target.metrics.friendsCount" :analysis-store="targetsTargetInformationVKontakteUserFriendsAnalysisStore"></target-metric>
            </template>
            <template v-if="$page.target.type == 'youtube-channel'">
                <target-metric title="Subscribers" :value="$page.target.metrics.followers" :analysis-store="targetsTargetInformationYoutubeChannelSubscribersAnalysisStore"></target-metric>
                <target-metric title="Views" :value="$page.target.metrics.viewsCount" :analysis-store="targetsTargetInformationYoutubeChannelViewsAnalysisStore"></target-metric>
            </template>
        </div>
    </div>
</template>

<script>
import TargetMetric from './target-metric'

import useTargetsTargetInformationFacebookPageFollowersAnalysisStore from '@/stores/targets/target/information-facebook-page-followers-analysis'
import useTargetsTargetInformationGroupPublishedContentAnalysisStore from '@/stores/targets/target/information-group-published-content-analysis'
import useTargetsTargetInformationSourcePublishedContentAnalysisStore from '@/stores/targets/target/information-source-published-content-analysis'
import useTargetsTargetInformationTelegramChannelSubscribersAnalysisStore from '@/stores/targets/target/information-telegram-channel-subscribers-analysis'
import useTargetsTargetInformationTelegramGroupMembersAnalysisStore from '@/stores/targets/target/information-telegram-group-members-analysis'
import useTargetsTargetInformationVKontakteCommunityMembersAnalysisStore from '@/stores/targets/target/information-vkontakte-community-members-analysis'
import useTargetsTargetInformationVKontakteUserFollowersAnalysisStore from '@/stores/targets/target/information-vkontakte-user-followers-analysis'
import useTargetsTargetInformationVKontakteUserFriendsAnalysisStore from '@/stores/targets/target/information-vkontakte-user-friends-analysis'
import useTargetsTargetInformationYoutubeChannelSubscribersAnalysisStore from '@/stores/targets/target/information-youtube-channel-subscribers-analysis'
import useTargetsTargetInformationYoutubeChannelViewsAnalysisStore from '@/stores/targets/target/information-youtube-channel-views-analysis'

import { mapStores } from 'pinia'

export default {
    components: {
        TargetMetric
    },

    computed: {
        ...mapStores(
            useTargetsTargetInformationFacebookPageFollowersAnalysisStore,
            useTargetsTargetInformationGroupPublishedContentAnalysisStore,
            useTargetsTargetInformationSourcePublishedContentAnalysisStore,
            useTargetsTargetInformationTelegramChannelSubscribersAnalysisStore,
            useTargetsTargetInformationTelegramGroupMembersAnalysisStore,
            useTargetsTargetInformationVKontakteCommunityMembersAnalysisStore,
            useTargetsTargetInformationVKontakteUserFollowersAnalysisStore,
            useTargetsTargetInformationVKontakteUserFriendsAnalysisStore,
            useTargetsTargetInformationYoutubeChannelSubscribersAnalysisStore,
            useTargetsTargetInformationYoutubeChannelViewsAnalysisStore
        )
    }
}
</script>
