<template>
    <div>
        <p v-if="new Date(message.createdAt).getDate() !== new Date(previousMessage?.createdAt)?.getDate()" class="text-center font-medium border-b border-gray-300 mb-3 mx-64">
            {{ $date(message.createdAt) }}
        </p>

        <div class="flex items-center relative px-64">
            <div v-if="message.agent" class="flex-1"></div>

            <div v-if="! message.agent && activeMessage === message.id" class="absolute left-1 w-60 text-right">
                <p class="text-sm text-gray-700">{{ $dateTime(message.createdAt) }}</p>
                <a v-if="! message.reminderAt" class="text-sm text-blue-600" href="#" @click.prevent="openReminderModalForActiveMessage">
                    Set a reminder...
                </a>
                <p v-else>
                    Reminder set for {{ $dateTime(message.reminderAt) }}
                </p>
            </div>

            <p v-html="text" class="px-3 py-1.5 rounded-md shadow-sm cursor-pointer message-text transition ease-in-out duration-500 break-all" :class="[ message.agent ? 'text-white bg-blue-500 hover:bg-blue-900 rounded-br-none' : 'bg-white hover:bg-gray-100 rounded-bl-none' ]"></p>

            <div v-if="message.agent && activeMessage === message.id" class="absolute right-1 w-60 text-left">
                <p class="text-sm text-gray-700">{{ $dateTime(message.createdAt) }} by {{ message.agent.name }}</p>
                <a v-if="! message.reminderAt" class="text-sm text-blue-600" href="#" @click.prevent="openReminderModalForActiveMessage">
                    Set a reminder...
                </a>
                <p v-else>
                    Reminder set for {{ $dateTime(message.reminderAt) }}
                </p>
            </div>

            <div v-if="! message.agent" class="flex-1"></div>
        </div>
    </div>
</template>

<script>
import useThreadStore from '@/admin/stores/support/thread'

import { sanitizeHTML } from '@/helpers'

import { mapActions, mapState, mapWritableState } from 'pinia'
import { marked } from 'marked'

export default {
    props: {
        previousMessage: {
            type: Object,
            required: false,
        },
        message: {
            type: Object,
            required: true,
        }
    },

    computed: {
        text() {
            return sanitizeHTML(marked.parse(this.message.body))
        },

        ...mapState(useThreadStore, [
            'activeMessage'
        ])
    },

    methods: {
        ...mapActions(useThreadStore, [
            'openReminderModalForActiveMessage'
        ])
    }
}
</script>

<style>
.message-text h1 { font-size: 24px; line-height: 30px }
.message-text h2 { font-size: 20px; line-height: 26px }
.message-text h3 { font-size: 16px; line-height: 22px }

.message-text p:not(:last-child) { padding-bottom: 16px; }

.message-text p:after {
    content: "";
    display: inline-block;
    width: 0;
}

.message-text ul { list-style-type: disc; padding: 0 1rem }
.message-text ol { list-style-type: decimal; padding: 0 1rem }

.message-text li {
    padding-bottom: 2px;
}

.message-text a {
    font-weight: bold;
    text-decoration: underline;
}
</style>

