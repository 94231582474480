import { defineTargetAudienceAnalysisStore } from '@/stores/analysis/analyses/target-audience'

export const useTargetsTargetInformationTargetAudienceAnalysisStore = defineTargetAudienceAnalysisStore({
    id: 'targetsTargetInformationTargetAudienceAnalysis',

    name: '',

    date: { type: 'past', date: { past: 1, unit: 'years' } },
    granularity: 'week',

    series: [],

    chainAnalysisOptions: (store, options) => {
        options.chart.spacing = [ 0, 0, 10, 0 ]
        options.plotOptions.series.marker.enabled = true
        options.xAxis.labels.rotation = 0
        options.legend.margin = 15

        return options
    },

    state: () => ({
        target: null
    }),

    actions: {
        setTarget(target) {
            this.target = target

            this.series[0] = {
                datasetId: target.id,
                datasetFamily: target.family,
                datasetName: target.title
            }
        }
    }
})

export default useTargetsTargetInformationTargetAudienceAnalysisStore
