<template>
    <ui-modal name="report-edit" :title="report.id ? 'Edit a Report' : 'Create a new Report'" :max-width="520" :max-height="780" @opened="opened">
        <ui-form :store="modalsReportEditStore" v-slot="{ inputs, submitting, errors }">
            <label for="name" class="block text-sm font-medium text-gray-700">
                Name
            </label>
            <div class="mt-2">
                <ui-input type="text" id="name" v-model="inputs.name" :errors="errors.name" ref="name"></ui-input>
            </div>

            <label for="description" class="block text-sm font-medium text-gray-700 mt-4">
                Description
            </label>
            <div class="mt-2">
                <ui-input type="textarea" id="description" rows="2" v-model="inputs.description" :errors="errors.description"></ui-input>
            </div>

            <label for="template" class="block text-sm font-medium text-gray-700 mt-4">
                Template
            </label>
            <div class="mt-2 flex flex-wrap items-center justify-center">
                <div class="h-24 w-24 mx-2 rounded-md border border-2 border-gray-200" :class="{ 'border-gerulata-blue-600': inputs.configuration.height === 1123 && inputs.configuration.width === 794 }" @click="setReportDimensions(1123, 794)">
                    <img class="h-full w-full" src="@/assets/icons/dashboards/report-a4.svg" alt="Report A4">
                    <p class="mt-2 text-sm text-center text-gray-700">A4</p>
                </div>

                <div class="h-24 w-24 mx-2 rounded-md border border-2 border-gray-200" :class="{ 'border-gerulata-blue-600': inputs.configuration.height === 794 && inputs.configuration.width === 1123 }" @click="setReportDimensions(794, 1123)">
                    <img class="h-full w-full" src="@/assets/icons/dashboards/report-a4-horizontal.svg" alt="Report A4 Horizontal">
                    <p class="mt-2 text-sm text-center text-gray-700">A4 Horizontal</p>
                </div>

                <ui-dropdown width="w-64">
                    <template v-slot:trigger>
                        <div class="h-24 w-24 mx-2 rounded-md border border-2 border-gray-200" :class="{ 'border-gerulata-blue-600': ! [1123, 794].includes(inputs.configuration.height) || ! [794, 1123].includes(inputs.configuration.width) }">
                            <img class="h-full w-full" src="@/assets/icons/dashboards/report-custom.svg" alt="Report Custom">
                            <p class="mt-2 text-sm text-center text-gray-700">Custom</p>
                        </div>
                    </template>

                    <template v-slot:content="{ close }">
                        <div class="px-4 py-3">
                            <div class="flex items-center space-x-3">
                                <div>
                                    <label class="block text-gray-400 text-xs font-semibold uppercase" for="height">
                                        Height
                                    </label>
                                    <div class="flex items-center space-x-1">
                                        <ui-input type="number" id="height" v-model.number="inputs.configuration.height" ref="height"></ui-input>
                                        <p>px</p>
                                    </div>
                                </div>
                                <div>
                                    <label class="block text-gray-400 text-xs font-semibold uppercase" for="width">
                                        Width
                                    </label>
                                    <div class="flex items-center space-x-1">
                                        <ui-input type="number" id="width" v-model.number="inputs.configuration.width" ref="width"></ui-input>
                                        <p>px</p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-3">
                                <ui-button @click.native="close" color="blue">
                                    Apply
                                </ui-button>
                            </div>
                        </div>
                    </template>
                </ui-dropdown>
            </div>

            <div class="flex items-start justify-between mt-12">
                <div>
                    <label for="name" class="block text-sm font-medium text-gray-700">
                        Header settings
                    </label>
                    <div class="mt-3 space-y-1">
                        <div class="flex items-center space-x-2 font-semibold">
                            <input type="checkbox" id="displayHeader" v-model="inputs.configuration.displayHeader" ref="displayHeader">
                            <p>Display header</p>
                        </div>

                        <div class="flex items-center space-x-2">
                            <input type="checkbox" id="displayHeaderImage" v-model="inputs.configuration.displayHeaderImage" ref="displayHeaderImage">
                            <p>Display header image</p>
                        </div>

                        <div class="flex items-center space-x-2">
                            <input type="checkbox" id="displayHeaderTitle" v-model="inputs.configuration.displayHeaderTitle" ref="displayHeaderTitle">
                            <p>Display header title</p>
                        </div>

                        <div class="flex items-center space-x-2">
                            <input type="checkbox" id="displayHeaderSideText" v-model="inputs.configuration.displayHeaderSideText" ref="displayHeaderSideText">
                            <p>Display header side text</p>
                        </div>
                    </div>
                </div>

                <div>
                    <label for="name" class="block text-sm font-medium text-gray-700">
                        Footer settings
                    </label>
                    <div class="mt-3 space-y-1">
                        <div class="flex items-center space-x-2 font-semibold">
                            <input type="checkbox" id="displayFooter" v-model="inputs.configuration.displayFooter" ref="displayFooter">
                            <p>Display footer</p>
                        </div>

                        <div class="flex items-center space-x-2">
                            <input type="checkbox" id="displayFooterPagination" v-model="inputs.configuration.displayFooterPagination" ref="displayFooterPagination">
                            <p>Display footer pagination</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteReport" v-if="inputs.report.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="inputs.report.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.report.id ? 'Update' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useModalsReportEditStore from '@/stores/dashboards/modals/report-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useModalsReportEditStore),

        report() { return this.modalsReportEditStore.inputs?.report }
    },

    methods: {
        ...mapActions(useModalsReportEditStore, {
            setReportDimensions: 'setDimensions',

            deleteReport: 'delete'
        }),

        opened() { this.$refs.name.focus() }
    }
}
</script>
