import { defineContentStreamStore } from '../reusable/stream'
import { useRoute, useRouter } from '@/helpers'

import filter from 'just-filter'

export const useContentStreamStore = defineContentStreamStore({
    id: 'contentIndexStream',

    showsResultsBar: false,

    searchContext: 'All Content',

    actions: {
        triggerQueryChange() {
            if (this.pauseTriggeringUpdates) return
            if (! this.appliedFilters.length) return

            if (! this.filter('date').value) {
                this.withoutTriggeringUpdates(() => {
                    this.filter('date').set({ type: 'past', date: { past: '1', unit: 'months' } })
                })
            }

            let query = filter(useRoute().query, key => {
                return ! (key.match(/filter\[.+?\]/) || [ 'perspective', 'sorting' ].includes(key))
            })

            useRouter().push(
                {
                    name: 'content.perspectives.perspective',
                    params: { perspectiveId: 'new' },
                    query: { ...query, ...this.toQuery() }
                },
                null,
                error => { if (error.name != 'NavigationDuplicated') throw error }
            )
        }
    }
})

export default useContentStreamStore
