import api from '@/admin/api'

import useLicenseSettingsFormStore from '@/admin/stores/accounts/license-form'

import { defineStore } from 'pinia'

export const useLicenseStore = defineStore({
    id: 'license',

    state: () => ({
        license: null,
        licenseLoaded: false,
        licensePromise: null,

        billingCustomers: [],
        billingItems: [],
        billingTaxTypes: []
    }),

    actions : {
        async initialize(organizationId, license = null, force = false) {
            Promise.all([
                api.route('admin accounts billing customers').get().json(),
                api.route('admin accounts billing items').get().json(),
                api.route('admin accounts billing tax-types').get().json()
            ]).then(values => {
                this.billingCustomers = values[0].data
                this.billingItems = values[1].data
                this.billingTaxTypes = values[2].data
            })

            if (license && license !== 'new') {
                await this.load(organizationId, license, force)
            } else {
                this.license = {}
                useLicenseSettingsFormStore().$reset()
                useLicenseSettingsFormStore().organizationId = organizationId
                useLicenseSettingsFormStore().inputs.name = 'New License'
            }
        },

        async load(organizationId, licenseId, force = false) {
            if (this.licenseLoaded && ! force) return Promise.resolve()
            if (this.licensePromise) return this.licensePromise

            this.$reset()

            useLicenseSettingsFormStore().$reset()

            return this.licensePromise = api.route('admin accounts organizations licenses details', { organizationId: organizationId, licenseId: licenseId } ).get().json(res => {
                this.license = res.data
                this.licenseLoaded = true
                this.licensePromise = null

                useLicenseSettingsFormStore().organizationId = organizationId
                useLicenseSettingsFormStore().licenseId = licenseId

                useLicenseSettingsFormStore().inputs.name = this.license.name
                useLicenseSettingsFormStore().inputs.validFrom = new Date(this.license.validFrom)
                useLicenseSettingsFormStore().inputs.validTo = new Date(this.license.validTo)

                useLicenseSettingsFormStore().inputs.billingType = this.license.preferences.billingType
                useLicenseSettingsFormStore().inputs.billing = this.license.preferences.billing
                useLicenseSettingsFormStore().inputs.features = this.license.preferences.features
                useLicenseSettingsFormStore().inputs.budgets = this.license.preferences.budgets
            })
        }
    }
})

export default useLicenseStore
