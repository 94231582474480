import useWorkspacesStore from '@/admin/stores/accounts/workspaces'
import useModalsWorkspaceCreateStore from '@/admin/stores/accounts/modals/workspace-create'

import api from "@/admin/api"

import { defineForm } from "@/stores/reusable/form"
import { nextTick } from 'vue'

export const useWorkspaceSettingsFormStore = defineForm({
    id: 'workspaceSettingsForm',

    state: {
        organizationId: null,
        workspaceId: null
    },

    inputs: () => ({
        name: null,
        ownerName: null,
        ownerEmail: null
    }),

    submitRequest() {
        return api.route(this.workspaceId
            ? 'admin accounts organizations workspaces update'
            : 'admin accounts organizations workspaces store',
            { organizationId: this.organizationId, workspaceId: this.workspaceId }
        ).formData({
            _method: this.workspaceId ? 'put' : 'post',
            name: this.inputs.name,
            owner_name: this.inputs.ownerName,
            owner_email: this.inputs.ownerEmail
        }).post()
    },

    async onResponse(res) {
        useModalsWorkspaceCreateStore().cancel()

        await useWorkspacesStore().load(this.organizationId, true)

        nextTick(() => {
            window.scrollTo(0, 0);
        })
    }
})

export default useWorkspaceSettingsFormStore
