<template>
    <ui-modal name="targets-list-duplicate" title="Duplicate a List of sources" @opened="opened" :scrollable="false">
        <ui-form :store="targetsModalsListDuplicateStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-800 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <p class="text-sm text-gray-700 mt-2">
                The created duplicate will be freely editable to suit your needs.
            </p>

            <div class="flex justify-between space-x-4 mt-8">
                <div class="flex items-center">
                    <label for="workspace" class="inline-block text-sm text-gray-800 mr-1">This list will be duplicated into workspace</label>
                    <ui-dropdown class="inline-block ">
                        <template v-slot:trigger>
                            <span class="p-2 h-7 text-sm font-medium leading-4 bg-gray-50 text-gray-900 rounded-sm" v-if="inputs.workspace">
                                {{inputs.workspace.name}}
                                <ui-icon name="chevron-down" class="text-lg text-gray-700"></ui-icon>
                            </span>
                        </template>

                        <template v-slot:content>
                            <ui-dropdown-link :selected="workspace.id == inputs.workspace.id" @click="inputs.workspace = workspace" :key="workspace.id" v-for="workspace in workspaces">
                                <div class="inline-flex items-center pt-1">
                                    <img class="h-6 w-6 rounded-sm shadow-sm object-cover" :src="workspace.avatarUrl">
                                    <div class="ml-2">
                                        {{workspace.name}}
                                    </div>
                                </div>
                            </ui-dropdown-link>
                        </template>
                    </ui-dropdown>
                </div>
                <ui-button type="submit" size="lg" :color="inputs.list.id ? 'blue' : 'green'" :disabled="submitting">
                    Duplicate
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useTargetsModalsListDuplicateStore from '@/stores/targets/modals/list-duplicate'
import useMyWorkspacesStore from '@/stores/me/workspaces'

import { mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useTargetsModalsListDuplicateStore),
        ...mapState(useMyWorkspacesStore, {
            workspaces: 'items'
        })
    },

    methods: {
        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
